import { IconButton, Tooltip, TooltipProps } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { pdf } from '@react-pdf/renderer';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { saveAs } from 'file-saver';
import React from 'react';

interface Props {
  disabled?: boolean;
  testId: string;
  title: string;
  placement: TooltipProps['placement'];
  document: React.ReactElement | null;
  fileName: string;
}

export const DownloadPDFButtonIcon = ({
  disabled = false,
  testId,
  title,
  placement,
  document,
  fileName
}: Props): JSX.Element => {
  /** In the event of reported performance issues: see the C10R Bad Scenario Mitigation Musings doc in google drive */
  const disableDownload = disabled || !document;
  const lazyDownloadLink = async (): Promise<void> => {
    if (document) {
      const generatedDoc = pdf(document);
      try {
        const blob = await generatedDoc.toBlob();
        saveAs(blob, `${fileName}.pdf`);
      } catch {
        snackbarState({
          severity: 'error',
          title: 'Unable to create PDF',
          message:
            'Please refresh and try again. If problem persists contact Llama Pod',
          type: 'generic'
        });
      }
    }
  };

  return (
    <Tooltip
      data-testid={`${testId}Tooltip`}
      title={disableDownload ? 'Download disabled' : title}
      placement={placement}
    >
      <span>
        <IconButton
          disabled={disableDownload}
          aria-label="Download PDF"
          data-testid={testId}
          onClick={lazyDownloadLink}
        >
          <GetAppIcon
            color={disableDownload ? 'disabled' : 'inherit'}
            htmlColor="rgba(0,0,0,0.7)"
          />
        </IconButton>
      </span>
    </Tooltip>
  );
};
