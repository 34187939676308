import { Typography } from '@material-ui/core';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { useShiftableClick } from 'hooks/useShiftableClick';
import React from 'react';
import styled from 'styled-components';
import { ProgressionTypes } from '../../../utils/activityPlan';
import { CopyToClipboardButtonIcon } from '../../CopyToClipboardButtonIcon';
import { ProgressionHeader } from '../ProgressionHeader';

export interface AcuteWeekHeaderProps {
  id: string;
  week: number;
  current?: boolean | null;
  expanded: boolean;
  etSessionTime: number;
  canCopyExercises: boolean;
  onToggleCollapse: () => void;
  onCopyUniqueExercises: () => void;
  onCopyAllExercises: () => void;
}

const ChildWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChildTypography = styled(Typography)`
  margin-left: 0.3rem;
`;

export const AcuteWeekHeader = ({
  id,
  week,
  current,
  expanded,
  etSessionTime,
  canCopyExercises,
  onToggleCollapse,
  onCopyUniqueExercises,
  onCopyAllExercises
}: AcuteWeekHeaderProps): JSX.Element => {
  const handleCopyExercisesToClipboard = useShiftableClick({
    onClick: onCopyAllExercises,
    onShiftClick: onCopyUniqueExercises
  });

  return (
    <ProgressionHeader
      id={id}
      title={`${ProgressionTypes.acute} ${week}`}
      current={current}
      expanded={expanded}
      onToggleCollapse={onToggleCollapse}
      centerProps={{ gap: 4, justifyContent: 'flex-end' }}
    >
      <ChildWrapper>
        <AvTimerIcon />
        <ChildTypography variant="caption">
          {`${etSessionTime} minutes`}
        </ChildTypography>
      </ChildWrapper>
      <CopyToClipboardButtonIcon
        onClick={handleCopyExercisesToClipboard}
        title={`Copy ${ProgressionTypes.acute} ${week} exercises to clipboard`}
        testId="copyWeekButton"
        disabled={!canCopyExercises}
      />
    </ProgressionHeader>
  );
};
