import { Typography } from '@material-ui/core';
import { green, orange } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Circle from '@material-ui/icons/Lens';
import React from 'react';
import styled from 'styled-components';

const withinTargetRange = (actual: number, target: number): boolean => {
  const theFivePercent = target * 0.05;
  return actual >= target - theFivePercent && actual <= target + theFivePercent;
};

const TargetPointsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const TargetPointsTypography = styled(Typography)`
  margin-left: 0.3rem;
`;

export interface TargetPointsProps {
  actual: number;
  target: number;
}

export const TargetPoints = ({
  actual,
  target
}: TargetPointsProps): JSX.Element => (
  <TargetPointsWrapper data-testid="targetPoints">
    {withinTargetRange(actual, target) ? (
      <CheckCircleIcon
        data-testid="greenCircleIcon"
        style={{ color: green[500] }}
      />
    ) : (
      <Circle data-testid="orangeCircleIcon" style={{ color: orange[500] }} />
    )}
    <TargetPointsTypography variant="overline">
      {`${actual}/${target} TP`}
    </TargetPointsTypography>
  </TargetPointsWrapper>
);
