import { InputAdornment, TextField } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import { FormikHandlers } from 'formik';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

interface Props {
  searchTerm: string;
  handleChange: FormikHandlers['handleChange'];
  disabled?: boolean;
  autoFocus?: boolean;
  error?: boolean;
  errorMessage?: string;
  name?: string;
  placeholder?: string;
}

const SearchBar = styled(TextField)`
  ${({ theme }) => `
    height: 36px;
    width: 408px;
    color: ${theme.palette.grey[600]};
    margin: 0;
  `}
`;

const SearchAdornment = styled(InputAdornment)`
  color: ${({ theme }) => theme.palette.grey[600]};
`;

export default function Search({
  searchTerm,
  handleChange,
  disabled = false,
  autoFocus = false,
  error = false,
  errorMessage,
  name = 'search',
  placeholder = 'Search'
}: Props): JSX.Element {
  const searchBarRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (autoFocus) {
      searchBarRef?.current?.focus();
    }
  }, [autoFocus]);

  return (
    <SearchBar
      inputRef={searchBarRef}
      value={searchTerm}
      onChange={handleChange}
      error={error}
      name={name}
      id={`${name}Input`}
      aria-label={`${name} input`}
      placeholder={placeholder}
      variant="outlined"
      margin="dense"
      disabled={disabled}
      data-testid="searchInput"
      InputProps={{
        startAdornment: (
          <SearchAdornment position="start">
            <SearchIcon />
          </SearchAdornment>
        )
      }}
      helperText={errorMessage || ' '}
      FormHelperTextProps={{ error }}
      inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
    />
  );
}
