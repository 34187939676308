import { Link, RouteComponentProps } from '@reach/router';
import { Body } from 'components/layout/Body';
import { PageCard } from 'components/PageCard';
import React from 'react';

const ProgramsSwitchboard: React.FC<RouteComponentProps> = (): JSX.Element => {
  return (
    <Body pageTitle="Programs" testId="programsSwitchboardPage">
      <PageCard>
        <Link to="/programs/acute">Acute Templates Index Page</Link>
        <Link to="/programs/chronic">Chronic Configurations Index Page</Link>
        <Link to="/programs/perisurgical">
          Perisurgical Templates Index Page
        </Link>
      </PageCard>
    </Body>
  );
};

export default ProgramsSwitchboard;
