import { Button, Fade, Modal, Typography } from '@material-ui/core';
import React from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import styled from 'styled-components';

export interface DiffViewerModalProps {
  activityPlanName: string;
  oldValue: string;
  newValue: string;
  isOpen: boolean;
  onCloseDiffViewer: () => void;
}

const CenterModal = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
`;

const Content = styled.main`
  align-self: center;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.common.white};
  padding: ${({ theme }) => theme.spacing(6)};
  max-height: 600px;
`;

const DiffWrapper = styled.div`
  overflow-y: scroll;
`;

const Title = styled(Typography)`
  padding-bottom: ${({ theme }) => theme.spacing(6)};
`;

const ConfirmButton = styled(Button)`
  margin-top: ${({ theme }) => theme.spacing(4)};
  align-self: flex-end;
`;

export const DiffViewerModal = ({
  activityPlanName,
  oldValue,
  newValue,
  isOpen,
  onCloseDiffViewer
}: DiffViewerModalProps): JSX.Element => {
  return (
    <Modal
      aria-labelledby="modalTitle"
      open={isOpen}
      onClose={onCloseDiffViewer}
      closeAfterTransition
    >
      <Fade in={isOpen}>
        <CenterModal>
          <Content>
            <Title
              id="modalTitle"
              variant="h6"
            >{`${activityPlanName} changes`}</Title>
            <DiffWrapper>
              <ReactDiffViewer
                oldValue={oldValue}
                newValue={newValue}
                splitView={true}
                hideLineNumbers={true}
                compareMethod={DiffMethod.WORDS}
              />
            </DiffWrapper>
            <ConfirmButton onClick={onCloseDiffViewer}>OK</ConfirmButton>
          </Content>
        </CenterModal>
      </Fade>
    </Modal>
  );
};
