import { Progression } from 'types/activityPlan';
import { DraftProgression } from 'types/draftActivityPlan';
import { doProgressionsHaveExercises } from 'utils/activityPlan';
import {
  copyAllExercises,
  copyUniqueExercises
} from 'utils/copyExercisesToClipboard';
import { useShiftableClick } from './useShiftableClick';

interface UseCopyPlanToClipboardRes {
  handleCopyExercisesToClipboard: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  canCopyToClipboard: boolean;
}

export const useCopyPlanToClipboard = <
  T extends DraftProgression | Progression
>(
  progressions: T[] = []
): UseCopyPlanToClipboardRes => ({
  canCopyToClipboard: doProgressionsHaveExercises(progressions),
  handleCopyExercisesToClipboard: useShiftableClick({
    onClick: () =>
      copyAllExercises(
        progressions,
        'All activity plan exercises copied to clipboard'
      ),
    onShiftClick: () =>
      copyUniqueExercises(
        progressions,
        'All activity plan exercises copied to clipboard'
      )
  })
});
