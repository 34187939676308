import React from 'react';
import { RepType } from 'types';
import { DraggableExercise } from '../DraggableExercise';
import { EditableExerciseChip } from '../EditableExerciseChip';

export interface EditableChronicExerciseProps {
  index: number;
  id: string;
  name: string;
  anatomicalName?: string | null;
  pointsPerRep: number;
  reps: number;
  holdTime: number;
  thumbnailUrl?: string;
  repType?: RepType | null;
  substitutionText?: string;
  onPointsPerRepChange: (value: number) => void;
  onRepsChange: (value: number) => void;
  onHoldTimeChange: (value: number) => void;
  onDelete: (name: string) => void;
  onDuplicate: (name: string) => void;
  onEdit: (name: string) => void;
  onInvalidPointsPerRep: (value: number, reasons: string[]) => void;
  onInvalidReps: (value: number, reasons: string[]) => void;
  onInvalidHold: (value: number, reasons: string[]) => void;
}

const validations = [
  (value: number) => ({
    isValid: value > 0,
    reason: 'must be greater than 0'
  }),
  (value: number) => ({
    isValid: value < 100,
    reason: 'must be less than 100'
  })
];

export const EditableChronicExercise = ({
  index,
  id,
  name,
  anatomicalName,
  pointsPerRep,
  reps,
  holdTime,
  thumbnailUrl,
  repType,
  substitutionText,
  onPointsPerRepChange,
  onRepsChange,
  onHoldTimeChange,
  onDelete,
  onDuplicate,
  onEdit,
  onInvalidPointsPerRep,
  onInvalidReps,
  onInvalidHold
}: EditableChronicExerciseProps): JSX.Element => {
  return (
    <DraggableExercise
      index={index}
      name={name}
      anatomicalName={anatomicalName}
      id={id}
      thumbnailUrl={thumbnailUrl}
      substitutionText={substitutionText}
      menuItems={[
        {
          text: 'Edit',
          onClick: (_, name) => onEdit(name)
        },
        {
          text: 'Duplicate',
          onClick: (_, name) => onDuplicate(name)
        },
        {
          text: 'Delete',
          onClick: (_, name) => onDelete(name)
        }
      ]}
    >
      <EditableExerciseChip
        label="pts/rep"
        value={pointsPerRep}
        onEdit={onPointsPerRepChange}
        onInvalid={onInvalidPointsPerRep}
        validations={validations}
      />
      <EditableExerciseChip
        repType={repType}
        label="reps"
        value={reps}
        onEdit={onRepsChange}
        onInvalid={onInvalidReps}
        validations={validations}
      />
      <EditableExerciseChip
        label="sec hold"
        value={holdTime / 1000}
        onEdit={value => onHoldTimeChange(value * 1000)}
        onInvalid={onInvalidHold}
        validations={validations}
      />
    </DraggableExercise>
  );
};
