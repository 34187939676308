import { ApolloClient, ApolloError, useQuery } from '@apollo/client';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import {
  GetAllAcuteTemplatesDocument,
  GetAllAcuteTemplatesQuery,
  GetAllAcuteTemplatesQueryVariables
} from 'types';
import { Programs } from 'utils/programConstants';

type UseAcuteTemplateQuery = {
  loading: boolean;
  error: ApolloError | undefined;
  data: GetAllAcuteTemplatesQuery | undefined;
  client: ApolloClient<any>;
};
export const useAcuteTemplateLibrary = (
  page: number,
  count: number
): UseAcuteTemplateQuery => {
  const { defaultErrorHandler } = useDefaultErrorHandler();

  const { loading, error, data, client } = useQuery<
    GetAllAcuteTemplatesQuery,
    GetAllAcuteTemplatesQueryVariables
  >(GetAllAcuteTemplatesDocument, {
    onError: defaultErrorHandler,
    variables: {
      activityPlansGetInput: {
        program: Programs.ACUTE,
        page,
        count
      }
    }
  });

  return {
    loading,
    error,
    data,
    client
  };
};
