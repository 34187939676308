import { useLazyQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { navigate, RouteComponentProps } from '@reach/router';
import { Body } from 'components/layout/Body';
import { PageCard } from 'components/PageCard';
import Search from 'components/Search';
import { Form as FormikForm, Formik, FormikHelpers, FormikProps } from 'formik';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GetUserDocument, GetUserQuery, GetUserQueryVariables } from 'types';
import { User } from 'types/user';
import { number, NumberSchema, object } from 'yup';

interface InitialValues {
  searchUsers: string;
}

interface ValidationSchema {
  searchUsers: NumberSchema<number | undefined, Record<string, unknown>>;
}

const initialValues = {
  searchUsers: ''
};

const validationSchema: ValidationSchema = {
  searchUsers: number()
    .typeError('Field must be a number')
    .required()
    .positive()
    .integer()
};

const SearchWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const UsersIndex: React.FC<RouteComponentProps> = (): JSX.Element => {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const [searchResults, setSearchResults] = useState<User[] | null>([]);
  const [getUser, { loading, data, error }] = useLazyQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GetUserDocument, {
    onError: defaultErrorHandler
  });
  const [isSearched, setIsSearched] = useState<boolean>(false);

  const handleUserLookup = (values: InitialValues): void => {
    setIsSearched(true);
    getUser({ variables: { userId: parseInt(values['searchUsers']) } });
  };

  useEffect(() => {
    if (error) {
      setSearchResults(null);
      return;
    }

    if (data?.userGet?.id) {
      navigate(`/users/${data.userGet.id}`);
    }
  }, [data, error]);

  const handleKeyPress =
    (event: React.KeyboardEvent) =>
    async (
      values: InitialValues,
      formHelpers: FormikHelpers<InitialValues>
    ): Promise<void> => {
      setIsSearched(false);
      event.persist();
      await formHelpers.validateForm(values);
    };

  return (
    <Body pageTitle="Search users">
      <PageCard>
        <Formik
          initialValues={initialValues}
          validationSchema={object().shape(validationSchema)}
          onSubmit={handleUserLookup}
        >
          {({
            handleChange,
            values,
            errors,
            ...rest
          }: FormikProps<InitialValues>) => {
            return (
              <FormikForm
                onKeyDown={event => handleKeyPress(event)(values, { ...rest })}
              >
                <SearchWrapper>
                  <Search
                    autoFocus
                    searchTerm={values.searchUsers}
                    handleChange={handleChange}
                    error={Boolean(errors.searchUsers)}
                    errorMessage={errors.searchUsers}
                    name="searchUsers"
                    placeholder="Search patients by User ID"
                  />
                </SearchWrapper>
                {isSearched &&
                  !loading &&
                  !searchResults &&
                  !errors.searchUsers && (
                    <Typography
                      variant="body1"
                      data-testid="userSearchErrorMessage"
                    >{`No patients found with id #${values.searchUsers}`}</Typography>
                  )}
              </FormikForm>
            );
          }}
        </Formik>
      </PageCard>
    </Body>
  );
};

export default UsersIndex;
