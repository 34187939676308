import { Typography } from '@material-ui/core';
import { Compare } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

export interface BetaProductionIndicatorProps {
  isBeta?: boolean;
}

const Span = styled(Typography)`
  color: ${({ theme }) => theme.palette.grey[500]};
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
`;

export const BetaProductionIndicator = ({
  isBeta
}: BetaProductionIndicatorProps): JSX.Element => (
  <Span>
    <Compare />
    {isBeta ? (
      <Typography component={'span'} data-testid="betaConfig">
        Beta Config
      </Typography>
    ) : (
      <Typography data-testid="productionConfig">Production Config</Typography>
    )}
  </Span>
);
