import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { setUserAuthState } from 'apollo/reactive-variables/userAuthState';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
  addRefreshBreadcrumb,
  addSessionBreadcrumb,
  Severity
} from 'utils/sentry';
import { LocalStorage, LocalStorageKey } from '../utils/localStorage';
import { useLogout } from './useLogout';

enum Buffer {
  ONE_MINUTE = 60000,
  // Only to be used for manual testing purposes
  EVERY_TEN_SECONDS = 890000,
  TWENTY_SECONDS = 20000
}

// Pivot between a UNIX timestamp and JS Time
const TIME_CONVERSION = 1000;
const expiryToTime = (expiry: number): number => expiry * TIME_CONVERSION;

const msUntilExpiry = (expiry: number): number =>
  expiryToTime(expiry) - Date.now();

const secondsUntilExpiry = (expiry: number): number =>
  Math.floor(msUntilExpiry(expiry) / TIME_CONVERSION);

const calculateDelay = (expiry: number): number =>
  msUntilExpiry(expiry) - Buffer.ONE_MINUTE;

const isPast = (time: number, threshold = 0): boolean =>
  Date.now() - time > threshold;

const isExpired = (expiry: number | null): boolean =>
  expiry === null || isPast(expiryToTime(expiry));

let countdownInterval: null | number = null;

const stopCountdownInterval = (): void => {
  if (countdownInterval) {
    clearInterval(countdownInterval);
    countdownInterval = null;
  }
};

const startCountdownInterval = (
  refreshExpiry: number,
  handleExpiredSessionLogout: () => void
): void => {
  const secondsLeft = secondsUntilExpiry(refreshExpiry);

  snackbarState({
    type: 'idle',
    secondsLeft
  });

  addSessionBreadcrumb({
    message: 'User session will soon expire',
    level: Severity.Info,
    data: { secondsLeft }
  });

  countdownInterval = setInterval(() => {
    if (isExpired(refreshExpiry)) {
      addSessionBreadcrumb({
        message: 'User session expired',
        level: Severity.Info
      });
      snackbarState({ type: 'expired' });
      stopCountdownInterval();
      handleExpiredSessionLogout();
    }
  }, 1000);
};

interface RefreshLoopAPI {
  start: (accessTokenExpiry: number, refreshTokenExpiry: number) => void;
  extendSession: () => void;
}

export const useRefreshLoop = ({
  refresh,
  stopRefreshingIdleThreshold,
  aboutToExpireIdleThreshold
}: {
  refresh: () => Promise<boolean>;
  stopRefreshingIdleThreshold: number;
  aboutToExpireIdleThreshold: number;
}): RefreshLoopAPI => {
  const { handleExpiredSessionLogout } = useLogout();
  // Input refresh might change over time. This prevents duplicates.
  const refreshFn = useRef<typeof refresh>(refresh);
  useEffect(() => {
    refreshFn.current = refresh;
  }, [refresh]);

  const [refreshExpiry, setRefreshExpiry] = useState<number | null>(null);

  // Light idle - blocks accessToken refreshes
  const [isLightIdle, setIsLightIdle] = useState<boolean>(false);
  // Hard idle - display prompt and allow refreshToken to expire
  const [isHardIdle, setIsHardIdle] = useState<boolean>(false);
  useEffect(() => {
    if (refreshExpiry) {
      if (isHardIdle && countdownInterval === null) {
        startCountdownInterval(refreshExpiry, handleExpiredSessionLogout);
      } else {
        stopCountdownInterval();
      }
    }
  }, [handleExpiredSessionLogout, isHardIdle, refreshExpiry]);

  const { start: startTimer, getLastActiveTime } = useIdleTimer({
    timeout: aboutToExpireIdleThreshold,
    stopOnIdle: true,
    crossTab: { emitOnAllTabs: true },
    startManually: true,
    startOnMount: false,
    onIdle: () => {
      addSessionBreadcrumb({
        message: 'User went idle',
        level: Severity.Info
      });
      setIsHardIdle(true);
    },
    onAction: () => {
      // Short circuit auto-refresh - only refresh if user accepts prompt to continue
      if (isHardIdle) {
        return;
      }

      // If able, automatically refresh tokens
      if (isLightIdle && !isExpired(refreshExpiry)) {
        addSessionBreadcrumb({
          message: 'User went from idle to active',
          level: Severity.Info
        });
        addRefreshBreadcrumb({
          level: Severity.Debug,
          message: 'Refresh attempt triggered by user becoming active',
          data: { refreshExpiry }
        });
        refreshFn.current();
      }
      setIsLightIdle(false);
    },
    debounce: 1000,
    eventsThrottle: 500
  });

  const start = useCallback(
    (accessExpiry: number, refreshExpiry: number): void => {
      const accessExpiryDelayMs = calculateDelay(accessExpiry);
      /**
       * In the event that a user's session expires and they
       * log back in, we need to reset these states when
       * initializing the session again. Without doing so
       * these states will not reset, making the countdown
       * snackbar visible when they log back in.
       */
      setIsLightIdle(false);
      setIsHardIdle(false);
      snackbarState(null);

      addSessionBreadcrumb({
        level: Severity.Info,
        message: 'Starting user session',
        data: { accessExpiry, refreshExpiry, accessExpiryDelayMs }
      });

      setRefreshExpiry(refreshExpiry);
      startTimer();

      setUserAuthState({
        refreshTimeoutId: setTimeout(() => {
          const lastActiveTime = getLastActiveTime();
          if (!isPast(lastActiveTime, stopRefreshingIdleThreshold)) {
            addRefreshBreadcrumb({
              level: Severity.Debug,
              message: 'Refresh attempt triggered by the refresh loop',
              data: {
                accessExpiry,
                refreshExpiry
              }
            });
            refreshFn.current();
          } else {
            addSessionBreadcrumb({
              level: Severity.Info,
              message: 'User is inactive',
              data: { accessExpiry, refreshExpiry, lastActiveTime }
            });

            setIsLightIdle(true);
          }
        }, accessExpiryDelayMs)
      });
    },
    [getLastActiveTime, startTimer, stopRefreshingIdleThreshold]
  );

  const extendSession = useCallback(async () => {
    if (isHardIdle && !isExpired(refreshExpiry)) {
      addRefreshBreadcrumb({
        level: Severity.Debug,
        message: 'Refresh attempt triggered while extending session',
        data: { refreshExpiry }
      });
      await refreshFn.current();
      LocalStorage.set(LocalStorageKey.ExtendSession, Date.now());
      setIsLightIdle(false);
      setIsHardIdle(false);
    }
  }, [isHardIdle, refreshExpiry]);

  // Close the snackbar and reset idle when another tab runs `extendSession`
  useEffect(() => {
    window.addEventListener('storage', (event: StorageEvent): void => {
      if (event.key === LocalStorageKey.ExtendSession) {
        addRefreshBreadcrumb({
          level: Severity.Debug,
          message: 'Refresh attempt triggered by another tab extending session'
        });
        refreshFn.current();
        setIsLightIdle(false);
        setIsHardIdle(false);
        snackbarState(null);
      }
    });
  }, []);

  return { start, extendSession };
};
