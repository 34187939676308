import { MuiThemeProvider, StylesProvider } from '@material-ui/core/styles';
import React, { ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from 'styles/GlobalStyle';
import materialTheme from './materialTheme';

const ThemeProviders = ({ children }: { children: ReactNode }): JSX.Element => (
  <StylesProvider injectFirst>
    <MuiThemeProvider theme={materialTheme}>
      <ThemeProvider theme={materialTheme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  </StylesProvider>
);

export default ThemeProviders;
