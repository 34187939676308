import { SplitFactory } from '@splitsoftware/splitio-browserjs';
import { IClient } from '@splitsoftware/splitio-browserjs/types/splitio';
import { useEffect, useState } from 'react';
import { getSharedSplitIOKey } from '../config/getConfig';

class SharedFeatureFlagClient {
  private static clients: Record<string, IClient> = {};

  private static async getClient(userId: string): Promise<IClient> {
    if (SharedFeatureFlagClient.clients[userId]) {
      return SharedFeatureFlagClient.clients[userId];
    }

    const factory = SplitFactory({
      core: {
        authorizationKey: getSharedSplitIOKey(),
        key: userId
      }
    });

    const client = factory.client();
    await client.ready();

    SharedFeatureFlagClient.clients[userId] = client;
    return client;
  }

  public static getFeatureForUser(
    flag: string,
    userId: string
  ): Promise<boolean> {
    return SharedFeatureFlagClient.getClient(userId)
      .then(client => client.getTreatment(flag, { userId }))
      .then(treatment => treatment === 'on');
  }
}

export const useSharedFeatureFlag = (
  flag: string,
  userId?: string
): {
  isLoading: boolean;
  treatment: boolean | undefined;
  error: Error | null;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [treatment, setTreatment] = useState<boolean | undefined>(undefined);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    if (!userId) {
      setIsLoading(false);
      setTreatment(false);
    } else {
      setIsLoading(true);
      SharedFeatureFlagClient.getFeatureForUser(flag, userId)
        .then(_treatment => setTreatment(_treatment))
        .catch(e => setError(e))
        .finally(() => setIsLoading(false));
    }
  }, [flag, userId]);

  return { isLoading, treatment, error };
};
