import {
  useActivityPlan,
  useActivityPlanApi
} from 'providers/ActivityPlanProvider';
import { useDraftActivityPlanAPI } from 'providers/DraftActivityPlanProvider';
import { useCallback, useEffect, useState } from 'react';
import { ActivityPlan, ConfigSummary } from 'types/activityPlan';

interface UseConfigStreamsHandler {
  activityPlan?: ActivityPlan;
  loading: boolean;
  onLow: () => void;
  onMedium: () => void;
  onHigh: () => void;
}

/**
 * This hook defines the ChronicActivityPlanTabs handlers and
 * passes the current `activityPlan` and `loading` states
 * so that we don't have to import `useActivityPlan` to the
 * Editable/ChronicStreams components.
 */
export const useConfigStreamsHandler = (): UseConfigStreamsHandler => {
  const { setActivityPlan, setDiffActivityPlan } = useActivityPlanApi();
  const { activityPlan, chronicConfigs, diffConfigs, betaConfigs, loading } =
    useActivityPlan();
  const { forceAutosave } = useDraftActivityPlanAPI();
  const [lowUuid, setLowUuid] = useState<string>('');
  const [mediumUuid, setMediumUuid] = useState<string>('');
  const [highUuid, setHighUuid] = useState<string>('');
  const [lowDiffUuid, setLowDiffUuid] = useState<string>('');
  const [mediumDiffUuid, setMediumDiffUuid] = useState<string>('');
  const [highDiffUuid, setHighDiffUuid] = useState<string>('');

  const setStreamUuids = (
    configs: ConfigSummary[],
    diffConfigs?: ConfigSummary[]
  ): void => {
    configs.forEach(config => {
      if (config.uri.includes(':low')) {
        setLowUuid(config.uuid);
      } else if (config.uri.includes(':medium')) {
        setMediumUuid(config.uuid);
      } else if (config.uri.includes(':high')) {
        setHighUuid(config.uuid);
      }
    });

    if (diffConfigs) {
      diffConfigs.forEach(config => {
        if (config.uri.includes(':low')) {
          setLowDiffUuid(config.uuid);
        } else if (config.uri.includes(':medium')) {
          setMediumDiffUuid(config.uuid);
        } else if (config.uri.includes(':high')) {
          setHighDiffUuid(config.uuid);
        }
      });
    }
  };

  useEffect(() => {
    if (chronicConfigs) {
      setStreamUuids(chronicConfigs);
    } else if (betaConfigs && diffConfigs) {
      setStreamUuids(betaConfigs, diffConfigs);
    }
  }, [chronicConfigs, betaConfigs, diffConfigs]);

  const onLow = useCallback(async () => {
    await forceAutosave();
    setActivityPlan(lowUuid);
    if (diffConfigs) {
      setDiffActivityPlan(lowDiffUuid);
    }
  }, [
    lowUuid,
    lowDiffUuid,
    diffConfigs,
    setActivityPlan,
    setDiffActivityPlan,
    forceAutosave
  ]);

  const onMedium = useCallback(async () => {
    await forceAutosave();
    setActivityPlan(mediumUuid);
    if (diffConfigs) {
      setDiffActivityPlan(mediumDiffUuid);
    }
  }, [
    mediumUuid,
    mediumDiffUuid,
    diffConfigs,
    setActivityPlan,
    setDiffActivityPlan,
    forceAutosave
  ]);

  const onHigh = useCallback(async () => {
    await forceAutosave();
    setActivityPlan(highUuid);
    if (diffConfigs) {
      setDiffActivityPlan(highDiffUuid);
    }
  }, [
    highUuid,
    highDiffUuid,
    diffConfigs,
    setActivityPlan,
    setDiffActivityPlan,
    forceAutosave
  ]);

  return { activityPlan, loading, onLow, onMedium, onHigh };
};
