import { useQuery } from '@apollo/client';
import { Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import ChronicConfigsLibrary from 'components/ChronicConfigsLibrary';
import { Body } from 'components/layout/Body';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { PageCard } from 'components/PageCard';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  GetAllChronicConfigsDocument,
  GetAllChronicConfigsQuery,
  GetAllChronicConfigsQueryVariables
} from 'types';
import { formatConfigsForChronicLibrary } from 'utils/chronicConfigs';
import { Programs } from 'utils/programConstants';

const Library = styled(PageCard)`
  padding-top: 30px;
  min-height: calc(100vh - 188px);
`;

const NoConfigsWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(4)};
`;

const defaultPage = 0;
const defaultCount = 250;

const ChronicConfigurationsIndex: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const { defaultErrorHandler } = useDefaultErrorHandler();
    const { loading, error, data, client } = useQuery<
      GetAllChronicConfigsQuery,
      GetAllChronicConfigsQueryVariables
    >(GetAllChronicConfigsDocument, {
      onError: defaultErrorHandler,
      variables: {
        activityPlansGetInput: {
          program: Programs.CHRONIC,
          page: defaultPage,
          count: defaultCount
        }
      }
    });

    useEffect(() => {
      if (error) {
        snackbarState({
          type: 'generic',
          severity: 'error',
          title: 'Unable to load chronic configs',
          message: `Try again. Error: ${error.message}`
        });
        return;
      }
    }, [client, error]);

    return loading ? (
      <LoadingSpinner />
    ) : (
      <Body
        pageTitle="Chronic Configurations"
        testId="chronicConfigurationsIndex"
      >
        <Library>
          {data?.activityPlansGetAll?.activityPlans &&
          data?.activityPlansGetAll?.activityPlans?.length > 0 ? (
            <ChronicConfigsLibrary
              showSearch
              activityPlans={formatConfigsForChronicLibrary(
                data.activityPlansGetAll.activityPlans
              )}
            />
          ) : (
            <NoConfigsWrapper>
              <Typography variant="h6" component="h3">
                No configs found
              </Typography>
            </NoConfigsWrapper>
          )}
        </Library>
      </Body>
    );
  };

export default ChronicConfigurationsIndex;
