import {
  Chip as MuiChip,
  colors,
  IconButton,
  Tooltip,
  Typography
} from '@material-ui/core';
import { BuildRounded as BuildIcon } from '@material-ui/icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EllipsisMenu, { MenuItem } from 'components/EllipsisMenu';
import React from 'react';
import styled from 'styled-components';
import { removeWhitespace } from 'utils/helpers';
import { Center, CenterProps, Right, Row } from './threeColumnFlexRow';

export interface ProgressionHeaderProps {
  id: string;
  title: string;
  current?: boolean | null;
  children: React.ReactNode;
  menuItems?: MenuItem[];
  expanded: boolean;
  onToggleCollapse: () => void;
  centerProps?: Pick<CenterProps, 'gap' | 'justifyContent' | 'paddingRight'>;
  numEtSessions?: number;
  wasModifiedByAdmin?: boolean;
  etpeVersion?: string | null;
  height?: number;
}

const ColumnLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const HeaderRow = styled(props => <Row {...props} />)`
  ${({ height, theme }) => `
  height: ${height ?? 40}px;
  background-color: ${colors.grey[200]};
  font-color: ${theme.palette.text.primary};
  `};
`;

const Chip = styled(MuiChip)`
  justify-self: flex-start;
  background-color: ${colors.green[300]};
  color: ${({ theme }) => theme.palette.common.white};
`;

const ChipWrapper = styled.div`
  display: flex;
  padding-left: ${({ theme }) => theme.spacing(3)};
  align-items: center;
`;

const PlaylistChip = styled(MuiChip)`
  background-color: ${colors.grey[500]};
  padding: ${({ theme }) => theme.spacing(0, 1)};
  color: ${({ theme }) => theme.palette.common.white};
  height: 20px;
`;

const ETPEGeneratedChip = styled(MuiChip)`
  background-color: ${colors.green[400]};
  padding: ${({ theme }) => theme.spacing(0, 1)};
  color: ${({ theme }) => theme.palette.common.white};
  height: 20px;
`;

const playlistText = (numPlaylists: number): string =>
  numPlaylists === 1 ? `${numPlaylists} playlist` : `${numPlaylists} playlists`;

const Build = styled(BuildIcon)`
  color: ${colors.grey[500]};
`;

const TopRow = styled.div`
  display: flex;
`;

const BottomRow = styled.div`
  display: flex;
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacing(2)};
`;

/**
 * Base layout for a progression header
 */
export const ProgressionHeader = ({
  id,
  title,
  current,
  children,
  menuItems,
  expanded,
  onToggleCollapse,
  centerProps,
  numEtSessions,
  wasModifiedByAdmin,
  etpeVersion,
  height
}: ProgressionHeaderProps): JSX.Element => (
  <HeaderRow elevation={0} height={height} data-testid="progressionHeader">
    <IconButton
      aria-label={expanded ? 'Collapse' : 'Expand'}
      onClick={onToggleCollapse}
      data-testid="progressionHeaderChevron"
    >
      {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
    </IconButton>
    <ColumnLeft>
      <TopRow>
        <Typography variant="h6">{title}</Typography>
        {current ? (
          <ChipWrapper>
            <Chip size="small" label="Current" data-testid="currentWeek" />
          </ChipWrapper>
        ) : null}
      </TopRow>
      <BottomRow>
        {numEtSessions ? (
          <Tooltip
            data-testid="playlistTooltip"
            title={playlistText(numEtSessions)}
            placement="bottom"
          >
            <PlaylistChip
              aria-label={playlistText(numEtSessions)}
              size="small"
              label={numEtSessions}
              data-testid="playlistChip"
            />
          </Tooltip>
        ) : null}
        {wasModifiedByAdmin ? (
          <Tooltip
            data-testid="customTooltip"
            title="Custom"
            placement="bottom"
          >
            <Build data-testid="customIcon" />
          </Tooltip>
        ) : null}
        {etpeVersion ? (
          <ETPEGeneratedChip size="small" label="ETPE Generated" />
        ) : null}
      </BottomRow>
    </ColumnLeft>
    <Center {...centerProps}>{children}</Center>
    <Right>
      {menuItems && menuItems.length > 0 ? (
        <EllipsisMenu
          ariaLabel={`${title} menu`}
          items={menuItems}
          popupId={`${removeWhitespace(title)}${id}Menu`}
          testId={`${removeWhitespace(title)}${id}MenuButton`}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: -10,
            horizontal: 12
          }}
        />
      ) : null}
    </Right>
  </HeaderRow>
);
