import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  concat,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject
} from '@apollo/client';
import { withScalars } from 'apollo-link-scalars';
import { getBffHost } from 'config/getConfig';
import { buildClientSchema, IntrospectionQuery } from 'graphql';
import introspectionResult from 'introspection.json';
import React, { ReactNode } from 'react';
import { userAuthState } from '../reactive-variables/userAuthState';

const schema = buildClientSchema(
  introspectionResult as unknown as IntrospectionQuery
);
const httpLink = new HttpLink({ uri: getBffHost(), credentials: 'include' });

const scalarsLink = withScalars({
  schema,
  typesMap: {
    DateTime: {
      serialize: (parsed: Date | null): string | null =>
        parsed ? parsed.toISOString() : null,
      parseValue: (raw: number | string | null): Date | null =>
        raw ? new Date(raw) : null
    }
  }
});

const apolloLink = ApolloLink.from([scalarsLink, httpLink]);

const authMiddleWare = new ApolloLink((operation, forward) => {
  const { accessToken } = userAuthState();
  operation.setContext({
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : ''
    }
  });

  return forward(operation);
});

const cache = new InMemoryCache({
  typePolicies: {
    User: {
      keyFields: ['uuid', 'id']
    },
    ActivityPlan: {
      keyFields: ['uuid']
    },
    ActivityPlanDraft: {
      keyFields: ['uuid']
    },
    Progression: {
      keyFields: ['uuid']
    }
  }
});

const link = concat(authMiddleWare, apolloLink);

const createClient = (): ApolloClient<NormalizedCacheObject> =>
  new ApolloClient({ cache, link });

interface ApolloProps {
  children: ReactNode;
}

export default function Apollo({ children }: ApolloProps): JSX.Element {
  return <ApolloProvider client={createClient()}>{children}</ApolloProvider>;
}
