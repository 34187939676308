import { QueryResult, useQuery } from '@apollo/client';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import { GetUserDocument, GetUserQuery, GetUserQueryVariables } from 'types';

type GetUserQueryResult = QueryResult<GetUserQuery, GetUserQueryVariables>;

export const useGetUser = async (
  userId: string
): Promise<GetUserQueryResult> => {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  return useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, {
    onError: defaultErrorHandler,
    variables: { userId: parseInt(userId) }
  });
};
