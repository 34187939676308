import { Chip, Divider, Paper, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { LlamaPlaceholderSVG } from 'styles/assets/LlamaPlaceholderSVG';
import { RepType } from 'types';
import { Equipment } from 'types/exerciseRepo';
import { millisToSeconds } from 'utils/time';
import { RepTypeChip } from './RepTypeChip';

export interface DetailsCardProps {
  repType?: RepType | null;
  equipment: Equipment[];
  bodyRegion?: string;
  bodyPosition?: string;
  exerciseType?: string;
  reps: number;
  holdTime: number;
  pointsPerRep: number;
  switchLimbTime?: number | null;
}

const Card = styled(props => <Paper role="tree" {...props} />)`
  display: flex;
`;

const Column = styled.div`
  padding: ${({ theme }) => theme.spacing(3, 6)};
  min-width: 185px;
`;

const CenterColumn = styled(Column)`
  position: relative;
  min-width: 300px;
`;

const DetailsGroup = styled(props => <div role="group" {...props} />)`
  display: grid;
  grid-auto-rows: minmax(100px, auto);
  gap: ${({ theme }) => theme.spacing(4)};
  margin-top: ${({ theme }) => theme.spacing(5)}; ;
`;

const DetailsGroupLeft = styled(DetailsGroup)`
  grid-template-columns: repeat(3, 1fr);
`;

const DetailsGroupRight = styled(DetailsGroup)`
  grid-template-columns: repeat(2, 1fr);
`;

const DetailItem = styled(props => <div role="treeitem" {...props} />)`
  grid-column: ${props => props.column};
  grid-row: ${props => props.row};
  margin-right: ${({ theme }) => theme.spacing(1)};
`;

const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Detail = ({
  title,
  value,
  column = '1',
  row = '1',
  children
}: {
  title: string;
  value?: string | number;
  column?: string;
  row?: string;
  children?: React.ReactNode;
}): JSX.Element => (
  <DetailItem column={column} row={row}>
    <Typography variant="body2" color="textSecondary">
      {title}
    </Typography>
    {value ?? children}
  </DetailItem>
);

export const DetailsCard = ({
  repType,
  equipment,
  bodyRegion,
  bodyPosition,
  exerciseType,
  reps,
  holdTime,
  pointsPerRep,
  switchLimbTime
}: DetailsCardProps): JSX.Element => (
  <Card data-testid="detailsCard">
    <Column>
      <Typography variant="h6" component="h3">
        Exercise Details
      </Typography>
      <DetailsGroupLeft>
        <Detail title="Rep Type">
          {repType ? (
            <RepTypeChip repType={repType} />
          ) : (
            <Chip size="small" label={'N/A'} />
          )}
        </Detail>
        <Detail
          title="Equipment"
          value={
            equipment.length > 0
              ? equipment.map(item => `${item.name}`).join(', ')
              : 'None'
          }
          column="2"
        />
        <Detail
          title="Body Region"
          row="2"
          value={bodyRegion ?? 'Coming soon'}
        />
        <Detail
          title="Body Position"
          column="2"
          row="2"
          value={bodyPosition ?? 'Coming soon'}
        />
        <Detail
          title="Exercise Type"
          column="3"
          row="2"
          value={exerciseType ?? 'Coming soon'}
        />
      </DetailsGroupLeft>
    </Column>
    <Divider orientation="vertical" flexItem />
    <CenterColumn>
      <Placeholder>
        <LlamaPlaceholderSVG height="220px" />
      </Placeholder>
      <Placeholder>
        <Typography variant="h6" component="h3">
          Section for angle or other unique info for exercise
        </Typography>
      </Placeholder>
    </CenterColumn>
    <Divider orientation="vertical" flexItem />
    <Column>
      <Typography variant="h6" component="h3">
        Default Rep Details
      </Typography>
      <DetailsGroupRight>
        <Detail title="Reps" value={reps} />
        <Detail
          title="Hold time"
          value={millisToSeconds(holdTime)}
          column="2"
        />
        <Detail title="Points per rep" value={pointsPerRep} row="2" />
        {switchLimbTime ? (
          <Detail
            title="Timer - switch sides"
            value={millisToSeconds(switchLimbTime)}
            column="2"
            row="2"
          />
        ) : null}
      </DetailsGroupRight>
    </Column>
  </Card>
);
