import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date type as integer */
  DateTime: Date;
};

/** All the accepted roles allowed for the configurator provided by JWT */
export enum AcceptedRoles {
  BillingOps = 'BILLING_OPS',
  C10RUser = 'C10R_USER',
  CareCoordinator = 'CARE_COORDINATOR',
  Coach = 'COACH',
  DeliveryOps = 'DELIVERY_OPS',
  MyUser = 'MY_USER',
  NursePractitioner = 'NURSE_PRACTITIONER',
  PartnershipManager = 'PARTNERSHIP_MANAGER',
  PhxUser = 'PHX_USER',
  PhysicalTherapist = 'PHYSICAL_THERAPIST',
  Super = 'SUPER'
}

export type ActivityPlanAssignInput = {
  activityPlanUuid: Scalars['String'];
  user: ActivityPlanAssignUserInput;
};

export type ActivityPlanAssignUserInput = {
  indication: Scalars['String'];
  pathwayUuid: Scalars['String'];
  program?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type ActivityPlanBetaDraftsDeleteInput = {
  indication: Scalars['String'];
  program: Scalars['String'];
};

export type ActivityPlanBetasCommitInput = {
  indication: Scalars['String'];
  program: Scalars['String'];
};

export type ActivityPlanBetasCreateInput = {
  indication: Scalars['String'];
  program: Scalars['String'];
};

export type ActivityPlanBetasDeleteInput = {
  indication: Scalars['String'];
  program: Scalars['String'];
};

export type ActivityPlanBetasDraftsCreateInput = {
  indication: Scalars['String'];
  program: Scalars['String'];
};

export type ActivityPlanBetasPromoteInput = {
  indication: Scalars['String'];
  program: Scalars['String'];
};

export type ActivityPlanCreateInput = {
  bodyRegion: Scalars['String'];
  condition: Scalars['String'];
  frequency: Scalars['Float'];
  name: Scalars['String'];
  program: Scalars['String'];
  weeks?: Maybe<Scalars['Float']>;
};

export type ActivityPlanDraftCommitInput = {
  activityPlanUuid: Scalars['String'];
  resetStartDate?: Maybe<Scalars['Boolean']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type ActivityPlanDraftCreateInput = {
  activityPlanUuid: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};

export type ActivityPlanDraftDeleteInput = {
  activityPlanUuid: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};

export type ActivityPlanGetByUserInput = {
  activityPlanUuid: Scalars['String'];
  userUuid: Scalars['String'];
};

export enum ActivityPlanStatus {
  Assigned = 'ASSIGNED',
  Deactivated = 'DEACTIVATED',
  Published = 'PUBLISHED'
}

export type ActivityPlansGetInput = {
  alphas?: Maybe<Scalars['Boolean']>;
  betas?: Maybe<Scalars['Boolean']>;
  count: Scalars['Float'];
  indication?: Maybe<Scalars['String']>;
  page: Scalars['Float'];
  program: Scalars['String'];
};

export type EtSessionActivitiesInput = {
  exercises: Array<ExerciseInput>;
  kind: Scalars['String'];
  userSubstitutions?: Maybe<Array<UserSubstitutionInput>>;
  uuid?: Maybe<Scalars['String']>;
};

export type EtSessionInput = {
  exercises: Array<ExerciseInput>;
};

export type ExerciseGetByUuidInput = {
  uuid: Scalars['String'];
};

export type ExerciseInput = {
  flexTime?: Maybe<Scalars['Int']>;
  holdTime?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  pointsPerRep?: Maybe<Scalars['Int']>;
  reps: Scalars['Int'];
  restTime?: Maybe<Scalars['Int']>;
  switchLimbTime?: Maybe<Scalars['Int']>;
  uuid: Scalars['String'];
};

export enum MotionDefinitionType {
  Flex = 'FLEX',
  Hold = 'HOLD',
  Rest = 'REST'
}

export type OriginalExerciseDataInput = {
  flexTime?: Maybe<Scalars['Int']>;
  holdTime?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  originalActivityUuid: Scalars['String'];
  pointsPerRep?: Maybe<Scalars['Int']>;
  reps: Scalars['Int'];
  restTime?: Maybe<Scalars['Int']>;
  switchLimbTime?: Maybe<Scalars['Int']>;
  uuid: Scalars['String'];
};

export type ProgressionDraftCreateInput = {
  activityPlanUuid: Scalars['String'];
  etSession?: Maybe<EtSessionInput>;
  order?: Maybe<Scalars['Int']>;
  userUuid?: Maybe<Scalars['String']>;
};

export type ProgressionDraftDeleteInput = {
  activityPlanUuid: Scalars['String'];
  progressionUuid: Scalars['String'];
  userUuid?: Maybe<Scalars['String']>;
};

export type ProgressionDraftUpdateInput = {
  activityPlanUuid: Scalars['String'];
  progressions: Array<ProgressionsInput>;
  userUuid?: Maybe<Scalars['String']>;
};

export type ProgressionGetInput = {
  activityPlanUuid: Scalars['String'];
  progressionUuid: Scalars['String'];
};

export type ProgressionsInput = {
  activities: Array<EtSessionActivitiesInput>;
  order: Scalars['Int'];
  uuid: Scalars['String'];
};

export enum RepType {
  Alternating = 'ALTERNATING',
  Bilateral = 'BILATERAL',
  MultiMotion = 'MULTI_MOTION',
  SwitchSides = 'SWITCH_SIDES',
  Unilateral = 'UNILATERAL'
}

export type SubstitutionExerciseDataInput = {
  name: Scalars['String'];
  uuid: Scalars['String'];
};

export type UserSubstitutionInput = {
  newExercise: SubstitutionExerciseDataInput;
  originalExercise: OriginalExerciseDataInput;
};

export type VerifyInput = {
  email: Scalars['String'];
  googleIdToken: Scalars['String'];
};

export type EtSessionFragment = {
  __typename: 'EtSessionActivity';
  uuid: string;
  kind: string;
  data?: Maybe<{
    __typename: 'EtSessionActivityMetadata';
    exercises: Array<{
      __typename: 'ExerciseConfig';
      uuid: string;
      holdTime?: Maybe<number>;
      name: string;
      reps: number;
      order?: Maybe<number>;
      flexTime?: Maybe<number>;
      restTime?: Maybe<number>;
      switchLimbTime?: Maybe<number>;
      numSteps: number;
      pointsPerRep?: Maybe<number>;
      repType?: Maybe<RepType>;
      numHoldMotions: number;
      anatomicalName?: Maybe<string>;
      thumbnail?: Maybe<{
        __typename: 'Asset';
        contentType: string;
        description?: Maybe<string>;
        url: string;
      }>;
    }>;
    userSubstitutions?: Maybe<
      Array<{
        __typename: 'UserSubstitution';
        newExercise: {
          __typename: 'UserSubstitutionMetadata';
          name: string;
          uuid: string;
        };
        originalExercise: {
          __typename: 'OriginalExerciseData';
          name: string;
          uuid: string;
          order: number;
          reps: number;
          pointsPerRep?: Maybe<number>;
          restTime?: Maybe<number>;
          holdTime?: Maybe<number>;
          flexTime?: Maybe<number>;
          switchLimbTime?: Maybe<number>;
          originalActivityUuid: string;
        };
      }>
    >;
  }>;
};

export type ActivityPlanFragment = {
  __typename: 'ActivityPlan';
  uuid: string;
  name?: Maybe<string>;
  uri: string;
  userUuid?: Maybe<string>;
  startedAt?: Maybe<Date>;
  updatedAt: Date;
  status: ActivityPlanStatus;
  isBeta?: Maybe<boolean>;
  data?: Maybe<{
    __typename: 'ActivityPlanMetaData';
    bodyRegion?: Maybe<string>;
    condition?: Maybe<string>;
  }>;
  draft?: Maybe<{
    __typename: 'ActivityPlanDraft';
    uuid: string;
    name?: Maybe<string>;
    uri: string;
    userUuid?: Maybe<string>;
    updatedAt: Date;
    isOwnedByCurrentAdmin: boolean;
    createdAt: Date;
    data?: Maybe<{
      __typename: 'ActivityPlanMetaData';
      bodyRegion?: Maybe<string>;
      condition?: Maybe<string>;
    }>;
    progressions: Array<{
      __typename: 'Progression';
      uuid: string;
      order: number;
      current?: Maybe<boolean>;
      isCustom?: Maybe<boolean>;
      wasModifiedByAdmin: boolean;
      etpeVersion?: Maybe<string>;
      data:
        | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
        | {
            __typename: 'ProgressionMetaDataChronic';
            totalLevelPoints: number;
            numEtSessions: number;
            etSessionPoints: number;
          }
        | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
      activities?: Maybe<
        Array<{
          __typename: 'EtSessionActivity';
          uuid: string;
          kind: string;
          data?: Maybe<{
            __typename: 'EtSessionActivityMetadata';
            exercises: Array<{
              __typename: 'ExerciseConfig';
              uuid: string;
              holdTime?: Maybe<number>;
              name: string;
              reps: number;
              order?: Maybe<number>;
              flexTime?: Maybe<number>;
              restTime?: Maybe<number>;
              switchLimbTime?: Maybe<number>;
              numSteps: number;
              pointsPerRep?: Maybe<number>;
              repType?: Maybe<RepType>;
              numHoldMotions: number;
              anatomicalName?: Maybe<string>;
              thumbnail?: Maybe<{
                __typename: 'Asset';
                contentType: string;
                description?: Maybe<string>;
                url: string;
              }>;
            }>;
            userSubstitutions?: Maybe<
              Array<{
                __typename: 'UserSubstitution';
                newExercise: {
                  __typename: 'UserSubstitutionMetadata';
                  name: string;
                  uuid: string;
                };
                originalExercise: {
                  __typename: 'OriginalExerciseData';
                  name: string;
                  uuid: string;
                  order: number;
                  reps: number;
                  pointsPerRep?: Maybe<number>;
                  restTime?: Maybe<number>;
                  holdTime?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  originalActivityUuid: string;
                };
              }>
            >;
          }>;
        }>
      >;
    }>;
  }>;
  progressions: Array<{
    __typename: 'Progression';
    uuid: string;
    order: number;
    current?: Maybe<boolean>;
    isCustom?: Maybe<boolean>;
    wasModifiedByAdmin: boolean;
    etpeVersion?: Maybe<string>;
    data:
      | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
      | {
          __typename: 'ProgressionMetaDataChronic';
          totalLevelPoints: number;
          numEtSessions: number;
          etSessionPoints: number;
        }
      | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
    activities?: Maybe<
      Array<{
        __typename: 'EtSessionActivity';
        uuid: string;
        kind: string;
        data?: Maybe<{
          __typename: 'EtSessionActivityMetadata';
          exercises: Array<{
            __typename: 'ExerciseConfig';
            uuid: string;
            holdTime?: Maybe<number>;
            name: string;
            reps: number;
            order?: Maybe<number>;
            flexTime?: Maybe<number>;
            restTime?: Maybe<number>;
            switchLimbTime?: Maybe<number>;
            numSteps: number;
            pointsPerRep?: Maybe<number>;
            repType?: Maybe<RepType>;
            numHoldMotions: number;
            anatomicalName?: Maybe<string>;
            thumbnail?: Maybe<{
              __typename: 'Asset';
              contentType: string;
              description?: Maybe<string>;
              url: string;
            }>;
          }>;
          userSubstitutions?: Maybe<
            Array<{
              __typename: 'UserSubstitution';
              newExercise: {
                __typename: 'UserSubstitutionMetadata';
                name: string;
                uuid: string;
              };
              originalExercise: {
                __typename: 'OriginalExerciseData';
                name: string;
                uuid: string;
                order: number;
                reps: number;
                pointsPerRep?: Maybe<number>;
                restTime?: Maybe<number>;
                holdTime?: Maybe<number>;
                flexTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                originalActivityUuid: string;
              };
            }>
          >;
        }>;
      }>
    >;
  }>;
};

export type AssetFragment = {
  __typename: 'Asset';
  description?: Maybe<string>;
  url: string;
};

export type DraftActivityPlanFragment = {
  __typename: 'ActivityPlanDraft';
  uuid: string;
  name?: Maybe<string>;
  uri: string;
  userUuid?: Maybe<string>;
  updatedAt: Date;
  isOwnedByCurrentAdmin: boolean;
  createdAt: Date;
  data?: Maybe<{
    __typename: 'ActivityPlanMetaData';
    bodyRegion?: Maybe<string>;
    condition?: Maybe<string>;
  }>;
  progressions: Array<{
    __typename: 'Progression';
    uuid: string;
    order: number;
    current?: Maybe<boolean>;
    isCustom?: Maybe<boolean>;
    wasModifiedByAdmin: boolean;
    etpeVersion?: Maybe<string>;
    data:
      | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
      | {
          __typename: 'ProgressionMetaDataChronic';
          totalLevelPoints: number;
          numEtSessions: number;
          etSessionPoints: number;
        }
      | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
    activities?: Maybe<
      Array<{
        __typename: 'EtSessionActivity';
        uuid: string;
        kind: string;
        data?: Maybe<{
          __typename: 'EtSessionActivityMetadata';
          exercises: Array<{
            __typename: 'ExerciseConfig';
            uuid: string;
            holdTime?: Maybe<number>;
            name: string;
            reps: number;
            order?: Maybe<number>;
            flexTime?: Maybe<number>;
            restTime?: Maybe<number>;
            switchLimbTime?: Maybe<number>;
            numSteps: number;
            pointsPerRep?: Maybe<number>;
            repType?: Maybe<RepType>;
            numHoldMotions: number;
            anatomicalName?: Maybe<string>;
            thumbnail?: Maybe<{
              __typename: 'Asset';
              contentType: string;
              description?: Maybe<string>;
              url: string;
            }>;
          }>;
          userSubstitutions?: Maybe<
            Array<{
              __typename: 'UserSubstitution';
              newExercise: {
                __typename: 'UserSubstitutionMetadata';
                name: string;
                uuid: string;
              };
              originalExercise: {
                __typename: 'OriginalExerciseData';
                name: string;
                uuid: string;
                order: number;
                reps: number;
                pointsPerRep?: Maybe<number>;
                restTime?: Maybe<number>;
                holdTime?: Maybe<number>;
                flexTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                originalActivityUuid: string;
              };
            }>
          >;
        }>;
      }>
    >;
  }>;
};

export type DraftActivityPlanSummaryFragment = {
  __typename: 'ActivityPlanDraft';
  uuid: string;
  updatedAt: Date;
  createdAt: Date;
  isOwnedByCurrentAdmin: boolean;
};

export type EquipmentFragment = {
  __typename: 'Equipment';
  uuid: string;
  name: string;
  image?: Maybe<{
    __typename: 'Asset';
    description?: Maybe<string>;
    url: string;
  }>;
};

export type EtPreviewFragment = {
  __typename: 'ExercisePreview';
  message: string;
  verbalCue?: Maybe<{
    __typename: 'VerbalCue';
    uuid: string;
    text?: Maybe<string>;
    url: string;
  }>;
  image?: Maybe<{
    __typename: 'Asset';
    description?: Maybe<string>;
    url: string;
  }>;
};

export type ExerciseFragment = {
  __typename: 'ExerciseConfig';
  uuid: string;
  holdTime?: Maybe<number>;
  name: string;
  reps: number;
  order?: Maybe<number>;
  flexTime?: Maybe<number>;
  restTime?: Maybe<number>;
  switchLimbTime?: Maybe<number>;
  numSteps: number;
  pointsPerRep?: Maybe<number>;
  repType?: Maybe<RepType>;
  numHoldMotions: number;
  anatomicalName?: Maybe<string>;
  thumbnail?: Maybe<{
    __typename: 'Asset';
    contentType: string;
    description?: Maybe<string>;
    url: string;
  }>;
};

export type ExtendedExerciseFragment = {
  __typename: 'ExerciseGetPayload';
  uuid: string;
  name: string;
  reps: number;
  anatomicalName?: Maybe<string>;
  repType?: Maybe<RepType>;
  holdTime?: Maybe<number>;
  pointsPerRep?: Maybe<number>;
  switchLimbTime?: Maybe<number>;
  numHoldMotions: number;
  equipment?: Maybe<
    Array<{
      __typename: 'Equipment';
      uuid: string;
      name: string;
      image?: Maybe<{
        __typename: 'Asset';
        description?: Maybe<string>;
        url: string;
      }>;
    }>
  >;
  etPreview: {
    __typename: 'ExercisePreview';
    message: string;
    verbalCue?: Maybe<{
      __typename: 'VerbalCue';
      uuid: string;
      text?: Maybe<string>;
      url: string;
    }>;
    image?: Maybe<{
      __typename: 'Asset';
      description?: Maybe<string>;
      url: string;
    }>;
  };
  motions: Array<{
    __typename: 'MotionDefinition';
    uuid: string;
    type: MotionDefinitionType;
    text: string;
    image?: Maybe<{
      __typename: 'Asset';
      description?: Maybe<string>;
      url: string;
    }>;
    verbalCues: Array<{
      __typename: 'VerbalCue';
      uuid: string;
      text?: Maybe<string>;
      url: string;
    }>;
  }>;
};

export type MotionFragment = {
  __typename: 'MotionDefinition';
  uuid: string;
  type: MotionDefinitionType;
  text: string;
  image?: Maybe<{
    __typename: 'Asset';
    description?: Maybe<string>;
    url: string;
  }>;
  verbalCues: Array<{
    __typename: 'VerbalCue';
    uuid: string;
    text?: Maybe<string>;
    url: string;
  }>;
};

export type ProgressionDataChronicFragment = {
  __typename: 'ProgressionMetaDataChronic';
  totalLevelPoints: number;
  numEtSessions: number;
  etSessionPoints: number;
};

export type ProgressionDataAcuteFragment = {
  __typename: 'ProgressionMetaDataAcute';
  frequency: number;
};

export type ProgressionDataPerisurgicalFragment = {
  __typename: 'ProgressionMetaDataPerisurgical';
  frequency: number;
};

export type ProgressionFragment = {
  __typename: 'Progression';
  uuid: string;
  order: number;
  current?: Maybe<boolean>;
  isCustom?: Maybe<boolean>;
  wasModifiedByAdmin: boolean;
  etpeVersion?: Maybe<string>;
  data:
    | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
    | {
        __typename: 'ProgressionMetaDataChronic';
        totalLevelPoints: number;
        numEtSessions: number;
        etSessionPoints: number;
      }
    | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
  activities?: Maybe<
    Array<{
      __typename: 'EtSessionActivity';
      uuid: string;
      kind: string;
      data?: Maybe<{
        __typename: 'EtSessionActivityMetadata';
        exercises: Array<{
          __typename: 'ExerciseConfig';
          uuid: string;
          holdTime?: Maybe<number>;
          name: string;
          reps: number;
          order?: Maybe<number>;
          flexTime?: Maybe<number>;
          restTime?: Maybe<number>;
          switchLimbTime?: Maybe<number>;
          numSteps: number;
          pointsPerRep?: Maybe<number>;
          repType?: Maybe<RepType>;
          numHoldMotions: number;
          anatomicalName?: Maybe<string>;
          thumbnail?: Maybe<{
            __typename: 'Asset';
            contentType: string;
            description?: Maybe<string>;
            url: string;
          }>;
        }>;
        userSubstitutions?: Maybe<
          Array<{
            __typename: 'UserSubstitution';
            newExercise: {
              __typename: 'UserSubstitutionMetadata';
              name: string;
              uuid: string;
            };
            originalExercise: {
              __typename: 'OriginalExerciseData';
              name: string;
              uuid: string;
              order: number;
              reps: number;
              pointsPerRep?: Maybe<number>;
              restTime?: Maybe<number>;
              holdTime?: Maybe<number>;
              flexTime?: Maybe<number>;
              switchLimbTime?: Maybe<number>;
              originalActivityUuid: string;
            };
          }>
        >;
      }>;
    }>
  >;
};

export type ProgressionsFragment = {
  __typename: 'ActivityPlan';
  progressions: Array<{
    __typename: 'Progression';
    uuid: string;
    order: number;
    current?: Maybe<boolean>;
    isCustom?: Maybe<boolean>;
    wasModifiedByAdmin: boolean;
    etpeVersion?: Maybe<string>;
    data:
      | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
      | {
          __typename: 'ProgressionMetaDataChronic';
          totalLevelPoints: number;
          numEtSessions: number;
          etSessionPoints: number;
        }
      | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
    activities?: Maybe<
      Array<{
        __typename: 'EtSessionActivity';
        uuid: string;
        kind: string;
        data?: Maybe<{
          __typename: 'EtSessionActivityMetadata';
          exercises: Array<{
            __typename: 'ExerciseConfig';
            uuid: string;
            holdTime?: Maybe<number>;
            name: string;
            reps: number;
            order?: Maybe<number>;
            flexTime?: Maybe<number>;
            restTime?: Maybe<number>;
            switchLimbTime?: Maybe<number>;
            numSteps: number;
            pointsPerRep?: Maybe<number>;
            repType?: Maybe<RepType>;
            numHoldMotions: number;
            anatomicalName?: Maybe<string>;
            thumbnail?: Maybe<{
              __typename: 'Asset';
              contentType: string;
              description?: Maybe<string>;
              url: string;
            }>;
          }>;
          userSubstitutions?: Maybe<
            Array<{
              __typename: 'UserSubstitution';
              newExercise: {
                __typename: 'UserSubstitutionMetadata';
                name: string;
                uuid: string;
              };
              originalExercise: {
                __typename: 'OriginalExerciseData';
                name: string;
                uuid: string;
                order: number;
                reps: number;
                pointsPerRep?: Maybe<number>;
                restTime?: Maybe<number>;
                holdTime?: Maybe<number>;
                flexTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                originalActivityUuid: string;
              };
            }>
          >;
        }>;
      }>
    >;
  }>;
};

export type UserFragment = {
  __typename: 'User';
  id: string;
  uuid: string;
  indication: string;
  program: string;
  pathwayUuid: string;
  level?: Maybe<number>;
  activityPlans: Array<{
    __typename: 'ActivityPlan';
    uuid: string;
    uri: string;
    name?: Maybe<string>;
    status: ActivityPlanStatus;
    updatedAt: Date;
    draft?: Maybe<{
      __typename: 'ActivityPlanDraft';
      uuid: string;
      updatedAt: Date;
      createdAt: Date;
      isOwnedByCurrentAdmin: boolean;
    }>;
  }>;
};

export type UserSubstitutionMetadataFragment = {
  __typename: 'UserSubstitutionMetadata';
  name: string;
  uuid: string;
};

export type OriginalExerciseDataFragment = {
  __typename: 'OriginalExerciseData';
  name: string;
  uuid: string;
  order: number;
  reps: number;
  pointsPerRep?: Maybe<number>;
  restTime?: Maybe<number>;
  holdTime?: Maybe<number>;
  flexTime?: Maybe<number>;
  switchLimbTime?: Maybe<number>;
  originalActivityUuid: string;
};

export type UserSubstitutionFragment = {
  __typename: 'UserSubstitution';
  newExercise: {
    __typename: 'UserSubstitutionMetadata';
    name: string;
    uuid: string;
  };
  originalExercise: {
    __typename: 'OriginalExerciseData';
    name: string;
    uuid: string;
    order: number;
    reps: number;
    pointsPerRep?: Maybe<number>;
    restTime?: Maybe<number>;
    holdTime?: Maybe<number>;
    flexTime?: Maybe<number>;
    switchLimbTime?: Maybe<number>;
    originalActivityUuid: string;
  };
};

export type VerbalCueFragment = {
  __typename: 'VerbalCue';
  uuid: string;
  text?: Maybe<string>;
  url: string;
};

export type AssignActivityPlanMutationVariables = Exact<{
  activityPlanAssignInput: ActivityPlanAssignInput;
}>;

export type AssignActivityPlanMutation = {
  __typename: 'Mutation';
  activityPlanAssign: {
    __typename: 'ActivityPlanAssignPayload';
    activityPlan?: Maybe<{
      __typename: 'ActivityPlan';
      uuid: string;
      name?: Maybe<string>;
      uri: string;
      userUuid?: Maybe<string>;
      startedAt?: Maybe<Date>;
      updatedAt: Date;
      status: ActivityPlanStatus;
      isBeta?: Maybe<boolean>;
      data?: Maybe<{
        __typename: 'ActivityPlanMetaData';
        bodyRegion?: Maybe<string>;
        condition?: Maybe<string>;
      }>;
      draft?: Maybe<{
        __typename: 'ActivityPlanDraft';
        uuid: string;
        name?: Maybe<string>;
        uri: string;
        userUuid?: Maybe<string>;
        updatedAt: Date;
        isOwnedByCurrentAdmin: boolean;
        createdAt: Date;
        data?: Maybe<{
          __typename: 'ActivityPlanMetaData';
          bodyRegion?: Maybe<string>;
          condition?: Maybe<string>;
        }>;
        progressions: Array<{
          __typename: 'Progression';
          uuid: string;
          order: number;
          current?: Maybe<boolean>;
          isCustom?: Maybe<boolean>;
          wasModifiedByAdmin: boolean;
          etpeVersion?: Maybe<string>;
          data:
            | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
            | {
                __typename: 'ProgressionMetaDataChronic';
                totalLevelPoints: number;
                numEtSessions: number;
                etSessionPoints: number;
              }
            | {
                __typename: 'ProgressionMetaDataPerisurgical';
                frequency: number;
              };
          activities?: Maybe<
            Array<{
              __typename: 'EtSessionActivity';
              uuid: string;
              kind: string;
              data?: Maybe<{
                __typename: 'EtSessionActivityMetadata';
                exercises: Array<{
                  __typename: 'ExerciseConfig';
                  uuid: string;
                  holdTime?: Maybe<number>;
                  name: string;
                  reps: number;
                  order?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  restTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  numSteps: number;
                  pointsPerRep?: Maybe<number>;
                  repType?: Maybe<RepType>;
                  numHoldMotions: number;
                  anatomicalName?: Maybe<string>;
                  thumbnail?: Maybe<{
                    __typename: 'Asset';
                    contentType: string;
                    description?: Maybe<string>;
                    url: string;
                  }>;
                }>;
                userSubstitutions?: Maybe<
                  Array<{
                    __typename: 'UserSubstitution';
                    newExercise: {
                      __typename: 'UserSubstitutionMetadata';
                      name: string;
                      uuid: string;
                    };
                    originalExercise: {
                      __typename: 'OriginalExerciseData';
                      name: string;
                      uuid: string;
                      order: number;
                      reps: number;
                      pointsPerRep?: Maybe<number>;
                      restTime?: Maybe<number>;
                      holdTime?: Maybe<number>;
                      flexTime?: Maybe<number>;
                      switchLimbTime?: Maybe<number>;
                      originalActivityUuid: string;
                    };
                  }>
                >;
              }>;
            }>
          >;
        }>;
      }>;
      progressions: Array<{
        __typename: 'Progression';
        uuid: string;
        order: number;
        current?: Maybe<boolean>;
        isCustom?: Maybe<boolean>;
        wasModifiedByAdmin: boolean;
        etpeVersion?: Maybe<string>;
        data:
          | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
          | {
              __typename: 'ProgressionMetaDataChronic';
              totalLevelPoints: number;
              numEtSessions: number;
              etSessionPoints: number;
            }
          | {
              __typename: 'ProgressionMetaDataPerisurgical';
              frequency: number;
            };
        activities?: Maybe<
          Array<{
            __typename: 'EtSessionActivity';
            uuid: string;
            kind: string;
            data?: Maybe<{
              __typename: 'EtSessionActivityMetadata';
              exercises: Array<{
                __typename: 'ExerciseConfig';
                uuid: string;
                holdTime?: Maybe<number>;
                name: string;
                reps: number;
                order?: Maybe<number>;
                flexTime?: Maybe<number>;
                restTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                numSteps: number;
                pointsPerRep?: Maybe<number>;
                repType?: Maybe<RepType>;
                numHoldMotions: number;
                anatomicalName?: Maybe<string>;
                thumbnail?: Maybe<{
                  __typename: 'Asset';
                  contentType: string;
                  description?: Maybe<string>;
                  url: string;
                }>;
              }>;
              userSubstitutions?: Maybe<
                Array<{
                  __typename: 'UserSubstitution';
                  newExercise: {
                    __typename: 'UserSubstitutionMetadata';
                    name: string;
                    uuid: string;
                  };
                  originalExercise: {
                    __typename: 'OriginalExerciseData';
                    name: string;
                    uuid: string;
                    order: number;
                    reps: number;
                    pointsPerRep?: Maybe<number>;
                    restTime?: Maybe<number>;
                    holdTime?: Maybe<number>;
                    flexTime?: Maybe<number>;
                    switchLimbTime?: Maybe<number>;
                    originalActivityUuid: string;
                  };
                }>
              >;
            }>;
          }>
        >;
      }>;
    }>;
  };
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename: 'Mutation';
  logout: { __typename: 'LogoutPayload'; success: boolean };
};

export type RefreshMutationVariables = Exact<{ [key: string]: never }>;

export type RefreshMutation = {
  __typename: 'Mutation';
  refresh: {
    __typename: 'RefreshPayload';
    accessToken: string;
    accessExpiry: number;
    refreshExpiry: number;
    roles: Array<AcceptedRoles>;
    adminId: number;
  };
};

export type VerifyMutationVariables = Exact<{
  verifyInput: VerifyInput;
}>;

export type VerifyMutation = {
  __typename: 'Mutation';
  verify: {
    __typename: 'VerifyPayload';
    accessToken: string;
    accessExpiry: number;
    refreshExpiry: number;
    roles: Array<AcceptedRoles>;
    adminId: number;
  };
};

export type BatchCommitBetaDraftsMutationVariables = Exact<{
  input: ActivityPlanBetasCommitInput;
}>;

export type BatchCommitBetaDraftsMutation = {
  __typename: 'Mutation';
  activityPlanBetasBatchCommit: {
    __typename: 'ActivityPlanBetasCommitPayload';
    betas: Array<{ __typename: 'ActivityPlanBetaPartial'; uuid: string }>;
  };
};

export type BatchCreateBetaDraftsMutationVariables = Exact<{
  input: ActivityPlanBetasDraftsCreateInput;
}>;

export type BatchCreateBetaDraftsMutation = {
  __typename: 'Mutation';
  activityPlanBetasDraftsBatchCreate: {
    __typename: 'ActivityPlanBetasDraftsCreatePayload';
    drafts: Array<{ __typename: 'ActivityPlanBetaPartial'; uuid: string }>;
  };
};

export type BatchCreateBetasMutationVariables = Exact<{
  input: ActivityPlanBetasCreateInput;
}>;

export type BatchCreateBetasMutation = {
  __typename: 'Mutation';
  activityPlanBetasBatchCreate: {
    __typename: 'ActivityPlanBetasCreatePayload';
    betas: Array<{
      __typename: 'ActivityPlanBetaPartial';
      uuid: string;
      uri: string;
    }>;
  };
};

export type BatchDeleteBetaDraftsMutationVariables = Exact<{
  input: ActivityPlanBetaDraftsDeleteInput;
}>;

export type BatchDeleteBetaDraftsMutation = {
  __typename: 'Mutation';
  activityPlanBetaDraftsDelete: {
    __typename: 'ActivityPlanBetaDraftsDeletePayload';
    program: string;
    indication: string;
  };
};

export type BatchDeleteBetasMutationVariables = Exact<{
  input: ActivityPlanBetasDeleteInput;
}>;

export type BatchDeleteBetasMutation = {
  __typename: 'Mutation';
  activityPlanBetasBatchDelete: {
    __typename: 'ActivityPlanBetasDeletePayload';
    program: string;
    indication: string;
  };
};

export type BatchPromoteBetasMutationVariables = Exact<{
  input: ActivityPlanBetasPromoteInput;
}>;

export type BatchPromoteBetasMutation = {
  __typename: 'Mutation';
  activityPlanBetasBatchPromote: {
    __typename: 'ActivityPlanBetasPromotePayload';
    alphas: Array<{
      __typename: 'ActivityPlanBetaPartial';
      uuid: string;
      uri: string;
      stream: string;
    }>;
  };
};

export type CreateActivityPlanMutationVariables = Exact<{
  activityPlanCreateInput: ActivityPlanCreateInput;
}>;

export type CreateActivityPlanMutation = {
  __typename: 'Mutation';
  activityPlanCreate: {
    __typename: 'ActivityPlanCreatePayload';
    activityPlan?: Maybe<{
      __typename: 'ActivityPlan';
      uuid: string;
      name?: Maybe<string>;
      uri: string;
    }>;
  };
};

export type CommitDraftMutationVariables = Exact<{
  input: ActivityPlanDraftCommitInput;
}>;

export type CommitDraftMutation = {
  __typename: 'Mutation';
  activityPlanDraftCommit: {
    __typename: 'ActivityPlanDraftCommitPayload';
    activityPlan: {
      __typename: 'ActivityPlan';
      uuid: string;
      name?: Maybe<string>;
      uri: string;
      userUuid?: Maybe<string>;
      startedAt?: Maybe<Date>;
      updatedAt: Date;
      status: ActivityPlanStatus;
      isBeta?: Maybe<boolean>;
      data?: Maybe<{
        __typename: 'ActivityPlanMetaData';
        bodyRegion?: Maybe<string>;
        condition?: Maybe<string>;
      }>;
      draft?: Maybe<{
        __typename: 'ActivityPlanDraft';
        uuid: string;
        name?: Maybe<string>;
        uri: string;
        userUuid?: Maybe<string>;
        updatedAt: Date;
        isOwnedByCurrentAdmin: boolean;
        createdAt: Date;
        data?: Maybe<{
          __typename: 'ActivityPlanMetaData';
          bodyRegion?: Maybe<string>;
          condition?: Maybe<string>;
        }>;
        progressions: Array<{
          __typename: 'Progression';
          uuid: string;
          order: number;
          current?: Maybe<boolean>;
          isCustom?: Maybe<boolean>;
          wasModifiedByAdmin: boolean;
          etpeVersion?: Maybe<string>;
          data:
            | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
            | {
                __typename: 'ProgressionMetaDataChronic';
                totalLevelPoints: number;
                numEtSessions: number;
                etSessionPoints: number;
              }
            | {
                __typename: 'ProgressionMetaDataPerisurgical';
                frequency: number;
              };
          activities?: Maybe<
            Array<{
              __typename: 'EtSessionActivity';
              uuid: string;
              kind: string;
              data?: Maybe<{
                __typename: 'EtSessionActivityMetadata';
                exercises: Array<{
                  __typename: 'ExerciseConfig';
                  uuid: string;
                  holdTime?: Maybe<number>;
                  name: string;
                  reps: number;
                  order?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  restTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  numSteps: number;
                  pointsPerRep?: Maybe<number>;
                  repType?: Maybe<RepType>;
                  numHoldMotions: number;
                  anatomicalName?: Maybe<string>;
                  thumbnail?: Maybe<{
                    __typename: 'Asset';
                    contentType: string;
                    description?: Maybe<string>;
                    url: string;
                  }>;
                }>;
                userSubstitutions?: Maybe<
                  Array<{
                    __typename: 'UserSubstitution';
                    newExercise: {
                      __typename: 'UserSubstitutionMetadata';
                      name: string;
                      uuid: string;
                    };
                    originalExercise: {
                      __typename: 'OriginalExerciseData';
                      name: string;
                      uuid: string;
                      order: number;
                      reps: number;
                      pointsPerRep?: Maybe<number>;
                      restTime?: Maybe<number>;
                      holdTime?: Maybe<number>;
                      flexTime?: Maybe<number>;
                      switchLimbTime?: Maybe<number>;
                      originalActivityUuid: string;
                    };
                  }>
                >;
              }>;
            }>
          >;
        }>;
      }>;
      progressions: Array<{
        __typename: 'Progression';
        uuid: string;
        order: number;
        current?: Maybe<boolean>;
        isCustom?: Maybe<boolean>;
        wasModifiedByAdmin: boolean;
        etpeVersion?: Maybe<string>;
        data:
          | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
          | {
              __typename: 'ProgressionMetaDataChronic';
              totalLevelPoints: number;
              numEtSessions: number;
              etSessionPoints: number;
            }
          | {
              __typename: 'ProgressionMetaDataPerisurgical';
              frequency: number;
            };
        activities?: Maybe<
          Array<{
            __typename: 'EtSessionActivity';
            uuid: string;
            kind: string;
            data?: Maybe<{
              __typename: 'EtSessionActivityMetadata';
              exercises: Array<{
                __typename: 'ExerciseConfig';
                uuid: string;
                holdTime?: Maybe<number>;
                name: string;
                reps: number;
                order?: Maybe<number>;
                flexTime?: Maybe<number>;
                restTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                numSteps: number;
                pointsPerRep?: Maybe<number>;
                repType?: Maybe<RepType>;
                numHoldMotions: number;
                anatomicalName?: Maybe<string>;
                thumbnail?: Maybe<{
                  __typename: 'Asset';
                  contentType: string;
                  description?: Maybe<string>;
                  url: string;
                }>;
              }>;
              userSubstitutions?: Maybe<
                Array<{
                  __typename: 'UserSubstitution';
                  newExercise: {
                    __typename: 'UserSubstitutionMetadata';
                    name: string;
                    uuid: string;
                  };
                  originalExercise: {
                    __typename: 'OriginalExerciseData';
                    name: string;
                    uuid: string;
                    order: number;
                    reps: number;
                    pointsPerRep?: Maybe<number>;
                    restTime?: Maybe<number>;
                    holdTime?: Maybe<number>;
                    flexTime?: Maybe<number>;
                    switchLimbTime?: Maybe<number>;
                    originalActivityUuid: string;
                  };
                }>
              >;
            }>;
          }>
        >;
      }>;
    };
  };
};

export type CreateDraftMutationVariables = Exact<{
  input: ActivityPlanDraftCreateInput;
}>;

export type CreateDraftMutation = {
  __typename: 'Mutation';
  activityPlanDraftCreate: {
    __typename: 'ActivityPlanDraftCreatePayload';
    draft: {
      __typename: 'ActivityPlanDraft';
      uuid: string;
      name?: Maybe<string>;
      uri: string;
      userUuid?: Maybe<string>;
      updatedAt: Date;
      isOwnedByCurrentAdmin: boolean;
      createdAt: Date;
      data?: Maybe<{
        __typename: 'ActivityPlanMetaData';
        bodyRegion?: Maybe<string>;
        condition?: Maybe<string>;
      }>;
      progressions: Array<{
        __typename: 'Progression';
        uuid: string;
        order: number;
        current?: Maybe<boolean>;
        isCustom?: Maybe<boolean>;
        wasModifiedByAdmin: boolean;
        etpeVersion?: Maybe<string>;
        data:
          | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
          | {
              __typename: 'ProgressionMetaDataChronic';
              totalLevelPoints: number;
              numEtSessions: number;
              etSessionPoints: number;
            }
          | {
              __typename: 'ProgressionMetaDataPerisurgical';
              frequency: number;
            };
        activities?: Maybe<
          Array<{
            __typename: 'EtSessionActivity';
            uuid: string;
            kind: string;
            data?: Maybe<{
              __typename: 'EtSessionActivityMetadata';
              exercises: Array<{
                __typename: 'ExerciseConfig';
                uuid: string;
                holdTime?: Maybe<number>;
                name: string;
                reps: number;
                order?: Maybe<number>;
                flexTime?: Maybe<number>;
                restTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                numSteps: number;
                pointsPerRep?: Maybe<number>;
                repType?: Maybe<RepType>;
                numHoldMotions: number;
                anatomicalName?: Maybe<string>;
                thumbnail?: Maybe<{
                  __typename: 'Asset';
                  contentType: string;
                  description?: Maybe<string>;
                  url: string;
                }>;
              }>;
              userSubstitutions?: Maybe<
                Array<{
                  __typename: 'UserSubstitution';
                  newExercise: {
                    __typename: 'UserSubstitutionMetadata';
                    name: string;
                    uuid: string;
                  };
                  originalExercise: {
                    __typename: 'OriginalExerciseData';
                    name: string;
                    uuid: string;
                    order: number;
                    reps: number;
                    pointsPerRep?: Maybe<number>;
                    restTime?: Maybe<number>;
                    holdTime?: Maybe<number>;
                    flexTime?: Maybe<number>;
                    switchLimbTime?: Maybe<number>;
                    originalActivityUuid: string;
                  };
                }>
              >;
            }>;
          }>
        >;
      }>;
    };
  };
};

export type CreateDraftProgressionMutationVariables = Exact<{
  input: ProgressionDraftCreateInput;
}>;

export type CreateDraftProgressionMutation = {
  __typename: 'Mutation';
  progressionDraftCreate: {
    __typename: 'ProgressionDraftCreatePayload';
    progressions: Array<{
      __typename: 'Progression';
      uuid: string;
      order: number;
      current?: Maybe<boolean>;
      isCustom?: Maybe<boolean>;
      wasModifiedByAdmin: boolean;
      etpeVersion?: Maybe<string>;
      data:
        | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
        | {
            __typename: 'ProgressionMetaDataChronic';
            totalLevelPoints: number;
            numEtSessions: number;
            etSessionPoints: number;
          }
        | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
      activities?: Maybe<
        Array<{
          __typename: 'EtSessionActivity';
          uuid: string;
          kind: string;
          data?: Maybe<{
            __typename: 'EtSessionActivityMetadata';
            exercises: Array<{
              __typename: 'ExerciseConfig';
              uuid: string;
              holdTime?: Maybe<number>;
              name: string;
              reps: number;
              order?: Maybe<number>;
              flexTime?: Maybe<number>;
              restTime?: Maybe<number>;
              switchLimbTime?: Maybe<number>;
              numSteps: number;
              pointsPerRep?: Maybe<number>;
              repType?: Maybe<RepType>;
              numHoldMotions: number;
              anatomicalName?: Maybe<string>;
              thumbnail?: Maybe<{
                __typename: 'Asset';
                contentType: string;
                description?: Maybe<string>;
                url: string;
              }>;
            }>;
            userSubstitutions?: Maybe<
              Array<{
                __typename: 'UserSubstitution';
                newExercise: {
                  __typename: 'UserSubstitutionMetadata';
                  name: string;
                  uuid: string;
                };
                originalExercise: {
                  __typename: 'OriginalExerciseData';
                  name: string;
                  uuid: string;
                  order: number;
                  reps: number;
                  pointsPerRep?: Maybe<number>;
                  restTime?: Maybe<number>;
                  holdTime?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  originalActivityUuid: string;
                };
              }>
            >;
          }>;
        }>
      >;
    }>;
  };
};

export type DeleteDraftMutationVariables = Exact<{
  input: ActivityPlanDraftDeleteInput;
}>;

export type DeleteDraftMutation = {
  __typename: 'Mutation';
  activityPlanDraftDelete: {
    __typename: 'ActivityPlanDraftDeletePayload';
    activityPlan: {
      __typename: 'ActivityPlan';
      uuid: string;
      name?: Maybe<string>;
      uri: string;
      userUuid?: Maybe<string>;
      startedAt?: Maybe<Date>;
      updatedAt: Date;
      status: ActivityPlanStatus;
      isBeta?: Maybe<boolean>;
      data?: Maybe<{
        __typename: 'ActivityPlanMetaData';
        bodyRegion?: Maybe<string>;
        condition?: Maybe<string>;
      }>;
      draft?: Maybe<{
        __typename: 'ActivityPlanDraft';
        uuid: string;
        name?: Maybe<string>;
        uri: string;
        userUuid?: Maybe<string>;
        updatedAt: Date;
        isOwnedByCurrentAdmin: boolean;
        createdAt: Date;
        data?: Maybe<{
          __typename: 'ActivityPlanMetaData';
          bodyRegion?: Maybe<string>;
          condition?: Maybe<string>;
        }>;
        progressions: Array<{
          __typename: 'Progression';
          uuid: string;
          order: number;
          current?: Maybe<boolean>;
          isCustom?: Maybe<boolean>;
          wasModifiedByAdmin: boolean;
          etpeVersion?: Maybe<string>;
          data:
            | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
            | {
                __typename: 'ProgressionMetaDataChronic';
                totalLevelPoints: number;
                numEtSessions: number;
                etSessionPoints: number;
              }
            | {
                __typename: 'ProgressionMetaDataPerisurgical';
                frequency: number;
              };
          activities?: Maybe<
            Array<{
              __typename: 'EtSessionActivity';
              uuid: string;
              kind: string;
              data?: Maybe<{
                __typename: 'EtSessionActivityMetadata';
                exercises: Array<{
                  __typename: 'ExerciseConfig';
                  uuid: string;
                  holdTime?: Maybe<number>;
                  name: string;
                  reps: number;
                  order?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  restTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  numSteps: number;
                  pointsPerRep?: Maybe<number>;
                  repType?: Maybe<RepType>;
                  numHoldMotions: number;
                  anatomicalName?: Maybe<string>;
                  thumbnail?: Maybe<{
                    __typename: 'Asset';
                    contentType: string;
                    description?: Maybe<string>;
                    url: string;
                  }>;
                }>;
                userSubstitutions?: Maybe<
                  Array<{
                    __typename: 'UserSubstitution';
                    newExercise: {
                      __typename: 'UserSubstitutionMetadata';
                      name: string;
                      uuid: string;
                    };
                    originalExercise: {
                      __typename: 'OriginalExerciseData';
                      name: string;
                      uuid: string;
                      order: number;
                      reps: number;
                      pointsPerRep?: Maybe<number>;
                      restTime?: Maybe<number>;
                      holdTime?: Maybe<number>;
                      flexTime?: Maybe<number>;
                      switchLimbTime?: Maybe<number>;
                      originalActivityUuid: string;
                    };
                  }>
                >;
              }>;
            }>
          >;
        }>;
      }>;
      progressions: Array<{
        __typename: 'Progression';
        uuid: string;
        order: number;
        current?: Maybe<boolean>;
        isCustom?: Maybe<boolean>;
        wasModifiedByAdmin: boolean;
        etpeVersion?: Maybe<string>;
        data:
          | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
          | {
              __typename: 'ProgressionMetaDataChronic';
              totalLevelPoints: number;
              numEtSessions: number;
              etSessionPoints: number;
            }
          | {
              __typename: 'ProgressionMetaDataPerisurgical';
              frequency: number;
            };
        activities?: Maybe<
          Array<{
            __typename: 'EtSessionActivity';
            uuid: string;
            kind: string;
            data?: Maybe<{
              __typename: 'EtSessionActivityMetadata';
              exercises: Array<{
                __typename: 'ExerciseConfig';
                uuid: string;
                holdTime?: Maybe<number>;
                name: string;
                reps: number;
                order?: Maybe<number>;
                flexTime?: Maybe<number>;
                restTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                numSteps: number;
                pointsPerRep?: Maybe<number>;
                repType?: Maybe<RepType>;
                numHoldMotions: number;
                anatomicalName?: Maybe<string>;
                thumbnail?: Maybe<{
                  __typename: 'Asset';
                  contentType: string;
                  description?: Maybe<string>;
                  url: string;
                }>;
              }>;
              userSubstitutions?: Maybe<
                Array<{
                  __typename: 'UserSubstitution';
                  newExercise: {
                    __typename: 'UserSubstitutionMetadata';
                    name: string;
                    uuid: string;
                  };
                  originalExercise: {
                    __typename: 'OriginalExerciseData';
                    name: string;
                    uuid: string;
                    order: number;
                    reps: number;
                    pointsPerRep?: Maybe<number>;
                    restTime?: Maybe<number>;
                    holdTime?: Maybe<number>;
                    flexTime?: Maybe<number>;
                    switchLimbTime?: Maybe<number>;
                    originalActivityUuid: string;
                  };
                }>
              >;
            }>;
          }>
        >;
      }>;
    };
  };
};

export type DeleteDraftProgressionMutationVariables = Exact<{
  input: ProgressionDraftDeleteInput;
}>;

export type DeleteDraftProgressionMutation = {
  __typename: 'Mutation';
  progressionDraftDelete: {
    __typename: 'ProgressionDraftDeletePayload';
    progressions: Array<{
      __typename: 'Progression';
      uuid: string;
      order: number;
      current?: Maybe<boolean>;
      isCustom?: Maybe<boolean>;
      wasModifiedByAdmin: boolean;
      etpeVersion?: Maybe<string>;
      data:
        | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
        | {
            __typename: 'ProgressionMetaDataChronic';
            totalLevelPoints: number;
            numEtSessions: number;
            etSessionPoints: number;
          }
        | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
      activities?: Maybe<
        Array<{
          __typename: 'EtSessionActivity';
          uuid: string;
          kind: string;
          data?: Maybe<{
            __typename: 'EtSessionActivityMetadata';
            exercises: Array<{
              __typename: 'ExerciseConfig';
              uuid: string;
              holdTime?: Maybe<number>;
              name: string;
              reps: number;
              order?: Maybe<number>;
              flexTime?: Maybe<number>;
              restTime?: Maybe<number>;
              switchLimbTime?: Maybe<number>;
              numSteps: number;
              pointsPerRep?: Maybe<number>;
              repType?: Maybe<RepType>;
              numHoldMotions: number;
              anatomicalName?: Maybe<string>;
              thumbnail?: Maybe<{
                __typename: 'Asset';
                contentType: string;
                description?: Maybe<string>;
                url: string;
              }>;
            }>;
            userSubstitutions?: Maybe<
              Array<{
                __typename: 'UserSubstitution';
                newExercise: {
                  __typename: 'UserSubstitutionMetadata';
                  name: string;
                  uuid: string;
                };
                originalExercise: {
                  __typename: 'OriginalExerciseData';
                  name: string;
                  uuid: string;
                  order: number;
                  reps: number;
                  pointsPerRep?: Maybe<number>;
                  restTime?: Maybe<number>;
                  holdTime?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  originalActivityUuid: string;
                };
              }>
            >;
          }>;
        }>
      >;
    }>;
  };
};

export type SaveDraftMutationVariables = Exact<{
  input: ProgressionDraftUpdateInput;
}>;

export type SaveDraftMutation = {
  __typename: 'Mutation';
  progressionDraftUpdate: {
    __typename: 'ProgressionDraftUpdatePayload';
    progressions: Array<{
      __typename: 'Progression';
      uuid: string;
      order: number;
      current?: Maybe<boolean>;
      isCustom?: Maybe<boolean>;
      wasModifiedByAdmin: boolean;
      etpeVersion?: Maybe<string>;
      data:
        | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
        | {
            __typename: 'ProgressionMetaDataChronic';
            totalLevelPoints: number;
            numEtSessions: number;
            etSessionPoints: number;
          }
        | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
      activities?: Maybe<
        Array<{
          __typename: 'EtSessionActivity';
          uuid: string;
          kind: string;
          data?: Maybe<{
            __typename: 'EtSessionActivityMetadata';
            exercises: Array<{
              __typename: 'ExerciseConfig';
              uuid: string;
              holdTime?: Maybe<number>;
              name: string;
              reps: number;
              order?: Maybe<number>;
              flexTime?: Maybe<number>;
              restTime?: Maybe<number>;
              switchLimbTime?: Maybe<number>;
              numSteps: number;
              pointsPerRep?: Maybe<number>;
              repType?: Maybe<RepType>;
              numHoldMotions: number;
              anatomicalName?: Maybe<string>;
              thumbnail?: Maybe<{
                __typename: 'Asset';
                contentType: string;
                description?: Maybe<string>;
                url: string;
              }>;
            }>;
            userSubstitutions?: Maybe<
              Array<{
                __typename: 'UserSubstitution';
                newExercise: {
                  __typename: 'UserSubstitutionMetadata';
                  name: string;
                  uuid: string;
                };
                originalExercise: {
                  __typename: 'OriginalExerciseData';
                  name: string;
                  uuid: string;
                  order: number;
                  reps: number;
                  pointsPerRep?: Maybe<number>;
                  restTime?: Maybe<number>;
                  holdTime?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  originalActivityUuid: string;
                };
              }>
            >;
          }>;
        }>
      >;
    }>;
  };
};

export type GetActivityPlanQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetActivityPlanQuery = {
  __typename: 'Query';
  activityPlanGet: {
    __typename: 'ActivityPlan';
    uuid: string;
    name?: Maybe<string>;
    uri: string;
    userUuid?: Maybe<string>;
    startedAt?: Maybe<Date>;
    updatedAt: Date;
    status: ActivityPlanStatus;
    isBeta?: Maybe<boolean>;
    data?: Maybe<{
      __typename: 'ActivityPlanMetaData';
      bodyRegion?: Maybe<string>;
      condition?: Maybe<string>;
    }>;
    draft?: Maybe<{
      __typename: 'ActivityPlanDraft';
      uuid: string;
      name?: Maybe<string>;
      uri: string;
      userUuid?: Maybe<string>;
      updatedAt: Date;
      isOwnedByCurrentAdmin: boolean;
      createdAt: Date;
      data?: Maybe<{
        __typename: 'ActivityPlanMetaData';
        bodyRegion?: Maybe<string>;
        condition?: Maybe<string>;
      }>;
      progressions: Array<{
        __typename: 'Progression';
        uuid: string;
        order: number;
        current?: Maybe<boolean>;
        isCustom?: Maybe<boolean>;
        wasModifiedByAdmin: boolean;
        etpeVersion?: Maybe<string>;
        data:
          | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
          | {
              __typename: 'ProgressionMetaDataChronic';
              totalLevelPoints: number;
              numEtSessions: number;
              etSessionPoints: number;
            }
          | {
              __typename: 'ProgressionMetaDataPerisurgical';
              frequency: number;
            };
        activities?: Maybe<
          Array<{
            __typename: 'EtSessionActivity';
            uuid: string;
            kind: string;
            data?: Maybe<{
              __typename: 'EtSessionActivityMetadata';
              exercises: Array<{
                __typename: 'ExerciseConfig';
                uuid: string;
                holdTime?: Maybe<number>;
                name: string;
                reps: number;
                order?: Maybe<number>;
                flexTime?: Maybe<number>;
                restTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                numSteps: number;
                pointsPerRep?: Maybe<number>;
                repType?: Maybe<RepType>;
                numHoldMotions: number;
                anatomicalName?: Maybe<string>;
                thumbnail?: Maybe<{
                  __typename: 'Asset';
                  contentType: string;
                  description?: Maybe<string>;
                  url: string;
                }>;
              }>;
              userSubstitutions?: Maybe<
                Array<{
                  __typename: 'UserSubstitution';
                  newExercise: {
                    __typename: 'UserSubstitutionMetadata';
                    name: string;
                    uuid: string;
                  };
                  originalExercise: {
                    __typename: 'OriginalExerciseData';
                    name: string;
                    uuid: string;
                    order: number;
                    reps: number;
                    pointsPerRep?: Maybe<number>;
                    restTime?: Maybe<number>;
                    holdTime?: Maybe<number>;
                    flexTime?: Maybe<number>;
                    switchLimbTime?: Maybe<number>;
                    originalActivityUuid: string;
                  };
                }>
              >;
            }>;
          }>
        >;
      }>;
    }>;
    progressions: Array<{
      __typename: 'Progression';
      uuid: string;
      order: number;
      current?: Maybe<boolean>;
      isCustom?: Maybe<boolean>;
      wasModifiedByAdmin: boolean;
      etpeVersion?: Maybe<string>;
      data:
        | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
        | {
            __typename: 'ProgressionMetaDataChronic';
            totalLevelPoints: number;
            numEtSessions: number;
            etSessionPoints: number;
          }
        | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
      activities?: Maybe<
        Array<{
          __typename: 'EtSessionActivity';
          uuid: string;
          kind: string;
          data?: Maybe<{
            __typename: 'EtSessionActivityMetadata';
            exercises: Array<{
              __typename: 'ExerciseConfig';
              uuid: string;
              holdTime?: Maybe<number>;
              name: string;
              reps: number;
              order?: Maybe<number>;
              flexTime?: Maybe<number>;
              restTime?: Maybe<number>;
              switchLimbTime?: Maybe<number>;
              numSteps: number;
              pointsPerRep?: Maybe<number>;
              repType?: Maybe<RepType>;
              numHoldMotions: number;
              anatomicalName?: Maybe<string>;
              thumbnail?: Maybe<{
                __typename: 'Asset';
                contentType: string;
                description?: Maybe<string>;
                url: string;
              }>;
            }>;
            userSubstitutions?: Maybe<
              Array<{
                __typename: 'UserSubstitution';
                newExercise: {
                  __typename: 'UserSubstitutionMetadata';
                  name: string;
                  uuid: string;
                };
                originalExercise: {
                  __typename: 'OriginalExerciseData';
                  name: string;
                  uuid: string;
                  order: number;
                  reps: number;
                  pointsPerRep?: Maybe<number>;
                  restTime?: Maybe<number>;
                  holdTime?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  originalActivityUuid: string;
                };
              }>
            >;
          }>;
        }>
      >;
    }>;
  };
};

export type GetActivityPlanByUserQueryVariables = Exact<{
  input: ActivityPlanGetByUserInput;
}>;

export type GetActivityPlanByUserQuery = {
  __typename: 'Query';
  activityPlanGetByUser: {
    __typename: 'ActivityPlan';
    uuid: string;
    name?: Maybe<string>;
    uri: string;
    userUuid?: Maybe<string>;
    startedAt?: Maybe<Date>;
    updatedAt: Date;
    status: ActivityPlanStatus;
    isBeta?: Maybe<boolean>;
    data?: Maybe<{
      __typename: 'ActivityPlanMetaData';
      bodyRegion?: Maybe<string>;
      condition?: Maybe<string>;
    }>;
    draft?: Maybe<{
      __typename: 'ActivityPlanDraft';
      uuid: string;
      name?: Maybe<string>;
      uri: string;
      userUuid?: Maybe<string>;
      updatedAt: Date;
      isOwnedByCurrentAdmin: boolean;
      createdAt: Date;
      data?: Maybe<{
        __typename: 'ActivityPlanMetaData';
        bodyRegion?: Maybe<string>;
        condition?: Maybe<string>;
      }>;
      progressions: Array<{
        __typename: 'Progression';
        uuid: string;
        order: number;
        current?: Maybe<boolean>;
        isCustom?: Maybe<boolean>;
        wasModifiedByAdmin: boolean;
        etpeVersion?: Maybe<string>;
        data:
          | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
          | {
              __typename: 'ProgressionMetaDataChronic';
              totalLevelPoints: number;
              numEtSessions: number;
              etSessionPoints: number;
            }
          | {
              __typename: 'ProgressionMetaDataPerisurgical';
              frequency: number;
            };
        activities?: Maybe<
          Array<{
            __typename: 'EtSessionActivity';
            uuid: string;
            kind: string;
            data?: Maybe<{
              __typename: 'EtSessionActivityMetadata';
              exercises: Array<{
                __typename: 'ExerciseConfig';
                uuid: string;
                holdTime?: Maybe<number>;
                name: string;
                reps: number;
                order?: Maybe<number>;
                flexTime?: Maybe<number>;
                restTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                numSteps: number;
                pointsPerRep?: Maybe<number>;
                repType?: Maybe<RepType>;
                numHoldMotions: number;
                anatomicalName?: Maybe<string>;
                thumbnail?: Maybe<{
                  __typename: 'Asset';
                  contentType: string;
                  description?: Maybe<string>;
                  url: string;
                }>;
              }>;
              userSubstitutions?: Maybe<
                Array<{
                  __typename: 'UserSubstitution';
                  newExercise: {
                    __typename: 'UserSubstitutionMetadata';
                    name: string;
                    uuid: string;
                  };
                  originalExercise: {
                    __typename: 'OriginalExerciseData';
                    name: string;
                    uuid: string;
                    order: number;
                    reps: number;
                    pointsPerRep?: Maybe<number>;
                    restTime?: Maybe<number>;
                    holdTime?: Maybe<number>;
                    flexTime?: Maybe<number>;
                    switchLimbTime?: Maybe<number>;
                    originalActivityUuid: string;
                  };
                }>
              >;
            }>;
          }>
        >;
      }>;
    }>;
    progressions: Array<{
      __typename: 'Progression';
      uuid: string;
      order: number;
      current?: Maybe<boolean>;
      isCustom?: Maybe<boolean>;
      wasModifiedByAdmin: boolean;
      etpeVersion?: Maybe<string>;
      data:
        | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
        | {
            __typename: 'ProgressionMetaDataChronic';
            totalLevelPoints: number;
            numEtSessions: number;
            etSessionPoints: number;
          }
        | { __typename: 'ProgressionMetaDataPerisurgical'; frequency: number };
      activities?: Maybe<
        Array<{
          __typename: 'EtSessionActivity';
          uuid: string;
          kind: string;
          data?: Maybe<{
            __typename: 'EtSessionActivityMetadata';
            exercises: Array<{
              __typename: 'ExerciseConfig';
              uuid: string;
              holdTime?: Maybe<number>;
              name: string;
              reps: number;
              order?: Maybe<number>;
              flexTime?: Maybe<number>;
              restTime?: Maybe<number>;
              switchLimbTime?: Maybe<number>;
              numSteps: number;
              pointsPerRep?: Maybe<number>;
              repType?: Maybe<RepType>;
              numHoldMotions: number;
              anatomicalName?: Maybe<string>;
              thumbnail?: Maybe<{
                __typename: 'Asset';
                contentType: string;
                description?: Maybe<string>;
                url: string;
              }>;
            }>;
            userSubstitutions?: Maybe<
              Array<{
                __typename: 'UserSubstitution';
                newExercise: {
                  __typename: 'UserSubstitutionMetadata';
                  name: string;
                  uuid: string;
                };
                originalExercise: {
                  __typename: 'OriginalExerciseData';
                  name: string;
                  uuid: string;
                  order: number;
                  reps: number;
                  pointsPerRep?: Maybe<number>;
                  restTime?: Maybe<number>;
                  holdTime?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  originalActivityUuid: string;
                };
              }>
            >;
          }>;
        }>
      >;
    }>;
  };
};

export type GetAllAcuteTemplatesQueryVariables = Exact<{
  activityPlansGetInput: ActivityPlansGetInput;
}>;

export type GetAllAcuteTemplatesQuery = {
  __typename: 'Query';
  activityPlansGetAll: {
    __typename: 'ActivityPlansGetPayload';
    page: number;
    totalCount: number;
    activityPlans: Array<{
      __typename: 'ActivityPlan';
      uuid: string;
      name?: Maybe<string>;
      numProgressions: number;
      uri: string;
      data?: Maybe<{
        __typename: 'ActivityPlanMetaData';
        condition?: Maybe<string>;
        bodyRegion?: Maybe<string>;
      }>;
      draft?: Maybe<{
        __typename: 'ActivityPlanDraft';
        uuid: string;
        updatedAt: Date;
        createdAt: Date;
        isOwnedByCurrentAdmin: boolean;
      }>;
    }>;
  };
};

export type GetAllChronicConfigsQueryVariables = Exact<{
  activityPlansGetInput: ActivityPlansGetInput;
}>;

export type GetAllChronicConfigsQuery = {
  __typename: 'Query';
  activityPlansGetAll: {
    __typename: 'ActivityPlansGetPayload';
    activityPlans: Array<{
      __typename: 'ActivityPlan';
      numProgressions: number;
      uuid: string;
      name?: Maybe<string>;
      uri: string;
      userUuid?: Maybe<string>;
      startedAt?: Maybe<Date>;
      updatedAt: Date;
      status: ActivityPlanStatus;
      isBeta?: Maybe<boolean>;
      data?: Maybe<{
        __typename: 'ActivityPlanMetaData';
        bodyRegion?: Maybe<string>;
        condition?: Maybe<string>;
      }>;
      draft?: Maybe<{
        __typename: 'ActivityPlanDraft';
        uuid: string;
        name?: Maybe<string>;
        uri: string;
        userUuid?: Maybe<string>;
        updatedAt: Date;
        isOwnedByCurrentAdmin: boolean;
        createdAt: Date;
        data?: Maybe<{
          __typename: 'ActivityPlanMetaData';
          bodyRegion?: Maybe<string>;
          condition?: Maybe<string>;
        }>;
        progressions: Array<{
          __typename: 'Progression';
          uuid: string;
          order: number;
          current?: Maybe<boolean>;
          isCustom?: Maybe<boolean>;
          wasModifiedByAdmin: boolean;
          etpeVersion?: Maybe<string>;
          data:
            | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
            | {
                __typename: 'ProgressionMetaDataChronic';
                totalLevelPoints: number;
                numEtSessions: number;
                etSessionPoints: number;
              }
            | {
                __typename: 'ProgressionMetaDataPerisurgical';
                frequency: number;
              };
          activities?: Maybe<
            Array<{
              __typename: 'EtSessionActivity';
              uuid: string;
              kind: string;
              data?: Maybe<{
                __typename: 'EtSessionActivityMetadata';
                exercises: Array<{
                  __typename: 'ExerciseConfig';
                  uuid: string;
                  holdTime?: Maybe<number>;
                  name: string;
                  reps: number;
                  order?: Maybe<number>;
                  flexTime?: Maybe<number>;
                  restTime?: Maybe<number>;
                  switchLimbTime?: Maybe<number>;
                  numSteps: number;
                  pointsPerRep?: Maybe<number>;
                  repType?: Maybe<RepType>;
                  numHoldMotions: number;
                  anatomicalName?: Maybe<string>;
                  thumbnail?: Maybe<{
                    __typename: 'Asset';
                    contentType: string;
                    description?: Maybe<string>;
                    url: string;
                  }>;
                }>;
                userSubstitutions?: Maybe<
                  Array<{
                    __typename: 'UserSubstitution';
                    newExercise: {
                      __typename: 'UserSubstitutionMetadata';
                      name: string;
                      uuid: string;
                    };
                    originalExercise: {
                      __typename: 'OriginalExerciseData';
                      name: string;
                      uuid: string;
                      order: number;
                      reps: number;
                      pointsPerRep?: Maybe<number>;
                      restTime?: Maybe<number>;
                      holdTime?: Maybe<number>;
                      flexTime?: Maybe<number>;
                      switchLimbTime?: Maybe<number>;
                      originalActivityUuid: string;
                    };
                  }>
                >;
              }>;
            }>
          >;
        }>;
      }>;
      progressions: Array<{
        __typename: 'Progression';
        uuid: string;
        order: number;
        current?: Maybe<boolean>;
        isCustom?: Maybe<boolean>;
        wasModifiedByAdmin: boolean;
        etpeVersion?: Maybe<string>;
        data:
          | { __typename: 'ProgressionMetaDataAcute'; frequency: number }
          | {
              __typename: 'ProgressionMetaDataChronic';
              totalLevelPoints: number;
              numEtSessions: number;
              etSessionPoints: number;
            }
          | {
              __typename: 'ProgressionMetaDataPerisurgical';
              frequency: number;
            };
        activities?: Maybe<
          Array<{
            __typename: 'EtSessionActivity';
            uuid: string;
            kind: string;
            data?: Maybe<{
              __typename: 'EtSessionActivityMetadata';
              exercises: Array<{
                __typename: 'ExerciseConfig';
                uuid: string;
                holdTime?: Maybe<number>;
                name: string;
                reps: number;
                order?: Maybe<number>;
                flexTime?: Maybe<number>;
                restTime?: Maybe<number>;
                switchLimbTime?: Maybe<number>;
                numSteps: number;
                pointsPerRep?: Maybe<number>;
                repType?: Maybe<RepType>;
                numHoldMotions: number;
                anatomicalName?: Maybe<string>;
                thumbnail?: Maybe<{
                  __typename: 'Asset';
                  contentType: string;
                  description?: Maybe<string>;
                  url: string;
                }>;
              }>;
              userSubstitutions?: Maybe<
                Array<{
                  __typename: 'UserSubstitution';
                  newExercise: {
                    __typename: 'UserSubstitutionMetadata';
                    name: string;
                    uuid: string;
                  };
                  originalExercise: {
                    __typename: 'OriginalExerciseData';
                    name: string;
                    uuid: string;
                    order: number;
                    reps: number;
                    pointsPerRep?: Maybe<number>;
                    restTime?: Maybe<number>;
                    holdTime?: Maybe<number>;
                    flexTime?: Maybe<number>;
                    switchLimbTime?: Maybe<number>;
                    originalActivityUuid: string;
                  };
                }>
              >;
            }>;
          }>
        >;
      }>;
    }>;
  };
};

export type GetAllExercisesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllExercisesQuery = {
  __typename: 'Query';
  exercisesGetAll: Array<{
    __typename: 'ExerciseConfig';
    uuid: string;
    holdTime?: Maybe<number>;
    name: string;
    reps: number;
    order?: Maybe<number>;
    flexTime?: Maybe<number>;
    restTime?: Maybe<number>;
    switchLimbTime?: Maybe<number>;
    numSteps: number;
    pointsPerRep?: Maybe<number>;
    repType?: Maybe<RepType>;
    numHoldMotions: number;
    anatomicalName?: Maybe<string>;
    thumbnail?: Maybe<{
      __typename: 'Asset';
      contentType: string;
      description?: Maybe<string>;
      url: string;
    }>;
  }>;
};

export type GetAllPerisurgicalTemplatesQueryVariables = Exact<{
  activityPlansGetInput: ActivityPlansGetInput;
}>;

export type GetAllPerisurgicalTemplatesQuery = {
  __typename: 'Query';
  activityPlansGetAll: {
    __typename: 'ActivityPlansGetPayload';
    page: number;
    totalCount: number;
    activityPlans: Array<{
      __typename: 'ActivityPlan';
      uuid: string;
      name?: Maybe<string>;
      numProgressions: number;
      uri: string;
      data?: Maybe<{
        __typename: 'ActivityPlanMetaData';
        condition?: Maybe<string>;
        bodyRegion?: Maybe<string>;
      }>;
      draft?: Maybe<{
        __typename: 'ActivityPlanDraft';
        uuid: string;
        updatedAt: Date;
        createdAt: Date;
        isOwnedByCurrentAdmin: boolean;
      }>;
    }>;
  };
};

export type GetConfigsSummaryQueryVariables = Exact<{
  activityPlansGetInput: ActivityPlansGetInput;
}>;

export type GetConfigsSummaryQuery = {
  __typename: 'Query';
  activityPlansGetAll: {
    __typename: 'ActivityPlansGetPayload';
    activityPlans: Array<{
      __typename: 'ActivityPlan';
      uuid: string;
      uri: string;
      draft?: Maybe<{ __typename: 'ActivityPlanDraft'; uuid: string }>;
    }>;
  };
};

export type GetExerciseByUuidQueryVariables = Exact<{
  input: ExerciseGetByUuidInput;
}>;

export type GetExerciseByUuidQuery = {
  __typename: 'Query';
  exerciseGetByUuid: {
    __typename: 'ExerciseGetPayload';
    uuid: string;
    name: string;
    reps: number;
    anatomicalName?: Maybe<string>;
    repType?: Maybe<RepType>;
    holdTime?: Maybe<number>;
    pointsPerRep?: Maybe<number>;
    switchLimbTime?: Maybe<number>;
    numHoldMotions: number;
    equipment?: Maybe<
      Array<{
        __typename: 'Equipment';
        uuid: string;
        name: string;
        image?: Maybe<{
          __typename: 'Asset';
          description?: Maybe<string>;
          url: string;
        }>;
      }>
    >;
    etPreview: {
      __typename: 'ExercisePreview';
      message: string;
      verbalCue?: Maybe<{
        __typename: 'VerbalCue';
        uuid: string;
        text?: Maybe<string>;
        url: string;
      }>;
      image?: Maybe<{
        __typename: 'Asset';
        description?: Maybe<string>;
        url: string;
      }>;
    };
    motions: Array<{
      __typename: 'MotionDefinition';
      uuid: string;
      type: MotionDefinitionType;
      text: string;
      image?: Maybe<{
        __typename: 'Asset';
        description?: Maybe<string>;
        url: string;
      }>;
      verbalCues: Array<{
        __typename: 'VerbalCue';
        uuid: string;
        text?: Maybe<string>;
        url: string;
      }>;
    }>;
  };
};

export type GetUserQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;

export type GetUserQuery = {
  __typename: 'Query';
  userGet: {
    __typename: 'User';
    id: string;
    uuid: string;
    indication: string;
    program: string;
    pathwayUuid: string;
    level?: Maybe<number>;
    activityPlans: Array<{
      __typename: 'ActivityPlan';
      uuid: string;
      uri: string;
      name?: Maybe<string>;
      status: ActivityPlanStatus;
      updatedAt: Date;
      draft?: Maybe<{
        __typename: 'ActivityPlanDraft';
        uuid: string;
        updatedAt: Date;
        createdAt: Date;
        isOwnedByCurrentAdmin: boolean;
      }>;
    }>;
  };
};

export const ProgressionDataChronicFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressionDataChronic' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProgressionMetaDataChronic' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'totalLevelPoints' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numEtSessions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'etSessionPoints' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ProgressionDataChronicFragment, unknown>;
export const ProgressionDataAcuteFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressionDataAcute' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProgressionMetaDataAcute' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ProgressionDataAcuteFragment, unknown>;
export const ProgressionDataPerisurgicalFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ProgressionDataPerisurgical' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ProgressionMetaDataPerisurgical' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'frequency' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ProgressionDataPerisurgicalFragment, unknown>;
export const ExerciseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Exercise' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExerciseConfig' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flexTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'switchLimbTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numSteps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointsPerRep' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'thumbnail' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'contentType' } },
                { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                { kind: 'Field', name: { kind: 'Name', value: 'url' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'repType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numHoldMotions' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anatomicalName' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ExerciseFragment, unknown>;
export const UserSubstitutionMetadataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSubstitutionMetadata' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSubstitutionMetadata' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserSubstitutionMetadataFragment, unknown>;
export const OriginalExerciseDataFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'OriginalExerciseData' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'OriginalExerciseData' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointsPerRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'restTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'flexTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'switchLimbTime' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalActivityUuid' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<OriginalExerciseDataFragment, unknown>;
export const UserSubstitutionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'UserSubstitution' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'UserSubstitution' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'newExercise' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'UserSubstitutionMetadata' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'originalExercise' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'OriginalExerciseData' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserSubstitutionFragment, unknown>;
export const EtSessionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EtSession' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'EtSessionActivity' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'kind' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'exercises' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Exercise' }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'userSubstitutions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UserSubstitution' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EtSessionFragment, unknown>;
export const ProgressionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Progression' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Progression' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'order' } },
          { kind: 'Field', name: { kind: 'Name', value: 'current' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isCustom' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'wasModifiedByAdmin' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'etpeVersion' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProgressionDataChronic' }
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProgressionDataAcute' }
                },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ProgressionDataPerisurgical' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activities' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EtSession' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ProgressionFragment, unknown>;
export const DraftActivityPlanFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DraftActivityPlan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityPlanDraft' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOwnedByCurrentAdmin' }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bodyRegion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'condition' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progressions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Progression' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DraftActivityPlanFragment, unknown>;
export const ActivityPlanFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ActivityPlan' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityPlan' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
          { kind: 'Field', name: { kind: 'Name', value: 'userUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'startedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'status' } },
          { kind: 'Field', name: { kind: 'Name', value: 'isBeta' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'data' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'bodyRegion' } },
                { kind: 'Field', name: { kind: 'Name', value: 'condition' } }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'draft' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'DraftActivityPlan' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progressions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Progression' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ActivityPlanFragment, unknown>;
export const AssetFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Asset' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Asset' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AssetFragment, unknown>;
export const EquipmentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Equipment' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Equipment' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Asset' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EquipmentFragment, unknown>;
export const VerbalCueFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'VerbalCue' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'VerbalCue' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          { kind: 'Field', name: { kind: 'Name', value: 'url' } }
        ]
      }
    }
  ]
} as unknown as DocumentNode<VerbalCueFragment, unknown>;
export const EtPreviewFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EtPreview' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExercisePreview' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'message' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verbalCue' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VerbalCue' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Asset' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<EtPreviewFragment, unknown>;
export const MotionFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Motion' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'MotionDefinition' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'type' } },
          { kind: 'Field', name: { kind: 'Name', value: 'text' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Asset' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verbalCues' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'VerbalCue' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<MotionFragment, unknown>;
export const ExtendedExerciseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ExtendedExercise' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ExerciseGetPayload' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
          { kind: 'Field', name: { kind: 'Name', value: 'reps' } },
          { kind: 'Field', name: { kind: 'Name', value: 'anatomicalName' } },
          { kind: 'Field', name: { kind: 'Name', value: 'repType' } },
          { kind: 'Field', name: { kind: 'Name', value: 'holdTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pointsPerRep' } },
          { kind: 'Field', name: { kind: 'Name', value: 'switchLimbTime' } },
          { kind: 'Field', name: { kind: 'Name', value: 'numHoldMotions' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'equipment' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Equipment' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'etPreview' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'EtPreview' }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'motions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Motion' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ExtendedExerciseFragment, unknown>;
export const ProgressionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Progressions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityPlan' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progressions' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Progression' }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ProgressionsFragment, unknown>;
export const DraftActivityPlanSummaryFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'DraftActivityPlanSummary' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'ActivityPlanDraft' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
          { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'isOwnedByCurrentAdmin' }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<DraftActivityPlanSummaryFragment, unknown>;
export const UserFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'User' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'User' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'indication' } },
          { kind: 'Field', name: { kind: 'Name', value: 'program' } },
          { kind: 'Field', name: { kind: 'Name', value: 'pathwayUuid' } },
          { kind: 'Field', name: { kind: 'Name', value: 'level' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlans' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'draft' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'DraftActivityPlanSummary'
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<UserFragment, unknown>;
export const AssignActivityPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'assignActivityPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityPlanAssignInput' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanAssignInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanAssign' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activityPlanAssignInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityPlanAssignInput' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityPlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActivityPlan' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...ActivityPlanFragmentDoc.definitions,
    ...DraftActivityPlanFragmentDoc.definitions,
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  AssignActivityPlanMutation,
  AssignActivityPlanMutationVariables
>;
export const LogoutDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'logout' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'logout' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'success' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<LogoutMutation, LogoutMutationVariables>;
export const RefreshDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'refresh' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'refresh' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accessExpiry' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refreshExpiry' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'adminId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<RefreshMutation, RefreshMutationVariables>;
export const VerifyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'verify' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'verifyInput' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'VerifyInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'verify' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'verifyInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'verifyInput' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'accessToken' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'accessExpiry' }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'refreshExpiry' }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'roles' } },
                { kind: 'Field', name: { kind: 'Name', value: 'adminId' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<VerifyMutation, VerifyMutationVariables>;
export const BatchCommitBetaDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'batchCommitBetaDrafts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanBetasCommitInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanBetasBatchCommit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'betas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  BatchCommitBetaDraftsMutation,
  BatchCommitBetaDraftsMutationVariables
>;
export const BatchCreateBetaDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'batchCreateBetaDrafts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: {
                kind: 'Name',
                value: 'ActivityPlanBetasDraftsCreateInput'
              }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanBetasDraftsBatchCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'drafts' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  BatchCreateBetaDraftsMutation,
  BatchCreateBetaDraftsMutationVariables
>;
export const BatchCreateBetasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'batchCreateBetas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanBetasCreateInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanBetasBatchCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'betas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  BatchCreateBetasMutation,
  BatchCreateBetasMutationVariables
>;
export const BatchDeleteBetaDraftsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'batchDeleteBetaDrafts' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanBetaDraftsDeleteInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanBetaDraftsDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'program' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indication' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  BatchDeleteBetaDraftsMutation,
  BatchDeleteBetaDraftsMutationVariables
>;
export const BatchDeleteBetasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'batchDeleteBetas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanBetasDeleteInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanBetasBatchDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'program' } },
                { kind: 'Field', name: { kind: 'Name', value: 'indication' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  BatchDeleteBetasMutation,
  BatchDeleteBetasMutationVariables
>;
export const BatchPromoteBetasDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'batchPromoteBetas' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanBetasPromoteInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanBetasBatchPromote' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'alphas' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'stream' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  BatchPromoteBetasMutation,
  BatchPromoteBetasMutationVariables
>;
export const CreateActivityPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createActivityPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityPlanCreateInput' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanCreateInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activityPlanCreateInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityPlanCreateInput' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityPlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  CreateActivityPlanMutation,
  CreateActivityPlanMutationVariables
>;
export const CommitDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'commitDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanDraftCommitInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanDraftCommit' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityPlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActivityPlan' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...ActivityPlanFragmentDoc.definitions,
    ...DraftActivityPlanFragmentDoc.definitions,
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<CommitDraftMutation, CommitDraftMutationVariables>;
export const CreateDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanDraftCreateInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanDraftCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'draft' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'DraftActivityPlan' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...DraftActivityPlanFragmentDoc.definitions,
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<CreateDraftMutation, CreateDraftMutationVariables>;
export const CreateDraftProgressionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'createDraftProgression' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProgressionDraftCreateInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progressionDraftCreate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'progressions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Progression' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  CreateDraftProgressionMutation,
  CreateDraftProgressionMutationVariables
>;
export const DeleteDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanDraftDeleteInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanDraftDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityPlan' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActivityPlan' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...ActivityPlanFragmentDoc.definitions,
    ...DraftActivityPlanFragmentDoc.definitions,
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<DeleteDraftMutation, DeleteDraftMutationVariables>;
export const DeleteDraftProgressionDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'deleteDraftProgression' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProgressionDraftDeleteInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progressionDraftDelete' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'progressions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Progression' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  DeleteDraftProgressionMutation,
  DeleteDraftProgressionMutationVariables
>;
export const SaveDraftDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'saveDraft' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ProgressionDraftUpdateInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'progressionDraftUpdate' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'progressions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'Progression' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<SaveDraftMutation, SaveDraftMutationVariables>;
export const GetActivityPlanDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getActivityPlan' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'uuid' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanGet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'uuid' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'uuid' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityPlan' }
                }
              ]
            }
          }
        ]
      }
    },
    ...ActivityPlanFragmentDoc.definitions,
    ...DraftActivityPlanFragmentDoc.definitions,
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  GetActivityPlanQuery,
  GetActivityPlanQueryVariables
>;
export const GetActivityPlanByUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getActivityPlanByUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlanGetByUserInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlanGetByUser' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ActivityPlan' }
                }
              ]
            }
          }
        ]
      }
    },
    ...ActivityPlanFragmentDoc.definitions,
    ...DraftActivityPlanFragmentDoc.definitions,
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  GetActivityPlanByUserQuery,
  GetActivityPlanByUserQueryVariables
>;
export const GetAllAcuteTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllAcuteTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityPlansGetInput' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlansGetInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlansGetAll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activityPlansGetInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityPlansGetInput' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityPlans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numProgressions' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'condition' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bodyRegion' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'draft' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'DraftActivityPlanSummary'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...DraftActivityPlanSummaryFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  GetAllAcuteTemplatesQuery,
  GetAllAcuteTemplatesQueryVariables
>;
export const GetAllChronicConfigsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllChronicConfigs' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityPlansGetInput' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlansGetInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlansGetAll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activityPlansGetInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityPlansGetInput' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityPlans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'ActivityPlan' }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numProgressions' }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...ActivityPlanFragmentDoc.definitions,
    ...DraftActivityPlanFragmentDoc.definitions,
    ...ProgressionFragmentDoc.definitions,
    ...ProgressionDataChronicFragmentDoc.definitions,
    ...ProgressionDataAcuteFragmentDoc.definitions,
    ...ProgressionDataPerisurgicalFragmentDoc.definitions,
    ...EtSessionFragmentDoc.definitions,
    ...ExerciseFragmentDoc.definitions,
    ...UserSubstitutionFragmentDoc.definitions,
    ...UserSubstitutionMetadataFragmentDoc.definitions,
    ...OriginalExerciseDataFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  GetAllChronicConfigsQuery,
  GetAllChronicConfigsQueryVariables
>;
export const GetAllExercisesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllExercises' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exercisesGetAll' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'Exercise' }
                }
              ]
            }
          }
        ]
      }
    },
    ...ExerciseFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  GetAllExercisesQuery,
  GetAllExercisesQueryVariables
>;
export const GetAllPerisurgicalTemplatesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getAllPerisurgicalTemplates' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityPlansGetInput' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlansGetInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlansGetAll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activityPlansGetInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityPlansGetInput' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'page' } },
                { kind: 'Field', name: { kind: 'Name', value: 'totalCount' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityPlans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'numProgressions' }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'data' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'condition' }
                            },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'bodyRegion' }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'draft' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'FragmentSpread',
                              name: {
                                kind: 'Name',
                                value: 'DraftActivityPlanSummary'
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    ...DraftActivityPlanSummaryFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  GetAllPerisurgicalTemplatesQuery,
  GetAllPerisurgicalTemplatesQueryVariables
>;
export const GetConfigsSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getConfigsSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'activityPlansGetInput' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ActivityPlansGetInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'activityPlansGetAll' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'activityPlansGetInput' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'activityPlansGetInput' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'activityPlans' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'uuid' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'uri' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'draft' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'uuid' }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<
  GetConfigsSummaryQuery,
  GetConfigsSummaryQueryVariables
>;
export const GetExerciseByUuidDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getExerciseByUuid' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'input' }
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'ExerciseGetByUuidInput' }
            }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'exerciseGetByUuid' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'input' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ExtendedExercise' }
                }
              ]
            }
          }
        ]
      }
    },
    ...ExtendedExerciseFragmentDoc.definitions,
    ...EquipmentFragmentDoc.definitions,
    ...AssetFragmentDoc.definitions,
    ...EtPreviewFragmentDoc.definitions,
    ...VerbalCueFragmentDoc.definitions,
    ...MotionFragmentDoc.definitions
  ]
} as unknown as DocumentNode<
  GetExerciseByUuidQuery,
  GetExerciseByUuidQueryVariables
>;
export const GetUserDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'getUser' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'userId' }
          },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'Int' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'userGet' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'userId' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'userId' }
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'User' }
                }
              ]
            }
          }
        ]
      }
    },
    ...UserFragmentDoc.definitions,
    ...DraftActivityPlanSummaryFragmentDoc.definitions
  ]
} as unknown as DocumentNode<GetUserQuery, GetUserQueryVariables>;
