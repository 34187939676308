import { TableCell as MuiTableCell } from '@material-ui/core';
import { HeadCell } from 'components/SortTableHead';
import styled from 'styled-components';

interface CellProps {
  width?: number;
  paddingtop?: number;
  paddingbottom?: number;
  paddingright?: number;
  paddingleft?: number;
  children: React.ReactNode;
}

export const TableCell = styled(MuiTableCell)<CellProps>`
  width: ${({ width = 20 }) => width}%;
  padding: ${({
    theme,
    paddingtop = 0,
    paddingbottom = 0,
    paddingright = 4,
    paddingleft = 4
  }) => theme.spacing(paddingtop, paddingright, paddingbottom, paddingleft)};
`;

const MediumTableHeader = styled(TableCell)`
  width: 28%;
`;

const SmallTableHeader = styled(TableCell)`
  width: 12%;
`;

const ActionsTableHeader = styled(TableCell)`
  padding-left: 0;
`;

export const perisurgicalTableHeadCells: HeadCell[] = [
  {
    id: 'name',
    cell: TableCell,
    label: 'Name'
  },
  {
    id: 'bodyRegion',
    cell: TableCell,
    label: 'Body region'
  },
  {
    id: 'condition',
    cell: SmallTableHeader,
    label: 'Condition'
  },
  {
    id: 'numProgressions',
    cell: SmallTableHeader,
    label: 'Weeks'
  },
  {
    id: 'activeDraft',
    cell: SmallTableHeader,
    label: 'Active Draft'
  },
  {
    id: 'actions',
    cell: ActionsTableHeader,
    label: 'Actions'
  }
];

export const acuteTableHeadCells: HeadCell[] = [
  {
    id: 'name',
    cell: TableCell,
    label: 'Name'
  },
  {
    id: 'bodyRegion',
    cell: TableCell,
    label: 'Body region'
  },
  {
    id: 'condition',
    cell: SmallTableHeader,
    label: 'Condition'
  },
  {
    id: 'numProgressions',
    cell: SmallTableHeader,
    label: 'Weeks'
  },
  {
    id: 'activeDraft',
    cell: SmallTableHeader,
    label: 'Active Draft'
  },
  {
    id: 'actions',
    cell: ActionsTableHeader,
    label: 'Actions'
  }
];

export const chronicTableHeadCells: HeadCell[] = [
  {
    id: 'name',
    cell: TableCell,
    label: 'Name'
  },
  {
    id: 'indication',
    cell: TableCell,
    label: 'Indication'
  },
  {
    id: 'numProgressions',
    cell: SmallTableHeader,
    label: 'Levels'
  },
  {
    id: 'viewProduction',
    cell: SmallTableHeader,
    label: 'Production'
  },
  {
    id: 'testAction',
    cell: ActionsTableHeader,
    label: 'Test'
  }
];

export const templateTableHeadCells: HeadCell[] = [
  {
    id: 'name',
    cell: TableCell,
    label: 'Name'
  },
  {
    id: 'indication',
    cell: TableCell,
    label: 'Indication'
  },
  {
    id: 'condition',
    cell: TableCell,
    label: 'Condition'
  },
  {
    id: 'numProgressions',
    cell: SmallTableHeader,
    label: 'Weeks'
  },
  {
    id: 'actions',
    cell: ActionsTableHeader,
    label: 'Actions'
  }
];

export const exerciseTableHeadCells: HeadCell[] = [
  {
    id: 'name',
    cell: TableCell,
    label: 'Name'
  },
  {
    id: 'anatomicalName',
    cell: MediumTableHeader,
    label: 'Anatomical Name'
  },
  {
    id: 'repType',
    cell: SmallTableHeader,
    label: 'Rep Type'
  },
  {
    id: 'actions',
    cell: ActionsTableHeader,
    label: 'Actions'
  }
];
