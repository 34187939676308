import React from 'react';
import {
  ChronicActivityPlanTabs,
  ChronicActivityPlanTabsProps
} from './ChronicActivityPlanTabs';
import {
  EditableChronicActivityPlan,
  EditableChronicActivityPlanProps
} from './EditableChronicActivityPlan';

export interface ChronicStreamsProps
  extends EditableChronicActivityPlanProps,
    ChronicActivityPlanTabsProps {}

export const ChronicStreamsEdit = ({
  onLow,
  onMedium,
  onHigh,
  ...rest
}: ChronicStreamsProps): JSX.Element => {
  return (
    <EditableChronicActivityPlan
      toolbar={
        <ChronicActivityPlanTabs
          onLow={onLow}
          onMedium={onMedium}
          onHigh={onHigh}
        />
      }
      {...rest}
    />
  );
};
