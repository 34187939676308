import { useEffect, useState } from 'react';

/**
 * Runs an async function that may be redefined during the life of a component once and only once.
 */
export const useAsyncEffectOnce = <T>(
  fn: () => Promise<T>,
  // Next line disabled because it's wrong and `deps` are used...
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deps: React.DependencyList
): boolean => {
  const [isInProgress, setIsInProgress] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  useEffect(() => {
    // Prevent a new async task from running if another is in progress
    if (!isInProgress && !isDone) {
      setIsInProgress(true);
      (async () => {
        await fn();
        setIsDone(true);
      })();
    }
  }, [isInProgress, isDone, fn]);

  return isDone;
};
