import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GraphQLError } from 'graphql';
import { Event, useMixpanel } from 'hooks/useMixpanel';
import { LocalStorage, LocalStorageKey } from 'utils/localStorage';

export const useDefaultErrorHandler = (): {
  defaultErrorHandler: (error: ApolloError) => void;
} => {
  const { trackEvent } = useMixpanel();

  const errorMessage = (error: GraphQLError): string =>
    `${error.message}${error.path ? `\n\tPath: ${error.path}` : ''}${
      error?.extensions?.code ? `\n\tCode: ${error.extensions.code}` : ''
    }`;

  const defaultErrorHandler = (error: ApolloError): void => {
    error.graphQLErrors.forEach(error => {
      if (error?.extensions) {
        const { code } = error.extensions;
        if (code === 'UNAUTHENTICATED' || code === 'FORBIDDEN') {
          LocalStorage.set(LocalStorageKey.LogoutEvent, Date.now());
          return;
        }
        if (code === 'INTERNAL_SERVER_ERROR') {
          // message format - "Request failed with status code 409"
          error.extensions.httpStatusCode = error.message.split(' ')[5];
        }
      }
      if (process.env.NODE_ENV === 'development') {
        console.error(new Error(errorMessage(error)));
      }
      trackEvent(Event.GRAPHQL_ERROR, { errorMessage: errorMessage(error) });
      Sentry.captureException(new Error(errorMessage(error)));
    });
  };

  return { defaultErrorHandler };
};
