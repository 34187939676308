import React, { Children, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProgressionTypes } from 'utils/activityPlan';
import { ProgressionStage } from '../ProgressionStage';
import { AcuteWeekHeader } from './AcuteWeekHeader';

export interface AcuteWeekProps {
  id: string;
  children?: React.ReactNode | null;
  week: number;
  etSessionTime: number;
  current?: boolean | null;
  onCopyUniqueExercises: () => void;
  onCopyAllExercises: () => void;
  canCopyExercises: boolean;
  expanded: boolean;
}

const EmptyWeek = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  text-align: center;
`;

export const AcuteWeek = ({
  id,
  week,
  current,
  etSessionTime,
  children,
  canCopyExercises,
  onCopyUniqueExercises,
  onCopyAllExercises,
  expanded
}: AcuteWeekProps): JSX.Element => {
  const [localExpanded, setExpanded] = useState<boolean>(expanded);

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  return (
    <ProgressionStage
      expanded={localExpanded}
      id={`${ProgressionTypes.acute}${week}`}
      header={
        <AcuteWeekHeader
          id={id}
          week={week}
          etSessionTime={etSessionTime}
          current={current}
          onToggleCollapse={() => setExpanded(!localExpanded)}
          expanded={localExpanded}
          canCopyExercises={canCopyExercises}
          onCopyUniqueExercises={onCopyUniqueExercises}
          onCopyAllExercises={onCopyAllExercises}
        />
      }
    >
      {children && Children.toArray(children).length ? (
        children
      ) : (
        <EmptyWeek>There are no exercises in this week.</EmptyWeek>
      )}
    </ProgressionStage>
  );
};
