import { Maybe } from 'types';

interface ListItem<Metadata extends Record<string, unknown>> {
  data?: Maybe<Metadata>;
  name?: Maybe<string>;
}

type GetMetadata<Item extends ListItem<any>> = Item extends ListItem<
  infer Metadata
>
  ? Metadata
  : never;

export type FlattenedListItem<Item extends ListItem<any>> = GetMetadata<Item> &
  {
    [Key in Exclude<keyof Item, 'data'>]: Item[Key];
  };

const flattenItem = <Item extends ListItem<any>>({
  data,
  ...rest
}: Item): FlattenedListItem<Item> => ({ ...data, ...rest });

/*
 * Flattening the list objects here
 * so that we don't have to traverse
 * through nested objects when sorting.
 */
const flattenList = <Item extends ListItem<any>>(
  list: Item[] = []
): FlattenedListItem<Item>[] => list.map(flattenItem);

export const flattenAndFilterList = <Item extends ListItem<any>>(
  list: Item[] = [],
  searchQuery: string
): FlattenedListItem<Item>[] => {
  if (!list) {
    return [];
  }
  const flatList = flattenList(list);
  return searchQuery === ''
    ? flatList
    : flatList.filter(list => list.name.toLowerCase().includes(searchQuery));
};
