import { useMutation } from '@apollo/client';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import { refetchActivityPlan } from 'providers/ActivityPlanProvider';
import { useCallback, useEffect } from 'react';
import {
  CreateDraftProgressionDocument,
  CreateDraftProgressionMutation,
  CreateDraftProgressionMutationVariables,
  DeleteDraftProgressionDocument,
  DeleteDraftProgressionMutation,
  DeleteDraftProgressionMutationVariables,
  ProgressionDraftCreateInput
} from 'types';

interface UseMutateProgressionResponse {
  addProgression: (input: ProgressionDraftCreateInput) => void;
  deleteProgression: (
    activityPlanUuid: string,
    progressionUuid: string,
    userUuid?: null | string
  ) => void;
}

export const useMutateProgression = (): UseMutateProgressionResponse => {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const [progressionCreate, { error: createError }] = useMutation<
    CreateDraftProgressionMutation,
    CreateDraftProgressionMutationVariables
  >(CreateDraftProgressionDocument, {
    onError: defaultErrorHandler
  });

  const [progressionDelete, { error: deleteError }] = useMutation<
    DeleteDraftProgressionMutation,
    DeleteDraftProgressionMutationVariables
  >(DeleteDraftProgressionDocument, {
    onError: defaultErrorHandler
  });

  useEffect(() => {
    if (createError) {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: 'Unable to duplicate this week',
        message: 'Try again'
      });
      return;
    }

    if (deleteError) {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: 'Unable to delete week',
        message: 'Try again'
      });
      return;
    }
  }, [createError, deleteError]);

  const addProgression = useCallback(
    async (input: ProgressionDraftCreateInput): Promise<void> => {
      await progressionCreate({
        variables: {
          input
        },
        fetchPolicy: 'no-cache',
        refetchQueries: [
          refetchActivityPlan(input.activityPlanUuid, input.userUuid)
        ]
      });
    },
    [progressionCreate]
  );

  const deleteProgression = useCallback(
    (
      activityPlanUuid: string,
      progressionUuid: string,
      userUuid?: null | string
    ): void => {
      if (window.confirm('Are you sure you want to delete this progression?')) {
        progressionDelete({
          variables: {
            input: {
              activityPlanUuid,
              progressionUuid,
              userUuid
            }
          },
          fetchPolicy: 'no-cache',
          update(cache, result) {
            if (result.data) {
              cache.evict({
                id: cache.identify({
                  __typename: 'Progression',
                  uuid: progressionUuid
                })
              });
            }
          },
          refetchQueries: [refetchActivityPlan(activityPlanUuid, userUuid)]
        });
      }
    },
    [progressionDelete]
  );

  return {
    addProgression,
    deleteProgression
  };
};
