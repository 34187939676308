import { RouteComponentProps } from '@reach/router';
import { Body } from 'components/layout/Body';
import { PageCard } from 'components/PageCard';
import PerisurgicalTemplateLibrary from 'components/PerisurgicalTemplateLibrary';
import React from 'react';

const cardStyles = {
  paddingTop: '24px'
};

const PerisurgicalTemplatesIndex: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    return (
      <Body
        pageTitle="Perisurgical templates"
        testId="PerisurgicalTemplatesIndexPage"
      >
        <PageCard style={cardStyles}>
          <PerisurgicalTemplateLibrary showSearch showCreateLink />
        </PageCard>
      </Body>
    );
  };

export default PerisurgicalTemplatesIndex;
