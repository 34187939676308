import React from 'react';
import { RepType } from 'types';
import { DraggableExercise } from '../DraggableExercise';
import { EditableExerciseChip } from '../EditableExerciseChip';

export interface EditableAcuteExerciseProps {
  index: number;
  uuid: string;
  name: string;
  anatomicalName?: string | null;
  reps: number;
  holdTime: number;
  thumbnailUrl?: string;
  onRepsChange: (value: number) => void;
  onHoldTimeChange: (value: number) => void;
  onDelete: (name: string) => void;
  onDuplicate: (name: string) => void;
  onEdit: (name: string) => void;
  onInvalidReps: (value: number, reasons: string[]) => void;
  onInvalidHold: (value: number, reasons: string[]) => void;
  repType?: RepType | null;
}

const validations = [
  (value: number) => ({
    isValid: value > 0,
    reason: 'must be greater than 0'
  }),
  (value: number) => ({
    isValid: value < 100,
    reason: 'must be less than 100'
  })
];

export const EditableAcuteExercise = ({
  index,
  uuid,
  name,
  anatomicalName,
  reps,
  holdTime,
  thumbnailUrl,
  repType,
  onRepsChange,
  onHoldTimeChange,
  onDelete,
  onDuplicate,
  onEdit,
  onInvalidReps,
  onInvalidHold
}: EditableAcuteExerciseProps): JSX.Element => (
  <DraggableExercise
    index={index}
    name={name}
    anatomicalName={anatomicalName}
    id={uuid}
    thumbnailUrl={thumbnailUrl}
    menuItems={[
      {
        text: 'Edit',
        onClick: (_, name) => onEdit(name)
      },
      {
        text: 'Duplicate',
        onClick: (_, name) => onDuplicate(name)
      },
      {
        text: 'Delete',
        onClick: (_, name) => onDelete(name)
      }
    ]}
  >
    <EditableExerciseChip
      label="reps"
      value={reps}
      onEdit={onRepsChange}
      onInvalid={onInvalidReps}
      validations={validations}
      repType={repType}
    />
    <EditableExerciseChip
      label="sec hold"
      value={holdTime / 1000}
      onEdit={value => onHoldTimeChange(value * 1000)}
      onInvalid={onInvalidHold}
      validations={validations}
    />
  </DraggableExercise>
);
