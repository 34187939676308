import { LoadingSpinner } from 'components/LoadingSpinner';
import { Event, useMixpanel } from 'hooks/useMixpanel';
import React, {
  Children,
  Profiler,
  ProfilerOnRenderCallback,
  ReactNode
} from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

export interface ActivityPlanStageProps {
  children: ReactNode;
  toolbar?: null | ReactNode;
  footer?: null | ReactNode;
  loading?: boolean;
  mainComponent?: ({ children }: { children: ReactNode }) => JSX.Element;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-between;
  min-height: inherit;
  width: 100%;
`;

const Main = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(6)};
  width: 100%;
`;

interface MainComponentProps {
  children?: React.ReactNode;
}

/**
 * Creates a `Main` component decorated with a Droppable container.
 *
 * To create an ActivityPlan that's droppable, override the `mainComponent`
 * prop.
 *
 * Example:
 * ```jsx
 * <ActivityPlanStage
 *   mainComponent={DroppableMain}
 * />
 * ```
 */
export const DroppableMain = ({
  children
}: MainComponentProps): JSX.Element => (
  <Droppable droppableId="droppable-progressions" type="progression">
    {provided => (
      <Main ref={provided.innerRef} {...provided.droppableProps}>
        {children}
        {provided.placeholder}
      </Main>
    )}
  </Droppable>
);

const useProfilerCallback = (numChildren: number): ProfilerOnRenderCallback => {
  const { trackEvent } = useMixpanel();
  const onRenderCallback: ProfilerOnRenderCallback = (
    _id,
    phase,
    actualDuration,
    baseDuration,
    startTime,
    commitTime
  ) => {
    if (phase === 'mount') {
      trackEvent(Event.LOAD_ACTIVITY_PLAN, {
        numChildren,
        actualDuration,
        baseDuration,
        startTime,
        commitTime
      });
    }
  };

  return onRenderCallback;
};

export const ActivityPlanStage = ({
  toolbar,
  children,
  footer,
  loading,
  mainComponent: MainComponent = Main
}: ActivityPlanStageProps): JSX.Element => (
  <Profiler
    id="activityPlan"
    onRender={useProfilerCallback(Children.count(children))}
  >
    <Container data-testid="activityPlanContainer">
      {toolbar}
      {loading ? (
        <LoadingSpinner minHeight="inherit" />
      ) : (
        <MainComponent data-testid="activityPlanMain">{children}</MainComponent>
      )}
      {footer}
    </Container>
  </Profiler>
);
