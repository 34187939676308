import {
  LibraryBooks as LibraryBooksIcon,
  LocalLibrary as LocalLibraryIcon,
  PersonRounded as PersonRoundedIcon
} from '@material-ui/icons/';
import { Link as ReachLink } from '@reach/router';
import Logo from 'components/Logo';
import { NavigationItem } from 'components/MenuGroup';
import React from 'react';
import styled from 'styled-components';
import { hasSuperAccess } from 'utils/authorization';
import AdminMenu from './AdminMenu';
import MenuGroup from './MenuGroup';

const Nav = styled.nav`
  background: #1b2430;
  color: rgba(255, 255, 255, 0.87);
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  font-size: 14px;
  height: 100vh;
  overflow-y: auto;
  width: 250px;
`;

const LogoWrapper = styled.div`
  display: flex;
  height: ${({ theme }) => theme.spacing(16)};
`;

const Link = styled(ReachLink)`
  display: flex;
`;

const patientLibraryItems: NavigationItem[] = [
  {
    label: 'Find a patient',
    route: '/users',
    testId: 'userSearchNavLink'
  }
];

const acuteProgramLink: NavigationItem = {
  label: 'Acute',
  route: '/programs/acute',
  testId: 'acuteProgramIndexLink'
};

const chronicProgramLink: NavigationItem = {
  label: 'Chronic',
  route: '/programs/chronic',
  testId: 'chronicProgramIndexLink'
};

const perisurgicalProgramLink: NavigationItem = {
  label: 'Perisurgical',
  route: '/programs/perisurgical',
  testId: 'perisurgicalProgramIndexLink'
};

const exerciseLibraryLink: NavigationItem[] = [
  {
    label: 'Exercises',
    route: '/exercises',
    testId: 'exerciseLibraryLink'
  }
];

const useProgramLibraryItems = (): NavigationItem[] => [
  ...(hasSuperAccess() ? [acuteProgramLink] : []),
  chronicProgramLink,
  perisurgicalProgramLink
];

const Navigation = (): JSX.Element => {
  const programLibraryItems = useProgramLibraryItems();

  return (
    <Nav aria-label="primary" data-testid="sideNavMenu">
      <LogoWrapper>
        <Link to="/">
          <Logo textColor="#fff" />
        </Link>
      </LogoWrapper>
      <MenuGroup
        title="Patients"
        icon={PersonRoundedIcon}
        items={patientLibraryItems}
      />
      <MenuGroup
        title="Program Library"
        icon={LibraryBooksIcon}
        items={programLibraryItems}
      />
      <MenuGroup
        title="Exercise Library"
        icon={LocalLibraryIcon}
        items={exerciseLibraryLink}
      />
      <AdminMenu />
    </Nav>
  );
};

export default Navigation;
