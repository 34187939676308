import { Button } from '@material-ui/core';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import { Form as FormikForm, FormikProps } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  acuteConditions,
  getSelectInput,
  perisurgicalConditions,
  Programs,
  SelectInput,
  textInputs
} from 'utils/programConstants';

export interface InitialValues {
  [key: string]: string;
  bodyRegion: string;
  condition: string;
  frequency: string;
  weeks: string;
  templateName: string;
}

interface Props extends FormikProps<InitialValues> {
  has409Error: boolean;
  program: string;
}

const FormFields = styled.div`
  display: flex;
  flex-direction: column;
`;

const FormAction = styled.div`
  display: flex;
  justify-content: space-around;
  width: 200px;
  margin: 36px 0;
`;

const TemplateForm = ({
  has409Error,
  values,
  touched,
  errors,
  isSubmitting,
  handleChange,
  resetForm,
  dirty,
  setFieldValue,
  setFieldTouched,
  program
}: Props): JSX.Element => {
  const [selectInputsState, setSelectInputsState] = useState<SelectInput[]>(
    getSelectInput(program as Programs)
  );
  const { bodyRegion } = values;
  const bodyRegionUpdated = touched['bodyRegion'];
  const shouldDisableCondition = (inputName: string): boolean =>
    inputName === 'condition' && !bodyRegionUpdated;

  useEffect(() => {
    setSelectInputsState(getSelectInput(program as Programs));
  }, [program]);

  const handleTouchedOnChange = (
    event: React.ChangeEvent,
    fieldName: string
  ): void => {
    setFieldTouched(fieldName, true);
    handleChange(event);
  };

  const alertUser = useCallback(
    (e: BeforeUnloadEvent): void => {
      if (dirty) {
        e.preventDefault();
        e.returnValue = true;
      }
    },
    [dirty]
  );

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, [alertUser]);

  useEffect(() => {
    if (bodyRegionUpdated) {
      const options =
        program === 'acute'
          ? acuteConditions[bodyRegion]
          : perisurgicalConditions[bodyRegion];
      const conditionInput: SelectInput = {
        ...selectInputsState[1],
        options
      };
      selectInputsState.splice(1, 1, conditionInput);
      setSelectInputsState(selectInputsState);
      setFieldValue('condition', '');
      setFieldTouched('condition', false);
    }
  }, [
    program,
    selectInputsState,
    bodyRegion,
    bodyRegionUpdated,
    setSelectInputsState,
    setFieldValue,
    setFieldTouched
  ]);

  return (
    <FormikForm aria-label="Create new template form">
      <FormFields>
        {selectInputsState.map(({ name, label, options }) => (
          <Select
            key={name}
            value={values[name]}
            label={label}
            menuItems={options}
            name={name}
            error={Boolean(errors[name] && touched[name])}
            errorMessage={errors[name]}
            disabledMessage={
              shouldDisableCondition(name)
                ? 'Choose Body Region First'
                : undefined
            }
            handleChange={(event: React.ChangeEvent) =>
              handleTouchedOnChange(event, name)
            }
            disabled={isSubmitting || shouldDisableCondition(name)}
          />
        ))}
        {textInputs.map(({ name, label }) => {
          const error =
            Boolean(errors[name] && touched[name]) ||
            (has409Error && name === 'templateName');
          const errorMessage =
            has409Error && name === 'templateName'
              ? 'Name already exists'
              : errors[name];
          return (
            <TextInput
              key={name}
              value={values[name]}
              label={label}
              name={name}
              error={error}
              errorMessage={errorMessage}
              handleChange={handleChange}
              disabled={isSubmitting}
            />
          );
        })}
      </FormFields>
      <FormAction>
        <Button
          size="medium"
          color="primary"
          onClick={() => resetForm()}
          data-testid="clear-button"
        >
          Clear
        </Button>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          type="submit"
          disabled={isSubmitting}
          data-testid="save-button"
        >
          Save
        </Button>
      </FormAction>
    </FormikForm>
  );
};

export default TemplateForm;
