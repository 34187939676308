import { Box } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export interface ActivityPlanToolbarProps {
  /** icon buttons provided to the toolbar */
  children: React.ReactNode;
  /** edit, delete, and/or commit actions for an activity plan */
  actionButtons: React.ReactNode;
}

const Toolbar = styled(Box)`
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 64px;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  padding-left: ${({ theme }) => theme.spacing(4)};
  padding-right: ${({ theme }) => theme.spacing(9)};
`;

const ActionButtons = styled.div`
  border-left: 1px solid #e0e0e0;
  display: flex;
  gap: ${({ theme }) => theme.spacing(7)};
  margin-left: ${({ theme }) => theme.spacing(2)};
  padding-left: ${({ theme }) => theme.spacing(5)};
`;

export const ActivityPlanToolbar = ({
  children,
  actionButtons
}: ActivityPlanToolbarProps): JSX.Element => (
  <Toolbar boxShadow={2} data-testid="activityPlanToolbar">
    <Wrapper>
      {children}
      <ActionButtons>{actionButtons}</ActionButtons>
    </Wrapper>
  </Toolbar>
);
