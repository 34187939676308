import { makeVar } from '@apollo/client';
export interface SnackbarState {
  type?: 'idle' | 'expired' | 'generic';
}

export interface IdleSnackbarState {
  type: 'idle';
  secondsLeft: number;
}

export interface ExpiredSnackbarState {
  type: 'expired';
}

export interface GenericSnackbarState {
  severity: 'success' | 'error' | 'info' | 'warning';
  title?: string;
  message?: string;
  type: 'generic';
}

type SnackbarStates =
  | IdleSnackbarState
  | ExpiredSnackbarState
  | GenericSnackbarState;

export const snackbarState = makeVar<SnackbarStates | null>(null);
