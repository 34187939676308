import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export interface HeaderProps {
  title: string;
  subTitle?: string;
  children?: React.ReactNode;
  className?: string;
}

const Header = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  text-transform: capitalize;
`;

const FlexTypography = styled(Typography)<{ component: string }>`
  flex: 1 1 100%;
`;

const Details = styled.div`
  margin-top: ${({ theme }) => theme.spacing(2)};
  flex: 1 1 100%;

  font-size: 14px;
  letter-spacing: 0.15px;
  line-height: 20px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(4)};
`;

const C10RHeader = ({
  title,
  subTitle,
  children,
  className
}: HeaderProps): JSX.Element => (
  <Header className={className}>
    <FlexTypography component="h1" variant="h4" data-testid="C10rHeaderTitle">
      {title}
    </FlexTypography>
    {subTitle ? (
      <FlexTypography
        component="h2"
        variant="h5"
        data-testid="C10rHeaderSubTitle"
      >
        {subTitle}
      </FlexTypography>
    ) : null}
    {children ? (
      <Details data-testid="C10rHeaderDetails">{children}</Details>
    ) : null}
  </Header>
);

export { C10RHeader as Header };
