import React from 'react';
import {
  PerisurgicalWeekHeader,
  PerisurgicalWeekHeaderProps
} from './PerisurgicalWeekHeader';

export interface EditablePerisurgicalWeekHeaderProps
  extends PerisurgicalWeekHeaderProps {
  onDelete: () => void;
  onDuplicate: () => void;
  isDuplicateDisabled: boolean;
}

export const EditablePerisurgicalWeekHeader = ({
  onDuplicate,
  isDuplicateDisabled,
  onDelete,
  ...rest
}: EditablePerisurgicalWeekHeaderProps): JSX.Element => (
  <PerisurgicalWeekHeader
    {...rest}
    menuItems={[
      {
        text: 'Duplicate',
        onClick: onDuplicate,
        disabled: isDuplicateDisabled
      },
      {
        text: 'Delete',
        onClick: onDelete
      }
    ]}
  />
);
