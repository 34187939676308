import { ChronicStreams as ChronicStreamsUi } from 'components/activity-plan/chronic/ChronicStreamsView';
import { useConfigStreamsHandler } from 'providers/hooks/useConfigStreamsHandler';
import React from 'react';
import {
  copyAllExercises,
  copyUniqueExercises
} from 'utils/copyExercisesToClipboard';

export const ChronicStreams = (): JSX.Element | null => {
  const { activityPlan, loading, onLow, onMedium, onHigh } =
    useConfigStreamsHandler();

  return (
    <ChronicStreamsUi
      activityPlan={activityPlan}
      loading={loading}
      onCopyAllExercises={copyAllExercises}
      onCopyUniqueExercises={copyUniqueExercises}
      onLow={onLow}
      onMedium={onMedium}
      onHigh={onHigh}
    />
  );
};
