import { IconButton, Tooltip } from '@material-ui/core';
import { PauseCircleOutline, PlayCircleOutline } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';

export interface AudioPLayerIconButtonProps {
  url: string;
  name: string;
  disabled?: boolean;
}

export const AudioPlayerIconButton = ({
  url,
  name,
  disabled = false
}: AudioPLayerIconButtonProps): JSX.Element => {
  const audio = useRef(new Audio(url)).current;
  const [isPlaying, setIsPlaying] = useState(false);
  const label = isPlaying ? `Pause ${name} audio` : `Play ${name} audio`;

  useEffect(() => {
    isPlaying ? audio.play() : audio.pause();
  }, [audio, isPlaying]);

  useEffect(() => {
    const handler = (): void => setIsPlaying(false);
    audio.addEventListener('ended', handler);
    return () => audio.removeEventListener('ended', handler);
  }, [audio]);

  const handleTogglePlay = (): void => setIsPlaying(!isPlaying);

  return (
    <Tooltip
      title={disabled ? 'audio unavailable' : label}
      placement="top-end"
      data-testid="audioIconTooltip"
    >
      <span>
        <IconButton
          aria-label={label}
          onClick={handleTogglePlay}
          disabled={disabled}
        >
          {isPlaying ? (
            <PauseCircleOutline color={disabled ? 'disabled' : 'action'} />
          ) : (
            <PlayCircleOutline color={disabled ? 'disabled' : 'action'} />
          )}
        </IconButton>
      </span>
    </Tooltip>
  );
};
