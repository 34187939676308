import { FetchResult, QueryOptions, useMutation } from '@apollo/client';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { refetchActivityPlan } from 'providers/ActivityPlanProvider';
import { useCallback, useEffect } from 'react';
import {
  ActivityPlanDraftCommitInput,
  ActivityPlanDraftDeleteInput,
  CommitDraftDocument,
  CommitDraftMutation,
  CommitDraftMutationVariables,
  DeleteDraftDocument,
  DeleteDraftMutation,
  DeleteDraftMutationVariables,
  GetAllAcuteTemplatesDocument,
  GetAllChronicConfigsDocument,
  GetAllPerisurgicalTemplatesDocument,
  GetUserDocument,
  ProgressionDraftUpdateInput,
  SaveDraftDocument,
  SaveDraftMutation,
  SaveDraftMutationVariables,
  UserFragmentDoc
} from 'types';
import { handleDraftErrorMessage } from 'utils/errorFormatter';
import { Programs } from 'utils/programConstants';
import { useDefaultErrorHandler } from './useDefaultErrorHandler';

type DeleteDraft = ActivityPlanDraftDeleteInput & { draftUuid: string };
type CommitDraft = ActivityPlanDraftCommitInput & { draftUuid: string };

interface UseMutateDraft {
  // useMutation functions may return undefined or result.errors when an error occurs
  saveDraft: (
    input: ProgressionDraftUpdateInput
  ) => Promise<FetchResult<SaveDraftMutation> | undefined>;
  commitDraft: (
    input: CommitDraft,
    options: { userId?: string }
  ) => Promise<FetchResult<CommitDraftMutation>>;
  deleteDraft: (
    input: DeleteDraft,
    options: { userId?: string }
  ) => Promise<FetchResult<DeleteDraftMutation> | undefined>;
  commitInProcess: boolean;
  saveInProcess: boolean;
  deleteInProcess: boolean;
}

export const refetchLibraries = (): Array<QueryOptions> => {
  const defaultPage = 0;
  const defaultCount = 250;

  return [
    {
      query: GetAllAcuteTemplatesDocument,
      variables: {
        activityPlansGetInput: {
          program: Programs.ACUTE,
          page: defaultPage,
          count: defaultCount
        }
      }
    },
    {
      query: GetAllPerisurgicalTemplatesDocument,
      variables: {
        activityPlansGetInput: {
          program: Programs.PERISURGICAL,
          page: defaultPage,
          count: defaultCount
        }
      }
    },
    {
      query: GetAllChronicConfigsDocument,
      variables: {
        activityPlansGetInput: {
          program: Programs.CHRONIC,
          page: defaultPage,
          count: defaultCount
        }
      }
    }
  ];
};

export const useMutateDraft = (): UseMutateDraft => {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const [saveDraftMutation, { error, loading: saveInProcess }] = useMutation<
    SaveDraftMutation,
    SaveDraftMutationVariables
  >(SaveDraftDocument, {
    onError: defaultErrorHandler
  });

  const [commitDraftMutation, { loading: commitInProcess }] = useMutation<
    CommitDraftMutation,
    CommitDraftMutationVariables
  >(CommitDraftDocument, {
    onError: defaultErrorHandler
  });

  const [deleteDraftMutation, { loading: deleteInProcess }] = useMutation<
    DeleteDraftMutation,
    DeleteDraftMutationVariables
  >(DeleteDraftDocument, { onError: defaultErrorHandler });

  const saveDraft = useCallback(
    async (input: ProgressionDraftUpdateInput) =>
      saveDraftMutation({
        variables: { input },
        fetchPolicy: 'no-cache',
        refetchQueries: [
          refetchActivityPlan(input.activityPlanUuid, input.userUuid)
        ]
      }),
    [saveDraftMutation]
  );

  useEffect(() => {
    if (error) {
      // Follows the save draft error message
      // Use case, if there is a error in beta config save, and user switches
      // to another tab(low, medium or high), it will to autosave
      const errorMessage = handleDraftErrorMessage(error, 'save');
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: errorMessage,
        message: 'Try again'
      });
    }
  }, [error]);

  const commitDraft = useCallback(
    async (input: CommitDraft, { userId }: { userId?: string } = {}) => {
      const { activityPlanUuid, userUuid, draftUuid, resetStartDate } = input;

      return commitDraftMutation({
        variables: {
          input: {
            activityPlanUuid,
            userUuid,
            resetStartDate
          }
        },
        update(cache, result) {
          if (result.data) {
            cache.evict({
              id: cache.identify({
                __typename: 'ActivityPlanDraft',
                uuid: draftUuid
              })
            });

            cache.evict({
              id: cache.identify({
                __typename: 'ActivityPlan',
                uuid: activityPlanUuid
              })
            });
            if (userId) {
              const user = cache.readFragment({
                fragment: UserFragmentDoc,
                fragmentName: 'User',
                id: cache.identify({
                  __typename: 'User',
                  uuid: userUuid,
                  id: userId
                })
              });
              if (user) {
                cache.writeQuery({
                  query: GetUserDocument,
                  variables: { userId: parseInt(userId) },
                  data: {
                    __typename: 'Query',
                    userGet: {
                      ...user,
                      activityPlans: [
                        result.data?.activityPlanDraftCommit.activityPlan
                      ],
                      __typename: 'User'
                    }
                  }
                });
              }
            }
          }
        },
        refetchQueries: [...(!userId ? refetchLibraries() : [])]
      });
    },
    [commitDraftMutation]
  );

  const deleteDraft = useCallback(
    async (input: DeleteDraft, { userId }: { userId?: string } = {}) => {
      const { activityPlanUuid, userUuid, draftUuid } = input;

      return deleteDraftMutation({
        variables: {
          input: {
            activityPlanUuid,
            userUuid
          }
        },
        update(cache, result) {
          if (result.data) {
            cache.evict({
              id: cache.identify({
                __typename: 'ActivityPlanDraft',
                uuid: draftUuid
              })
            });
          }
        },
        refetchQueries: [
          ...(userId
            ? [{ query: GetUserDocument, variables: { userId } }]
            : refetchLibraries())
        ]
      });
    },
    [deleteDraftMutation]
  );

  return {
    saveDraft,
    commitDraft,
    commitInProcess,
    saveInProcess,
    deleteDraft,
    deleteInProcess
  };
};
