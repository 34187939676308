import { useMutation } from '@apollo/client';
import { Button } from '@material-ui/core';
import { navigate } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import {
  ClientUserAuthSet,
  setUserAuthState
} from 'apollo/reactive-variables/userAuthState';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import { Event, useMixpanel } from 'hooks/useMixpanel';
import { useRefreshContext } from 'providers/RefreshProvider';
import React, { useEffect, useRef } from 'react';
import {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
  useGoogleLogin
} from 'react-google-login';
import { testLogin } from 'test-utils/login';
import { VerifyDocument } from 'types';
import { LocalStorage, LocalStorageKey } from 'utils/localStorage';

const googleClientID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '';
const isTest = process.env.NODE_ENV === 'test';

const GoogleOAuth = (): JSX.Element => {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const { identifyAdmin, trackEvent } = useMixpanel();
  const [verify, { loading, error }] = useMutation<{
    verify: ClientUserAuthSet;
  }>(VerifyDocument, {
    onError: defaultErrorHandler
  });
  const { startRefreshCountdown } = useRefreshContext();
  const startRefreshCountdownRef = useRef<typeof startRefreshCountdown>(
    startRefreshCountdown
  );

  useEffect(() => {
    startRefreshCountdownRef.current = startRefreshCountdown;
  }, [startRefreshCountdown]);

  const onSuccess = async (
    res: GoogleLoginResponse | GoogleLoginResponseOffline
  ): Promise<void> => {
    const {
      tokenId,
      profileObj: { email, imageUrl, name }
    } = res as GoogleLoginResponse;
    const variables = { verifyInput: { email, googleIdToken: tokenId } };
    const response = await verify({ variables });
    LocalStorage.set(LocalStorageKey.PTUserInfo, { imageUrl, name, email });

    if (response.data) {
      const {
        verify: { accessToken, accessExpiry, refreshExpiry, roles, adminId }
      } = response.data;
      startRefreshCountdownRef.current(accessExpiry, refreshExpiry);
      setUserAuthState({
        accessToken,
        roles,
        adminId
      });
      identifyAdmin();
      trackEvent(Event.SIGN_IN);
      const path =
        LocalStorage.get(LocalStorageKey.LastVisitedPage)?.pathname ?? '/';
      navigate(path);
      LocalStorage.remove(LocalStorageKey.LastVisitedPage);
    }
  };

  useEffect(() => {
    if (error) {
      snackbarState({
        severity: 'error',
        message: 'Try again',
        title: "Something went wrong. We couldn't sign you in.",
        type: 'generic'
      });
      trackEvent(Event.SIGN_IN_FAIL, {
        errorMessage: error.message
      });
    }
  }, [error, trackEvent]);

  const handleOAuthFailure = (error: any): void => {
    snackbarState({
      severity: 'error',
      message: 'Try again',
      title: 'Something went wrong with the Google OAuth sign in',
      type: 'generic'
    });
    trackEvent(Event.GOOGLE_OAUTH_FAIL, { error });
  };

  const { signIn } = useGoogleLogin({
    onSuccess,
    clientId: googleClientID,
    onFailure: handleOAuthFailure,
    responseType: 'permission',
    accessType: 'online',
    prompt: 'consent'
  });

  if (loading) {
    return <>Loading...</>;
  }

  return (
    <form>
      <Button
        onClick={isTest ? testLogin : signIn}
        variant="contained"
        color="primary"
        data-cy="loginButton"
      >
        Login with Google
      </Button>
    </form>
  );
};

export default GoogleOAuth;
