import Navigation from 'components/Navigation';
import React, { ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
  children: ReactNode;
}

const LayoutWrapper = styled.div`
  display: flex;
  position: fixed;
  width: 100%;
`;

const PageWrapper = styled.div`
  width: 100%;
`;

export default function NavLayout({ children }: Props): JSX.Element {
  return (
    <LayoutWrapper>
      <Navigation />
      <PageWrapper>{children}</PageWrapper>
    </LayoutWrapper>
  );
}
