import { CircularProgress, Typography } from '@material-ui/core';
import format from 'date-fns/format';
import React from 'react';
import styled from 'styled-components';
import { DraftStatuses } from '../../providers/DraftActivityPlanProvider';
export interface DraftStatusProps {
  status: DraftStatuses;
  lastUpdatedAt?: Date;
}

const Span = styled.span`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const DraftStatus = ({
  status,
  lastUpdatedAt
}: DraftStatusProps): JSX.Element | null => {
  switch (status) {
    case DraftStatuses.IDLE:
      return lastUpdatedAt ? (
        <Span>Updated at {format(lastUpdatedAt, 'p M/d/yyyy').trim()}</Span>
      ) : null;
    case DraftStatuses.SAVING:
      return (
        <Span>
          <CircularProgress
            data-testid="savingProgress"
            aria-labelledby="draftStatus"
            size={16}
          />
          <Typography id="draftStatus">Saving changes</Typography>
        </Span>
      );
    case DraftStatuses.DISCARDING:
      return (
        <Span>
          <CircularProgress
            data-testid="discardingProgress"
            aria-labelledby="draftStatus"
            size={16}
          />
          <Typography id="draftStatus">Discarding changes</Typography>
        </Span>
      );
    case DraftStatuses.COMMITTING:
      return (
        <Span>
          <CircularProgress
            data-testid="committingProgress"
            aria-labelledby="draftStatus"
            size={16}
          />
          <Typography id="draftStatus">Publishing changes</Typography>
        </Span>
      );
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.error(new Error(`Encountered unsupported status: ${status}`));
      }
      return null;
    }
  }
};
