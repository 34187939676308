import { RouteComponentProps } from '@reach/router';
import { Body } from 'components/layout/Body';
import { PageCard } from 'components/PageCard';
import React from 'react';

const Home: React.FC<RouteComponentProps> = () => {
  return (
    <Body pageTitle="Hello Configurator">
      <PageCard></PageCard>
    </Body>
  );
};

export default Home;
