import {
  CardHeader as MuiCardHeader,
  IconButton,
  Typography
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

interface Props {
  handleClose: () => void;
}

const CardHeader = styled(MuiCardHeader)`
  ${({ theme }) => `
    padding: ${theme.spacing(5, 0, 4, 8)};

    .MuiCardHeader-action {
      margin: ${theme.spacing(0, 6, 0, 0)};
    }
  `}
`;

const CloseIcon = styled(Close)`
  color: ${({ theme }) => theme.palette.common.black};
`;

export default function ExerciseLibraryFormHeader({
  handleClose
}: Props): JSX.Element {
  return (
    <CardHeader
      action={
        <IconButton
          aria-label="close exercise library"
          data-testid={`closeExerciseLibraryButton`}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      }
      disableTypography
      title={
        <Typography variant="h4" component="h2" id="exercise-library-title">
          Exercise Library
        </Typography>
      }
    />
  );
}
