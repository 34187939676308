import { useMutation } from '@apollo/client';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import { useCallback, useEffect } from 'react';
import {
  AssignActivityPlanDocument,
  AssignActivityPlanMutation,
  GetUserDocument
} from 'types';
import { User as UserBase } from 'types/user';
import { Programs } from 'utils/programConstants';

interface UseMutateProgressionResponse {
  assignLoading: boolean;
  handleAssignTemplate: (templateUuid: string) => void;
}

type User = Pick<
  UserBase,
  'id' | 'indication' | 'uuid' | 'program' | 'pathwayUuid' | 'level'
>;

export const useMutateTemplates = (
  user?: User,
  program?: Programs
): UseMutateProgressionResponse => {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const [activityPlanAssign, { loading: assignLoading, error }] =
    useMutation<AssignActivityPlanMutation>(AssignActivityPlanDocument, {
      onError: defaultErrorHandler,
      update(cache, { data }) {
        if (data?.activityPlanAssign.activityPlan && user) {
          cache.writeQuery({
            query: GetUserDocument,
            variables: { userId: parseInt(user.id) },
            data: {
              __typename: 'Query',
              userGet: {
                ...user,
                activityPlans: [data?.activityPlanAssign.activityPlan],
                __typename: 'User'
              }
            }
          });
        }
      }
    });

  useEffect(() => {
    if (error) {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: 'Unable to assign template',
        message: 'Try again'
      });
    }
  }, [error]);

  const handleAssignTemplate = useCallback(
    async (templateUuid: string): Promise<void> => {
      const variables = {
        activityPlanAssignInput: {
          activityPlanUuid: templateUuid,
          user: {
            pathwayUuid: user?.pathwayUuid,
            uuid: user?.uuid,
            indication: user?.indication,
            program
          }
        }
      };
      await activityPlanAssign({ variables });
    },
    [activityPlanAssign, user, program]
  );

  return { assignLoading, handleAssignTemplate };
};
