// TODO: remove this function once the
// activity service is setup to extract

import { Programs } from './programConstants';

// the program info.
interface ExtractedProgramInfo {
  program: Programs;
  indication: string;
  stream: string;
  isBeta: boolean;
}

export const extractProgramInfoFromUri = (uri = '::'): ExtractedProgramInfo => {
  const uriSplitArray = uri.split(':');
  const program = uriSplitArray[0] as Programs;
  const indication = uriSplitArray[1];
  const stream = uriSplitArray[2];
  const isBeta = !!uriSplitArray[3];

  return { program, indication, stream, isBeta };
};

export const getIndication = (uri = '::'): string => {
  return extractProgramInfoFromUri(uri).indication;
};

export const isBeta = (uri = '::'): boolean => {
  return extractProgramInfoFromUri(uri).isBeta;
};

export const isChronic = ({ uri }: { uri: string }): boolean =>
  extractProgramInfoFromUri(uri).program === Programs.CHRONIC;

export const isAcute = ({ uri }: { uri: string }): boolean =>
  extractProgramInfoFromUri(uri).program === Programs.ACUTE;

export const isPerisurgical = ({ uri }: { uri: string }): boolean =>
  extractProgramInfoFromUri(uri).program === Programs.PERISURGICAL;
