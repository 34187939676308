import { UserSubstitutionFragment } from 'types';
import {
  ConfigSummary,
  isEtSessionActivity,
  Progression
} from 'types/activityPlan';
import { DraftProgression } from 'types/draftActivityPlan';
import { Programs } from './programConstants';

export const DEFAULT_POINTS_PER_REP = 10;
export const DEFAULT_HOLD_TIME = 2000;

export enum ProgressionTypes {
  acute = 'Week',
  chronic = 'Level',
  perisurgical = 'Week'
}

export const extractProgressionTypeFromUri = (uri: string): string => {
  const key = uri.split(':')[0];
  return ProgressionTypes[key as keyof typeof ProgressionTypes];
};

export const computeActualSessionPoints = (progression: Progression): number =>
  (
    progression.activities?.find(isEtSessionActivity)?.data?.exercises ?? []
  ).reduce(
    (points, { pointsPerRep, reps }) =>
      points + (pointsPerRep ?? DEFAULT_POINTS_PER_REP) * reps,
    0
  );

export const doesProgressionHaveExercises = <
  T extends DraftProgression | Progression
>(
  progression: T
): boolean =>
  (
    progression.activities?.find(activity => activity.kind === 'et-session')
      ?.data?.exercises ?? []
  ).length > 0;

export const doProgressionsHaveExercises = <
  T extends DraftProgression | Progression
>(
  progressions: T[]
): boolean => progressions.some(doesProgressionHaveExercises);

export const findCurrentProgressionNum = (
  progressions: Progression[]
): number | null => {
  const order = progressions.find(prog => prog.current)?.order;
  if (order !== undefined) {
    return order + 1;
  }
  return null;
};

export const findChronicStreamUuid = (
  stream: string,
  configs: ConfigSummary[]
): string | undefined =>
  configs?.find(config => config.uri.includes(stream))?.uuid;

export const createSubstitutionMap = (
  userSubstitutions: UserSubstitutionFragment[]
): Map<number, string> => {
  const substitutionMap = new Map<number, string>();
  userSubstitutions.forEach(({ originalExercise }) => {
    if (substitutionMap.get(originalExercise.order)) {
      throw new Error(
        'Substitution records contains more than one substitution for an exercise'
      );
    }
    substitutionMap.set(originalExercise.order, originalExercise.name);
  });
  return substitutionMap;
};

export const isSupportedPlan = (program: string): boolean =>
  [Programs.ACUTE, Programs.CHRONIC, Programs.PERISURGICAL].some(
    supportedProgram => supportedProgram === program
  );
