import { ActivityPlan } from 'types/activityPlan';
import { getIndication, isBeta } from './extractProgramInfoFromUri';

type Program = ActivityPlan & { hasBetas: boolean };

/**
 * @param configs
 * @returns unique set (by indication) of configs with added hasBetas property
 */
export const formatConfigsForChronicLibrary = (
  configs: ActivityPlan[]
): Program[] =>
  configs.reduce((programs: Program[], plan): Program[] => {
    // Only collect indications for base configurations.
    if (isBeta(plan.uri)) {
      return programs;
    }
    const planIndication = getIndication(plan.uri);
    const duplicateIndication = programs.find(
      ({ uri }) => getIndication(uri) === planIndication
    );
    const matchingBeta = configs.find(
      ({ uri }) => isBeta(uri) && getIndication(uri) === planIndication
    );
    return [
      ...programs,
      ...(!duplicateIndication ? [{ ...plan, hasBetas: !!matchingBeta }] : [])
    ];
  }, []);
