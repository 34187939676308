import React, { useState } from 'react';
import {
  DEFAULT_HOLD_TIME,
  doesProgressionHaveExercises
} from 'utils/activityPlan';
import { isAcute } from 'utils/extractProgramInfoFromUri';
import { getEstimatedEtSessionTime } from 'utils/time';
import {
  ActivityPlan as ActivityPlanType,
  Progression
} from '../../../types/activityPlan';
import { ActivityPlanStage } from '../ActivityPlanStage';
import ExpandAllCheckbox from '../ExpandAllCheckbox';
import { AcuteExercise } from './AcuteExercise';
import { AcuteWeek } from './AcuteWeek';

export interface AcuteActivityProps {
  activityPlan: ActivityPlanType;
  onCopyUniqueExercises: (progression: Progression, message: string) => void;
  onCopyAllExercises: (progression: Progression, message: string) => void;
}

const copyMessage = (week: number): string =>
  `Week ${week} exercise list copied to clipboard`;

export const AcuteActivityPlan = ({
  activityPlan,
  onCopyUniqueExercises,
  onCopyAllExercises
}: AcuteActivityProps): JSX.Element => {
  const [expandAll, setExpandAll] = useState<boolean>(true);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setExpandAll(event.currentTarget.checked);

  return (
    <ActivityPlanStage>
      <ExpandAllCheckbox onChange={handleOnChange} defaultChecked={expandAll} />
      {activityPlan.progressions.map(progression => {
        const { current, order, uuid, activities } = progression;
        const exercises = activities?.[0]?.data?.exercises ?? [];
        const week = order + 1;

        return isAcute(activityPlan) ? (
          <AcuteWeek
            key={uuid}
            id={uuid}
            week={week}
            etSessionTime={getEstimatedEtSessionTime(exercises)}
            current={current}
            canCopyExercises={doesProgressionHaveExercises(progression)}
            onCopyUniqueExercises={() =>
              onCopyUniqueExercises(progression, copyMessage(week))
            }
            onCopyAllExercises={() =>
              onCopyAllExercises(progression, copyMessage(week))
            }
            expanded={expandAll}
          >
            {exercises.map(exercise => (
              <AcuteExercise
                key={exercise.uuid + exercise.order}
                uuid={exercise.uuid}
                name={exercise.name}
                reps={exercise.reps}
                thumbnailUrl={exercise.thumbnail?.url}
                holdTime={exercise.holdTime ?? DEFAULT_HOLD_TIME}
                repType={exercise.repType}
                anatomicalName={exercise.anatomicalName}
              />
            ))}
          </AcuteWeek>
        ) : (
          <p>Not acute</p>
        );
      })}
    </ActivityPlanStage>
  );
};
