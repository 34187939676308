const bffConfig: Record<string, string> = {
  local: 'http://localhost:3003/graphql',
  dev: 'https://configurator-bff.hingehealth.dev/graphql',
  stage: 'https://configurator-bff.hingehealth.io/graphql',
  production: 'https://configurator-bff.hingehealth.com/graphql',
};

export function getBffHost(): string {
  switch (process.env.REACT_APP_BFF_URL) {
    case 'production':
      return bffConfig.production;
    case 'dev':
      return bffConfig.dev;
    case 'stage':
      return bffConfig.stage;
    default:
      return bffConfig.local;
  }
}

const sharedSplitIOKey = {
  growProd: "b2qokvdcn8ord5pkp5vph5sjp1hs6f15h1g9",
  growDev: "at2ffb6pk4gurkclnlrguon499lecqeunooj",
  dev: "duc0uf7b5pr46cc3sq782dil3jl04bdut7r8",
  stage: "mfbc3t6t54mv0bk5291fpvmr7o4d6vkfii8s",
};

export function getSharedSplitIOKey(): string {
  // Logic reference:
  // https://github.com/hinge-health/phoenix/blob/master/src/commons/Store/FeatureFlags/SharedFeatureFlagsClient.ts#L26
  switch (process.env.REACT_APP_BFF_URL) {
    case 'production':
      return sharedSplitIOKey.growProd;
    case 'dev':
      return sharedSplitIOKey.dev;
    case 'stage':
      return sharedSplitIOKey.stage;
    default:
      return sharedSplitIOKey.growDev;
  }
}

const translationConfig: Record<string, string> = {
  dev: 'https://cdn.hingehealth.dev',
  stage: 'https://cdn.hingehealth.io',
  production: 'https://cdn.hingehealth.com',
};

export function getExerciseTranslationBase(): string {
  switch (process.env.REACT_APP_ENV) {
    case 'production':
      return translationConfig.production;
    case 'dev':
      return translationConfig.dev;
    case 'stage':
      return translationConfig.stage;
    default:
      return translationConfig.dev;
  }
}
