import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { ChronicStreamsEdit as ChronicStreamsEditUi } from 'components/activity-plan/chronic/ChronicStreamsEdit';
import {
  useDraftActivityPlan,
  useDraftActivityPlanAPI
} from 'providers/DraftActivityPlanProvider';
import { useConfigStreamsHandler } from 'providers/hooks/useConfigStreamsHandler';
import React from 'react';
import { TargetProgression } from 'types/targetProgression';
import {
  copyAllExercises,
  copyUniqueExercises
} from 'utils/copyExercisesToClipboard';

const createInvalidHandler =
  (name: string, min: number): (() => void) =>
  () => {
    snackbarState({
      type: 'generic',
      severity: 'error',
      message: `Please enter a number greater than ${min} for ${name}`
    });
  };

const onInvalidHoldExercise = createInvalidHandler('hold time', 0);
const onInvalidPointsPerRepExercise = createInvalidHandler('pointsPerRep', 0);
const onInvalidRepsExercise = createInvalidHandler('reps', 0);

export interface EditableChronicActivityPlanProps {
  onAddExercises: (target: TargetProgression) => void;
}

export const EditableChronicStreams = ({
  onAddExercises
}: EditableChronicActivityPlanProps): JSX.Element | null => {
  const { loading, onLow, onMedium, onHigh } = useConfigStreamsHandler();
  const { draft } = useDraftActivityPlan();
  const {
    removeExercise,
    duplicateExercise,
    editExerciseDetails,
    moveExercise
  } = useDraftActivityPlanAPI();

  return (
    <ChronicStreamsEditUi
      activityPlan={draft}
      loading={loading}
      onLow={onLow}
      onMedium={onMedium}
      onHigh={onHigh}
      onAddExercises={onAddExercises}
      onCopyAllExercises={copyAllExercises}
      onCopyUniqueExercises={copyUniqueExercises}
      onDeleteExercise={removeExercise}
      onDuplicateExercise={duplicateExercise}
      onEditExercise={editExerciseDetails}
      onMoveExercise={moveExercise}
      onInvalidHoldExercise={onInvalidHoldExercise}
      onInvalidRepsExercise={onInvalidRepsExercise}
      onInvalidPointsPerRepExercise={onInvalidPointsPerRepExercise}
    />
  );
};
