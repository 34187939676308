import {
  Backdrop,
  Box,
  Button as MuiButton,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider,
  Fade,
  Modal,
  Typography
} from '@material-ui/core';
import { navigate } from '@reach/router';
import { AcuteActivityPlan } from 'connected/activity-plan/AcuteActivityPlan';
import { PerisurgicalActivityPlan } from 'connected/activity-plan/PerisurgicalActivityPlan';
import {
  ActivityPlanProvider,
  useActivityPlan
} from 'providers/ActivityPlanProvider';
import { DraftActivityPlanProvider } from 'providers/DraftActivityPlanProvider';
import React from 'react';
import styled from 'styled-components';
import { User } from 'types/user';
import { hasSuperAccess } from 'utils/authorization';
import { extractProgramInfoFromUri } from 'utils/extractProgramInfoFromUri';
import { Programs } from 'utils/programConstants';
import { useMutateTemplates } from '../hooks/useMutateTemplates';
import { LoadingSpinner } from './LoadingSpinner';

interface Props {
  targetTemplate: string | null;
  user?: User;
  program: Programs;
  handleClosePreviewModal: () => void;
}

const Card = styled(MuiCard)`
  height: calc(100% - 64px);
  margin: ${({ theme }) => theme.spacing(8, 'auto')};
  max-width: 680px;
  position: relative;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  height: 64px;
  padding-left: ${({ theme }) => theme.spacing(6)};
`;

const CardContent = styled(MuiCardContent)`
  height: calc(100% - 150px);
  overflow-y: auto;
`;

const ActionsWrapper = styled(Box)`
  background-color: ${({ theme }) => theme.palette.common.white};
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  padding: ${({ theme }) => theme.spacing(2, 0)};
  position: absolute;
  width: 100%;
`;

const Button = styled(MuiButton)`
  height: 36px;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const PreviewActivityPlan = ({ uri }: { uri: string }): JSX.Element | null => {
  const { program } = extractProgramInfoFromUri(uri);
  switch (program) {
    case Programs.PERISURGICAL:
      return <PerisurgicalActivityPlan />;
    case Programs.ACUTE:
      return <AcuteActivityPlan />;
    default:
      return null;
  }
};

const PreviewModalBody = ({
  open,
  user,
  program,
  handleClosePreviewModal
}: Omit<Props, 'targetTemplate'> & { open: boolean }): JSX.Element => {
  const hasEditAccess = hasSuperAccess();
  const { activityPlan, loading } = useActivityPlan();
  const assignable = Boolean(user) && hasEditAccess;
  const { assignLoading, handleAssignTemplate } = useMutateTemplates(
    user,
    program
  );

  const handleEditNavigation = (): void => {
    navigate(`/programs/${program}/${activityPlan?.uuid}/edit`);
  };

  return (
    <Modal
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
      data-testid="templatePreviewModal"
    >
      <Fade in={open}>
        <Card>
          <Header data-testid="templatePreviewModalHeader">
            <Typography variant="h6" component="h3">
              {activityPlan?.name}
            </Typography>
          </Header>
          <Divider />
          <CardContent>
            {!activityPlan || loading ? (
              <LoadingSpinner />
            ) : (
              <PreviewActivityPlan uri={activityPlan.uri} />
            )}
          </CardContent>
          <ActionsWrapper borderTop={1} borderColor="divider">
            <Button
              color="primary"
              onClick={handleClosePreviewModal}
              data-testid="closePreviewModalButton"
            >
              CLOSE
            </Button>
            {assignable ? (
              <Button
                color="primary"
                disabled={assignLoading}
                onClick={() => {
                  if (activityPlan) {
                    handleAssignTemplate(activityPlan.uuid);
                  }
                }}
                data-testid="assignPreviewModalButton"
              >
                ASSIGN
              </Button>
            ) : hasEditAccess ? (
              <Button
                color="primary"
                onClick={handleEditNavigation}
                data-testid="editPreviewModalButton"
              >
                EDIT
              </Button>
            ) : null}
          </ActionsWrapper>
        </Card>
      </Fade>
    </Modal>
  );
};

export default function PreviewModal({
  targetTemplate,
  user,
  program,
  handleClosePreviewModal
}: Props): JSX.Element | null {
  const open = Boolean(targetTemplate);

  return (
    <ActivityPlanProvider uuid={targetTemplate}>
      <DraftActivityPlanProvider>
        <PreviewModalBody
          open={open}
          handleClosePreviewModal={handleClosePreviewModal}
          user={user}
          program={program}
        />
      </DraftActivityPlanProvider>
    </ActivityPlanProvider>
  );
}
