import { Chip as MuiChip } from '@material-ui/core';
import { common } from '@material-ui/core/colors';
import React from 'react';
import styled from 'styled-components';
import { RepType } from 'types';
import { repTypeProps } from 'utils/programConstants';

export interface RepTypeChipProps {
  repType?: RepType | null;
}

const Chip = styled(props => <MuiChip {...props} />)`
  background-color: ${props => props.bgcolor};
  color: ${common.white};
`;

export const RepTypeChip = ({
  repType
}: RepTypeChipProps): JSX.Element | null => {
  return repType ? (
    <Chip
      size="small"
      label={repTypeProps[repType].label}
      bgcolor={repTypeProps[repType].color}
    />
  ) : null;
};

export default RepTypeChip;
