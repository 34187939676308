import {
  Button,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableRow as MuiTableRow,
  Typography
} from '@material-ui/core';
import { navigate } from '@reach/router';
import { useSorted } from 'hooks/useSorted';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import {
  ActivityPlanStatus,
  DraftActivityPlanSummaryFragment,
  Maybe
} from 'types';
import { isSupportedPlan } from 'utils/activityPlan';
import { hasSuperAccess } from 'utils/authorization';
import { extractProgramInfoFromUri } from 'utils/extractProgramInfoFromUri';
import { userActivityPlansTableHeadCells } from 'utils/userActivityPlansTableCells';
import { ActivityPlanStatusWithCaption } from './activity-plan/ActivityPlanStatus';
import SortTableHead from './SortTableHead';

interface UserActivityPlan {
  uuid: string;
  uri: string;
  name?: string | null;
  draft?: Maybe<DraftActivityPlanSummaryFragment>;
  status: ActivityPlanStatus;
  updatedAt: Date;
}

interface Props {
  activityPlans: UserActivityPlan[];
  userId: string;
}

interface FormattedActivityPlan {
  uuid: string;
  program: string;
  indication: string;
  name?: string | null;
  draft?: Maybe<DraftActivityPlanSummaryFragment>;
  status: ActivityPlanStatus;
  updatedAt: Date;
}

type OrderByUserActivityPlans = 'program' | 'indication' | 'name';

type InterfaceToRecord<I> = {
  [P in keyof I]: I[P];
};

const isOrderByUserActivityPlans = (
  property: string
): property is OrderByUserActivityPlans => {
  return (
    property === 'program' || property === 'indication' || property === 'name'
  );
};

const Header = styled.header`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(5)};
    text-indent: ${theme.spacing(2)};
  `}
`;

const Divider = styled(MuiDivider)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const TableRow = styled(MuiTableRow)`
  height: 52px;
`;

const TableCell = styled(MuiTableCell)`
  padding: ${({ theme }) => theme.spacing(0, 4)};
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
`;

export default function UserActivityPlansTable({
  activityPlans,
  userId
}: Props): JSX.Element {
  const hasEditAccess = hasSuperAccess();
  const { order, orderBy, toggle, setItems, items } = useSorted<
    OrderByUserActivityPlans,
    InterfaceToRecord<FormattedActivityPlan>
  >({
    initialOrderBy: 'program'
  });

  useEffect(() => {
    const formattedActivityPlans = activityPlans.map(
      (activityPlan: UserActivityPlan): FormattedActivityPlan => {
        const { uuid, uri, name, draft, status, updatedAt } = activityPlan;
        const { program, indication } = extractProgramInfoFromUri(uri);
        return { uuid, program, indication, name, draft, status, updatedAt };
      }
    );
    setItems(formattedActivityPlans);
  }, [activityPlans, setItems]);

  const handleRequestSort = (
    event: React.MouseEvent,
    property: string
  ): void => {
    if (isOrderByUserActivityPlans(property)) {
      toggle(property);
    }
  };

  return (
    <div data-testid="userActivityPlansTable">
      <Header>
        <Typography variant="h5" component="h2">
          Assigned Activity Plans
        </Typography>
      </Header>
      <Divider />
      <Table aria-label="Assigned activity plans table">
        <SortTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          tableHeadCells={userActivityPlansTableHeadCells}
        />
        <TableBody data-testid="userActivityPlansTableBody">
          {items.map(
            ({
              uuid,
              program,
              indication,
              name,
              draft,
              status,
              updatedAt
            }): JSX.Element => (
              <TableRow key={uuid} data-testid="templateLibraryItem">
                <TableCell data-testid="programCell">{program}</TableCell>
                <TableCell data-testid="indicationCell">{indication}</TableCell>
                <TableCell data-testid="nameCell">
                  {/* Name is currently null, but will be sent in the future */}
                  <Typography color="textSecondary">{name}</Typography>
                </TableCell>
                <TableCell data-testid="statusCell">
                  <ActivityPlanStatusWithCaption
                    status={status}
                    updatedAt={updatedAt}
                  />
                </TableCell>
                <TableCell>
                  <ActionsWrapper>
                    <Button
                      color="primary"
                      onClick={() =>
                        navigate(`/users/${userId}/activity-plans/${uuid}`)
                      }
                      data-testid="viewPlanButton"
                      disabled={!isSupportedPlan(program)}
                    >
                      VIEW
                    </Button>
                    {hasEditAccess ? (
                      <Button
                        color="primary"
                        onClick={() =>
                          navigate(
                            `/users/${userId}/activity-plans/${uuid}/edit`
                          )
                        }
                        data-testid="editPlanButton"
                        disabled={
                          !!(draft && !draft.isOwnedByCurrentAdmin) ||
                          !isSupportedPlan(program)
                        }
                      >
                        EDIT
                      </Button>
                    ) : null}
                  </ActionsWrapper>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </div>
  );
}
