import React from 'react';
import { ActivityPlan } from 'types/activityPlan';
import { extractProgramInfoFromUri } from 'utils/extractProgramInfoFromUri';
import { Programs } from 'utils/programConstants';
import { ChronicUserPDFDocument } from './ChronicUserPDFDocument';
import { DefaultUserPDFDocument } from './DefaultUserPDFDocument';

interface PDFDocumentSwitchProps {
  activityPlan: ActivityPlan;
  userId: string;
}
export const UserPDFDocument = ({
  activityPlan,
  userId
}: PDFDocumentSwitchProps): JSX.Element => {
  const { program } = extractProgramInfoFromUri(activityPlan.uri);
  switch (program) {
    case Programs.CHRONIC:
      return (
        <ChronicUserPDFDocument activityPlan={activityPlan} userId={userId} />
      );
    default:
      return (
        <DefaultUserPDFDocument
          activityPlan={activityPlan}
          userId={userId}
          startedAt={activityPlan.startedAt}
        />
      );
  }
};
