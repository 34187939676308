import { camelCase } from 'lodash';

const isExerciseSlug = (text: string): boolean => {
  let isSlug = true;
  const splitString = text.split('-');
  splitString.forEach(item => {
    if (item === '' || item !== item.toLowerCase()) {
      isSlug = false;
    }
  });
  return isSlug;
};

export const isSlug = (text: string): boolean => {
  const splitText = text.split('.');

  if (splitText.length === 1 || !isExerciseSlug(splitText[0])) {
    return false;
  }

  for (let i = 1; i < splitText.length; i++) {
    if (!camelCase(splitText[i]) || splitText[i].includes(' ')) {
      return false;
    }
  }
  return true;
};
