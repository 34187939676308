import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';
import * as Sentry from '@sentry/react';
import { useAsyncEffectOnce } from 'hooks/useAsyncEffectOnce';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import { useLogout } from 'hooks/useLogout';
import { Event, useMixpanel } from 'hooks/useMixpanel';
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { RefreshDocument } from 'types';
import { addRefreshBreadcrumb } from 'utils/sentry';
import {
  ClientUserAuthSet,
  setUserAuthState,
  userAuthState
} from '../apollo/reactive-variables/userAuthState';
import { useRefreshLoop } from '../hooks/useRefreshLoop';

interface RefreshProviderProps {
  children: ReactNode;
}

export type RefreshContextType = {
  startRefreshCountdown: (accessExpiry: number, refreshExpiry: number) => void;
  extendSession: () => void;
};

export const RefreshContext = createContext<RefreshContextType | undefined>(
  undefined
);

export default function RefreshProvider({
  children
}: RefreshProviderProps): ReactElement | null {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const { trackEvent } = useMixpanel();
  const { handleLogout } = useLogout();
  const [refreshMutation] = useMutation<{
    refresh: ClientUserAuthSet;
  }>(RefreshDocument, { onError: defaultErrorHandler });

  interface TryRefresh {
    onRefreshFailure: () => void;
    onSuccess: (expiry: ClientUserAuthSet) => void;
    refreshType: 'refresh-loop' | 'initial-load';
  }

  const tryRefresh = useCallback(
    async ({
      onRefreshFailure,
      onSuccess,
      refreshType
    }: TryRefresh): Promise<boolean> => {
      try {
        const response = await refreshMutation();
        trackEvent(Event.REFRESH);
        if (!response?.data) {
          trackEvent(Event.REFRESH_FAIL, {
            errorMessage: response?.errors?.[0]?.message ?? 'No error'
          });
          addRefreshBreadcrumb({
            message: 'Unsuccessfully attempted to refresh',
            level: Sentry.Severity.Info,
            data: {
              refreshType,
              errors: response.errors,
              context: response.context,
              extensions: response.extensions
            }
          });
          onRefreshFailure();
          return false;
        }

        const refreshData = response?.data?.refresh;

        const eventData = {
          refreshType,
          adminId: refreshData.adminId,
          now: Date.now(),
          accessExpiry: refreshData.accessExpiry,
          refreshExpiry: refreshData.refreshExpiry
        };

        trackEvent(Event.REFRESH_SUCCESS, eventData);

        addRefreshBreadcrumb({
          message: 'Refreshed successfully',
          level: Sentry.Severity.Info,
          data: eventData
        });

        onSuccess(refreshData);
        return true;
      } catch (error) {
        trackEvent(Event.REFRESH_FAIL, { error });
        Sentry.captureException(error, { extra: { refreshType } });
        onRefreshFailure();
        return false;
      }
    },
    [refreshMutation, trackEvent]
  );

  const FIFTY_FIVE_MINUTES = 60000 * 55;
  const TWO_MINUTES = 60000 * 2;
  const { start, extendSession } = useRefreshLoop({
    aboutToExpireIdleThreshold: FIFTY_FIVE_MINUTES,
    stopRefreshingIdleThreshold: TWO_MINUTES,
    refresh: () =>
      tryRefresh({
        refreshType: 'refresh-loop',
        onRefreshFailure: handleLogout,
        onSuccess: ({
          accessToken,
          accessExpiry,
          refreshExpiry,
          roles,
          adminId
        }: ClientUserAuthSet) => {
          setUserAuthState({
            accessToken,
            roles,
            adminId
          });
          start(accessExpiry, refreshExpiry);
        }
      })
  });

  const isDone = useAsyncEffectOnce(
    () =>
      tryRefresh({
        refreshType: 'initial-load',
        onRefreshFailure: () => {
          navigate('/login');
        },
        onSuccess: ({
          accessToken,
          accessExpiry,
          refreshExpiry,
          roles,
          adminId
        }: ClientUserAuthSet) => {
          setUserAuthState({
            accessToken,
            roles,
            adminId
          });
          start(accessExpiry, refreshExpiry);
        }
      }),
    [tryRefresh, start]
  );

  useEffect(
    () => () => {
      const { refreshTimeoutId, ...rest } = userAuthState();
      if (refreshTimeoutId) {
        clearTimeout(refreshTimeoutId);
        userAuthState({ refreshTimeoutId: null, ...rest });
      }
    },
    []
  );

  const value = useMemo(
    () => ({ startRefreshCountdown: start, extendSession }),
    [start, extendSession]
  );

  return isDone ? (
    <RefreshContext.Provider value={value}>{children}</RefreshContext.Provider>
  ) : null;
}

/**
 * Custom hook to access RefreshContext methods
 */
export function useRefreshContext(): RefreshContextType {
  return {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    ...useContext(RefreshContext)!
  };
}
