import React, { ReactNode, useState } from 'react';
import {
  computeActualSessionPoints,
  createSubstitutionMap,
  DEFAULT_HOLD_TIME,
  DEFAULT_POINTS_PER_REP,
  doesProgressionHaveExercises
} from 'utils/activityPlan';
import { getEstimatedEtSessionTime } from 'utils/time';
import {
  ActivityPlan as ActivityPlanType,
  isProgressionDataChronic,
  Progression
} from '../../../types/activityPlan';
import { ActivityPlanStage } from '../ActivityPlanStage';
import ExpandAllCheckbox from '../ExpandAllCheckbox';
import { ChronicExercise } from './ChronicExercise';
import { ChronicLevel } from './ChronicLevel';

export interface ChronicActivityProps {
  activityPlan?: ActivityPlanType;
  loading?: boolean;
  toolbar?: ReactNode;
  userLevel?: number | null;
  onCopyUniqueExercises: (progression: Progression, message: string) => void;
  onCopyAllExercises: (progression: Progression, message: string) => void;
}

const copyMessage = (level: number): string =>
  `Level ${level} exercise list copied to clipboard`;

export const ChronicActivityPlan = ({
  activityPlan,
  loading,
  toolbar,
  userLevel,
  onCopyUniqueExercises,
  onCopyAllExercises
}: ChronicActivityProps): JSX.Element => {
  const [expandAll, setExpandAll] = useState<boolean>(false);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setExpandAll(event.currentTarget.checked);

  return (
    <ActivityPlanStage toolbar={toolbar} loading={loading || !activityPlan}>
      <ExpandAllCheckbox onChange={handleOnChange} defaultChecked={expandAll} />
      {activityPlan?.progressions.map(progression => {
        const {
          current,
          data,
          order,
          uuid,
          activities,
          wasModifiedByAdmin,
          etpeVersion
        } = progression;
        const exercises = activities?.[0]?.data?.exercises ?? [];
        const userSubstitutions =
          activities?.[0]?.data?.userSubstitutions ?? [];
        const userSubstitutionsMap = createSubstitutionMap(userSubstitutions);
        const level = order + 1;
        return isProgressionDataChronic(data) ? (
          <ChronicLevel
            key={uuid}
            id={uuid}
            level={level}
            current={userLevel ? userLevel === level : current}
            points={computeActualSessionPoints(progression)}
            targetPoints={data.etSessionPoints}
            estimatedTime={getEstimatedEtSessionTime(exercises)}
            numEtSessions={data.numEtSessions}
            wasModifiedByAdmin={Boolean(wasModifiedByAdmin)}
            etpeVersion={etpeVersion}
            canCopyExercises={doesProgressionHaveExercises(progression)}
            expanded={expandAll}
            onCopyUniqueExercises={() =>
              onCopyUniqueExercises(progression, copyMessage(level))
            }
            onCopyAllExercises={() =>
              onCopyAllExercises(progression, copyMessage(level))
            }
          >
            {exercises.map(
              ({
                uuid,
                order,
                name,
                anatomicalName,
                reps,
                thumbnail,
                pointsPerRep,
                holdTime,
                repType
              }) => {
                const substitutionName = userSubstitutionsMap.get(order ?? -1);
                const substitutionText = substitutionName
                  ? `Substituted for ${substitutionName}`
                  : undefined;
                return (
                  <ChronicExercise
                    key={uuid + order}
                    uuid={uuid}
                    name={name}
                    anatomicalName={anatomicalName}
                    reps={reps}
                    thumbnailUrl={thumbnail?.url}
                    pointsPerRep={pointsPerRep ?? DEFAULT_POINTS_PER_REP}
                    holdTime={holdTime ?? DEFAULT_HOLD_TIME}
                    repType={repType}
                    substitutionText={substitutionText}
                  />
                );
              }
            )}
          </ChronicLevel>
        ) : (
          <p>Not chronic</p>
        );
      })}
    </ActivityPlanStage>
  );
};
