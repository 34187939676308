import {
  TableCell,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
  TableSortLabel
} from '@material-ui/core';
import { OrderDirection } from 'hooks/useSorted';
import React from 'react';
import styled from 'styled-components';
export interface HeadCell {
  id: string;
  cell: typeof TableCell;
  label: string;
}
interface Props {
  order: OrderDirection;
  orderBy: string;
  onRequestSort: (event: React.MouseEvent, property: string) => void;
  tableHeadCells: HeadCell[];
}

const TableHead = styled(MuiTableHead)`
  .MuiTableCell-head {
    font-weight: bold;
  }
`;

const TableRow = styled(MuiTableRow)`
  height: 52px;
`;

const SortLabel = styled.span`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 20px;
  width: 1px;
`;

export default function SortTableHead({
  order,
  orderBy,
  onRequestSort,
  tableHeadCells
}: Props): JSX.Element {
  return (
    <TableHead data-testid="sortTableHead">
      <TableRow>
        {tableHeadCells.map((headCell: HeadCell) => {
          const { id, cell: Cell, label } = headCell;
          return id === 'actions' ? (
            <Cell key={id}>Actions</Cell>
          ) : (
            <Cell key={id}>
              <TableSortLabel
                active={orderBy === id}
                direction={orderBy === id ? order : 'asc'}
                onClick={event => onRequestSort(event, id)}
                data-testid={`${id}SortHead`}
              >
                {label}
                {orderBy === id ? (
                  <SortLabel>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </SortLabel>
                ) : null}
              </TableSortLabel>
            </Cell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
