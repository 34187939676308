import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { ProgressionProps, ProgressionStage } from './ProgressionStage';

export interface DraggableProgressionProps extends ProgressionProps {
  index: number;
  draggableId: string;
  scrollToId: string;
}

const DraggableItem = styled.div`
  display: flex;
  width: 100%;
`;

export const DraggableProgression = ({
  index,
  draggableId,
  scrollToId,
  children,
  ...progressionProps
}: DraggableProgressionProps): JSX.Element => (
  <Draggable draggableId={`Draggable_${draggableId}`} index={index}>
    {provided => (
      <DraggableItem
        {...provided.dragHandleProps}
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <ProgressionStage id={scrollToId} {...progressionProps}>
          {children}
        </ProgressionStage>
      </DraggableItem>
    )}
  </Draggable>
);
