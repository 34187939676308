import { useQuery } from '@apollo/client';
import { AppBar, IconButton, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { RouteComponentProps } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { DetailsCard } from 'components/exercise-repo/DetailsCard';
import { MotionsTable } from 'components/exercise-repo/MotionsTable';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  GetExerciseByUuidDocument,
  GetExerciseByUuidQuery,
  GetExerciseByUuidQueryVariables
} from 'types';

interface Props extends RouteComponentProps {
  uuid?: string;
}

const TopNav = styled(AppBar)`
  flex-direction: row;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(1, 5)};
  background-color: white;
`;

const ScrollArea = styled.div`
  margin: auto;
  max-height: 100vh;
  overflow: auto;
`;

const Main = styled.main`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(6)};
  margin: ${({ theme }) => theme.spacing(8, 12)};
  max-width: ${({ theme }) => theme.spacing(270.5)};
`;

const SubTitle = styled(props => <Typography component="h2" {...props} />)`
  margin-left: ${({ theme }) => theme.spacing(2)};
`;

export const ExerciseView = ({ uuid = '', navigate }: Props): JSX.Element => {
  const { t } = useTranslation();
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const { loading, data, error } = useQuery<
    GetExerciseByUuidQuery,
    GetExerciseByUuidQueryVariables
  >(GetExerciseByUuidDocument, {
    onError: defaultErrorHandler,
    variables: {
      input: { uuid }
    }
  });

  useEffect(() => {
    if (error) {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: `Unable to load exercise for uuid: ${uuid}`,
        message: `Try again. Error: ${error.message}`
      });
    }
  }, [error, uuid]);

  if (loading || !data) {
    return <LoadingSpinner />;
  }

  const {
    name,
    anatomicalName,
    motions,
    equipment,
    reps,
    holdTime,
    pointsPerRep,
    switchLimbTime,
    repType,
    etPreview,
    numHoldMotions
  } = data.exerciseGetByUuid;

  return (
    <>
      <TopNav position="sticky">
        <IconButton
          edge="start"
          aria-label="Go back"
          onClick={() => navigate?.('../')}
        >
          <ArrowBack />
        </IconButton>
        <Typography color="textPrimary" component="h1" variant="h5">
          {t(name)}
        </Typography>
        <SubTitle color="textSecondary" variant="body1">
          {anatomicalName}
        </SubTitle>
      </TopNav>
      <ScrollArea>
        <Main>
          <DetailsCard
            equipment={equipment ?? []}
            repType={repType}
            reps={reps ?? 8}
            holdTime={holdTime ? holdTime / numHoldMotions : 3000}
            pointsPerRep={pointsPerRep ?? 10}
            switchLimbTime={switchLimbTime}
          />
          <MotionsTable etPreview={etPreview} motions={motions} />
        </Main>
      </ScrollArea>
    </>
  );
};
