import { RouteComponentProps } from '@reach/router';
import AcuteTemplateLibrary from 'components/AcuteTemplateLibrary';
import { Body } from 'components/layout/Body';
import { PageCard } from 'components/PageCard';
import React from 'react';

const cardStyles = {
  paddingTop: '30px',
  minHeight: 'calc(100vh - 188px)'
};

const AcuteTemplatesIndex: React.FC<RouteComponentProps> = (): JSX.Element => {
  return (
    <Body pageTitle="Acute templates" testId="acuteTemplatesIndexPage">
      <PageCard style={cardStyles}>
        <AcuteTemplateLibrary showSearch showCreateLink />
      </PageCard>
    </Body>
  );
};

export default AcuteTemplatesIndex;
