import { IconButton, Tooltip, TooltipProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface Props {
  disabled?: boolean;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  testId?: string;
  title: string;
  placement?: TooltipProps['placement'];
}

const CopyIconButton = styled(IconButton)`
  span {
    svg {
      fill: ${({ disabled, theme }) =>
        disabled ? theme.palette.action.disabled : 'rgba(0,0,0,0.7)'};
    }
  }
`;

export const CopyToClipboardButtonIcon = ({
  disabled,
  onClick,
  testId,
  title,
  placement
}: Props): JSX.Element => {
  return (
    <Tooltip
      data-testid={testId ? `${testId}Tooltip` : null}
      title={title}
      placement={placement ?? 'bottom-end'}
    >
      <span>
        <CopyIconButton
          data-testid={testId ?? null}
          onClick={onClick}
          disabled={disabled ?? false}
          aria-label={title}
        >
          {/* This SVG icon is not available in the MUI library ... derp  */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
          </svg>
        </CopyIconButton>
      </span>
    </Tooltip>
  );
};
