import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Header } from './Header';

export interface BodyProps {
  fullHeight?: boolean;
  pageTitle?: string;
  header?: ReactNode;
  children?: ReactNode;
  testId?: string;
}

const Main = styled.main<{
  fullHeight: boolean;
}>`
  height: ${({ fullHeight, theme }) =>
    fullHeight ? '100vh' : `calc(100vh - ${theme.spacing(16)})`};
  padding: ${({ theme }) => theme.spacing(5, 16, 6)};
  overflow-y: auto;
  width: 100%;
  box-sizing: border-box;
`;

const Section = styled.section`
  margin: ${({ theme }) => theme.spacing(6, 0)};
`;

export const Body = ({
  fullHeight = true,
  pageTitle,
  header,
  children,
  testId
}: BodyProps): JSX.Element => (
  <Main data-testid={testId} fullHeight={fullHeight}>
    {header || (pageTitle ? <Header title={pageTitle ?? ''} /> : null)}
    {children ? <Section>{children}</Section> : null}
  </Main>
);
