import { Button } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { ActivityPlanToolbar } from 'components/activity-plan/ActivityPlanToolbar';
import { AcuteHeader } from 'components/activity-plan/acute/AcuteHeader';
import { CopyToClipboardButtonIcon } from 'components/CopyToClipboardButtonIcon';
import { DownloadPDFButtonIcon } from 'components/DownloadPDFButtonIcon';
import { ActivityPlanPageLayout } from 'components/layout/ActivityPlanPageLayout';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { AcuteTemplatePDFDocument } from 'components/PDFDocuments/AcuteTemplatePDFDocument';
import { AcuteActivityPlan } from 'connected/activity-plan/AcuteActivityPlan';
import { useCopyPlanToClipboard } from 'hooks/useCopyPlanToClipboard';
import {
  ActivityPlanProvider,
  useActivityPlan
} from 'providers/ActivityPlanProvider';
import { DraftActivityPlanProvider } from 'providers/DraftActivityPlanProvider';
import React from 'react';
import styled from 'styled-components';

const EditButton = styled(Button)`
  height: 36px;
  margin-left: ${({ theme }) => theme.spacing(5)};
`;

const AcuteTemplateView = ({ onEdit }: { onEdit: () => void }): JSX.Element => {
  const { loading, activityPlan } = useActivityPlan();
  const { canCopyToClipboard, handleCopyExercisesToClipboard } =
    useCopyPlanToClipboard(activityPlan?.progressions);

  if (loading || !activityPlan) {
    return <LoadingSpinner />;
  }

  return (
    <ActivityPlanPageLayout
      stickyToolbar={
        <ActivityPlanToolbar
          actionButtons={
            <EditButton
              variant="contained"
              color="primary"
              data-testid="editTemplateButton"
              onClick={onEdit}
              disabled={
                !!(
                  activityPlan.draft &&
                  !activityPlan.draft.isOwnedByCurrentAdmin
                )
              }
            >
              EDIT
            </EditButton>
          }
        >
          <DownloadPDFButtonIcon
            testId="acuteViewDownloadIcon"
            title="Download PDF"
            placement="bottom-start"
            document={<AcuteTemplatePDFDocument activityPlan={activityPlan} />}
            fileName={activityPlan?.name ?? 'Acute template'}
            disabled={loading}
          />
          <CopyToClipboardButtonIcon
            disabled={!canCopyToClipboard}
            onClick={handleCopyExercisesToClipboard}
            testId="acuteViewCopyIcon"
            title={
              canCopyToClipboard
                ? 'Copy all activity plan exercises'
                : 'Copy all exercises disabled'
            }
            placement="bottom-start"
          />
        </ActivityPlanToolbar>
      }
      header={<AcuteHeader viewOnly name={activityPlan.name ?? ''} />}
      testId="acuteTemplateViewPage"
    >
      <AcuteActivityPlan />
    </ActivityPlanPageLayout>
  );
};

interface Props extends RouteComponentProps {
  uuid?: string;
}

const ConnectedAcuteTemplateView = ({ uuid, navigate }: Props): JSX.Element => (
  <ActivityPlanProvider
    uuid={uuid ?? ''}
    onError={err => {
      throw new Error(`Unable to view acute template (${err})`);
    }}
  >
    <DraftActivityPlanProvider>
      <AcuteTemplateView onEdit={() => navigate?.('edit')} />
    </DraftActivityPlanProvider>
  </ActivityPlanProvider>
);

export { ConnectedAcuteTemplateView as AcuteTemplateView };
