import * as Sentry from '@sentry/react';
import { getExerciseTranslationBase } from 'config/getConfig';
import i18n, { InitOptions } from 'i18next';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { isSlug } from './isSlug';

const CDN_BASE_URL = getExerciseTranslationBase();
const DEFAULT_LNG = 'en-US';
const MISSING_TRANSLATION_KEY_ERROR_MSG = 'Missing translation key.';
const MISSING_INTERPOLATION_KEY_ERROR_MSG =
  'Missing variable for interpolation.';
const TRANSLATION_LOADING_ERROR = 'Unable to load translations.';

const handleParseMissingKey = (key: string): string => {
  if (isSlug(key)) {
    console.log(MISSING_TRANSLATION_KEY_ERROR_MSG, key);
    Sentry.captureException(new Error(MISSING_TRANSLATION_KEY_ERROR_MSG), {
      extra: { key }
    });
  }
  return key;
};

const handleMissingInterpolation = (key: string): string => {
  Sentry.captureException(new Error(MISSING_INTERPOLATION_KEY_ERROR_MSG), {
    extra: { key }
  });
  return key;
};

export const i18nConfig: InitOptions = {
  lng: DEFAULT_LNG,
  parseMissingKeyHandler: (key: string) => handleParseMissingKey(key),
  missingInterpolationHandler: (key: string) => handleMissingInterpolation(key),
  backend: {
    crossDomain: true,
    loadPath: `${CDN_BASE_URL}/localized-text/exercises/v1/${DEFAULT_LNG}.json`,
    reloadInterval: false
  },
  returnObjects: true
};

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init(i18nConfig, (err, t) => {
    if (err) {
      console.log(TRANSLATION_LOADING_ERROR, err);
      Sentry.captureException(new Error(TRANSLATION_LOADING_ERROR), {
        extra: err
      });
    }
    return t;
  });

export default i18n;
