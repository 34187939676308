import { Chip, ChipProps } from '@material-ui/core';
import React from 'react';
import { RepType } from 'types';
import { RepTypeAvatar } from './RepTypeAvatar';

export interface ExerciseChipProps {
  label: string;
  value: string | number;
  onClick?: ChipProps['onClick'];
  repType?: RepType | null;
}

/**
 * A single piece of readonly information about an exercise.
 */
export const ExerciseChip = ({
  label,
  value,
  onClick,
  repType
}: ExerciseChipProps): JSX.Element => (
  <Chip
    label={`${value} ${label}`}
    size="small"
    variant="outlined"
    aria-label={`${
      repType ? repType.toLowerCase() + ' ' : ''
    }${value} ${label}`}
    data-testid={`${label.replace(/\s/g, '')}_ExerciseChip`}
    clickable={onClick !== undefined}
    onClick={onClick}
    avatar={<RepTypeAvatar repType={repType} />}
  />
);
