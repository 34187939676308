import { redirectTo, RouteComponentProps, useNavigate } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { ActionButton } from 'components/activity-plan/ActionButton';
import { ActivityPlanToolbar } from 'components/activity-plan/ActivityPlanToolbar';
import { DiscardDraftButton } from 'components/activity-plan/DiscardDraftButton';
import { UserHeader } from 'components/activity-plan/UserHeader';
import { CopyToClipboardButtonIcon } from 'components/CopyToClipboardButtonIcon';
import { DownloadPDFButtonIcon } from 'components/DownloadPDFButtonIcon';
import ExerciseLibrary from 'components/ExerciseLibrary';
import { ActivityPlanPageLayout } from 'components/layout/ActivityPlanPageLayout';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { UserPDFDocument } from 'components/PDFDocuments/UserPDFDocumentSwitch';
import { ResetButtonIcon } from 'components/ResetButtonIcon';
import { EditableAcuteActivityPlan } from 'connected/activity-plan/EditableAcuteActivityPlan';
import { EditableChronicActivityPlan } from 'connected/activity-plan/EditableChronicActivityPlan';
import { EditablePerisurgicalActivityPlan } from 'connected/activity-plan/EditablePerisurgicalActivityPlan';
import { useCopyPlanToClipboard } from 'hooks/useCopyPlanToClipboard';
import { useGetUser } from 'hooks/useGetUser';
import { Event, useMixpanel } from 'hooks/useMixpanel';
import {
  ActivityPlanProvider,
  useActivityPlan
} from 'providers/ActivityPlanProvider';
import {
  DraftActivityPlanProvider,
  useDraftActivityPlan,
  useDraftActivityPlanAPI
} from 'providers/DraftActivityPlanProvider';
import { useCurrentProgressionNum } from 'providers/hooks/useCurrentProgressionNum';
import { useEnsureDraft } from 'providers/hooks/useEnsureDraft';
import React, { useEffect, useState } from 'react';
import { TargetProgression } from 'types/targetProgression';
import {
  extractProgressionTypeFromUri,
  isSupportedPlan
} from 'utils/activityPlan';
import { extractProgramInfoFromUri } from 'utils/extractProgramInfoFromUri';
import { Programs } from 'utils/programConstants';
import { useSharedFeatureFlag } from '../../hooks/useSharedFeatureFlag';

interface Props extends RouteComponentProps {
  id?: string;
  uuid?: string;
}

const createOnValidationError =
  ({ uri }: { uri: string }) =>
  (): void => {
    const progressionType = extractProgressionTypeFromUri(uri).toLowerCase();

    snackbarState({
      type: 'generic',
      severity: 'error',
      title: `Please add at least one ${progressionType} of exercises in order to publish`,
      message: 'Try again'
    });
  };

const EditableActivityPlan = ({
  program,
  setTargetProgression,
  userLevel
}: {
  program: Programs;
  setTargetProgression: React.Dispatch<
    React.SetStateAction<TargetProgression | null>
  >;
  userLevel: number | null | undefined;
}): JSX.Element => {
  switch (program) {
    case Programs.CHRONIC:
      return (
        <EditableChronicActivityPlan
          loading={false}
          onAddExercises={setTargetProgression}
          userLevel={userLevel}
        />
      );
    case Programs.PERISURGICAL:
      return (
        <EditablePerisurgicalActivityPlan
          onAddExercises={setTargetProgression}
        />
      );
    default:
      return (
        <EditableAcuteActivityPlan onAddExercises={setTargetProgression} />
      );
  }
};

const UserDetailBody = ({ userId }: { userId: string }): JSX.Element => {
  const { trackEvent } = useMixpanel();
  const { treatment: isTimeBasedUser } = useSharedFeatureFlag(
    'enableTimeBasedExercises',
    userId
  );

  const [canCreateDraft, setCanCreateDraft] = useState<boolean>(true);
  const navigate = useNavigate();
  const { activityPlan, loading } = useActivityPlan();
  const {
    draft,
    commitInProcess: publishInProcess,
    saveInProcess,
    status,
    isDirty
  } = useDraftActivityPlan();
  const { commit, discard, save } = useDraftActivityPlanAPI();
  const { canCopyToClipboard, handleCopyExercisesToClipboard } =
    useCopyPlanToClipboard(draft?.progressions);
  const { program, indication } = extractProgramInfoFromUri(draft?.uri);
  const disableToolbarButtons = !draft || saveInProcess || publishInProcess;
  const [targetProgression, setTargetProgression] =
    useState<TargetProgression | null>(null);

  const userLevelData = useGetUser(userId);

  const calculatedProgressionNum = useCurrentProgressionNum();
  const userLevel = userLevelData ? userLevelData.userGet.level : null;
  const currentProgressionNum = userLevel
    ? userLevel
    : calculatedProgressionNum;
  const isAcuteOrPerisurgicalProgram =
    program === Programs.ACUTE || program === Programs.PERISURGICAL;
  const resetStartDateConfirmation = (): boolean => {
    return window.confirm(`Are you sure you want to reset to week 1?`);
  };

  useEnsureDraft(canCreateDraft);

  useEffect(() => {
    if (draft && !draft.isOwnedByCurrentAdmin) {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: 'A draft is currently in progress',
        message: 'You must be the owner of this draft to continue editing'
      });
      navigate(`/users/${userId}/activity-plans/${activityPlan?.uuid}`);
    }
  }, [draft, activityPlan, userId, navigate]);

  if (loading || !draft || !activityPlan) {
    return <LoadingSpinner />;
  }

  const handlePublish = async ({
    resetStartDate
  }: {
    resetStartDate: boolean;
  }): Promise<void> => {
    const event = resetStartDate
      ? Event.RESET_START_DATE_ACTIVITY_PLAN_CLICKED
      : Event.PUBLISH_CLICKED;
    trackEvent(event);
    setCanCreateDraft(false);
    const { didCommit, errorMessage } = await commit({
      userId,
      onNotSaveable: createOnValidationError({ uri: draft.uri }),
      resetStartDate
    });
    if (didCommit) {
      navigate(`/users/${userId}`);
      snackbarState({
        type: 'generic',
        severity: 'success',
        message: resetStartDate
          ? 'Reset request completed!'
          : 'Draft was published!'
      });
    } else {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: errorMessage,
        message: 'Try again'
      });
    }
  };

  const handleDiscard = async (): Promise<void> => {
    setCanCreateDraft(false);
    const didDiscard = await discard({ userId });
    if (didDiscard) {
      navigate(`/users/${userId}`);
      snackbarState({
        type: 'generic',
        severity: 'success',
        message: 'All changes have been discarded!'
      });
    } else {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: 'Unable to discard changes',
        message: 'Try again'
      });
    }
  };

  const handleSaveDraft = async (): Promise<void> => {
    const { status: didSave, errorMessage } = await save();
    if (didSave) {
      snackbarState({
        type: 'generic',
        severity: 'success',
        message: 'All changes have been saved!'
      });
    } else {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: errorMessage,
        message: 'Try again'
      });
    }
  };

  return isSupportedPlan(program) ? (
    <ActivityPlanPageLayout
      stickyToolbar={
        <ActivityPlanToolbar
          actionButtons={
            <>
              <DiscardDraftButton
                onClick={handleDiscard}
                disabled={disableToolbarButtons}
              />
              <ActionButton
                text="SAVE DRAFT"
                onClick={handleSaveDraft}
                disabled={disableToolbarButtons || !isDirty}
              />
              <ActionButton
                text="SEND ACTIVITY PLAN"
                onClick={() => handlePublish({ resetStartDate: false })}
                disabled={disableToolbarButtons}
              />
            </>
          }
        >
          <DownloadPDFButtonIcon
            disabled={disableToolbarButtons}
            testId="userActivityPlanEditDownloadIcon"
            title="Download PDF"
            placement="bottom-start"
            document={
              <UserPDFDocument activityPlan={activityPlan} userId={userId} />
            }
            fileName={`${userId}-${program}-${indication}`}
          />
          <CopyToClipboardButtonIcon
            disabled={!canCopyToClipboard}
            onClick={handleCopyExercisesToClipboard}
            testId="UserPlanCopyExercisesButton"
            title={
              canCopyToClipboard
                ? 'Copy all activity plan exercises'
                : 'Copy all exercises disabled'
            }
            placement="bottom-start"
          />
          {isAcuteOrPerisurgicalProgram ? (
            <ResetButtonIcon
              disabled={disableToolbarButtons}
              title="Reset Start Date"
              placement="bottom-start"
              handlePublish={handlePublish}
              confirmationMessage={resetStartDateConfirmation}
            />
          ) : null}
        </ActivityPlanToolbar>
      }
      header={
        <UserHeader
          userId={userId}
          isTimeBasedUser={isTimeBasedUser}
          activityPlan={activityPlan}
          currentProgressionNum={currentProgressionNum}
          draft={draft}
          status={status}
          isBeta={Boolean(activityPlan.isBeta)}
        />
      }
      testId="userActivityPlanEditPage"
    >
      <EditableActivityPlan
        program={program}
        setTargetProgression={setTargetProgression}
        userLevel={userLevel}
      />
      {targetProgression && (
        <ExerciseLibrary
          targetProgression={targetProgression}
          handleCloseExerciseLibrary={() => setTargetProgression(null)}
        />
      )}
    </ActivityPlanPageLayout>
  ) : (
    <div>
      The user is assigned {program} program and it is not supported in
      configurator
    </div>
  );
};

export default function UserDetail({
  id: userId = '',
  uuid = ''
}: Props): JSX.Element {
  return (
    <ActivityPlanProvider
      uuid={uuid}
      userId={userId}
      onError={() => redirectTo(`/users/${userId}`)}
    >
      <DraftActivityPlanProvider>
        <UserDetailBody userId={userId} />
      </DraftActivityPlanProvider>
    </ActivityPlanProvider>
  );
}
