import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body, html, #root {
    background: #f7f9fc;
    border: 0;
    font-family: Lato, sans-serif;
    height: 100%;
    letter-spacing: 0.1px;
    margin: 0;
    padding: 0;
  }
`;

export default GlobalStyle;
