import {
  InputAdornment,
  StandardTextFieldProps,
  TextField as MuiTextField,
  Typography
} from '@material-ui/core';
import { Error } from '@material-ui/icons';
import { useChangeDetector } from 'hooks/useChangeDetector';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { RepType } from 'types';
import { RepTypeAvatar } from './RepTypeAvatar';

const TextField = styled((props: StandardTextFieldProps) => (
  <MuiTextField {...props} />
))`
  input {
    text-align: right;
    max-width: ${({ theme }) => theme.spacing(4)};
    padding-left: ${({ theme }) => theme.spacing(1)};
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input::selection {
    background-color: ${({ theme, error }) =>
      error && theme.palette.error.main + '1F'};
  }
  .MuiFormHelperText-root {
    position: absolute;
    white-space: nowrap;
    bottom: -18px;
  }
`;

const ErrorAdornment = styled(Error)`
  color: ${({ theme }) => theme.palette.error.main};
  margin: ${({ theme }) => theme.spacing(0, 0, 0, 2)};
`;

export type ValidationFn = (value: number) => {
  isValid: boolean;
  reason: string;
};

/**
 * Tuple: [isInvalid, messages]
 */
export interface ValidationResults {
  isInvalid: boolean;
  reasons: string[];
}

/**
 * Perform several validations at once and aggregates the results into a
 * single boolean, `isInvalid`, and a list of 'reasons' regarding
 * those validations.
 */
const validate = (
  validations: ValidationFn[],
  value: number
): ValidationResults =>
  validations.reduce(
    ({ isInvalid, reasons }: ValidationResults, validate) => {
      const { isValid, reason } = validate(value);
      return {
        isInvalid: isInvalid || !isValid,
        reasons: [...reasons, ...(!isValid ? [reason] : [])]
      };
    },
    { isInvalid: false, reasons: [] }
  );

export type ExerciseInputHandler = (
  value: number,
  validationResults: ValidationResults,
  ref: React.MutableRefObject<HTMLInputElement | undefined>
) => void;

const onEnterKeyDown =
  (onEnter: () => void): React.KeyboardEventHandler =>
  event => {
    if (event.key === 'Enter') {
      onEnter();
    }
  };

export interface ExerciseInputProps {
  name: string;
  value: number;
  className?: string;
  validations?: ValidationFn[];
  label?: string;
  unit?: string;
  repType?: RepType | null;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onBlur?: ExerciseInputHandler;
  onEnter?: ExerciseInputHandler;
  onChange?: ExerciseInputHandler;
}

export const ExerciseInput = ({
  name,
  label,
  value,
  validations,
  unit,
  className,
  repType,
  onFocus,
  onChange,
  onBlur,
  onEnter
}: ExerciseInputProps): JSX.Element => {
  const [localValue, setLocalValue] = useState<string | number>(value);
  const didValueChange = useChangeDetector(value);
  useEffect(() => {
    if (didValueChange(value)) {
      setLocalValue(value);
    }
  }, [didValueChange, value]);

  const [validationResults, setValidationResults] = useState<ValidationResults>(
    validate(validations ?? [], value)
  );
  const inputElement = useRef<HTMLInputElement>();

  const createHandler = (
    fn: ExerciseInputHandler | undefined
  ): undefined | (() => void) => {
    return fn !== undefined
      ? (): void => fn(Number(localValue), validationResults, inputElement)
      : undefined;
  };

  const handleEnter = createHandler(onEnter);
  const handleBlur = createHandler(onBlur);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    // We allow an empty string in input element, so don't convert to a number
    // when setting the local value (Note: `Number('') === 0`)
    setLocalValue(event.target.value);
    // Convert to a number for all other purposes, though
    const numericalValue = Number(event.target.value);
    const validationResults = validate(validations ?? [], numericalValue);
    setValidationResults(validationResults);
    onChange?.(numericalValue, validationResults, inputElement);
  };
  const onlyNumbers = (event: React.ChangeEvent<HTMLInputElement>): void => {
    event.target.value = event.target.value.replace(/[^0-9]/g, '');
  };

  return (
    <TextField
      className={className}
      autoFocus
      data-testid={`${name.replace(/\s/g, '')}_ExerciseInput`}
      error={validationResults.isInvalid}
      helperText={validationResults.reasons}
      inputRef={inputElement}
      type="number"
      value={localValue}
      label={label}
      name={name}
      aria-label={`${repType ? repType.toLowerCase() + ' ' : ''}${name}`}
      onFocus={onFocus}
      onBlur={handleBlur}
      onChange={handleChange}
      onKeyDown={handleEnter ? onEnterKeyDown(handleEnter) : undefined}
      onInput={(e: React.ChangeEvent<HTMLInputElement>) => onlyNumbers(e)}
      inputProps={{
        'aria-label': `${name} input`,
        'aria-invalid': validationResults.isInvalid,
        type: 'text',
        maxLength: 2
      }}
      InputProps={{
        startAdornment: <RepTypeAvatar repType={repType} />,
        endAdornment: (
          <InputAdornment position="end">
            {unit ? (
              <Typography component="p" className="exerciseInputUnit">
                {unit}
              </Typography>
            ) : null}
            {validationResults.isInvalid ? (
              <ErrorAdornment fontSize="small" />
            ) : null}
          </InputAdornment>
        )
      }}
    />
  );
};
