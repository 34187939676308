import { useSemaphore } from 'hooks/useSemaphore';
import {
  useActivityPlan,
  useActivityPlanApi
} from 'providers/ActivityPlanProvider';
import { useEffect } from 'react';

export const useEnsureDraft = (canCreateDraft: boolean): void => {
  const { activityPlan } = useActivityPlan();
  const { createDraft } = useActivityPlanApi();
  const { increment, decrement } = useSemaphore({ max: 1 });

  useEffect(() => {
    if (canCreateDraft && activityPlan && !activityPlan.draft) {
      (async () => {
        if (increment()) {
          await createDraft();
          decrement();
        }
      })();
    }
  }, [activityPlan, createDraft, increment, decrement, canCreateDraft]);
};
