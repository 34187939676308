import React from 'react';
import { RepType } from 'types';
import { Exercise } from '../Exercise';
import { ExerciseChip } from '../ExerciseChip';

export interface AcuteExerciseProps {
  uuid: string;
  name: string;
  anatomicalName?: string | null;
  reps: number;
  holdTime: number;
  thumbnailUrl?: string;
  repType?: RepType | null;
}

/**
 * Read-only view of an acute exercise
 */
export const AcuteExercise = ({
  uuid,
  name,
  anatomicalName,
  reps,
  holdTime,
  thumbnailUrl,
  repType
}: AcuteExerciseProps): JSX.Element => (
  <Exercise
    name={name}
    id={uuid}
    thumbnailUrl={thumbnailUrl}
    anatomicalName={anatomicalName}
  >
    <ExerciseChip label="reps" value={reps} repType={repType} />
    <ExerciseChip label="sec hold" value={holdTime / 1000} />
  </Exercise>
);
