import { useCallback, useState } from 'react';

export interface ExpandState {
  expanded: boolean;
  overrides: {
    [progressionUuid: string]: boolean;
  };
}

interface UseExpandState {
  expandState: ExpandState;
  reset: (expanded: boolean) => void;
  override: (itemId: string | number, expanded: boolean) => void;
}

/**
 * Manage state for a list of expandable items. This hook maintains
 * a global expanded value and a set of overrides. This is useful when
 * the UI requires both:
 * 1. item-level expand/collapse functionality
 * 2. a top-level switch to expand/collapse all.
 *
 * To make use of this hook:
 * 1. utilize `override` in the expand/collapse toggle logic of the items.
 * 2. check for an override, defaulting to expandState: `expandState.overrides[myId] ?? expandState.expanded`
 */
export const useExpandState = (defaultExpanded: boolean): UseExpandState => {
  const [expandState, setExpandState] = useState<ExpandState>({
    expanded: defaultExpanded,
    overrides: {}
  });

  /**
   * Reset the expandState with the provided expanded default value.
   * Doing so removes all overrides; a clean slate.
   */
  const reset = useCallback(
    (expanded: boolean): void => {
      setExpandState({
        expanded,
        overrides: {}
      });
    },
    [setExpandState]
  );

  /**
   * Create an expandState value for an item.
   */
  const override = useCallback(
    (itemId: string | number, expanded: boolean) => {
      expandState.overrides[`${itemId}`] = expanded;
      setExpandState(expandState);
    },
    [expandState, setExpandState]
  );

  return { expandState, reset, override };
};
