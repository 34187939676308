import {
  amber,
  indigo,
  lightGreen,
  purple,
  teal
} from '@material-ui/core/colors';

export enum Programs {
  ACUTE = 'acute',
  CHRONIC = 'chronic',
  PERISURGICAL = 'perisurgical'
}

export enum AcuteBodyRegions {
  NECK_UPPER_BACK = 'Neck / upper back',
  SHOULDER_UPPER_ARM = 'Shoulder / upper arm',
  ELBOW_WRIST_HAND = 'Elbow / wrist / hand',
  LOW_BACK = 'Low back',
  HIP = 'Hip',
  KNEE_THIGH = 'Knee / thigh',
  LOWER_LEG_ANKLE_FOOT = 'Lower leg / ankle / foot'
}

export enum PerisurgicalBodyRegions {
  ANKLE = 'Ankle',
  BACK = 'Back',
  ELBOW = 'Elbow',
  FOOT = 'Foot',
  HAND = 'Hand',
  HIP = 'Hip',
  KNEE = 'Knee',
  NECK = 'Neck',
  SHOULDER = 'Shoulder',
  WRIST = 'Wrist'
}

export enum AcuteConditions {
  ACHILLES_CALF_CONDITIONING = 'Achilles / calf conditioning',
  ANKLE_SPRAIN = 'Ankle sprain',
  ANTERIOR = 'Anterior',
  FROZEN_SHOULDER = 'Frozen shoulder',
  GENERIC = 'Generic',
  HAMSTRING_STRAIN = 'Hamstring strain',
  LATERAL = 'Lateral',
  PLANTAR_FASCIITIS = 'Plantar fasciitis',
  RADICULAR = 'Radicular',
  ROTATOR_CUFF_STRAIN_IMPINGEMENT = 'Rotator cuff strain / impingement'
}

export enum PerisurgicalConditions {
  GENERIC = 'Generic',
  LUMBAR_DECOMPRESSION = 'Lumbar Decompression / Discectomy ',
  LUMBAR_DISC_REPLACEMENT = 'Lumbar Disc Replacement',
  LUMBAR_FUSION = 'Lumbar Fusion',
  ARTHROSCOPY_LABRAL_REPAIR = 'Hip Scope - Labral Repair',
  ARTHROSCOPY_LABRAL_IMPINGEMENT = 'Hip Scope - Impingement',
  HIP_REPLACEMENT = 'Hip Replacement',
  ACL = 'ACL',
  PCL = 'PCL',
  KNEE_REPLACEMENT = 'Knee Replacement',
  ARTHROSCOPY = 'Arthroscopy',
  MENISCUS_REPAIR = 'Meniscus Repair',
  MICROFRACTURE = 'Microfracture / Chrondral Repair',
  DECOMPRESSION = 'Cervical Decompression / Discectomy',
  CERVICAL_DISC_REPLACEMENT = 'Cervical Disc Replacement',
  CERVICAL_FUSION = 'Cervical Fusion',
  IMPINGEMENT = 'Shoulder Impingement',
  ROTATOR_CUFF_REPAIR = 'Rotator Cuff Repair',
  DISLOCATION = 'Shoulder Dislocation'
}

export enum Frequency {
  ONE = '1x a week',
  TWO = '2x a week',
  THREE = '3x a week',
  FOUR = '4x a week',
  FIVE = '5x a week',
  SIX = '6x a week',
  SEVEN = '7x a week'
}

interface ConditionOptionsByAcuteRegion {
  [key: string]: string[];
  [AcuteBodyRegions.NECK_UPPER_BACK]: AcuteConditions[];
  [AcuteBodyRegions.SHOULDER_UPPER_ARM]: AcuteConditions[];
  [AcuteBodyRegions.ELBOW_WRIST_HAND]: AcuteConditions[];
  [AcuteBodyRegions.LOW_BACK]: AcuteConditions[];
  [AcuteBodyRegions.HIP]: AcuteConditions[];
  [AcuteBodyRegions.KNEE_THIGH]: AcuteConditions[];
  [AcuteBodyRegions.LOWER_LEG_ANKLE_FOOT]: AcuteConditions[];
}

interface ConditionOptionsByPerisurgicalRegion {
  [key: string]: string[];
  [PerisurgicalBodyRegions.ANKLE]: PerisurgicalConditions[];
  [PerisurgicalBodyRegions.BACK]: PerisurgicalConditions[];
  [PerisurgicalBodyRegions.ELBOW]: PerisurgicalConditions[];
  [PerisurgicalBodyRegions.FOOT]: PerisurgicalConditions[];
  [PerisurgicalBodyRegions.HAND]: PerisurgicalConditions[];
  [PerisurgicalBodyRegions.HIP]: PerisurgicalConditions[];
  [PerisurgicalBodyRegions.KNEE]: PerisurgicalConditions[];
  [PerisurgicalBodyRegions.NECK]: PerisurgicalConditions[];
  [PerisurgicalBodyRegions.SHOULDER]: PerisurgicalConditions[];
  [PerisurgicalBodyRegions.WRIST]: PerisurgicalConditions[];
}

export const acuteConditions: ConditionOptionsByAcuteRegion = {
  [AcuteBodyRegions.NECK_UPPER_BACK]: [
    AcuteConditions.GENERIC,
    AcuteConditions.RADICULAR
  ],
  [AcuteBodyRegions.SHOULDER_UPPER_ARM]: [
    AcuteConditions.GENERIC,
    AcuteConditions.ROTATOR_CUFF_STRAIN_IMPINGEMENT,
    AcuteConditions.FROZEN_SHOULDER
  ],
  [AcuteBodyRegions.ELBOW_WRIST_HAND]: [AcuteConditions.GENERIC],
  [AcuteBodyRegions.LOW_BACK]: [
    AcuteConditions.GENERIC,
    AcuteConditions.RADICULAR
  ],
  [AcuteBodyRegions.HIP]: [
    AcuteConditions.HAMSTRING_STRAIN,
    AcuteConditions.GENERIC
  ],
  [AcuteBodyRegions.KNEE_THIGH]: [
    AcuteConditions.GENERIC,
    AcuteConditions.ANTERIOR,
    AcuteConditions.LATERAL
  ],
  [AcuteBodyRegions.LOWER_LEG_ANKLE_FOOT]: [
    AcuteConditions.ACHILLES_CALF_CONDITIONING,
    AcuteConditions.ANKLE_SPRAIN,
    AcuteConditions.PLANTAR_FASCIITIS
  ]
};

export const perisurgicalConditions: ConditionOptionsByPerisurgicalRegion = {
  [PerisurgicalBodyRegions.ANKLE]: [PerisurgicalConditions.GENERIC],
  [PerisurgicalBodyRegions.BACK]: [
    PerisurgicalConditions.LUMBAR_DECOMPRESSION,
    PerisurgicalConditions.LUMBAR_DISC_REPLACEMENT,
    PerisurgicalConditions.LUMBAR_FUSION,
    PerisurgicalConditions.GENERIC
  ],
  [PerisurgicalBodyRegions.ELBOW]: [PerisurgicalConditions.GENERIC],
  [PerisurgicalBodyRegions.FOOT]: [PerisurgicalConditions.GENERIC],
  [PerisurgicalBodyRegions.HAND]: [PerisurgicalConditions.GENERIC],
  [PerisurgicalBodyRegions.HIP]: [
    PerisurgicalConditions.HIP_REPLACEMENT,
    PerisurgicalConditions.ARTHROSCOPY_LABRAL_REPAIR,
    PerisurgicalConditions.ARTHROSCOPY_LABRAL_IMPINGEMENT,
    PerisurgicalConditions.GENERIC
  ],
  [PerisurgicalBodyRegions.KNEE]: [
    PerisurgicalConditions.ACL,
    PerisurgicalConditions.ARTHROSCOPY,
    PerisurgicalConditions.KNEE_REPLACEMENT,
    PerisurgicalConditions.MENISCUS_REPAIR,
    PerisurgicalConditions.MICROFRACTURE,
    PerisurgicalConditions.PCL,
    PerisurgicalConditions.GENERIC
  ],
  [PerisurgicalBodyRegions.NECK]: [
    PerisurgicalConditions.DECOMPRESSION,
    PerisurgicalConditions.CERVICAL_DISC_REPLACEMENT,
    PerisurgicalConditions.CERVICAL_FUSION,
    PerisurgicalConditions.GENERIC
  ],
  [PerisurgicalBodyRegions.SHOULDER]: [
    PerisurgicalConditions.ROTATOR_CUFF_REPAIR,
    PerisurgicalConditions.DISLOCATION,
    PerisurgicalConditions.IMPINGEMENT,
    PerisurgicalConditions.GENERIC
  ],
  [PerisurgicalBodyRegions.WRIST]: [PerisurgicalConditions.GENERIC]
};

interface Input {
  label: string;
  name: string;
}

export interface SelectInput extends Input {
  options: (
    | AcuteConditions
    | PerisurgicalConditions
    | AcuteBodyRegions
    | PerisurgicalBodyRegions
    | Frequency
    | string
  )[];
}

export const getSelectInput = (program: Programs): SelectInput[] => {
  const bodyOptions =
    program === Programs.PERISURGICAL
      ? Object.values(PerisurgicalBodyRegions)
      : Object.values(AcuteBodyRegions);

  return [
    {
      label: 'Body region',
      options: bodyOptions,
      name: 'bodyRegion'
    },
    {
      label: 'Condition',
      options: [], // empty until a bodyRegion is selected
      name: 'condition'
    },
    {
      label: 'Frequency',
      options: Object.values(Frequency),
      name: 'frequency'
    }
  ];
};

export const textInputs: Input[] = [
  {
    label: 'Weeks (levels)',
    name: 'weeks'
  },
  {
    label: 'Template name',
    name: 'templateName'
  }
];

export const repTypeProps = {
  BILATERAL: {
    abbr: 'BL',
    label: 'Bilateral',
    color: amber[600]
  },
  ALTERNATING: {
    abbr: 'A',
    label: 'Alternating',
    color: lightGreen[700]
  },
  MULTI_MOTION: {
    abbr: 'M',
    label: 'Multi-motion',
    color: purple[700]
  },
  SWITCH_SIDES: {
    abbr: 'SS',
    label: 'Switch Sides',
    color: teal[700]
  },
  UNILATERAL: {
    abbr: 'UL',
    label: 'Unilateral',
    color: indigo[700]
  }
};
