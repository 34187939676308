import { PageCard } from 'components/PageCard';
import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Body } from './Body';

export interface ActivityPlanPageLayoutProps {
  stickyToolbar?: ReactNode;
  pageTitle?: string;
  header?: ReactNode;
  sidebar?: ReactNode;
  children?: ReactNode;
  testId?: string;
}

const Content = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.spacing(4)};
`;

const cardStyles = {
  display: 'flex',
  flexShrink: 0,
  minHeight: 'calc(100vh - 398px)',
  padding: '0',
  width: '726px'
};

export const ActivityPlanPageLayout = ({
  stickyToolbar,
  pageTitle,
  header,
  sidebar,
  children,
  testId
}: ActivityPlanPageLayoutProps): JSX.Element => {
  const fullHeight = !Boolean(stickyToolbar);

  return (
    <div data-testid="activityPlanPageLayout">
      {stickyToolbar || null}
      <Body
        fullHeight={fullHeight}
        pageTitle={pageTitle}
        header={header}
        testId={testId}
      >
        <Content>
          <PageCard style={cardStyles}>{children}</PageCard>
          {sidebar || null}
        </Content>
      </Body>
    </div>
  );
};
