import {
  Box,
  Button as MuiButton,
  Checkbox,
  Divider,
  FormControlLabel as MuiFormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { Form as FormikForm, FormikProps } from 'formik';
import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Exercise } from 'types/activityPlan';
import { removeWhitespace } from 'utils/helpers';
import ExerciseThumbnail from './activity-plan/ExerciseThumbnail';
import { RepTypeAvatar } from './activity-plan/RepTypeAvatar';

export interface InitialValues {
  selectedExercises: string[];
}

interface FormProps extends FormikProps<InitialValues> {
  saveInProcess: boolean;
  buttonLabel: string | undefined;
  exercises: Exercise[];
  handleCloseExerciseLibrary: () => void;
}

const Main = styled.main`
  margin-top: ${({ theme }) => theme.spacing(10)};
`;

const StyledForm = styled(FormikForm)`
  position: relative;
  height: calc(100vh - 16.5rem);
  padding-left: ${({ theme }) => theme.spacing(8)};
`;

const FormContent = styled.div`
  max-height: 32px;
`;

const ScrollArea = styled.div`
  margin-top: ${({ theme }) => theme.spacing(4)};
  overflow-y: auto;
  max-height: calc(100vh - 26rem);
`;

const CheckboxLabel = styled.div`
  display: flex;
  align-items: center;
`;

const FormAction = styled(Box)`
  height: 106px;
  display: flex;
  justify-content: flex-end;
`;

const Button = styled(MuiButton)`
  margin-right: ${({ theme }) => theme.spacing(6)};
  align-self: center;
`;

const FormControlLabel = styled(MuiFormControlLabel)`
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const PrimaryLabel = styled(Typography)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`;

const CheckBoxInput = withTranslation()(
  ({
    t,
    exercise: { uuid, thumbnail, name, repType, anatomicalName },
    onChange,
    values
  }: {
    t: TFunction;
    exercise: Exercise;
    onChange: FormProps['handleChange'];
    values: FormProps['values'];
  }): JSX.Element => (
    <Box>
      <ListItem alignItems="flex-start">
        <FormControlLabel
          label={
            <CheckboxLabel>
              {thumbnail ? <ExerciseThumbnail src={thumbnail.url} /> : null}
              <ListItemText
                primary={
                  <PrimaryLabel variant="body2">
                    {t(name)}
                    <RepTypeAvatar repType={repType} size={5.5} fontSize={12} />
                  </PrimaryLabel>
                }
                secondary={
                  <Typography variant="caption" color="textSecondary">
                    {anatomicalName}
                  </Typography>
                }
              />
            </CheckboxLabel>
          }
          htmlFor={name}
          control={
            <Checkbox
              onChange={onChange}
              color="primary"
              inputProps={{ id: name }}
              name="selectedExercises"
              value={uuid}
              checked={values.selectedExercises.includes(uuid)}
              data-testid={`${removeWhitespace(name)}Checkbox`}
            />
          }
        />
      </ListItem>
      <Divider />
    </Box>
  )
);

export default function ExerciseLibraryForm({
  saveInProcess,
  buttonLabel = 'progression',
  exercises,
  isSubmitting,
  values,
  handleChange
}: FormProps): JSX.Element {
  return (
    <Main>
      <StyledForm aria-label="Exercise library form">
        <FormContent>
          <Typography
            variant="subtitle2"
            color="textSecondary"
            component="h2"
          >{`${exercises.length} exercises`}</Typography>
          <ScrollArea>
            <FormGroup>
              <List>
                {exercises.map(exercise => (
                  <CheckBoxInput
                    key={exercise.uuid}
                    exercise={exercise}
                    values={values}
                    onChange={handleChange}
                  />
                ))}
              </List>
            </FormGroup>
          </ScrollArea>
          <FormAction borderTop={1} borderColor="divider">
            <Button
              variant="contained"
              color="primary"
              disabled={
                saveInProcess ||
                isSubmitting ||
                !values.selectedExercises.length
              }
              size="large"
              startIcon={<AddIcon />}
              type="submit"
              data-testid="addExercisesButton"
            >
              {`Add to ${buttonLabel}`}
            </Button>
          </FormAction>
        </FormContent>
      </StyledForm>
    </Main>
  );
}
