import * as Sentry from '@sentry/react';
import ErrorBoundaryFallback from 'components/ErrorBoundaryFallback';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

function getSentryRelease(): string {
  const nodeEnv = process.env.REACT_APP_ENV;
  const isDeploy = nodeEnv === 'staging' || nodeEnv === 'production';

  if (isDeploy) {
    if (!process.env.REACT_APP_RELEASE) {
      throw new Error(
        'process.env.REACT_APP_RELEASE must be defined for staging or production release'
      );
    }

    return process.env.REACT_APP_RELEASE?.slice(0, 7);
  }

  return 'local';
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  environment: process.env.REACT_APP_ENV,
  release: getSentryRelease(),
  attachStacktrace: true
});

function SentryErrorBoundary({ children }: Props): JSX.Element {
  return (
    <Sentry.ErrorBoundary fallback={ErrorBoundaryFallback}>
      {children}
    </Sentry.ErrorBoundary>
  );
}

export default SentryErrorBoundary;
