export enum LocalStorageKey {
  HingeToken = 'HingeToken',
  GoogleToken = 'GoogleToken',
  LogoutEvent = 'LogoutEvent',
  ExtendSession = 'ExtendSession',
  PTUserInfo = 'PTUserInfo',
  DebugMode = 'DebugMode',
  LastVisitedPage = 'LastVisitedPage'
}

const get = (key: LocalStorageKey): any => {
  try {
    const response = localStorage.getItem(key);
    return response ? JSON.parse(response) : null;
  } catch (err) {
    console.log('error getting item', err);
  }
};

const set = <D>(key: LocalStorageKey, data: D): void => {
  try {
    localStorage.setItem(key, JSON.stringify(data));
  } catch (err) {
    console.log('error setting item', err);
  }
};

const remove = (key: LocalStorageKey): void => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    console.log('error removing item', err);
  }
};

const clear = (): void => localStorage.clear();

export const LocalStorage = {
  get,
  set,
  remove,
  clear
};
