import { RepType } from 'types';
import { Exercise } from 'types/activityPlan';

const ONE_SECOND = 1000;
const ONE_MINUTE = 60000;
const EXTRA_TIME_PER_STEP = 2000;
const EXTRA_TIME_PER_EXERCISE = 1000;
const ESTIMATION_MULTIPLIER = 1.5;
const DEFAULT_SWITCH_LIMB_TIME = 2000;

const millisToMinutes = (millis: number): number => millis / ONE_MINUTE;

export const millisToSeconds = (millis: number): string =>
  (millis / ONE_SECOND).toFixed();

export const millisToSecondsOrNull = (
  millis: number | null | undefined
): number | null | undefined => (millis && millis / ONE_SECOND) ?? millis;

export const secondsToMillis = (seconds: number): number =>
  seconds * ONE_SECOND;

export const getEstimatedEtSessionTime = (exercises: Exercise[]): number => {
  const estimatedEtSessionTime = exercises.reduce(
    (
      acc,
      {
        reps,
        holdTime,
        flexTime,
        restTime,
        switchLimbTime,
        numSteps,
        repType,
        numHoldMotions
      }
    ) => {
      /*
       * PHX adds buffer time at:
       * 1. each step (reps are a subset of a step)
       * 2. each exercise
       * 3. to the entire session using an estimation multiplier
       * See PHX code => https://github.com/hinge-health/phoenix/blob/f3f7d492d40293937e1a2866cc62abf9e3259291/src/modules/et-session/utils.ts
       */

      let calculatedLimbTime = 0;
      if (repType === RepType.SwitchSides) {
        calculatedLimbTime = switchLimbTime || DEFAULT_SWITCH_LIMB_TIME;
      }
      const repTime =
        (flexTime ?? 0) + (restTime ?? 0) + (holdTime ?? 0) * numHoldMotions;

      const repetitionStepTime = repTime * reps;
      const switchLimbStepTime = calculatedLimbTime;
      const extraStepTime = numSteps * EXTRA_TIME_PER_STEP;

      const totalExerciseTime =
        repetitionStepTime +
        switchLimbStepTime +
        extraStepTime +
        EXTRA_TIME_PER_EXERCISE;

      return (acc += totalExerciseTime);
    },
    0
  );

  const totalEstimatedEtSessionTime =
    estimatedEtSessionTime * ESTIMATION_MULTIPLIER;
  return Math.round(millisToMinutes(totalEstimatedEtSessionTime));
};
