import React, { Children, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProgressionTypes } from 'utils/activityPlan';
import { ProgressionStage } from '../ProgressionStage';
import { ChronicLevelHeader } from './ChronicLevelHeader';

export interface ChronicLevelProps {
  children?: React.ReactNode | null;
  id: string;
  level: number;
  current?: boolean | null;
  points: number;
  targetPoints: number;
  estimatedTime: number;
  numEtSessions: number;
  wasModifiedByAdmin?: boolean;
  etpeVersion?: string | null;
  canCopyExercises: boolean;

  expanded: boolean;
  onCopyUniqueExercises: () => void;
  onCopyAllExercises: () => void;
}

const EmptyLevel = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
  text-align: center;
`;

export const ChronicLevel = ({
  id,
  level,
  current,
  points,
  targetPoints,
  estimatedTime,
  numEtSessions,
  wasModifiedByAdmin,
  etpeVersion,
  canCopyExercises,
  expanded,
  onCopyUniqueExercises,
  onCopyAllExercises,
  children
}: ChronicLevelProps): JSX.Element => {
  const [localExpanded, setExpanded] = useState<boolean>(expanded);

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  return (
    <ProgressionStage
      padding={4.5}
      expanded={localExpanded}
      id={`${ProgressionTypes.chronic}${level}`}
      header={
        <ChronicLevelHeader
          id={id}
          level={level}
          points={points}
          current={current}
          targetPoints={targetPoints}
          estimatedTime={estimatedTime}
          numEtSessions={numEtSessions}
          wasModifiedByAdmin={wasModifiedByAdmin}
          etpeVersion={etpeVersion}
          onToggleCollapse={() => setExpanded(!localExpanded)}
          expanded={localExpanded}
          canCopyExercises={canCopyExercises}
          onCopyUniqueExercises={onCopyUniqueExercises}
          onCopyAllExercises={onCopyAllExercises}
        />
      }
    >
      {children && Children.toArray(children).length ? (
        children
      ) : (
        <EmptyLevel>There are no exercises in this level.</EmptyLevel>
      )}
    </ProgressionStage>
  );
};
