import { RouteComponentProps } from '@reach/router';
import { ExercisesTable } from 'components/exercise-repo/ExercisesTable';
import { Body } from 'components/layout/Body';
import { PageCard } from 'components/PageCard';
import React from 'react';

const cardStyles = { paddingTop: '24px' };

const ExerciseIndex: React.FC<RouteComponentProps> = (): JSX.Element => {
  return (
    <Body pageTitle="Exercise Library" testId="exerciseIndex">
      <PageCard style={cardStyles}>
        <ExercisesTable />
      </PageCard>
    </Body>
  );
};

export default ExerciseIndex;
