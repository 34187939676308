import {
  PDFActivityPlanInfoItem,
  PDFActivityPlanInfoWrapper,
  PDFDocumentLetterhead,
  PDFProgression
} from 'components/PDFDocuments/PDFDocument';
import React from 'react';
import { ActivityPlan, Progression } from 'types/activityPlan';
import { DraftActivityPlan, DraftProgression } from 'types/draftActivityPlan';
import { ProgressionTypes } from 'utils/activityPlan';
import { Programs } from 'utils/programConstants';

interface Props {
  activityPlan: ActivityPlan | DraftActivityPlan | undefined;
}

export const AcuteTemplatePDFDocument = ({
  activityPlan
}: Props): JSX.Element | null => {
  if (!activityPlan) {
    return null;
  }

  const { name, progressions, data } = activityPlan;
  const pdfProgressions: JSX.Element[] = [];
  progressions.forEach((progression: Progression | DraftProgression) => {
    pdfProgressions.push(
      <PDFProgression
        progression={progression}
        type={ProgressionTypes[Programs.ACUTE]}
        key={progression.uuid}
      />
    );
  });

  return (
    <PDFDocumentLetterhead title={name ?? `Acute ${data?.bodyRegion}`}>
      <PDFActivityPlanInfoWrapper>
        <PDFActivityPlanInfoItem text="Program: Acute" />
        <PDFActivityPlanInfoItem text={`Body Region: ${data?.bodyRegion}`} />
        <PDFActivityPlanInfoItem text={`Condition: ${data?.condition}`} />
      </PDFActivityPlanInfoWrapper>
      {pdfProgressions}
    </PDFDocumentLetterhead>
  );
};
