import {
  ActivityPlanFragment,
  EtSessionFragment,
  ExerciseFragment,
  ProgressionDataAcuteFragment,
  ProgressionDataChronicFragment,
  ProgressionDataPerisurgicalFragment,
  ProgressionFragment
} from '../types';
import { DraftActivityPlan } from './draftActivityPlan';

export type ActivityPlan = ActivityPlanFragment;
export type Activity = EtSessionFragment;
export type Progression = ProgressionFragment;
export type ProgressionDataAcute = ProgressionDataAcuteFragment;
export type ProgressionDataChronic = ProgressionDataChronicFragment;
export type ProgressionDataPerisurgical = ProgressionDataPerisurgicalFragment;
export type ProgressionData =
  | ProgressionDataAcute
  | ProgressionDataChronic
  | ProgressionDataPerisurgical;
export type Exercise = ExerciseFragment;
export type ConfigSummary = Pick<ActivityPlan, 'uuid' | 'uri'> & {
  draft?: Pick<DraftActivityPlan, 'uuid'> | null;
};

export const isProgressionDataChronic = (
  data: ProgressionData
): data is ProgressionDataChronic =>
  data.__typename === 'ProgressionMetaDataChronic';

export const isProgressionDataPerisurgical = (
  data: ProgressionData
): data is ProgressionDataPerisurgical =>
  data.__typename === 'ProgressionMetaDataPerisurgical';

export const isEtSessionActivity = (
  activity: Activity
): activity is EtSessionFragment => activity.kind === 'et-session';

export const isKeyOfExercise = (key: string): key is keyof Exercise =>
  ['holdTime', 'name', 'order', 'reps', 'uuid', 'pointsPerRep'].includes(key);

export type DraggableItem = Exercise | Progression;
