import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExerciseFragment, GetAllExercisesQuery } from 'types';
import { FlattenedExercise, InterfaceToRecord } from 'types/exerciseRepo';
import { filterExercisesBySearch } from 'utils/exerciseLibrary';

export const useAddSearchTranslationHook = (
  data: GetAllExercisesQuery | undefined,
  setSearchedExercises:
    | Dispatch<SetStateAction<ExerciseFragment[]>>
    | ((items: InterfaceToRecord<FlattenedExercise>[]) => void)
): {
  exerciseSearchTerm: string;
  setExerciseSearchTerm: Dispatch<SetStateAction<string>>;
} => {
  const { t: translateContent } = useTranslation();
  const [exerciseSearchTerm, setExerciseSearchTerm] = useState<string>('');
  useEffect(() => {
    const translatedData = data
      ? data.exercisesGetAll.map((exercise: ExerciseFragment) => ({
          ...exercise,
          translatedName: translateContent(exercise.name)
        }))
      : [];
    const exercises = translatedData
      ? filterExercisesBySearch(exerciseSearchTerm, translatedData)
      : [];
    setSearchedExercises(exercises);
  }, [data, translateContent, setSearchedExercises, exerciseSearchTerm]);

  return {
    exerciseSearchTerm,
    setExerciseSearchTerm
  };
};
