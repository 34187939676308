import React, { useState } from 'react';
import {
  DEFAULT_HOLD_TIME,
  doesProgressionHaveExercises
} from 'utils/activityPlan';
import { isPerisurgical } from 'utils/extractProgramInfoFromUri';
import { getEstimatedEtSessionTime } from 'utils/time';
import {
  ActivityPlan as ActivityPlanType,
  Progression
} from '../../../types/activityPlan';
import { ActivityPlanStage } from '../ActivityPlanStage';
import ExpandAllCheckbox from '../ExpandAllCheckbox';
import { PerisurgicalExercise } from './PerisurgicalExercise';
import { PerisurgicalWeek } from './PerisurgicalWeek';

export interface PerisurgicalActivityProps {
  activityPlan: ActivityPlanType;
  onCopyUniqueExercises: (progression: Progression, message: string) => void;
  onCopyAllExercises: (progression: Progression, message: string) => void;
}

const copyMessage = (Week: number): string =>
  `Week ${Week} exercise list copied to clipboard`;

export const PerisurgicalActivityPlan = ({
  activityPlan,
  onCopyUniqueExercises,
  onCopyAllExercises
}: PerisurgicalActivityProps): JSX.Element => {
  const [expandAll, setExpandAll] = useState<boolean>(true);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setExpandAll(event.currentTarget.checked);

  return (
    <ActivityPlanStage>
      <ExpandAllCheckbox onChange={handleOnChange} defaultChecked={expandAll} />
      {activityPlan.progressions.map(progression => {
        const { current, order, uuid, activities } = progression;
        const exercises = activities?.[0]?.data?.exercises ?? [];
        const week = order + 1;
        return isPerisurgical(activityPlan) ? (
          <PerisurgicalWeek
            key={uuid}
            id={uuid}
            week={week}
            estimatedTime={getEstimatedEtSessionTime(exercises)}
            current={current}
            canCopyExercises={doesProgressionHaveExercises(progression)}
            onCopyUniqueExercises={() =>
              onCopyUniqueExercises(progression, copyMessage(week))
            }
            onCopyAllExercises={() =>
              onCopyAllExercises(progression, copyMessage(week))
            }
            expanded={expandAll}
          >
            {exercises.map(exercise => (
              <PerisurgicalExercise
                key={exercise.uuid + exercise.order}
                uuid={exercise.uuid}
                name={exercise.name}
                anatomicalName={exercise.anatomicalName}
                reps={exercise.reps}
                thumbnailUrl={exercise.thumbnail?.url}
                repType={exercise.repType}
                holdTime={exercise.holdTime ?? DEFAULT_HOLD_TIME}
              />
            ))}
          </PerisurgicalWeek>
        ) : (
          <p>Not perisurgical</p>
        );
      })}
    </ActivityPlanStage>
  );
};
