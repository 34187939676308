import { createMuiTheme } from '@material-ui/core/styles';
declare module '@material-ui/core/styles/createPalette' {
  interface Palette {
    brand: Palette['primary'];
  }
  interface PaletteOptions {
    brand: PaletteOptions['primary'];
  }
}

const materialTheme = createMuiTheme({
  overrides: {
    MuiCollapse: {
      hidden: {
        display: 'none'
      }
    }
  },
  palette: {
    primary: {
      main: '#1976d2'
    },
    brand: {
      main: '#fe6a2f'
    }
  },
  spacing: factor => `${4 * factor}px`,
  typography: {
    fontFamily: 'Lato, sans-serif',
    h1: {
      'font-size': '34px',
      'font-weight': 'normal',
      'letter-spacing': '0.25px',
      'line-height': '42px'
    },
    body1: {
      'font-size': '14px',
      'font-weight': 'normal',
      'letter-spacing': '0.15px',
      'line-height': '20px'
    }
  }
});

export default materialTheme;
