import { Avatar as MuiAvatar } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { RepType } from 'types';
import { repTypeProps } from 'utils/programConstants';
export interface RepTypeAvatarProps {
  repType?: RepType | null;
  size?: number;
  fontSize?: number;
}

const Abbr = styled(props => <abbr {...props} />)`
  ${({ fontSize }) => `
  font-size: ${fontSize ?? 10}px;
`};
  text-decoration: none;
`;

const Avatar = styled(props => <MuiAvatar {...props} />)`
  ${({ size, theme, color }) => `
     width: ${theme.spacing(size ?? 4.5)};
     height: ${theme.spacing(size ?? 4.5)};
     background: ${color};
     margin-left: ${theme.spacing(0.5)}
  `};
`;

export const RepTypeAvatar = ({
  repType,
  size,
  fontSize
}: RepTypeAvatarProps): JSX.Element | null => {
  return repType ? (
    <Avatar size={size} color={repTypeProps[repType].color} component="span">
      <Abbr fontSize={fontSize} title={repTypeProps[repType].label}>
        {repTypeProps[repType].abbr}
      </Abbr>
    </Avatar>
  ) : null;
};
