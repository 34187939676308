import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Exercise, ExerciseProps } from './Exercise';

export interface DraggableExerciseProps extends ExerciseProps {
  index: number;
}

const DraggableItem = styled.div`
  display: flex;
  width: 100%;
`;

export const DraggableExercise = ({
  index,
  children,
  ...exerciseProps
}: DraggableExerciseProps): JSX.Element => (
  <Draggable draggableId={`Draggable_${exerciseProps.id}`} index={index}>
    {provided => (
      <DraggableItem
        {...provided.dragHandleProps}
        {...provided.draggableProps}
        ref={provided.innerRef}
      >
        <Exercise {...exerciseProps}>{children}</Exercise>
      </DraggableItem>
    )}
  </Draggable>
);
