import { colors, Paper as MuiPaper } from '@material-ui/core';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { useInViewport } from 'hooks/useInViewport';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import styled from 'styled-components';

/**
 * React.forwardRef is required for Paper to obtain the ref passed to it from `<Container/>`,
 * then forward it to the MuiPaper component that it renders.  The `ref` does not get forwarded with other props.
 */
const Paper = React.forwardRef((props: Partial<ProgressionProps>, ref) => (
  <MuiPaper ref={ref} {...props} />
));
Paper.displayName = 'Paper';

const Container = styled(Paper)`
  background: ${colors.grey[200]};
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: ${({ theme, padding }) => theme.spacing(padding ?? 6)};
  .MuiCollapse-container {
    width: 100%;
  }
`;

const Main = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: ${({ theme }) => theme.spacing(3)};
  margin: ${({ theme }) => theme.spacing(4, 0)};
  width: 100%;
`;

interface MainComponentProps {
  children?: React.ReactNode;
}

/**
 * Creates a `Main` component decorated with a Droppable container.
 *
 * To create a ProgressionStage that's droppable, override the `mainComponent`
 * prop.
 *
 * Example:
 * ```jsx
 * <ProgressionStage
 *   mainComponent={createExerciseDropStage(progressionUuid)}
 * />
 * ```
 */
export const createExerciseDropStage = (
  id: string
): ((props: MainComponentProps) => JSX.Element) => {
  const MainComponent = ({ children }: MainComponentProps): JSX.Element => (
    <Droppable droppableId={id} type="exercise">
      {provided => (
        <Main ref={provided.innerRef} {...provided.droppableProps}>
          {children}
          {provided.placeholder}
        </Main>
      )}
    </Droppable>
  );
  return MainComponent;
};

export interface ProgressionProps {
  children: React.ReactNode;
  header?: null | JSX.Element;
  footer?: null | JSX.Element;
  mainComponent?: ({ children }: { children: React.ReactNode }) => JSX.Element;
  expanded?: boolean;
  id?: string;
  padding?: number;
}

const Placeholder = styled.div<{ numberOfExercises: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ theme, numberOfExercises }) =>
    /**
     * Exercises are 68px each
     * Exercises have 12px in between each other
     * Element itself has 32px of margin (16px on top & bottom), this is not included in height
     *
     * If these numbers get out of sync, it'll be gross.
     * Read about [Cumulative Layout Shift](https://web.dev/cls/?utm_source=devtools)
     */
    theme.spacing(numberOfExercises * 17 + (numberOfExercises - 1) * 3)};
`;

const LoadingPlaceholder = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <Placeholder numberOfExercises={React.Children.count(children)}>
    <LoadingSpinner value={Math.floor(Math.random() * (95 - 25 + 1) + 25)} />
  </Placeholder>
);

interface CollapseProps {
  children?: React.ReactNode;
  expanded: boolean;
}
const Collapse = ({ expanded, children }: CollapseProps): JSX.Element | null =>
  expanded ? <>{children}</> : null;

export const ProgressionStage = ({
  children,
  header,
  footer,
  mainComponent: MainComponent = Main,
  expanded = true,
  id,
  padding
}: ProgressionProps): JSX.Element => {
  const { ref, inViewport } = useInViewport();

  return (
    <Container data-testid="progression" id={id} ref={ref} padding={padding}>
      {header}
      <Collapse expanded={expanded}>
        <MainComponent data-testid="progressionStageMain">
          {inViewport || process.env.NODE_ENV === 'test' ? (
            children
          ) : (
            <LoadingPlaceholder>{children}</LoadingPlaceholder>
          )}
        </MainComponent>
        {footer}
      </Collapse>
    </Container>
  );
};
