import React from 'react';
import { ActivityPlanStatus } from 'types';
import { ProgressionTypes } from 'utils/activityPlan';
import { DraftStatuses } from '../../../providers/DraftActivityPlanProvider';
import { Header } from '../../layout/Header';
import { TimeBasedUserChip } from '../../TimeBasedUserChip';
import { ActivityPlanStatusChip } from '../ActivityPlanStatus';
import { BetaProductionIndicator } from '../BetaProductionIndicator';
import { CurrentProgression } from '../CurrentProgression';
import { DraftStatus } from '../DraftStatus';
import { ViewOnly } from '../ViewOnly';

export interface ChronicHeaderProps {
  name: string;
  viewOnly?: boolean;
  status?: DraftStatuses;
  lastUpdatedAt?: Date;
  draftStatus?: { status: DraftStatuses; lastUpdatedAt?: Date };
}

export const ChronicHeader = ({
  name,
  viewOnly,
  draftStatus
}: ChronicHeaderProps): JSX.Element => (
  <Header title={name}>
    {viewOnly ? <ViewOnly /> : null}
    {draftStatus ? (
      <DraftStatus
        lastUpdatedAt={draftStatus.lastUpdatedAt}
        status={draftStatus.status}
      />
    ) : null}
  </Header>
);

export interface ChronicUserHeaderProps {
  userId: string;
  isTimeBasedUser?: boolean;
  name: string;
  viewOnly?: boolean;
  status?: DraftStatuses;
  lastUpdatedAt?: Date;
  currentLevel: number | null;
  draftStatus?: { status: DraftStatuses; lastUpdatedAt: Date };
  activityPlanStatus: { status: ActivityPlanStatus };
  isBeta?: boolean;
}

export const ChronicUserHeader = ({
  userId,
  isTimeBasedUser,
  name,
  viewOnly,
  draftStatus,
  currentLevel,
  activityPlanStatus,
  isBeta
}: ChronicUserHeaderProps): JSX.Element => (
  <Header title={`User ID: ${userId}`} subTitle={name}>
    {isTimeBasedUser ? <TimeBasedUserChip /> : null}
    <ActivityPlanStatusChip status={activityPlanStatus.status} />
    {currentLevel ? (
      <CurrentProgression
        progressionLabel={ProgressionTypes.chronic}
        current={currentLevel}
        targetId={`${ProgressionTypes.chronic}${currentLevel}`}
      />
    ) : null}
    {viewOnly ? <ViewOnly /> : null}
    <BetaProductionIndicator isBeta={isBeta} />
    {draftStatus ? (
      <DraftStatus
        lastUpdatedAt={draftStatus.lastUpdatedAt}
        status={draftStatus.status}
      />
    ) : null}
  </Header>
);
