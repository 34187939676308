import { RouteComponentProps, useNavigate } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { ActionButton } from 'components/activity-plan/ActionButton';
import { ActivityPlanToolbar } from 'components/activity-plan/ActivityPlanToolbar';
import { DiscardDraftButton } from 'components/activity-plan/DiscardDraftButton';
import { PerisurgicalHeader } from 'components/activity-plan/perisurgical/PerisurgicalHeader';
import { CopyToClipboardButtonIcon } from 'components/CopyToClipboardButtonIcon';
import { DownloadPDFButtonIcon } from 'components/DownloadPDFButtonIcon';
import ExerciseLibrary from 'components/ExerciseLibrary';
import { ActivityPlanPageLayout } from 'components/layout/ActivityPlanPageLayout';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { PerisurgicalTemplatePDFDocument } from 'components/PDFDocuments/PerisurgicalTemplatePDFDocument';
import { EditablePerisurgicalActivityPlan } from 'connected/activity-plan/EditablePerisurgicalActivityPlan';
import { useCopyPlanToClipboard } from 'hooks/useCopyPlanToClipboard';
import {
  ActivityPlanProvider,
  useActivityPlan
} from 'providers/ActivityPlanProvider';
import {
  DraftActivityPlanProvider,
  useDraftActivityPlan,
  useDraftActivityPlanAPI
} from 'providers/DraftActivityPlanProvider';
import { useEnsureDraft } from 'providers/hooks/useEnsureDraft';
import React, { useEffect, useState } from 'react';
import { TargetProgression } from 'types/targetProgression';
import { extractProgressionTypeFromUri } from 'utils/activityPlan';
import { extractProgramInfoFromUri } from 'utils/extractProgramInfoFromUri';

const createOnValidationError =
  ({ uri }: { uri: string }) =>
  (): void => {
    const progressionType = extractProgressionTypeFromUri(uri).toLowerCase();

    snackbarState({
      type: 'generic',
      severity: 'error',
      title: `Please add at least one ${progressionType} of exercises in order to publish`,
      message: 'Try again'
    });
  };

const PerisurgicalTemplateEdit: React.FC<RouteComponentProps> =
  (): JSX.Element => {
    const [canCreateDraft, setCanCreateDraft] = useState<boolean>(true);
    const navigate = useNavigate();
    const { activityPlan, loading } = useActivityPlan();
    const {
      draft,
      saveInProcess,
      commitInProcess: publishInProcess,
      status,
      isDirty
    } = useDraftActivityPlan();
    const { commit, discard, save } = useDraftActivityPlanAPI();
    const { canCopyToClipboard, handleCopyExercisesToClipboard } =
      useCopyPlanToClipboard(draft?.progressions);
    const { program, indication } = extractProgramInfoFromUri(draft?.uri);
    const disableToolbarButtons = !draft || saveInProcess || publishInProcess;
    const [targetProgression, setTargetProgression] =
      useState<TargetProgression | null>(null);

    useEnsureDraft(canCreateDraft);

    useEffect(() => {
      if (draft && !draft.isOwnedByCurrentAdmin) {
        snackbarState({
          type: 'generic',
          severity: 'error',
          title: 'A draft is currently in progress',
          message: 'You must be the owner of this draft to continue editing'
        });
        navigate(`/programs/${program}/${activityPlan?.uuid}`);
      }
    }, [draft, navigate, program, activityPlan]);

    if (loading || !draft || !activityPlan) {
      return <LoadingSpinner />;
    }

    const handlePublish = async (): Promise<void> => {
      setCanCreateDraft(false);
      const { didCommit, activityPlanUuid, errorMessage } = await commit({
        onNotSaveable: createOnValidationError({ uri: draft.uri })
      });
      if (didCommit && activityPlanUuid) {
        navigate(`/programs/${program}/${activityPlanUuid}`);
        snackbarState({
          type: 'generic',
          severity: 'success',
          message: 'Draft was published!'
        });
      } else {
        snackbarState({
          type: 'generic',
          severity: 'error',
          title: errorMessage,
          message: 'Try again'
        });
      }
    };

    const handleDiscard = async (): Promise<void> => {
      setCanCreateDraft(false);
      const didDiscard = await discard();
      if (didDiscard) {
        navigate('../');
        snackbarState({
          type: 'generic',
          severity: 'success',
          message: 'All changes have been discarded!'
        });
      } else {
        snackbarState({
          type: 'generic',
          severity: 'error',
          title: 'Unable to discard changes',
          message: 'Try again'
        });
      }
    };

    const handleSaveDraft = async (): Promise<void> => {
      const { status: didSave, errorMessage } = await save();
      if (didSave) {
        snackbarState({
          type: 'generic',
          severity: 'success',
          message: 'All changes have been saved!'
        });
      } else {
        snackbarState({
          type: 'generic',
          severity: 'error',
          title: errorMessage,
          message: 'Try again'
        });
      }
    };

    return (
      <ActivityPlanPageLayout
        stickyToolbar={
          <ActivityPlanToolbar
            actionButtons={
              <>
                <DiscardDraftButton
                  onClick={handleDiscard}
                  disabled={disableToolbarButtons}
                />
                <ActionButton
                  text="SAVE DRAFT"
                  onClick={handleSaveDraft}
                  disabled={disableToolbarButtons || !isDirty}
                />
                <ActionButton
                  text="PUBLISH"
                  onClick={handlePublish}
                  disabled={disableToolbarButtons}
                />
              </>
            }
          >
            <DownloadPDFButtonIcon
              disabled={disableToolbarButtons}
              testId="TemplateEditDownloadIcon"
              title="Download PDF"
              placement="bottom-start"
              document={
                <PerisurgicalTemplatePDFDocument activityPlan={activityPlan} />
              }
              fileName={draft.name ?? 'exercise-therapy-template'}
            />
            <CopyToClipboardButtonIcon
              disabled={!canCopyToClipboard}
              onClick={handleCopyExercisesToClipboard}
              testId="TemplateEditCopyIcon"
              title={
                canCopyToClipboard
                  ? 'Copy all activity plan exercises'
                  : 'Copy all exercises disabled'
              }
              placement="bottom-start"
            />
          </ActivityPlanToolbar>
        }
        pageTitle={draft.name || 'Edit'}
        header={
          <PerisurgicalHeader
            name={draft.name ?? indication ?? 'Edit Perisurgical Template'}
            draftStatus={{ status, lastUpdatedAt: draft.updatedAt }}
          />
        }
        testId="editTemplatePage"
      >
        <EditablePerisurgicalActivityPlan
          onAddExercises={setTargetProgression}
        />
        {targetProgression && (
          <ExerciseLibrary
            targetProgression={targetProgression}
            handleCloseExerciseLibrary={() => setTargetProgression(null)}
          />
        )}
      </ActivityPlanPageLayout>
    );
  };

interface Props extends RouteComponentProps {
  uuid?: string;
}

const ConnectedPerisurgicalTemplateEdit = ({
  uuid = ''
}: Props): JSX.Element => (
  <ActivityPlanProvider uuid={uuid}>
    <DraftActivityPlanProvider>
      <PerisurgicalTemplateEdit />
    </DraftActivityPlanProvider>
  </ActivityPlanProvider>
);

export { ConnectedPerisurgicalTemplateEdit as PerisurgicalTemplateEdit };
