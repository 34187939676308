import React from 'react';
import styled from 'styled-components';

interface ExerciseThumbnailProps {
  src: string;
}

const Thumbnail = styled.img`
  margin-right: ${({ theme }) => theme.spacing(2)};
  border-radius: 4px;
  height: 40px;
  width: 40px;
`;

const ExerciseThumbnail = ({ src }: ExerciseThumbnailProps): JSX.Element => (
  <Thumbnail src={src} alt="" />
);

export default ExerciseThumbnail;
