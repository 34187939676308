import { useActivityPlan } from 'providers/ActivityPlanProvider';
import { findCurrentProgressionNum } from 'utils/activityPlan';

export const useCurrentProgressionNum = (): number | null => {
  const { activityPlan } = useActivityPlan();
  if (activityPlan?.progressions) {
    return findCurrentProgressionNum(activityPlan.progressions);
  }
  return null;
};
