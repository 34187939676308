import { userAuthState } from 'apollo/reactive-variables/userAuthState';
import { AcceptedRoles } from 'types';

enum SuperRoles {
  PT = AcceptedRoles.PhysicalTherapist,
  SUPER = AcceptedRoles.Super
}

export const hasSuperAccess = (): boolean => {
  const { roles } = userAuthState();
  return Boolean(roles?.some(role => role in SuperRoles));
};
