import { useSemaphore } from 'hooks/useSemaphore';
import {
  useActivityPlan,
  useActivityPlanApi
} from 'providers/ActivityPlanProvider';
import { useEffect } from 'react';

export const useEnsureBetaDrafts = async (
  canCreateBetaDrafts: boolean
): Promise<void> => {
  const { betaConfigs, didCreateBetas, didCreateBetaDrafts, loading } =
    useActivityPlan();
  const { createBetaDrafts } = useActivityPlanApi();
  const { increment, decrement } = useSemaphore({ max: 1 });

  useEffect(() => {
    if (
      canCreateBetaDrafts &&
      !loading &&
      (betaConfigs?.length === 3 || didCreateBetas) &&
      !betaConfigs?.find(beta => beta.draft) &&
      !didCreateBetaDrafts
    ) {
      (async () => {
        if (increment()) {
          await createBetaDrafts();
          decrement();
        }
      })();
    }
  }, [
    betaConfigs,
    canCreateBetaDrafts,
    didCreateBetas,
    didCreateBetaDrafts,
    loading,
    increment,
    decrement,
    createBetaDrafts
  ]);
};
