import React from 'react';

/**
 * A safe alternative to `useContext`. Combine this with a context that has no default
 * value (`React.createContext(undefined)`) to ensure that a Provider is always in scope.
 *
 * Example:
 * ```ts
 * const MyContext = React.createContext(undefined);
 * const useMyContext = safeUseContext(MyContext, 'MyContext');
 * ```
 */
export const safeUseContext = <Context extends React.Context<any>>(
  context: Context,
  contextName: string
) => (): Context extends React.Context<infer C>
  ? Exclude<C, undefined>
  : never => {
  const value = React.useContext(context);
  if (value === undefined) {
    throw Error(
      `Cannot use React.useContext(${contextName}) without a ${contextName}.Provider in scope.`
    );
  }
  return value;
};
