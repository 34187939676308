import { useExpandState } from 'hooks/useExpandState';
import React, { ReactNode } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { Progression } from 'types/activityPlan';
import { DraftActivityPlan, EditableExercise } from 'types/draftActivityPlan';
import { TargetProgression } from 'types/targetProgression';
import {
  createLocation,
  ExerciseLocation,
  MoveExercise
} from 'utils/draftActivityPlan';
import { isProgressionDataChronic } from '../../../types/activityPlan';
import {
  computeActualSessionPoints,
  createSubstitutionMap,
  DEFAULT_HOLD_TIME,
  DEFAULT_POINTS_PER_REP,
  doesProgressionHaveExercises
} from '../../../utils/activityPlan';
import { getEstimatedEtSessionTime } from '../../../utils/time';
import { ActivityPlanStage } from '../ActivityPlanStage';
import ExpandAllCheckbox from '../ExpandAllCheckbox';
import {
  ChronicEditExerciseModal,
  useChronicEditExerciseModal
} from './ChronicEditExerciseModal';
import { EditableChronicExercise } from './EditableChronicExercise';
import { EditableChronicLevel } from './EditableChronicLevel';

export interface EditableChronicActivityPlanProps {
  activityPlan?: DraftActivityPlan;
  loading: boolean;
  toolbar?: ReactNode;
  currentProgression?: number | null;
  onAddExercises: (target: TargetProgression) => void;
  onCopyUniqueExercises: (progression: Progression, message: string) => void;
  onCopyAllExercises: (progression: Progression, message: string) => void;
  onDeleteExercise: (location: ExerciseLocation) => void;
  onDuplicateExercise: (location: ExerciseLocation) => void;
  onEditExercise: (location: ExerciseLocation, edits: EditableExercise) => void;
  onInvalidPointsPerRepExercise: (value: number, reasons: string[]) => void;
  onInvalidRepsExercise: (value: number, reasons: string[]) => void;
  onInvalidHoldExercise: (value: number, reasons: string[]) => void;
  onMoveExercise: (move: MoveExercise) => void;
}

const copyMessage = (level: number): string =>
  `Level ${level} exercise list copied to clipboard`;
export const EditableChronicActivityPlan = ({
  activityPlan,
  loading,
  toolbar,
  currentProgression,
  onAddExercises,
  onDeleteExercise,
  onEditExercise,
  onDuplicateExercise,
  onCopyUniqueExercises,
  onCopyAllExercises,
  onInvalidPointsPerRepExercise,
  onInvalidRepsExercise,
  onInvalidHoldExercise,
  onMoveExercise
}: EditableChronicActivityPlanProps): JSX.Element => {
  const {
    expandState,
    reset: changeExpandState,
    override
  } = useExpandState(false);
  const {
    open: openEditExerciseModal,
    close: closeEditExerciseModal,
    modal
  } = useChronicEditExerciseModal();

  const onDragEnd = ({ destination, source }: DropResult): void => {
    if (destination) {
      const { droppableId, index } = destination;
      onMoveExercise({
        source: createLocation(source.droppableId, source.index),
        destination: createLocation(droppableId, index)
      });
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    changeExpandState(event.currentTarget.checked);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ActivityPlanStage toolbar={toolbar} loading={loading || !activityPlan}>
        <ExpandAllCheckbox
          onChange={handleOnChange}
          defaultChecked={expandState.expanded}
        />
        {activityPlan?.progressions.map((progression, index) => {
          const {
            data,
            order,
            uuid,
            activities,
            wasModifiedByAdmin,
            etpeVersion
          } = progression;
          const exercises = activities?.[0]?.data?.exercises ?? [];
          const userSubstitutions =
            activities?.[0]?.data?.userSubstitutions ?? [];
          const userSubstitutionsMap = createSubstitutionMap(userSubstitutions);

          return isProgressionDataChronic(data) ? (
            <EditableChronicLevel
              key={uuid}
              id={uuid}
              level={order + 1}
              current={currentProgression === order + 1}
              points={computeActualSessionPoints(progression)}
              targetPoints={data.etSessionPoints}
              estimatedTime={getEstimatedEtSessionTime(exercises)}
              numEtSessions={data.numEtSessions}
              wasModifiedByAdmin={Boolean(wasModifiedByAdmin)}
              etpeVersion={etpeVersion}
              expanded={expandState.overrides[order] ?? expandState.expanded}
              /**
               * Note: It's important to use `order` when overriding progression-level expand state
               *
               * Since Chronic User Plans only maintain a sparse set of custom progressions
               * laid over the corresponding Base Chronic Config, the first edit to a
               * progression leads to a brand new progression. Because of this, we
               * cannot override the expand/collapse state with the `progressionUuid`.
               * Instead, use `order`. Progressions in Chronic Configs cannot be moved so
               * this is guaranteed to be stable.
               */
              onExpandChange={(expanded: boolean) => override(order, expanded)}
              onAddExercise={() =>
                onAddExercises({ buttonLabel: 'Level', uuid, index })
              }
              canCopyExercises={doesProgressionHaveExercises(progression)}
              onCopyUniqueExercises={() =>
                onCopyUniqueExercises(progression, copyMessage(order + 1))
              }
              onCopyAllExercises={() =>
                onCopyAllExercises(progression, copyMessage(order + 1))
              }
            >
              {exercises.map((exercise, index) => {
                const { name, reps, holdTime, pointsPerRep, anatomicalName } =
                  exercise;
                const location = createLocation(uuid, exercise.order ?? 0);
                const id = `${progression.uuid}-${exercise.uuid}-${index}`;
                const key = `${id}-${reps}-${holdTime}`;
                const substitutionName = userSubstitutionsMap.get(
                  exercise.order ?? -1
                );
                const substitutionText = substitutionName
                  ? `Substituted for ${substitutionName}`
                  : undefined;
                return (
                  <EditableChronicExercise
                    key={key}
                    index={index}
                    id={id}
                    name={name}
                    anatomicalName={anatomicalName}
                    reps={reps}
                    thumbnailUrl={exercise.thumbnail?.url}
                    repType={exercise.repType}
                    pointsPerRep={pointsPerRep ?? DEFAULT_POINTS_PER_REP}
                    holdTime={holdTime ?? DEFAULT_HOLD_TIME}
                    substitutionText={substitutionText}
                    onRepsChange={reps => onEditExercise(location, { reps })}
                    onPointsPerRepChange={val =>
                      onEditExercise(location, { pointsPerRep: val })
                    }
                    onHoldTimeChange={holdTime =>
                      onEditExercise(location, { holdTime })
                    }
                    onEdit={() =>
                      openEditExerciseModal(location, {
                        name: exercise.name,
                        holdTime: exercise.holdTime ?? DEFAULT_HOLD_TIME,
                        pointsPerRep:
                          exercise.pointsPerRep ?? DEFAULT_POINTS_PER_REP,
                        reps: exercise.reps ?? 1,
                        switchLimbTime: exercise.switchLimbTime ?? null
                      })
                    }
                    onDelete={() => onDeleteExercise(location)}
                    onDuplicate={() => onDuplicateExercise(location)}
                    onInvalidPointsPerRep={onInvalidPointsPerRepExercise}
                    onInvalidReps={onInvalidRepsExercise}
                    onInvalidHold={onInvalidHoldExercise}
                  />
                );
              })}
            </EditableChronicLevel>
          ) : (
            <p>Not chronic</p>
          );
        })}
      </ActivityPlanStage>
      <ChronicEditExerciseModal
        open={modal !== null}
        name={modal?.name ?? ''}
        onCancel={closeEditExerciseModal}
        onSubmit={values => {
          if (modal) {
            onEditExercise(modal.location, values);
            closeEditExerciseModal();
          }
        }}
        initialValues={modal?.initialValues ?? null}
      />
    </DragDropContext>
  );
};
