import { TreeNode } from 'components/exercise-repo/FiltersDrawer';
import Fuse from 'fuse.js';
import { Exercise } from 'types/activityPlan';
import { TempExercise } from '../test-data/activityPlanData/exerciseData';
import { DEFAULT_HOLD_TIME } from './activityPlan';

export const mapFormValuesToExerciseObject = (
  values: string[],
  exercises: Exercise[]
): Exercise[] =>
  values.map((uuid: string) => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    let exercise = exercises.find(exercise => exercise.uuid === uuid)!;

    const divideholdByNum = (
      holdTime: number | null | undefined,
      numHoldMotions: number
    ): number => {
      return (holdTime ?? DEFAULT_HOLD_TIME * numHoldMotions) / numHoldMotions;
    };

    exercise = {
      ...exercise,
      holdTime: divideholdByNum(exercise.holdTime, exercise.numHoldMotions)
    };

    return exercise;
  });

export const filterExercisesBySearch = (
  searchTerm: string,
  exercises: Exercise[]
): Exercise[] => {
  if (searchTerm.length === 0 || searchTerm === ' ') {
    return exercises;
  } else {
    const fuse = new Fuse(exercises, {
      keys: ['name', 'anatomicalName', 'translatedName'],
      threshold: 0.4
    });
    const results = fuse.search(searchTerm);
    return results.map(exercise => exercise.item);
  }
};

//TODO: change TempExercise to Exercise when data, schema, and types are wired up

/**
 * Reformat mpath to match name for comparison with classifications that have a null mpath
 */
const changeMpathToName = (filter: string): string =>
  filter.split('/').slice(0, 1)[0].replace(/-/g, ' ');

/**
 * The classifications in the exercise seed data might include mpaths deeper than one level,
 * only use the first level in the front end UI (for now)
 * See https://hingehealth.atlassian.net/browse/LLAMA-2360
 */
const extractFirstLevelFromMpath = (
  exerciseClassifications: string[]
): string[] =>
  exerciseClassifications.map(exerciseClassification =>
    exerciseClassification.split('/').slice(0, 2).join('/')
  );

/**
 * Selected filters in state are a flat list.
 * Find only the selected filters in state that match a given classification.
 */
const findMatchingFilters = (
  selectedFilters: string[],
  classificationName: string
): string[] =>
  selectedFilters.filter(
    selectedFilter =>
      changeMpathToName(selectedFilter) === classificationName.toLowerCase()
  );

/**
 * Check if the list of filters found for a given classification
 * and the individual exercise's classification list have any common mpaths
 */
const haveCommonClassifications = (
  matchingFilters: string[],
  exerciseClassifications: string[]
): boolean => {
  /**
   * .some is used here to fulfill the OR relationship between filters within a given classification.
   * Example: If 'body-position/standing' and 'body-position/floor' are selected,
   * return any of the exercises that match either filter selection.
   */
  return matchingFilters.some(matchingFilter =>
    extractFirstLevelFromMpath(exerciseClassifications).includes(matchingFilter)
  );
};

/**
 * If there are no filters selected within a given classification, then we show the exercise.
 *    Example: If no filters within 'body-position' are selected,
 *             exercises with any 'body-position' classification are shown.
 * Otherwise, if filters are selected within the given classification,
 * check for exercise classifications that match any of the filters.
 */
const isShownByClassification = (
  selectedFilters: string[],
  classificationName: string,
  exerciseClassifications: string[]
): boolean => {
  const filtersMatchClassification = findMatchingFilters(
    selectedFilters,
    classificationName
  );

  return !filtersMatchClassification.length
    ? true
    : haveCommonClassifications(
        filtersMatchClassification,
        exerciseClassifications
      );
};

/**
 * If an exercise belongs to ALL classifications that include a selection, show the exercise.
 * Example: isShownByBodyArea && isShownByBodyPosition && isShownByExerciseType
 */
export const applyClassificationFiltersToExercises = (
  exercises: TempExercise[],
  selectedFilters: string[],
  classifications: TreeNode[]
): Exercise[] => {
  return exercises.filter(exercise => {
    const isShownByClassificationArray = classifications.map(classification => {
      return isShownByClassification(
        selectedFilters,
        classification.name,
        exercise.classifications
      );
    });

    return isShownByClassificationArray.every(Boolean);
  });
};
