import { useCallback, useEffect, useState } from 'react';
import { FlattenedListItem } from 'utils/libraryFiltering';

export type OrderDirection = 'asc' | 'desc';
interface SortItem<Item> {
  0: Item;
  1: number;
}

interface Sorted<
  Item extends Record<string, unknown>,
  OrderBy extends keyof Item
> {
  order: OrderDirection;
  orderBy: OrderBy;
  toggle: (property: OrderBy) => void;
  setItems: (items: Item[]) => void;
  items: Item[];
}

const descendingComparator = <
  Item extends FlattenedListItem<any>,
  OrderBy extends keyof Item
>(
  a: Item,
  b: Item,
  orderBy: OrderBy
): number => {
  const a$ = a[orderBy];
  const b$ = b[orderBy];
  const isAEmpty = a$ === null || a$ === undefined;
  const isBEmpty = b$ === null || b$ === undefined;

  if (isAEmpty && isBEmpty) return 0;
  else if (isAEmpty || b$ < a$) return -1;
  else if (isBEmpty || b$ > a$) return 1;
  else return 0;
};

const getComparator = <
  Item extends FlattenedListItem<any>,
  OrderBy extends keyof Item
>(
  order: OrderDirection,
  orderBy: OrderBy
): ((a: Item, b: Item) => number) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const sortList = <
  Item extends FlattenedListItem<any>,
  OrderBy extends keyof Item
>(
  flattenedList: Item[],
  order: OrderDirection,
  orderBy: OrderBy
): Item[] => {
  const comparator = getComparator<Item, OrderBy>(order, orderBy);
  const sortableList: SortItem<Item>[] = flattenedList.map((el, index) => [
    el,
    index
  ]);
  sortableList.sort((a: SortItem<Item>, b: SortItem<Item>): number => {
    const listKeyCompare = comparator(a[0], b[0]);
    if (listKeyCompare !== 0) return listKeyCompare;
    return a[1] - b[1];
  });
  return sortableList.map(el => el[0]);
};

export const useSorted = <
  OrderBy extends string,
  Item extends Record<string, unknown>
>({
  initialOrderBy
}: {
  initialOrderBy: OrderBy;
}): Sorted<Item, OrderBy> => {
  const [order, setOrder] = useState<OrderDirection>('asc');
  const [orderBy, setOrderBy] = useState<OrderBy>(initialOrderBy);
  const [items, setItems] = useState<Item[]>([]);
  const [sortedItems, setSortedItems] = useState<Item[]>([]);

  const toggle = useCallback(
    (property: OrderBy) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    },
    [order, orderBy]
  );

  useEffect(() => {
    setSortedItems(sortList(items, order, orderBy));
  }, [items, order, orderBy]);

  return {
    order,
    orderBy,
    toggle,
    setItems,
    items: sortedItems
  };
};
