import { useGetUser as useGetUserQuery } from 'apollo/queries/useGetUser.query';
import { useEffect, useState } from 'react';
import { GetUserQuery } from 'types';

export const useGetUser = (userId: string): GetUserQuery | undefined => {
  const [userLevelData, setUserLevelData] = useState<
    GetUserQuery | undefined
  >();

  const getUserPromise = useGetUserQuery(userId);
  useEffect(() => {
    (async () => {
      const { data } = await getUserPromise;
      setUserLevelData(data);
    })();
  }, [getUserPromise]);

  return userLevelData;
};
