import { makeVar } from '@apollo/client';
import { AcceptedRoles } from 'types';
import { setSentryScope } from 'utils/sentry';

export type ClientUserAuthSet = {
  accessToken: string;
  accessExpiry: number;
  refreshExpiry: number;
  roles: AcceptedRoles[];
  adminId: number;
};

export type UserAuthState = {
  accessToken: string | null;
  refreshTimeoutId: number | null;
  roles: AcceptedRoles[] | null;
  adminId: number | null;
};

export const initialUserAuthState = {
  accessToken: null,
  refreshTimeoutId: null,
  roles: null,
  adminId: null
};

export const userAuthState = makeVar<UserAuthState>(initialUserAuthState);

export const setUserAuthState = (
  newState: Partial<UserAuthState>
): UserAuthState => {
  setSentryScope(newState?.adminId ?? userAuthState().adminId);

  return userAuthState({
    ...userAuthState(),
    ...newState
  });
};

export const resetUserAuthState = (): UserAuthState =>
  userAuthState({
    ...initialUserAuthState
  });
