import { useSemaphore } from 'hooks/useSemaphore';
import {
  useActivityPlan,
  useActivityPlanApi
} from 'providers/ActivityPlanProvider';
import { useEffect } from 'react';

export const useEnsureBetas = async (
  canCreateBetas: boolean
): Promise<void> => {
  const { betaConfigs, loading } = useActivityPlan();
  const { createBetas } = useActivityPlanApi();
  const { increment, decrement } = useSemaphore({ max: 1 });

  useEffect(() => {
    if (canCreateBetas && !loading && betaConfigs?.length === 0) {
      (async () => {
        if (increment()) {
          await createBetas();
          decrement();
        }
      })();
    }
  }, [betaConfigs, canCreateBetas, loading, increment, decrement, createBetas]);
};
