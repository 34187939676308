import { Tooltip, Typography } from '@material-ui/core';
import ExerciseThumbnail from 'components/activity-plan/ExerciseThumbnail';
import EllipsisMenu, { MenuItem } from 'components/EllipsisMenu';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { removeWhitespace, truncateString } from 'utils/helpers';
import { Center, Left, Right, Row } from './threeColumnFlexRow';
export interface ExerciseProps {
  name: string;
  anatomicalName?: string | null;
  id: number | string;
  thumbnailUrl?: string;
  children: React.ReactNode;
  menuItems?: MenuItem[];
  substitutionText?: string;
}

const ExerciseRow = styled(Row)`
  padding: ${({ theme }) => theme.spacing(3, 2, 3, 4)};
  min-height: ${({ theme }) => theme.spacing(11)};
`;

const NameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 172px;
`;

const CenterFlexEnd = styled(props => <Center {...props} />)`
  ${({ hasMenu, theme }) => `
  align-items: center;
  gap: ${theme.spacing(4)};
  justify-content: flex-end;
  padding-left: ${theme.spacing(2.5)};
  padding-right: ${hasMenu ? theme.spacing(0.5) : theme.spacing(12)};
`}
`;

const NoWrapTypography = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

/**
 * Base layout for an exercise
 */
export const Exercise = ({
  name,
  anatomicalName,
  id,
  thumbnailUrl,
  children,
  menuItems,
  substitutionText
}: ExerciseProps): JSX.Element => {
  const { t } = useTranslation();
  const hasMenu = menuItems && menuItems.length > 0;
  
  //As we are initializing the i18next translation schema with this structure { 'some-string': { name: 'Some String' } }
  //so .name suffix is required to find out the actual string inside i18next translation object, else if .name suffix is not there in a particular data, t(name) will return an object.

  const nameWrapperTitle = typeof t(name) === 'object'? t(name.concat('.name')) : t(name);
  
  return (
    <ExerciseRow data-testid="exercise">
      <Left>
        {thumbnailUrl ? <ExerciseThumbnail src={thumbnailUrl} /> : null}
        <NameWrapper>
          <Typography>{nameWrapperTitle}</Typography>
          {anatomicalName ? (
            <Tooltip title={anatomicalName} placement="bottom-end" arrow>
              <NoWrapTypography variant="caption" color="textSecondary">
                {truncateString(anatomicalName, 0, 30)}
              </NoWrapTypography>
            </Tooltip>
          ) : null}
          {substitutionText ? (
            <Tooltip title={substitutionText} placement="bottom" arrow>
              <NoWrapTypography variant="caption" color="textSecondary">
                {truncateString(substitutionText, 0, 30)}
              </NoWrapTypography>
            </Tooltip>
          ) : null}
        </NameWrapper>
      </Left>
      <CenterFlexEnd hasMenu={hasMenu}>{children}</CenterFlexEnd>
      <Right>
        {hasMenu ? (
          <EllipsisMenu
            ariaLabel={`${name} menu`}
            items={menuItems ?? []}
            popupId={`${removeWhitespace(name)}${id}Menu`}
            testId={`${removeWhitespace(name)}${id}MenuButton`}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          />
        ) : null}
      </Right>
    </ExerciseRow>
  );
};
