import { Button } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import { ActivityPlanToolbar } from 'components/activity-plan/ActivityPlanToolbar';
import { PerisurgicalHeader } from 'components/activity-plan/perisurgical/PerisurgicalHeader';
import { CopyToClipboardButtonIcon } from 'components/CopyToClipboardButtonIcon';
import { DownloadPDFButtonIcon } from 'components/DownloadPDFButtonIcon';
import { ActivityPlanPageLayout } from 'components/layout/ActivityPlanPageLayout';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { PerisurgicalTemplatePDFDocument } from 'components/PDFDocuments/PerisurgicalTemplatePDFDocument';
import { PerisurgicalActivityPlan } from 'connected/activity-plan/PerisurgicalActivityPlan';
import { useCopyPlanToClipboard } from 'hooks/useCopyPlanToClipboard';
import {
  ActivityPlanProvider,
  useActivityPlan
} from 'providers/ActivityPlanProvider';
import { DraftActivityPlanProvider } from 'providers/DraftActivityPlanProvider';
import React from 'react';
import styled from 'styled-components';

const EditButton = styled(Button)`
  height: 36px;
`;

const PerisurgicalTemplateView = ({
  onEdit
}: {
  onEdit: () => void;
}): JSX.Element => {
  const { loading, activityPlan } = useActivityPlan();
  const { canCopyToClipboard, handleCopyExercisesToClipboard } =
    useCopyPlanToClipboard(activityPlan?.progressions);

  if (loading || !activityPlan) {
    return <LoadingSpinner />;
  }

  return (
    <ActivityPlanPageLayout
      stickyToolbar={
        <ActivityPlanToolbar
          actionButtons={
            <EditButton
              variant="contained"
              color="primary"
              data-testid="editTemplateButton"
              onClick={onEdit}
              disabled={
                !!(
                  activityPlan.draft &&
                  !activityPlan.draft.isOwnedByCurrentAdmin
                )
              }
            >
              EDIT
            </EditButton>
          }
        >
          <DownloadPDFButtonIcon
            testId="perisurgicalViewDownloadIcon"
            title="Download PDF"
            placement="bottom-start"
            document={
              <PerisurgicalTemplatePDFDocument activityPlan={activityPlan} />
            }
            fileName={activityPlan?.name ?? 'Perisurgical template'}
          />
          <CopyToClipboardButtonIcon
            disabled={!canCopyToClipboard}
            onClick={handleCopyExercisesToClipboard}
            testId="perisurgicalViewCopyIcon"
            title={
              canCopyToClipboard
                ? 'Copy all activity plan exercises'
                : 'Copy all exercises disabled'
            }
            placement="bottom-start"
          />
        </ActivityPlanToolbar>
      }
      header={<PerisurgicalHeader viewOnly name={activityPlan.name ?? ''} />}
      testId="perisurgicalTemplateViewPage"
    >
      <PerisurgicalActivityPlan />
    </ActivityPlanPageLayout>
  );
};

interface Props extends RouteComponentProps {
  uuid?: string;
}

const ConnectedPerisurgicalTemplateView = ({
  uuid,
  navigate
}: Props): JSX.Element => {
  return (
    <ActivityPlanProvider
      uuid={uuid ?? ''}
      onError={err => {
        throw new Error(`Unable to view perisurgical template (${err})`);
      }}
    >
      <DraftActivityPlanProvider>
        <PerisurgicalTemplateView onEdit={() => navigate?.('edit')} />
      </DraftActivityPlanProvider>
    </ActivityPlanProvider>
  );
};

export { ConnectedPerisurgicalTemplateView as PerisurgicalTemplateView };
