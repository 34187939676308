type ClickEvent<Element extends HTMLElement> = React.MouseEvent<
  Element,
  MouseEvent
>;

export interface ShiftableClick {
  onClick: () => void;
  onShiftClick?: () => void;
}

/**
 * Create a Click handler with a Shift modifier. When the `onShiftClick` handler
 * is defined and the Shift key is being held during the click, the `onShiftClick`
 * handler will be run. Otherwise, the `onClick` handler is run.
 */
export const useShiftableClick =
  ({ onClick, onShiftClick }: ShiftableClick) =>
  async <E extends HTMLElement>(event: ClickEvent<E>): Promise<void> =>
    event.shiftKey && onShiftClick ? onShiftClick() : onClick();
