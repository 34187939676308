import { CircularProgress, CircularProgressProps } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

interface LoadingSpinnerProps extends CircularProgressProps {
  minHeight?: string;
}

const LoadingWrapper = styled.div<LoadingSpinnerProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: ${({ minHeight }) => minHeight};
`;

export const LoadingSpinner = ({
  minHeight = '100vh',
  ...rest
}: LoadingSpinnerProps): JSX.Element => (
  <LoadingWrapper minHeight={minHeight}>
    <CircularProgress data-testid="loading" aria-label="loading" {...rest} />
  </LoadingWrapper>
);
