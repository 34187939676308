import { Typography } from '@material-ui/core';
import AvTimerIcon from '@material-ui/icons/AvTimer';
import { useShiftableClick } from 'hooks/useShiftableClick';
import React from 'react';
import styled from 'styled-components';
import { ProgressionTypes } from '../../../utils/activityPlan';
import { ProgressionHeader } from '../ProgressionHeader';
import { TargetPoints } from '../TargetPoints';

export interface ChronicLevelHeaderProps {
  id: string;
  level: number;
  current?: boolean | null;
  points: number;
  targetPoints: number;
  estimatedTime: number;
  numEtSessions: number;
  wasModifiedByAdmin?: boolean;
  etpeVersion?: string | null;
  expanded: boolean;
  canCopyExercises: boolean;
  onToggleCollapse: () => void;
  onCopyUniqueExercises: () => void;
  onCopyAllExercises: () => void;
}

const ChildWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const ChildTypography = styled(Typography)`
  margin-left: 0.3rem;
`;

export const ChronicLevelHeader = ({
  id,
  level,
  current,
  points,
  targetPoints,
  estimatedTime,
  numEtSessions,
  wasModifiedByAdmin,
  etpeVersion,
  expanded,
  canCopyExercises,
  onToggleCollapse,
  onCopyUniqueExercises,
  onCopyAllExercises
}: ChronicLevelHeaderProps): JSX.Element => {
  return (
    <ProgressionHeader
      id={id}
      title={`${ProgressionTypes.chronic} ${level}`}
      current={current}
      expanded={expanded}
      onToggleCollapse={onToggleCollapse}
      numEtSessions={numEtSessions}
      wasModifiedByAdmin={wasModifiedByAdmin}
      etpeVersion={etpeVersion}
      height={56}
      centerProps={{ gap: 6, justifyContent: 'flex-end', paddingRight: 6 }}
      menuItems={[
        {
          text: 'Copy exercise list',
          disabled: !canCopyExercises,
          onClick: useShiftableClick({
            onClick: onCopyAllExercises,
            onShiftClick: onCopyUniqueExercises
          })
        }
      ]}
    >
      <TargetPoints actual={points} target={targetPoints} />

      <ChildWrapper>
        <AvTimerIcon />
        <ChildTypography variant="caption">
          {`${estimatedTime} minutes`}
        </ChildTypography>
      </ChildWrapper>
    </ProgressionHeader>
  );
};
