import { ApolloError, FetchResult, useMutation } from '@apollo/client';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { useCallback } from 'react';
import {
  ActivityPlanBetasCommitInput,
  ActivityPlanBetasDraftsCreateInput,
  BatchCommitBetaDraftsDocument,
  BatchCommitBetaDraftsMutation,
  BatchCommitBetaDraftsMutationVariables,
  BatchDeleteBetaDraftsDocument,
  BatchDeleteBetaDraftsMutation,
  BatchDeleteBetaDraftsMutationVariables
} from 'types';
import { ConfigSummary } from 'types/activityPlan';
import { useDefaultErrorHandler } from './useDefaultErrorHandler';

interface UseMutateBetas {
  commitBetasInProcess: boolean;
  deleteBetaDraftsInProcess: boolean;
  commitDrafts: (
    input: ActivityPlanBetasCommitInput
  ) => Promise<FetchResult<BatchCommitBetaDraftsMutation> | undefined>;
  deleteDrafts: (
    input: ActivityPlanBetasDraftsCreateInput
  ) => Promise<FetchResult<BatchDeleteBetaDraftsMutation> | undefined>;
}

export const useMutateBetas = (
  betas?: ConfigSummary[] | null
): UseMutateBetas => {
  const { defaultErrorHandler } = useDefaultErrorHandler();

  const [batchCommit, { loading: commitBetasInProcess }] = useMutation<
    BatchCommitBetaDraftsMutation,
    BatchCommitBetaDraftsMutationVariables
  >(BatchCommitBetaDraftsDocument, {
    onError: (error: ApolloError) => {
      defaultErrorHandler(error);
    }
  });

  const [batchDeleteDrafts, { loading: deleteBetaDraftsInProcess }] =
    useMutation<
      BatchDeleteBetaDraftsMutation,
      BatchDeleteBetaDraftsMutationVariables
    >(BatchDeleteBetaDraftsDocument, {
      onError: (error: ApolloError) => {
        defaultErrorHandler(error);
        snackbarState({
          type: 'generic',
          severity: 'error',
          title: 'Unable to discard drafts for tests',
          message: 'Please try again'
        });
      }
    });

  const commitDrafts = useCallback(
    async (input: ActivityPlanBetasCommitInput) =>
      batchCommit({
        variables: { input },
        update(cache, { data }) {
          if (data && betas) {
            betas.forEach(beta => {
              cache.evict({
                id: cache.identify({
                  __typename: 'ActivityPlanDraft',
                  uuid: beta.draft?.uuid
                })
              });
            });
          }
        }
      }),
    [batchCommit, betas]
  );

  const deleteDrafts = useCallback(
    async (input: ActivityPlanBetasDraftsCreateInput) =>
      batchDeleteDrafts({
        variables: { input },
        update(cache, { data }) {
          if (data && betas) {
            betas.forEach(beta => {
              cache.evict({
                id: cache.identify({
                  __typename: 'ActivityPlanDraft',
                  uuid: beta.draft?.uuid
                })
              });
            });
          }
        }
      }),
    [batchDeleteDrafts, betas]
  );

  return {
    commitDrafts,
    deleteDrafts,
    commitBetasInProcess,
    deleteBetaDraftsInProcess
  };
};
