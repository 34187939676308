import { Paper, Tab, Tabs as MuiTabs } from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

const TabContainer = styled(Paper)`
  border-radius: unset;
  display: flex;
  width: 100%;
`;

const Tabs = styled(MuiTabs)`
  width: 100%;
`;

export interface ChronicActivityPlanTabsProps {
  onLow: () => void;
  onMedium: () => void;
  onHigh: () => void;
}

export const ChronicActivityPlanTabs = ({
  onLow,
  onMedium,
  onHigh
}: ChronicActivityPlanTabsProps): JSX.Element => {
  const [selectedTab, setSelectedTab] = useState(0);

  const onChange = (event: ChangeEvent<any>, newValue: number): void => {
    setSelectedTab(newValue);
    switch (newValue) {
      case 0:
        onLow();
        break;
      case 1:
        onMedium();
        break;
      case 2:
        onHigh();
        break;
    }
  };

  return (
    <TabContainer>
      <Tabs
        value={selectedTab}
        onChange={onChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label="LOW" />
        <Tab label="MEDIUM" />
        <Tab label="HIGH" />
      </Tabs>
    </TabContainer>
  );
};
