import { TableCell } from '@material-ui/core';
import { HeadCell } from 'components/SortTableHead';
import styled from 'styled-components';

const TableBodyCell = styled(TableCell)`
  padding: ${({ theme }) => theme.spacing(0, 4)};
`;

const TableCell15 = styled(TableBodyCell)`
  width: 15%;
`;

const TableCell10 = styled(TableBodyCell)`
  width: 10%;
`;

export const userActivityPlansTableHeadCells: HeadCell[] = [
  {
    id: 'program',
    cell: TableCell10,
    label: 'Program'
  },
  {
    id: 'indication',
    cell: TableCell15,
    label: 'Indication'
  },
  {
    id: 'name',
    cell: TableCell15,
    label: 'Name'
  },
  {
    id: 'status',
    cell: TableCell15,
    label: 'Status'
  },
  {
    id: 'actions',
    cell: TableCell15,
    label: 'Actions'
  }
];
