import {
  PDFActivityPlanInfoItem,
  PDFActivityPlanInfoWrapper,
  PDFDocumentLetterhead,
  PDFProgression
} from 'components/PDFDocuments/PDFDocument';
import { format } from 'date-fns';
import React from 'react';
import { ActivityPlan, Progression } from 'types/activityPlan';
import { DraftActivityPlan, DraftProgression } from 'types/draftActivityPlan';
import { ProgressionTypes } from 'utils/activityPlan';
import { extractProgramInfoFromUri } from 'utils/extractProgramInfoFromUri';

interface Props {
  activityPlan: ActivityPlan | DraftActivityPlan | undefined;
  userId: string;
  startedAt?: Date | null;
}

export const DefaultUserPDFDocument = ({
  activityPlan,
  userId,
  startedAt
}: Props): JSX.Element | null => {
  if (!activityPlan) {
    return null;
  }

  const { uri, progressions } = activityPlan;
  const { program, indication } = extractProgramInfoFromUri(uri);
  const pdfProgressions: JSX.Element[] = [];
  progressions.forEach((progression: Progression | DraftProgression) => {
    pdfProgressions.push(
      <PDFProgression
        progression={progression}
        type={ProgressionTypes[program]}
        key={progression.uuid}
      />
    );
  });

  return (
    <PDFDocumentLetterhead title="Exercise Therapy Plan">
      <PDFActivityPlanInfoWrapper>
        <PDFActivityPlanInfoItem text={`User ID: ${userId}`} />
        <PDFActivityPlanInfoItem text={`Program: ${program}`} />
        <PDFActivityPlanInfoItem text={`Indication: ${indication}`} />
        <PDFActivityPlanInfoItem
          text={`Plan Started: ${
            startedAt ? format(startedAt, 'MM/dd/yyy') : 'Not started'
          }`}
        />
      </PDFActivityPlanInfoWrapper>
      {pdfProgressions}
    </PDFDocumentLetterhead>
  );
};
