import { Paper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

/** Base layout for a flexible row component */
export const Row = styled(Paper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

/** Left child in a flexible row component */
export const Left = styled.div`
  display: flex;
  flex: 0 0 auto;
  align-items: center;
`;

type DivProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

export interface CenterProps extends DivProps {
  justifyContent?: 'flex-start' | 'flex-end' | 'center';
  /**
   * Add a gap between elements of 0, 8, 16, 24, and 32 pixels, respectively
   */
  gap?: 0 | 2 | 4 | 6 | 8;
  /** Add padding between center elements and menu button of 0, 8, 16, and 24 pixels respectively */
  paddingRight?: 0 | 2 | 4 | 6;
}

/** Center child in a flexible row component */
export const Center = styled.div<CenterProps>`
  display: inline-flex;
  flex: 1 1 auto;
  justify-content: ${({ justifyContent }) => justifyContent ?? 'center'};
  gap: ${({ gap, theme }) => theme.spacing(gap ?? 3)};
  padding-right: ${({ paddingRight, theme }) =>
    paddingRight ? theme.spacing(paddingRight) : 0};
`;

/** Right child in a flexible row component */
export const Right = styled.div`
  flex: 0 0 auto;
`;
