import {
  ApolloError,
  PureQueryOptions,
  useLazyQuery,
  useMutation
} from '@apollo/client';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { safeUseContext } from 'hooks/safeUseContext';
import { useChangeDetector } from 'hooks/useChangeDetector';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import { refetchLibraries } from 'hooks/useMutateDraft';
import React, { useEffect, useMemo, useState } from 'react';
import {
  BatchCreateBetaDraftsDocument,
  BatchCreateBetaDraftsMutation,
  BatchCreateBetaDraftsMutationVariables,
  BatchCreateBetasDocument,
  BatchCreateBetasMutation,
  BatchCreateBetasMutationVariables,
  BatchDeleteBetasDocument,
  BatchDeleteBetasMutation,
  BatchDeleteBetasMutationVariables,
  BatchPromoteBetasDocument,
  BatchPromoteBetasMutation,
  BatchPromoteBetasMutationVariables,
  CreateDraftDocument,
  CreateDraftMutation,
  CreateDraftMutationVariables,
  GetActivityPlanByUserDocument,
  GetActivityPlanByUserQuery,
  GetActivityPlanByUserQueryVariables,
  GetActivityPlanDocument,
  GetActivityPlanQuery,
  GetActivityPlanQueryVariables,
  GetAllChronicConfigsDocument,
  GetAllChronicConfigsQuery,
  GetAllChronicConfigsQueryVariables,
  GetConfigsSummaryDocument,
  GetConfigsSummaryQuery,
  GetConfigsSummaryQueryVariables,
  GetUserDocument,
  GetUserQuery,
  GetUserQueryVariables
} from 'types';
import { ActivityPlan, ConfigSummary } from 'types/activityPlan';
import { findChronicStreamUuid } from 'utils/activityPlan';
import { Programs } from 'utils/programConstants';

type ProgramAndIndicationInput = {
  program: Programs;
  indication: string;
};

interface ActivityPlanContextType {
  loading: boolean;
  activityPlan?: ActivityPlan;
  diffActivityPlan?: ActivityPlan;
  betaConfigs?: ConfigSummary[];
  chronicConfigs?: ConfigSummary[];
  diffConfigs?: ConfigSummary[];
  didCreateBetas: boolean;
  didCreateBetaDrafts: boolean;
}

const ActivityPlanContext = React.createContext<
  ActivityPlanContextType | undefined
>(undefined);

export const useActivityPlan = safeUseContext(
  ActivityPlanContext,
  'ActivityPlanContext'
);

interface ActivityPlanApiContextType {
  createDraft: () => Promise<boolean>;
  createBetas: () => Promise<boolean>;
  createBetaDrafts: () => Promise<boolean>;
  deleteBetas: () => Promise<boolean>;
  promoteBetas: () => Promise<boolean>;
  setActivityPlan: (activityPlanUuid: string, userId?: string) => void;
  setDiffActivityPlan: (activityPlanUuid: string) => void;
}

const ActivityPlanApiContext = React.createContext<
  ActivityPlanApiContextType | undefined
>(undefined);

export const useActivityPlanApi = safeUseContext(
  ActivityPlanApiContext,
  'ActivityPlanApiContext'
);

interface ActivityPlanProps {
  children?: React.ReactNode;
  uuid: string | null;
  userId?: string;
  configsInput?: ProgramAndIndicationInput;
  betas?: boolean;
  onError?: (error: ApolloError) => void;
}

export const ActivityPlanProvider = ({
  children,
  uuid: initialUuid,
  userId: initialUserId,
  configsInput,
  betas,
  onError
}: ActivityPlanProps): JSX.Element => {
  const [uuid, setActivityPlanUuid] = useState<string | null>(initialUuid);
  const [diffUuid, setDiffUuid] = useState<string | null>(null);
  const [userId, setUserId] = useState<string | undefined>(initialUserId);
  const [didCreateBetas, setDidCreateBetas] = useState<boolean>(false);
  const [didCreateBetaDrafts, setDidCreateBetaDrafts] =
    useState<boolean>(false);

  const didUuidChange = useChangeDetector(initialUuid);
  useEffect(() => {
    if (didUuidChange(initialUuid)) {
      setActivityPlanUuid(initialUuid);
    }
  }, [didUuidChange, initialUuid]);

  const didUserIdChange = useChangeDetector(initialUserId);
  useEffect(() => {
    if (didUserIdChange(initialUserId)) {
      setUserId(initialUserId);
    }
  }, [didUserIdChange, initialUserId]);

  const { defaultErrorHandler } = useDefaultErrorHandler();
  const [getUser, { data: userData }] = useLazyQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GetUserDocument, {
    onError: defaultErrorHandler
  });

  const [
    getActivityPlanByUser,
    { data: userPlanData, loading: userPlanLoading }
  ] = useLazyQuery<
    GetActivityPlanByUserQuery,
    GetActivityPlanByUserQueryVariables
  >(GetActivityPlanByUserDocument, {
    onError: (error: ApolloError): void => {
      defaultErrorHandler(error);
      onError?.(error);
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: `Unable to find activity plan with uuid ${uuid} for user ${userId}`,
        message: 'Try again'
      });
    }
  });

  const [getActivityPlan, { data: planData, loading: planLoading }] =
    useLazyQuery<GetActivityPlanQuery, GetActivityPlanQueryVariables>(
      GetActivityPlanDocument,
      {
        onError: (error: ApolloError): void => {
          defaultErrorHandler(error);
          onError?.(error);
          snackbarState({
            type: 'generic',
            severity: 'error',
            title: `Unable to find activity plan with uuid ${uuid}`,
            message: 'Try again'
          });
        }
      }
    );

  const [
    getDiffActivityPlan,
    { data: diffPlanData, loading: diffPlanLoading }
  ] = useLazyQuery<GetActivityPlanQuery, GetActivityPlanQueryVariables>(
    GetActivityPlanDocument,
    {
      onError: (error: ApolloError): void => {
        defaultErrorHandler(error);
        onError?.(error);
        snackbarState({
          type: 'generic',
          severity: 'error',
          title: `Unable to find alpha activity plan for diff with uuid ${uuid}`,
          message: 'Try again'
        });
      }
    }
  );

  const [
    getChronicConfigsByIndication,
    {
      loading: chronicConfigsLoading,
      data: chronicConfigsData,
      called: chronicConfigsCalled
    }
  ] = useLazyQuery<
    GetAllChronicConfigsQuery,
    GetAllChronicConfigsQueryVariables
  >(GetAllChronicConfigsDocument, {
    onError: (error: ApolloError): void => {
      defaultErrorHandler(error);
      onError?.(error);
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: `Unable to find chronic configs for indication ${configsInput?.indication}`,
        message: 'Try again'
      });
    }
  });

  const [getConfigsSummary, { data: configsData, loading: configsLoading }] =
    useLazyQuery<GetConfigsSummaryQuery, GetConfigsSummaryQueryVariables>(
      GetConfigsSummaryDocument,
      {
        onError: (error: ApolloError): void => {
          defaultErrorHandler(error);
          onError?.(error);
        }
      }
    );

  const [
    batchCreateBetas,
    { data: createBetasData, loading: isCreatingBetas }
  ] = useMutation<BatchCreateBetasMutation, BatchCreateBetasMutationVariables>(
    BatchCreateBetasDocument,
    {
      onError: (error: ApolloError): void => {
        defaultErrorHandler(error);
        onError?.(error);
        snackbarState({
          type: 'generic',
          severity: 'error',
          title: `Unable to create tests for ${configsInput?.program} ${configsInput?.indication}`,
          message: 'Try again'
        });
      }
    }
  );

  const chronicConfigs = useMemo(
    () =>
      chronicConfigsData?.activityPlansGetAll.activityPlans.map(config => ({
        uuid: config.uuid,
        uri: config.uri
      })),
    [chronicConfigsData]
  );

  const betaConfigs = useMemo(() => {
    const betasArray: ActivityPlan[] | ConfigSummary[] | undefined =
      createBetasData
        ? createBetasData.activityPlanBetasBatchCreate.betas
        : configsData?.activityPlansGetAll.activityPlans;

    return betasArray
      ?.filter((config: ConfigSummary) => config.uri.includes(':beta'))
      .map((beta: ConfigSummary) => ({
        uuid: beta.uuid,
        uri: beta.uri,
        draft: beta?.draft
      }));
  }, [configsData, createBetasData]);

  const diffConfigs = useMemo(
    () =>
      configsData?.activityPlansGetAll.activityPlans
        .filter((config: ConfigSummary) => !config.uri.includes(':beta'))
        .map((alpha: ConfigSummary) => ({
          uuid: alpha.uuid,
          uri: alpha.uri
        })),
    [configsData]
  );

  const activityPlan = useMemo(() => {
    if (!configsInput) {
      return userPlanData
        ? userPlanData.activityPlanGetByUser
        : planData?.activityPlanGet;
    } else {
      return planData?.activityPlanGet;
    }
  }, [userPlanData, planData, configsInput]);

  const diffActivityPlan = useMemo(
    () => diffPlanData?.activityPlanGet,
    [diffPlanData]
  );

  useEffect(() => {
    if (uuid) {
      if (userId && userData) {
        getActivityPlanByUser({
          variables: {
            input: {
              userUuid: userData?.userGet.uuid,
              activityPlanUuid: uuid
            }
          }
        });
      } else if (userId) {
        getUser({ variables: { userId: parseInt(userId) } });
      } else {
        getActivityPlan({ variables: { uuid } });
      }
    } else if (betas && configsInput) {
      // Get Alpha and Beta uuids
      getConfigsSummary({
        variables: {
          activityPlansGetInput: {
            count: 6,
            page: 0,
            ...configsInput
          }
        }
      });
    } else if (configsInput && !chronicConfigsCalled) {
      getConfigsSummary({
        variables: {
          activityPlansGetInput: {
            count: 3,
            page: 0,
            betas: true,
            ...configsInput
          }
        }
      });
      getChronicConfigsByIndication({
        variables: {
          activityPlansGetInput: {
            count: 3,
            page: 0,
            alphas: true,
            ...configsInput
          }
        }
      });
    }
  }, [
    uuid,
    betas,
    userId,
    getUser,
    userData,
    configsInput,
    getConfigsSummary,
    getActivityPlan,
    getActivityPlanByUser,
    getChronicConfigsByIndication,
    chronicConfigsCalled,
    betaConfigs,
    chronicConfigs
  ]);

  useEffect(() => {
    if (diffUuid) {
      getDiffActivityPlan({ variables: { uuid: diffUuid } });
    }
  }, [diffUuid, getDiffActivityPlan]);

  useEffect(() => {
    if (chronicConfigs?.length === 3 && !uuid) {
      const lowStreamUuid = findChronicStreamUuid('low', chronicConfigs);
      if (lowStreamUuid) {
        getActivityPlan({ variables: { uuid: lowStreamUuid } });
      }
    }
  }, [chronicConfigs, getActivityPlan, configsInput, uuid]);

  useEffect(() => {
    if (betas && configsInput && betaConfigs?.length === 3 && !uuid) {
      const lowStreamUuid = findChronicStreamUuid('low', betaConfigs);
      if (lowStreamUuid) {
        getActivityPlan({ variables: { uuid: lowStreamUuid } });
      }
    }
  }, [betas, betaConfigs, getActivityPlan, configsInput, uuid]);

  useEffect(() => {
    if (betas && configsInput && diffConfigs?.length === 3 && !diffUuid) {
      const lowStreamUuid = findChronicStreamUuid('low', diffConfigs);
      if (lowStreamUuid) {
        getDiffActivityPlan({ variables: { uuid: lowStreamUuid } });
      }
    }
  }, [betas, diffConfigs, getDiffActivityPlan, configsInput, diffUuid]);

  const [createDraft, { loading: isCreatingDraft }] = useMutation<
    CreateDraftMutation,
    CreateDraftMutationVariables
  >(CreateDraftDocument, {
    onError: (error: ApolloError): void => {
      defaultErrorHandler(error);
      onError?.(error);
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: `Unable to create draft for activity plan ${uuid}`,
        message: 'Try again'
      });
    }
  });

  const [batchDeleteBetas] = useMutation<
    BatchDeleteBetasMutation,
    BatchDeleteBetasMutationVariables
  >(BatchDeleteBetasDocument, {
    onError: (error: ApolloError): void => {
      defaultErrorHandler(error);
      onError?.(error);
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: `Unable to delete tests for ${configsInput?.program} ${configsInput?.indication}`,
        message: 'Try again'
      });
    }
  });

  const [batchPromoteBetas] = useMutation<
    BatchPromoteBetasMutation,
    BatchPromoteBetasMutationVariables
  >(BatchPromoteBetasDocument, {
    onError: (error: ApolloError): void => {
      defaultErrorHandler(error);
      onError?.(error);
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: `Unable to promote tests for ${configsInput?.program} ${configsInput?.indication}`,
        message: 'Try again'
      });
    },
    refetchQueries: refetchLibraries()
  });

  const [batchCreateBetaDrafts, { loading: isCreatingBetaDrafts }] =
    useMutation<
      BatchCreateBetaDraftsMutation,
      BatchCreateBetaDraftsMutationVariables
    >(BatchCreateBetaDraftsDocument, {
      onError: (error: ApolloError): void => {
        defaultErrorHandler(error);
        onError?.(error);
        snackbarState({
          type: 'generic',
          severity: 'error',
          title: `Unable to create drafts for tests`,
          message: 'Try again'
        });
      },
      refetchQueries: uuid ? [refetchActivityPlan(uuid)] : []
    });

  const value = useMemo(
    () => ({
      activityPlan,
      diffActivityPlan,
      chronicConfigs,
      betaConfigs,
      diffConfigs,
      loading:
        userPlanLoading ||
        planLoading ||
        diffPlanLoading ||
        chronicConfigsLoading ||
        isCreatingDraft ||
        isCreatingBetas ||
        isCreatingBetaDrafts ||
        configsLoading,
      didCreateBetas,
      didCreateBetaDrafts
    }),
    [
      activityPlan,
      diffActivityPlan,
      chronicConfigs,
      betaConfigs,
      diffConfigs,
      chronicConfigsLoading,
      userPlanLoading,
      configsLoading,
      planLoading,
      diffPlanLoading,
      isCreatingDraft,
      isCreatingBetas,
      isCreatingBetaDrafts,
      didCreateBetas,
      didCreateBetaDrafts
    ]
  );

  const api = useMemo(
    () => ({
      setActivityPlan: (activityPlanUuid: string, userId?: string) => {
        setActivityPlanUuid(activityPlanUuid);
        setUserId(userId);
      },
      setDiffActivityPlan: (activityPlanUuid: string) => {
        setDiffUuid(activityPlanUuid);
      },
      createDraft: async () => {
        if (activityPlan) {
          if (!activityPlan.draft) {
            const result = await createDraft({
              variables: {
                input: {
                  activityPlanUuid: activityPlan.uuid,
                  ...(activityPlan.userUuid
                    ? { userUuid: activityPlan.userUuid }
                    : null)
                }
              },
              update(cache, { data }) {
                cache.modify({
                  id: cache.identify(activityPlan),
                  fields: {
                    draft: () => data?.activityPlanDraftCreate?.draft ?? null
                  }
                });
              },
              refetchQueries: [
                refetchActivityPlan(activityPlan.uuid, activityPlan.userUuid)
              ]
            });

            // useMutation functions may return undefined or result.errors when an error occurs
            if (result && !result.errors) {
              return true;
            } else {
              return false;
            }
          }
        }
        return false;
      },
      createBetas: async () => {
        if (betaConfigs?.length === 3) {
          return false;
        }

        if (configsInput) {
          const result = await batchCreateBetas({
            variables: { input: configsInput },
            refetchQueries: [
              {
                query: GetConfigsSummaryDocument,
                variables: {
                  activityPlansGetInput: {
                    count: 3,
                    page: 0,
                    betas: true,
                    ...configsInput
                  }
                }
              },
              {
                query: GetAllChronicConfigsDocument,
                variables: {
                  activityPlansGetInput: {
                    program: Programs.CHRONIC,
                    page: 0,
                    count: 250
                  }
                }
              }
            ]
          });

          // useMutation functions may return undefined or result.errors when an error occurs
          if (result && !result.errors) {
            setDidCreateBetas(true);
            return true;
          } else {
            setDidCreateBetas(false);
            return false;
          }
        }
        return false;
      },
      createBetaDrafts: async () => {
        if (betaConfigs && configsInput) {
          const result = await batchCreateBetaDrafts({
            variables: { input: configsInput },
            refetchQueries: [
              {
                query: GetConfigsSummaryDocument,
                variables: {
                  activityPlansGetInput: {
                    count: 3,
                    page: 0,
                    betas,
                    ...configsInput
                  }
                }
              }
            ]
          });

          // useMutation functions may return undefined or result.errors when an error occurs
          if (result && !result.errors) {
            setDidCreateBetaDrafts(true);
            return true;
          } else {
            setDidCreateBetaDrafts(false);
            return false;
          }
        }
        return false;
      },
      deleteBetas: async () => {
        if (betaConfigs && configsInput) {
          const result = await batchDeleteBetas({
            variables: { input: { ...configsInput } },
            update: cache => {
              betaConfigs
                .map(beta => beta.uuid)
                .forEach(uuid => {
                  cache.evict({
                    id: cache.identify({
                      __typename: 'ActivityPlan',
                      uuid
                    })
                  });
                });
              cache.gc();
            }
          });

          // useMutation functions may return undefined or result.errors when an error occurs
          if (result && !result.errors) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      },
      promoteBetas: async () => {
        if (betaConfigs && configsInput) {
          const result = await batchPromoteBetas({
            variables: { input: { ...configsInput } },
            update: cache => {
              betaConfigs
                .map(beta => beta.uuid)
                .forEach(uuid => {
                  cache.evict({
                    id: cache.identify({
                      __typename: 'ActivityPlan',
                      uuid
                    })
                  });
                });
              cache.gc();
            },
            refetchQueries: [
              {
                query: GetAllChronicConfigsDocument,
                variables: {
                  activityPlansGetInput: {
                    count: 3,
                    page: 0,
                    alphas: true,
                    ...configsInput
                  }
                }
              }
            ]
          });

          // useMutation functions may return undefined or result.errors when an error occurs
          if (result && !result.errors) {
            return true;
          } else {
            return false;
          }
        }
        return false;
      }
    }),
    [
      activityPlan,
      betaConfigs,
      betas,
      batchCreateBetaDrafts,
      batchCreateBetas,
      batchDeleteBetas,
      batchPromoteBetas,
      configsInput,
      createDraft
    ]
  );

  return (
    <ActivityPlanContext.Provider value={value}>
      <ActivityPlanApiContext.Provider value={api}>
        {children}
      </ActivityPlanApiContext.Provider>
    </ActivityPlanContext.Provider>
  );
};

export const refetchActivityPlan = (
  uuid: string,
  userUuid?: null | string
): PureQueryOptions =>
  userUuid
    ? {
        query: GetActivityPlanByUserDocument,
        variables: {
          input: {
            activityPlanUuid: uuid,
            userUuid
          }
        }
      }
    : {
        query: GetActivityPlanDocument,
        variables: { uuid }
      };
