import { Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import React, { useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import styled from 'styled-components';
import { Progression } from 'types/activityPlan';
import { DraftActivityPlan, EditableExercise } from 'types/draftActivityPlan';
import { TargetProgression } from 'types/targetProgression';
import {
  createLocation,
  ExerciseLocation,
  MoveExercise,
  MoveProgression
} from 'utils/draftActivityPlan';
import {
  DEFAULT_HOLD_TIME,
  doesProgressionHaveExercises
} from '../../../utils/activityPlan';
import { getEstimatedEtSessionTime } from '../../../utils/time';
import { ActivityPlanStage, DroppableMain } from '../ActivityPlanStage';
import ExpandAllCheckbox from '../ExpandAllCheckbox';
import { EditablePerisurgicalExercise } from './EditablePerisurgicalExercise';
import { EditablePerisurgicalWeek } from './EditablePerisurgicalWeek';
import {
  PerisurgicalEditExerciseModal,
  usePerisurgicalEditExerciseModal
} from './PerisurgicalEditExerciseModal';

export interface EditablePerisurgicalActivityPlanProps {
  activityPlan: DraftActivityPlan;
  currentProgression?: number | null;
  onAddExercises: (target: TargetProgression) => void;
  onCopyUniqueExercises: (progression: Progression, message: string) => void;
  onCopyAllExercises: (progression: Progression, message: string) => void;
  onDeleteExercise: (location: ExerciseLocation) => void;
  onDuplicateExercise: (location: ExerciseLocation) => void;
  onEditExercise: (location: ExerciseLocation, edits: EditableExercise) => void;
  onInvalidRepsExercise: (value: number, reasons: string[]) => void;
  onInvalidHoldExercise: (value: number, reasons: string[]) => void;
  onMoveExercise: (move: MoveExercise) => void;
  onMoveProgression: (move: MoveProgression) => void;
  onDeleteProgression: (progressionUuid: string) => void;
  onDuplicateProgression: (progression: Progression) => void;
  onAddProgression: () => void;
}

const copyMessage = (week: number): string =>
  `Week ${week} exercise list copied to clipboard`;

const AddProgressionButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing(10)};
  margin-left: ${({ theme }) => theme.spacing(6)};
  width: 100%;
`;

export const EditablePerisurgicalActivityPlan = ({
  activityPlan,
  currentProgression,
  onAddExercises,
  onDeleteExercise,
  onDeleteProgression,
  onEditExercise,
  onDuplicateExercise,
  onCopyUniqueExercises,
  onCopyAllExercises,
  onInvalidRepsExercise,
  onInvalidHoldExercise,
  onMoveExercise,
  onMoveProgression,
  onDuplicateProgression,
  onAddProgression
}: EditablePerisurgicalActivityPlanProps): JSX.Element => {
  const {
    open: openEditExerciseModal,
    close: closeEditExerciseModal,
    modal
  } = usePerisurgicalEditExerciseModal();
  const [expandAll, setExpandAll] = useState<boolean>(true);

  const onDragEnd = ({ destination, source, type }: DropResult): void => {
    if (destination) {
      const { droppableId, index } = destination;

      switch (type) {
        case 'exercise':
          return onMoveExercise({
            source: createLocation(source.droppableId, source.index),
            destination: createLocation(droppableId, index)
          });
        case 'progression':
        default:
          return onMoveProgression({
            destination: index,
            source: source.index
          });
      }
    }
  };

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>): void =>
    setExpandAll(event.currentTarget.checked);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <ActivityPlanStage
        mainComponent={DroppableMain}
        footer={
          <AddProgressionButtonWrapper>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              startIcon={<AddIcon />}
              onClick={onAddProgression}
              data-testid="addProgressionButton"
            >
              {`Add Week`}
            </Button>
          </AddProgressionButtonWrapper>
        }
      >
        <ExpandAllCheckbox
          onChange={handleOnChange}
          defaultChecked={expandAll}
        />
        {activityPlan.progressions.map((progression, index) => {
          const { order, uuid, activities } = progression;
          const exercises = activities?.[0]?.data?.exercises ?? [];
          const week = order + 1;

          const handleDuplicateProgression = async (): Promise<void> => {
            await onDuplicateProgression(progression);
            snackbarState({
              type: 'generic',
              severity: 'success',
              message: `Week ${week} has been duplicated`
            });
          };

          return (
            <EditablePerisurgicalWeek
              key={uuid}
              id={uuid}
              week={week}
              index={index}
              current={currentProgression === order + 1}
              estimatedTime={getEstimatedEtSessionTime(exercises)}
              onDelete={() => onDeleteProgression(uuid)}
              onDuplicate={handleDuplicateProgression}
              isDuplicateDisabled={exercises.length === 0}
              expanded={expandAll}
              canCopyExercises={doesProgressionHaveExercises(progression)}
              onAddNewExercises={() =>
                onAddExercises({
                  buttonLabel: 'Week',
                  uuid,
                  index
                })
              }
              onCopyUniqueExercises={() =>
                onCopyUniqueExercises(progression, copyMessage(week))
              }
              onCopyAllExercises={() =>
                onCopyAllExercises(progression, copyMessage(week))
              }
            >
              {exercises.map((exercise, index) => {
                const { name, reps, holdTime, anatomicalName } = exercise;
                const location = createLocation(uuid, exercise.order ?? 0);
                const id = `${progression.uuid}-${exercise.uuid}-${index}`;
                const key = `${id}-${reps}-${holdTime}`;

                return (
                  <EditablePerisurgicalExercise
                    uuid={id}
                    key={key}
                    index={index}
                    name={name}
                    anatomicalName={anatomicalName}
                    reps={reps}
                    thumbnailUrl={exercise.thumbnail?.url}
                    repType={exercise.repType}
                    holdTime={holdTime ?? DEFAULT_HOLD_TIME}
                    onRepsChange={reps => onEditExercise(location, { reps })}
                    onHoldTimeChange={holdTime =>
                      onEditExercise(location, { holdTime })
                    }
                    onEdit={() =>
                      openEditExerciseModal(location, {
                        name: exercise.name,
                        holdTime: exercise.holdTime ?? DEFAULT_HOLD_TIME,
                        reps: exercise.reps ?? 1,
                        switchLimbTime: exercise.switchLimbTime ?? null
                      })
                    }
                    onDelete={() => onDeleteExercise(location)}
                    onDuplicate={() => onDuplicateExercise(location)}
                    onInvalidReps={onInvalidRepsExercise}
                    onInvalidHold={onInvalidHoldExercise}
                  />
                );
              })}
            </EditablePerisurgicalWeek>
          );
        })}
      </ActivityPlanStage>
      <PerisurgicalEditExerciseModal
        open={modal !== null}
        name={modal?.name ?? ''}
        onCancel={closeEditExerciseModal}
        onSubmit={values => {
          if (modal) {
            onEditExercise(modal.location, values);
            closeEditExerciseModal();
          }
        }}
        initialValues={modal?.initialValues ?? null}
      />
    </DragDropContext>
  );
};
