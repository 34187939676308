import { Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { LoadingSpinner } from './LoadingSpinner';

interface Props {
  isLoading: boolean;
}

const NoTemplatesWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ theme }) => theme.spacing(4)};
`;

export const NoTemplates = ({ isLoading }: Props): JSX.Element => (
  <NoTemplatesWrapper>
    <Typography variant="h6" component="h3">
      {isLoading ? <LoadingSpinner /> : 'No templates found :('}
    </Typography>
  </NoTemplatesWrapper>
);
