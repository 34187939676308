import { Chip as MuiChip, ChipProps, colors } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

const Chip = styled(MuiChip)`
  background-color: ${colors.red[500]};
  color: ${colors.common.white};
`;

export type TimeBasedUserChipProps = Omit<ChipProps, 'label'>;

export const TimeBasedUserChip = (
  props: TimeBasedUserChipProps
): JSX.Element => {
  return <Chip size={'small'} {...props} label="Time-based User" />;
};
