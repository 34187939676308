import { useCallback, useRef } from 'react';

/**
 * Detect when a variable changes. This hook is useful when writing a useEffect
 * that modifies a value it depends on, causing an infinite loop. This can
 * happen when merging an upstream piece of data into local state.
 *
 * Example:
 * ```ts
 * const [thing, setThing] = useState();
 * useEffect(() => {
 *   if (incomingThing) {
 *     setThing({ ...thing, ...incomingThing });
 *   }
 * }, [incomingThing, thing]);
 * ```
 *
 * In this particular case, we would only like to `setThing` when `incomingThing` has changed.
 * `useChangeDetector` can be utilized in this particular case to specifically monitor `incomingThing`
 * across renders.
 *
 * Example:
 * ```ts
 * const [thing, setThing] = useState();
 * const didIncomingThingChange = useChangeDetector(incomingThing);
 * useEffect(() => {
 *   if (didIncomingThingChange(incomingThing)) {
 *     setThing({ ...thing, ...incomingThing });
 *   }
 * }, [incomingThing, thing]);
 * ```
 */
export const useChangeDetector = <T>(
  initialValue: T
): ((newValue: T) => boolean) => {
  const ref = useRef(initialValue);
  return useCallback(newValue => {
    if (ref.current !== newValue) {
      ref.current = newValue;
      return true;
    }
    return false;
  }, []);
};
