import { TextField } from '@material-ui/core';
import { FormikHandlers } from 'formik';
import React from 'react';
import styled from 'styled-components';

interface Props {
  value: string;
  label: string;
  name: string;
  error: boolean;
  errorMessage: string | undefined;
  disabled?: boolean;
  handleChange: FormikHandlers['handleChange'];
}

const Control = styled.div`
  width: 200px;
  margin-bottom: ${props => props.theme.spacing(6)};
`;

const TextInput = ({
  value,
  label,
  name,
  error,
  errorMessage,
  disabled = false,
  handleChange
}: Props): JSX.Element => {
  const helperText = disabled
    ? 'Disabled'
    : errorMessage && error
    ? errorMessage
    : ' ';
  const helperTextProps = {
    error,
    id: 'error',
    'data-testid': `${name}-error`
  };
  const inputLabelProps = {
    htmlFor: `${name}-input`
  };

  return (
    <Control>
      <TextField
        fullWidth
        value={value}
        label={label}
        id={`${name}-input`}
        InputLabelProps={inputLabelProps}
        name={name}
        onChange={handleChange}
        aria-describedby={errorMessage && 'error'}
        error={error}
        helperText={helperText}
        FormHelperTextProps={helperTextProps}
        data-testid={`${name}-input`}
        disabled={disabled}
        inputProps={{ 'data-testid': `${name}-textfield` }}
      />
    </Control>
  );
};

export default TextInput;
