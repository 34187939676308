import { navigate, RouteComponentProps } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { ActionButton } from 'components/activity-plan/ActionButton';
import { ChronicHeader } from 'components/activity-plan/chronic/ChronicHeader';
import { DiscardDraftButton } from 'components/activity-plan/DiscardDraftButton';
import { CopyToClipboardButtonIcon } from 'components/CopyToClipboardButtonIcon';
import {
  ABInfoSection,
  ABInfoSectionType
} from 'components/diff/ABInfoSection';
import { DownloadPDFButtonIcon } from 'components/DownloadPDFButtonIcon';
import ExerciseLibrary from 'components/ExerciseLibrary';
import { ActivityPlanPageLayout } from 'components/layout/ActivityPlanPageLayout';
import { ChronicConfigPDFDocument } from 'components/PDFDocuments/ChronicConfigPDFDocument';
import { EditableChronicStreams } from 'connected/activity-plan/EditableChronicStreams';
import { useCopyPlanToClipboard } from 'hooks/useCopyPlanToClipboard';
import {
  ActivityPlanProvider,
  useActivityPlan
} from 'providers/ActivityPlanProvider';
import {
  DraftActivityPlanProvider,
  useDraftActivityPlan,
  useDraftActivityPlanAPI
} from 'providers/DraftActivityPlanProvider';
import { useEnsureBetaDrafts } from 'providers/hooks/useEnsureBetaDrafts';
import { useEnsureBetas } from 'providers/hooks/useEnsureBetas';
import React, { useState } from 'react';
import { TargetProgression } from 'types/targetProgression';
import { countDiffs } from 'utils/draftActivityPlan';
import { Programs } from 'utils/programConstants';
import { allExercisesToString } from 'utils/stringifyProgressions';
import { ActivityPlanToolbar } from '../../components/activity-plan/ActivityPlanToolbar';

const ChronicBetasEdit = ({
  indication
}: {
  indication?: string;
}): JSX.Element => {
  const [canCreateBetaDrafts, setCanCreateBetaDrafts] = useState<boolean>(true);
  const { activityPlan, diffActivityPlan } = useActivityPlan();
  const [targetProgression, setTargetProgression] =
    useState<TargetProgression | null>(null);
  const { status, draft, saveInProcess, isDirty } = useDraftActivityPlan();
  const { batchCommitBetas, batchDeleteBetaDrafts, save } =
    useDraftActivityPlanAPI();
  const { canCopyToClipboard, handleCopyExercisesToClipboard } =
    useCopyPlanToClipboard(activityPlan?.progressions);
  const disableToolbarButtons = !draft || saveInProcess;

  useEnsureBetas(canCreateBetaDrafts);
  useEnsureBetaDrafts(canCreateBetaDrafts);

  const handleCommitDrafts = async (): Promise<void> => {
    setCanCreateBetaDrafts(false);
    const { didCommit: didCommitBetas, errorMessage } =
      await batchCommitBetas();
    if (didCommitBetas) {
      navigate(`/programs/chronic/${indication}/test`);
      snackbarState({
        type: 'generic',
        severity: 'success',
        title: `All drafts for Chronic ${indication} streams have been published`
      });
    } else {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: errorMessage,
        message: 'Try again'
      });
    }
  };

  const handleSaveDraft = async (): Promise<void> => {
    const { status: didSave, errorMessage } = await save();
    if (didSave) {
      snackbarState({
        type: 'generic',
        severity: 'success',
        message: 'All changes have been saved!'
      });
    } else {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: errorMessage,
        message: 'Try again'
      });
    }
  };

  const handleDiscardDrafts = async (): Promise<void> => {
    setCanCreateBetaDrafts(false);
    const didDeleteDrafts = await batchDeleteBetaDrafts();
    if (didDeleteDrafts) {
      navigate(`/programs/chronic/${indication}/test`);
      snackbarState({
        type: 'generic',
        severity: 'success',
        title: `All drafts for Chronic ${indication} streams have been discarded`
      });
    }
  };

  const betaDraftOrPlan = draft ?? activityPlan;
  const diffViewerChanges = {
    oldValue: diffActivityPlan
      ? allExercisesToString(diffActivityPlan.progressions, true)
      : '',
    newValue: betaDraftOrPlan
      ? allExercisesToString(betaDraftOrPlan.progressions, true)
      : '',
    number:
      diffActivityPlan && betaDraftOrPlan
        ? countDiffs(diffActivityPlan, betaDraftOrPlan)
        : 0
  };

  return (
    <ActivityPlanPageLayout
      sidebar={
        <ABInfoSection
          type={ABInfoSectionType.EDITING_TEST}
          activityPlanName={activityPlan?.name ?? `Chronic ${indication}`}
          changes={diffViewerChanges}
        />
      }
      stickyToolbar={
        <ActivityPlanToolbar
          actionButtons={
            <>
              <DiscardDraftButton
                onClick={handleDiscardDrafts}
                disabled={disableToolbarButtons}
              />
              <ActionButton
                text="SAVE DRAFT"
                onClick={handleSaveDraft}
                disabled={disableToolbarButtons || !isDirty}
              />
              <ActionButton
                text="PUBLISH TEST"
                onClick={handleCommitDrafts}
                disabled={disableToolbarButtons}
              />
            </>
          }
        >
          <DownloadPDFButtonIcon
            testId="baseChronicBetasEditDownloadIcon"
            title="Download PDF"
            placement="bottom-start"
            document={<ChronicConfigPDFDocument activityPlan={activityPlan} />}
            fileName={activityPlan?.name ?? 'exercise-therapy-template'}
          />
          <CopyToClipboardButtonIcon
            disabled={!canCopyToClipboard}
            onClick={handleCopyExercisesToClipboard}
            testId="baseChronicBetasEditCopyIcon"
            title={
              canCopyToClipboard
                ? 'Copy all activity plan exercises'
                : 'Copy all exercises disabled'
            }
            placement="bottom-start"
          />
        </ActivityPlanToolbar>
      }
      header={
        <ChronicHeader
          name={`Chronic ${indication} Program`}
          draftStatus={{
            status,
            lastUpdatedAt: draft?.updatedAt
          }}
        />
      }
      testId="baseChronicBetasEditPage"
    >
      <EditableChronicStreams onAddExercises={setTargetProgression} />
      {targetProgression && (
        <ExerciseLibrary
          targetProgression={targetProgression}
          handleCloseExerciseLibrary={() => setTargetProgression(null)}
        />
      )}
    </ActivityPlanPageLayout>
  );
};

interface Props extends RouteComponentProps {
  indication?: string;
}

const ConnectedChronicBetasEdit = ({ indication = '' }: Props): JSX.Element => {
  return (
    <ActivityPlanProvider
      betas
      uuid={''}
      configsInput={{ program: Programs.CHRONIC, indication }}
      onError={err => {
        throw new Error(`Unable to edit test Chronic Activity Plan (${err})`);
      }}
    >
      <DraftActivityPlanProvider indication={indication}>
        <ChronicBetasEdit indication={indication} />
      </DraftActivityPlanProvider>
    </ActivityPlanProvider>
  );
};

export { ConnectedChronicBetasEdit as ChronicBetasEdit };
