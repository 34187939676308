import { ChronicActivityPlan as ChronicActivityPlanUI } from 'components/activity-plan/chronic/ChronicActivityPlan';
import { useActivityPlan } from 'providers/ActivityPlanProvider';
import React from 'react';
import {
  copyAllExercises,
  copyUniqueExercises
} from 'utils/copyExercisesToClipboard';

export const ChronicActivityPlan = ({
  userLevel
}: {
  userLevel: number | null | undefined;
}): JSX.Element | null => {
  const { activityPlan } = useActivityPlan();

  return activityPlan ? (
    <ChronicActivityPlanUI
      activityPlan={activityPlan}
      onCopyAllExercises={copyAllExercises}
      onCopyUniqueExercises={copyUniqueExercises}
      userLevel={userLevel}
    />
  ) : null;
};
