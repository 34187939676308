import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Typography as MuiTypography
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { RouteComponentProps } from '@reach/router';
import BummerLlama from 'components/BummerLlama';
import { Event, useMixpanel } from 'hooks/useMixpanel';
import React, { useState } from 'react';
import styled from 'styled-components';
import ThemeProviders from 'styles/ThemeProviders';
import { CopyToClipboardButtonIcon } from './CopyToClipboardButtonIcon';

const FallbackWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(12, 16, 6)};
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  flex: 0 0 50%;
  width: 50%;
`;

const ImageWrapper = styled.div`
  flex: 1 1 auto;
`;

const Typography = styled(({ marginSpacing: _, ...props }) => (
  <MuiTypography {...props} />
))<{ marginSpacing?: number }>`
margin - bottom: ${({ theme, marginSpacing }) =>
  theme.spacing(marginSpacing ?? 8)};
`;

const Code = styled.code`
white - space: pre;
border: 1px solid ${({ theme }) => theme.palette.divider};
background - color: ${({ theme }) => theme.palette.grey['100']};
display: block;
overflow - x: scroll;
padding: ${({ theme }) => theme.spacing(3)};
`;

interface ErrorCardBaseProps {
  title: string;
  subtitle?: string | null;
  children: React.ReactNode;
  onCopy: () => void;
  className?: string;
}

const ErrorCardBase = ({
  title,
  subtitle,
  children,
  onCopy,
  className
}: ErrorCardBaseProps): JSX.Element => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Card className={className}>
      <CardHeader
        avatar={
          <IconButton
            aria-label={expanded ? 'Expand' : 'Collapse'}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        }
        title={title}
        subheader={subtitle}
        action={
          <CopyToClipboardButtonIcon
            onClick={onCopy}
            title={`Copy ${title} to clipboard`}
          />
        }
      />
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};

interface ErrorCardProps extends ErrorCardBaseProps {
  marginTop?: number;
}

const ErrorCard = styled(ErrorCardBase)<ErrorCardProps>`
margin - top: ${({ theme, marginTop }) => theme.spacing(marginTop ?? 0)};
`;

const copyErrorText = (error: Error): string =>
  `Error: ${error.name} - ${error.message} \nStacktrace: ${
    error.stack ? error.stack : 'No stacktrace found'
  } `;

interface StackTraceProps {
  error: Error;
}

const Stacktrace = ({ error }: StackTraceProps): JSX.Element => (
  <ErrorCard
    key={0}
    title="Error"
    subtitle={`${error.name} - ${error.message} `}
    onCopy={() => navigator.clipboard.writeText(copyErrorText(error))}
  >
    <Code>{error.stack ? error.stack : 'No stacktrace found.'}</Code>
  </ErrorCard>
);

const copyComponentStackText = (componentStack: string | null): string =>
  `Component Stack: ${
    componentStack ? componentStack : 'No component stack found.'
  } `;

interface ComponentStackProps {
  componentStack: string | null;
  marginTop?: number;
}

const ComponentStack = ({
  componentStack,
  marginTop
}: ComponentStackProps): JSX.Element => (
  <ErrorCard
    marginTop={marginTop}
    title="Component Stack"
    onCopy={() =>
      navigator.clipboard.writeText(copyComponentStackText(componentStack))
    }
  >
    <Code>{componentStack ? componentStack : 'No component stack found.'}</Code>
  </ErrorCard>
);

interface ErrorDetailsOptions {
  componentStack: string | null;
  eventId: string | null;
  error: Error;
}

const copyAllDetailsText = (details: ErrorDetailsOptions): string =>
  `EventId: ${details.eventId} \n\n${copyErrorText(
    details.error
  )} \n\n${copyComponentStackText(details.componentStack)} `;

const ErrorDetails = (details: ErrorDetailsOptions): JSX.Element => {
  return (
    <Card>
      <CardHeader
        title="Error Details"
        subheader={`eventID: ${details.eventId} `}
        action={
          <CopyToClipboardButtonIcon
            onClick={() =>
              navigator.clipboard.writeText(copyAllDetailsText(details))
            }
            title={`Copy all details to clipboard`}
          />
        }
      />
      <CardContent>
        <Stacktrace error={details.error} />
        <ComponentStack componentStack={details.componentStack} marginTop={8} />
      </CardContent>
    </Card>
  );
};

interface Props extends RouteComponentProps {
  resetError: () => void;
  componentStack: string | null;
  eventId: string | null;
  error: Error;
}

export default function ErrorBoundaryFallback({
  resetError,
  componentStack,
  eventId,
  error
}: Props): JSX.Element {
  const { trackEvent } = useMixpanel();
  if (error || eventId) {
    trackEvent(Event.ERROR_BOUNDARY, { error, sentryEventId: eventId });
  }

  return (
    <ThemeProviders>
      <FallbackWrapper data-testid="errorBoundaryFallback">
        <Content>
          <Typography variant="h1" marginSpacing={12}>
            Bummer... something isn&#39;t working.
          </Typography>
          <Typography variant="h5">
            Bribe our devs with funyuns and they&#39;ll try and fix it for you.
          </Typography>
          <ErrorDetails
            error={error}
            componentStack={componentStack}
            eventId={eventId}
          />
          <Button
            color="primary"
            size="large"
            variant="outlined"
            onClick={resetError}
          >
            Try again
          </Button>
        </Content>
        <ImageWrapper>
          <BummerLlama />
        </ImageWrapper>
      </FallbackWrapper>
    </ThemeProviders>
  );
}
