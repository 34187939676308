import SentryErrorBoundary from 'components/SentryErrorBoundary';
import Snackbar from 'components/Snackbar';
import i18n from 'i18n';
import RefreshProvider from 'providers/RefreshProvider';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import ThemeProviders from 'styles/ThemeProviders';
import Apollo from './apollo/providers/Apollo';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <SentryErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <Apollo>
          <RefreshProvider>
            <ThemeProviders>
              <Snackbar />
              <App />
            </ThemeProviders>
          </RefreshProvider>
        </Apollo>
      </I18nextProvider>
    </SentryErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
