import {
  Backdrop,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider
} from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import { ExerciseInput, ValidationFn } from './ExerciseInput';

interface Input {
  label: string;
  validations: ValidationFn[];
  unit?: string;
  initialValue?: null | number;
}

type Inputs = Record<string, Input>;
type Values = Record<string, number | null>;
type Errors = Record<string, boolean>;

interface Form {
  values: Values;
  onChange: (key: string, value: number | null, isInvalid: boolean) => void;
  isInvalid: boolean;
  setValues: React.Dispatch<React.SetStateAction<Values>>;
}

const useForm = (inputs: Inputs): Form => {
  const [values, setValues] = useState<Values>(
    inputConfigsToInitialValues(inputs)
  );
  const [errors, setErrors] = useState<Errors>(
    inputConfigsToInitialErrors(inputs)
  );

  const onChange = (
    key: string,
    value: number | null,
    isInvalid: boolean
  ): void => {
    setValues({ ...values, [key]: value });
    setErrors({ ...errors, [key]: isInvalid });
  };

  const isInvalid = Object.entries(errors).some(([_, isInvalid]) => isInvalid);

  return { values, onChange, isInvalid, setValues };
};

const inputConfigsToInitialValues = (inputs: Inputs): Values =>
  Object.fromEntries(
    Object.entries<Input>(inputs).map(([name, { initialValue }]) => [
      name,
      initialValue ?? null
    ])
  );

const inputConfigsToInitialErrors = (inputs: Inputs): Errors =>
  Object.fromEntries(
    Object.entries<Input>(inputs).map(
      ([name, { validations, initialValue }]) => [
        name,
        initialValue
          ? validations.some(
              validationFn => !validationFn(initialValue).isValid
            )
          : true
      ]
    )
  );

const Content = styled(DialogContent)`
  width: 445px;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(5)};
`;

export const EditExerciseModalInput = styled(ExerciseInput)`
  input,
  .MuiInputAdornment-root {
    font-size: 16px;
  }
  input {
    text-align: left;
    max-width: none;
  }
  .MuiInputAdornment-root {
    flex: 1;
    justify-content: flex-end;
  }
  width: 132px;
`;

export interface EditExerciseModalProps {
  name: string;
  open: boolean;
  inputs: Inputs;
  onSubmit: (values: Values) => void;
  onCancel: () => void;
}

export const EditExerciseModal = ({
  name,
  open,
  inputs,
  onSubmit,
  onCancel
}: EditExerciseModalProps): JSX.Element => {
  const { onChange, values, isInvalid, setValues } = useForm(inputs);
  return (
    <Dialog
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      data-testid="EditExerciseModal"
      aria-labelledby="EditExerciseModalTitle"
    >
      <form>
        <DialogTitle id="EditExerciseModalTitle">{name}</DialogTitle>
        <Divider />
        <Content>
          {Object.entries<Input>(inputs).map(([name, input], i) => (
            <EditExerciseModalInput
              key={i}
              name={name}
              label={input.label}
              unit={input.unit}
              value={input.initialValue ?? 0}
              onChange={(value, results) =>
                onChange(name, value, results.isInvalid)
              }
              validations={input.validations}
            />
          ))}
        </Content>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              onSubmit(values);
              setValues({});
            }}
            disabled={isInvalid}
            color="primary"
          >
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
