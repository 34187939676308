import { TableRow, Typography } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { LlamaPlaceholderSVG } from 'styles/assets/LlamaPlaceholderSVG';
import { MotionDefinitionType } from 'types';
import { Asset, VerbalCue } from 'types/exerciseRepo';
import { TableCell } from 'utils/libraryTableCells';
import { AudioPlayerIconButton } from './AudioPlayerIconButton';

interface RowProps {
  type: MotionDefinitionType | string;
  image?: Asset | null;
  text: string;
  verbalCues: VerbalCue[];
}

const Image = styled.img`
  height: ${({ theme }) => theme.spacing(12)};
  padding: ${({ theme }) => theme.spacing(3, 2, 3, 2)};
  vertical-align: middle;
`;

const AudioPlayerWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
`;

const Cell = styled(props => <TableCell {...props} />)`
  display: table-cell;
  vertical-align: middle;
  padding: ${({ theme }) => theme.spacing(0, 6, 0, 6)};
  width: ${({ width = 10 }) => width}%;
`;

const TypeCell = styled(props => <Cell {...props} />)`
  text-transform: capitalize;
`;

const Placeholder = styled.div`
  height: ${({ theme }) => theme.spacing(12)};
  padding: ${({ theme }) => theme.spacing(3, 2, 3, 2)};
`;
export const MotionsTableRow = ({
  type,
  image,
  text,
  verbalCues
}: RowProps): JSX.Element => (
  <TableRow>
    <TypeCell width={10}>
      <Typography variant="body2">{type.toLowerCase()}</Typography>
    </TypeCell>
    <Cell width={5}>
      {image ? (
        <Image src={image.url} alt={image.description ?? ''} />
      ) : (
        <Placeholder>
          <LlamaPlaceholderSVG height="48px" />
        </Placeholder>
      )}
    </Cell>
    <Cell width={30}>
      <Typography
        variant="body2"
        color={image?.description ? 'textPrimary' : 'textSecondary'}
      >
        {image?.description ?? 'no alt text available'}
      </Typography>
    </Cell>
    <Cell width={25}>
      <Typography variant="body2">{text}</Typography>
    </Cell>
    <Cell width={30}>
      {verbalCues.length > 0 ? (
        <>
          {verbalCues.map(({ uuid, url, text }: VerbalCue) => (
            <AudioPlayerWrapper key={uuid}>
              <AudioPlayerIconButton
                disabled={!url}
                url={url ?? ''}
                name="verbal cue"
              />
              <Typography variant="body2">{text}</Typography>
            </AudioPlayerWrapper>
          ))}
        </>
      ) : (
        <AudioPlayerWrapper>
          <AudioPlayerIconButton url="" name="verbal cue" disabled />
          <Typography color="textSecondary" variant="body2">
            None available
          </Typography>
        </AudioPlayerWrapper>
      )}
    </Cell>
  </TableRow>
);
