import { Redirect, RouteComponentProps } from '@reach/router';
import { userAuthState } from 'apollo/reactive-variables/userAuthState';
import NavLayout from 'components/NavLayout';
import NotFound from 'pages/NotFound';
import React from 'react';
import { hasSuperAccess } from 'utils/authorization';

interface Props extends RouteComponentProps {
  children: React.ReactNode;
}

const NavSwitch = ({
  path,
  children
}: {
  path?: string;
  children: React.ReactNode;
}): JSX.Element => {
  const isAuthorized = hasSuperAccess();
  if (!path) {
    return <NotFound />;
  }
  if (path.includes('/acute') || path.includes('/edit')) {
    return isAuthorized ? <>{children}</> : <NotFound />;
  }

  return <>{children}</>;
};

export default function PrivateRoute(props: Props): JSX.Element {
  const { children, location } = props;
  const { accessToken } = userAuthState();

  return accessToken ? (
    <NavLayout>
      <NavSwitch path={location?.pathname}>{children}</NavSwitch>
    </NavLayout>
  ) : (
    <Redirect to="/login" noThrow replace />
  );
}
