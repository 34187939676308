import { colors } from '@material-ui/core';
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View
} from '@react-pdf/renderer';
import React from 'react';
import logo from 'styles/assets/hingeHealthLogo.png';
import {
  Activity,
  Exercise,
  isEtSessionActivity,
  Progression
} from 'types/activityPlan';
import { ProgressionTypes } from 'utils/activityPlan';

Font.register({
  family: 'Lato',
  fonts: [
    { src: 'https://fonts.gstatic.com/s/lato/v17/S6uyw4BMUTPHvxk.ttf' },
    {
      src: 'https://fonts.gstatic.com/s/lato/v17/S6u9w4BMUTPHh6UVew8.ttf',
      fontWeight: 'bold'
    }
  ]
});

const mainStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    paddingLeft: 16,
    minHeight: 36,
    fontSize: 12,
    alignItems: 'center'
  },
  columnOne: {
    width: 170
  },
  columnTwo: {
    marginLeft: 40,
    width: 60
  },
  columnThree: {
    marginLeft: 40,
    width: 118
  }
});

const ExerciseRow = ({ exercise }: { exercise: Exercise }): JSX.Element => {
  const THREE_SECONDS = 3;
  const exerciseStyles = StyleSheet.create({
    exerciseWrapper: {
      borderBottom: colors.grey[700],
      borderBottomWidth: 1,
      paddingBottom: 10,
      paddingTop: 10
    }
  });
  const holdTime = exercise?.holdTime
    ? exercise.holdTime / 1000
    : THREE_SECONDS;

  return (
    <View style={[mainStyles.row, exerciseStyles.exerciseWrapper]} wrap={false}>
      <View style={mainStyles.columnOne}>
        {/** TODO: LLAMA-1710 add thumbnail */}
        <Text>{exercise.name}</Text>
      </View>
      <Text style={mainStyles.columnTwo}>{exercise.reps}</Text>
      <Text style={mainStyles.columnThree}>{holdTime} seconds</Text>
    </View>
  );
};

export const PDFProgression = ({
  progression,
  type
}: {
  progression: Progression;
  type: ProgressionTypes;
}): JSX.Element | null => {
  const styles = StyleSheet.create({
    progressionWrapper: {
      marginBottom: 24
    },
    progressionTitle: {
      fontSize: 18,
      height: 27,
      marginBottom: 8,
      fontWeight: 'bold',
      alignItems: 'center',
      flexDirection: 'row'
    },
    tableHeader: {
      borderBottom: colors.grey[700],
      borderBottomWidth: 1,
      fontWeight: 'bold'
    }
  });
  const etActivity = progression?.activities?.find((activity: Activity) =>
    isEtSessionActivity(activity)
  );
  const exercises = etActivity?.data?.exercises;
  const hasEtActivity = exercises && exercises?.length > 0;

  return hasEtActivity ? (
    <View style={styles.progressionWrapper}>
      <View style={styles.progressionTitle}>
        <Text>{`${type} ${progression.order + 1}`}</Text>
      </View>
      <View style={[mainStyles.row, styles.tableHeader]}>
        <Text style={mainStyles.columnOne}>Exercise</Text>
        <Text style={mainStyles.columnTwo}>Reps</Text>
        <Text style={mainStyles.columnThree}>Hold</Text>
      </View>
      {exercises &&
        exercises.map((exercise, index) => (
          <ExerciseRow
            exercise={exercise}
            key={`${progression.uuid}-${exercise.uuid}-${index}`}
          />
        ))}
    </View>
  ) : null;
};

export const PDFActivityPlanInfoItem = ({
  text
}: {
  text: string;
}): JSX.Element => <Text style={{ lineHeight: 1.5 }}>{text}</Text>;

export const PDFActivityPlanInfoWrapper = ({
  children
}: {
  children: React.ReactNode;
}): JSX.Element => (
  <View
    style={{
      textTransform: 'capitalize',
      fontSize: 12,
      marginBottom: 24
    }}
  >
    {children}
  </View>
);

export const PDFDocumentLetterhead = ({
  children,
  title
}: {
  children: React.ReactNode;
  title: string;
}): JSX.Element => {
  const styles = StyleSheet.create({
    page: {
      padding: 72,
      paddingBottom: 66,
      fontFamily: 'Lato',
      fontWeight: 'normal'
    },
    logo: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: 48,
      alignItems: 'center',
      fontSize: 12
    },
    title: {
      fontSize: 22,
      height: 36,
      marginBottom: 8,
      fontWeight: 'bold'
    },
    pageNumber: {
      fontSize: 12,
      textAlign: 'right',
      position: 'absolute',
      bottom: 30,
      left: 0,
      right: 24
    }
  });

  return (
    <Document>
      <Page size="LETTER" style={styles.page}>
        <View style={styles.logo}>
          <View>
            <Image src={logo} style={{ width: 144 }} />
          </View>
          <View>
            <Text>465 California St., 14th Floor</Text>
            <Text>San Francisco, CA 94104</Text>
          </View>
        </View>
        <Text style={styles.title}>{title}</Text>
        {children}
        <Text
          fixed
          render={({ pageNumber }) => pageNumber}
          style={styles.pageNumber}
        />
      </Page>
    </Document>
  );
};
