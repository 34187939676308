import { DraftStatuses } from 'providers/DraftActivityPlanProvider';
import React from 'react';
import { ActivityPlan } from 'types/activityPlan';
import { DraftActivityPlan } from 'types/draftActivityPlan';
import { extractProgramInfoFromUri } from 'utils/extractProgramInfoFromUri';
import { Programs } from 'utils/programConstants';
import { AcuteUserHeader } from './acute/AcuteHeader';
import { ChronicUserHeader } from './chronic/ChronicHeader';
import { PerisurgicalUserHeader } from './perisurgical/PerisurgicalHeader';

interface UserHeaderProps {
  userId: string;
  isTimeBasedUser?: boolean;
  activityPlan: ActivityPlan;
  currentProgressionNum: number | null;
  viewOnly?: boolean;
  draft?: DraftActivityPlan;
  status?: DraftStatuses;
  isBeta?: boolean;
}

export const UserHeader = ({
  userId,
  isTimeBasedUser,
  activityPlan,
  draft,
  currentProgressionNum,
  status,
  viewOnly = false,
  isBeta
}: UserHeaderProps): JSX.Element => {
  const { program, indication } = extractProgramInfoFromUri(activityPlan.uri);
  const draftStatus =
    draft && status
      ? {
          status,
          lastUpdatedAt: draft.updatedAt
        }
      : undefined;

  switch (program) {
    case Programs.CHRONIC:
      return (
        <ChronicUserHeader
          isBeta={isBeta}
          viewOnly={viewOnly}
          userId={userId}
          isTimeBasedUser={isTimeBasedUser}
          currentLevel={currentProgressionNum}
          name={activityPlan.name ?? indication}
          activityPlanStatus={{
            status: activityPlan.status
          }}
          draftStatus={draftStatus}
        />
      );
    case Programs.PERISURGICAL:
      return (
        <PerisurgicalUserHeader
          viewOnly={viewOnly}
          userId={userId}
          isTimeBasedUser={isTimeBasedUser}
          bodyRegion={activityPlan.data?.bodyRegion ?? '--'}
          currentWeek={currentProgressionNum}
          activityPlanStatus={{
            status: activityPlan.status
          }}
          draftStatus={draftStatus}
        />
      );
    default:
      return (
        <AcuteUserHeader
          viewOnly={viewOnly}
          userId={userId}
          isTimeBasedUser={isTimeBasedUser}
          bodyRegion={activityPlan.data?.bodyRegion ?? '--'}
          currentWeek={currentProgressionNum}
          activityPlanStatus={{
            status: activityPlan.status
          }}
          draftStatus={draftStatus}
        />
      );
  }
};
