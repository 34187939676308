import {
  Button as MuiButton,
  Chip,
  Divider,
  Paper as MuiPaper,
  Typography
} from '@material-ui/core';
import { TrackChanges } from '@material-ui/icons';
import { Science } from 'components/icon/Science';
import React, { useState } from 'react';
import styled from 'styled-components';
import { DiffViewerModal } from './DiffViewerModal';

const Paper = styled(MuiPaper)`
  height: 100%;
  width: 230px;
  padding: ${({ theme }) => theme.spacing(4)};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(4)};
`;

const TestChip = styled(Chip)`
  background-color: #6dd400;
  width: 58px;
`;

const Header = (): JSX.Element => (
  <header>
    <Typography variant="h6">Test Details</Typography>
    <Divider />
  </header>
);

const Span = styled(Typography)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
`;

const Button = styled(MuiButton)`
  text-transform: none;
  padding: 0;
`;

const changesText = (numberOfChanges: number): string =>
  numberOfChanges === 1
    ? `${numberOfChanges} Change`
    : `${numberOfChanges} Changes`;

const SeeChanges = ({
  numberOfChanges,
  onSeeDiff
}: {
  onSeeDiff: () => void;
  numberOfChanges?: number;
}): JSX.Element => (
  <Span>
    <TrackChanges />
    {numberOfChanges ? changesText(numberOfChanges) : null}
    <Button color="primary" onClick={onSeeDiff}>
      {numberOfChanges ? 'See diff' : 'See changes'}
    </Button>
  </Span>
);

export enum ABInfoSectionType {
  TEST_CONTROLLED_BY_SPLITIO = 'Test controlled by Split.io',
  EDITING_TEST = 'Editing test',
  TESTING_IN_PROGRESS = 'Testing in progress'
}

export interface ABInfoSectionProps {
  type: ABInfoSectionType;
  changes?: {
    number?: number;
    oldValue: string;
    newValue: string;
  };
  activityPlanName: string;
}

export const ABInfoSection = ({
  type,
  activityPlanName,
  changes
}: ABInfoSectionProps): JSX.Element => {
  const [isDiffOpen, setIsDiffOpen] = useState(false);

  return (
    <Paper square data-testid="abInfoSection">
      <Header />
      <TestChip label="Test" size="small" data-testid="ABInfoSectionChip" />
      <Span>
        <Science />
        {type}
      </Span>
      {changes === undefined || changes?.number === 0 ? null : (
        <>
          <SeeChanges
            onSeeDiff={() => setIsDiffOpen(true)}
            numberOfChanges={changes?.number}
          />
          <DiffViewerModal
            activityPlanName={activityPlanName}
            isOpen={isDiffOpen}
            newValue={changes.newValue}
            oldValue={changes.oldValue}
            onCloseDiffViewer={() => setIsDiffOpen(false)}
          />
        </>
      )}
    </Paper>
  );
};
