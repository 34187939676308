import { useCallback, useRef } from 'react';

interface Semaphore {
  increment: () => boolean;
  decrement: () => void;
}

/**
 * Implement concurrency limits. This hook is helpful when there is a
 * process that needs to be run a limited amount of times at once.
 * The semaphore can be incremented until it reaches its max, at
 * which point it will return `false` and not increment anymore.
 * The semaphore can also be decremented.
 *
 * This behavior allows you, the consumer, to implement a locking mechanism
 * by placing a process under a conditional that only run if the semaphore
 * is able to be incremented.
 *
 * Example:
 * ```ts
 *    const { increment, decrement } = useSempahore({ max: 1 });
 *    useEffect(() => {
 *      (async () => {
 *        if (!booleanStateDownstreamFromMyExampleFn && increment()) {
 *          await myExampleFn();
 *          decrement();
 *        }
 *      })();
 *    }, [myExampleFn, booleanStateDownstreamFromMyExampleFn])
 * ```
 */
export const useSemaphore = ({ max }: { max: number }): Semaphore => {
  const count = useRef<number>(0);

  const increment = useCallback((): boolean => {
    if (count.current < max) {
      count.current += 1;
      return true;
    } else {
      return false;
    }
  }, [max]);

  const decrement = useCallback(() => {
    if (count.current > 0) {
      count.current -= 1;
    }
  }, []);

  return { increment, decrement };
};
