import { isEqual, omit, orderBy } from 'lodash';
import {
  ActivityPlanFragment,
  DraftActivityPlanFragment,
  EtSessionActivitiesInput,
  ExerciseInput,
  ProgressionDraftCreateInput,
  ProgressionDraftUpdateInput,
  ProgressionsInput,
  UserSubstitutionFragment,
  UserSubstitutionInput
} from 'types';
import {
  Activity,
  ActivityPlan,
  Exercise,
  isEtSessionActivity,
  Progression
} from 'types/activityPlan';
import {
  DraftActivity,
  DraftActivityPlan,
  DraftExercise,
  DraftProgression,
  EditableExercise
} from 'types/draftActivityPlan';

const activityToDraft = (activity: Activity): DraftActivity => ({
  ...activity,
  data: {
    __typename: 'EtSessionActivityMetadata',
    exercises: (activity.data?.exercises ?? [])
      .map((exercise, i) => ({
        ...exercise,
        order: exercise.order ?? i
      }))
      .sort((a, b) => a.order - b.order),
    userSubstitutions: activity.data?.userSubstitutions
      ? [...activity.data.userSubstitutions]
      : undefined
  }
});

/**
 * Convert a plain Progression object into a draft version
 */
export const progressionToDraft = (
  progression: Progression
): DraftProgression => ({
  ...progression,
  activities: (progression.activities ?? [])
    // Ensure that [at least an empty] et-session is included
    .reduce<Activity[]>(
      ([etSession, ...rest]: Activity[], activity) => [
        // when an et-session is found, override the empty seeded et-session
        ...(isEtSessionActivity(activity)
          ? [{ ...etSession, ...activity }]
          : [etSession, activity]),
        ...rest
      ],
      // Kick off with an empty etSession
      [{ kind: 'et-session', __typename: 'EtSessionActivity', uuid: '' }]
    )
    // Include an exercises array within each activity
    .map(activityToDraft),
  dirty: false
});

/**
 * Convert a plain ActivityPlan object into a draft version.
 */
export const activityPlanToDraft = (
  activityPlan: DraftActivityPlanFragment
): DraftActivityPlan => ({
  ...activityPlan,
  __typename: 'ActivityPlanDraft',
  progressions: activityPlan.progressions
    .map(progressionToDraft)
    .sort((a, b) => a.order - b.order)
});

export const userSubstitutionToUserSubstitutionInput = (
  userSubstitution: UserSubstitutionFragment
): UserSubstitutionInput => {
  const originalExercise = omit(
    userSubstitution.originalExercise,
    '__typename'
  );
  const newExercise = omit(userSubstitution.newExercise, '__typename');
  return {
    originalExercise,
    newExercise
  };
};

/**
 * Prepare a draft to be saved.
 */
export const draftToProgressionDraftUpdateInput = (
  activityPlanUuid: string,
  { progressions, userUuid }: DraftActivityPlan
): ProgressionDraftUpdateInput => ({
  activityPlanUuid,
  userUuid,
  progressions: progressions
    .filter(progression => progression.dirty)
    .map(
      ({ uuid, order, activities }): ProgressionsInput => ({
        uuid,
        order,
        activities: activities.map(
          ({ kind, data }): EtSessionActivitiesInput => ({
            kind,
            exercises: (data?.exercises ?? []).map(
              (
                {
                  name,
                  uuid,
                  reps,
                  holdTime,
                  order,
                  pointsPerRep,
                  restTime,
                  switchLimbTime
                },
                i
              ): ExerciseInput => ({
                switchLimbTime,
                restTime,
                pointsPerRep,
                order: order ?? i,
                name,
                uuid,
                reps,
                ...(holdTime ? { holdTime } : {})
              })
            ),
            ...(data?.userSubstitutions && data?.userSubstitutions.length >= 1
              ? {
                  userSubstitutions: data?.userSubstitutions.map(
                    userSubstitutionToUserSubstitutionInput
                  )
                }
              : {})
          })
        )
      })
    )
});

/**
 * Determine if any exercises exist in any progression
 */
export const isDraftSaveable = ({ progressions }: DraftActivityPlan): boolean =>
  progressions.some(progression =>
    progression.activities.some(
      activity =>
        isEtSessionActivity(activity) && activity?.data?.exercises?.length
    )
  );

/**
 * Compute a new order value for items in an array when one of the items is
 * being shifted from one spot in the array to another. Items between the
 * source and destination indices need to either be shifted left or right.
 */
const updateOrder = (
  order: number,
  { destination, source }: MoveProgression
): number => {
  if (order === source) {
    return destination;
  } else if (source < destination && order <= destination && order > source) {
    return order - 1;
  } else if (source > destination && order < source && order >= destination) {
    return order + 1;
  } else {
    return order;
  }
};

/**
 * Shift order count left. This is for use when removing an item, in which
 * case items that came after the removed item need to shift left.
 */
const shiftOrderLeft = (removedOrder: number, order: number): number =>
  order > removedOrder ? order - 1 : order;

/**
 * Shift order count left. This is for use when adding an item, in which
 * case items that come after the added item need to shift right.
 */
const shiftOrderRight = (addedOrder: number, order: number): number =>
  order >= addedOrder ? order + 1 : order;

/**
 * Generically edit progressions within an ActivityPlan.
 */
const editProgressions = (
  activityPlan: DraftActivityPlan,
  fn: (p: DraftProgression[]) => DraftProgression[]
): DraftActivityPlan => ({
  ...activityPlan,
  progressions: orderBy(fn(activityPlan.progressions), ['order'], ['asc'])
});

/**
 * Clean up dirty flags. Use this when data has been saved and an update is impending.
 */
export const cleanProgressions = (
  activityPlan: DraftActivityPlan
): DraftActivityPlan =>
  editProgressions(activityPlan, progressions =>
    progressions.map(progression => ({ ...progression, dirty: false }))
  );

/**
 * Enforces an incremental ordering of progressions.
 */
export const ensureOrderedProgressions = (
  activityPlan: DraftActivityPlan
): DraftActivityPlan =>
  editProgressions(activityPlan, progressions =>
    orderBy(progressions, ['order'], ['asc']).map((progression, order) => ({
      ...progression,
      order,
      // only mark this as dirty if the progression is already dirty or the ordering changed
      dirty: progression.dirty || progression.order !== order
    }))
  );

export interface MoveProgression {
  destination: number;
  source: number;
}

/**
 * Change a progression's order, updating any other progression
 * that need to be shifted in order to acquiesce the target
 * progression's movement. Progressions with changed `order` values
 * are marked as `dirty`.
 */
export const moveProgression = (
  activityPlan: DraftActivityPlan,
  reorder: MoveProgression
): DraftActivityPlan =>
  editProgressions(activityPlan, progressions =>
    progressions.map(({ order, ...progression }) => {
      const updatedOrder = updateOrder(order, reorder);
      return {
        ...progression,
        ...(updatedOrder !== order
          ? {
              dirty: true,
              order: updatedOrder
            }
          : { order })
      };
    })
  );

/**
 * Target a specific progression for editing.
 */
const editProgression = (
  activityPlan: DraftActivityPlan,
  id: string,
  fn: (p: DraftProgression) => DraftProgression
): DraftActivityPlan =>
  editProgressions(activityPlan, progressions =>
    progressions.map(progression =>
      progression.uuid === id ? fn(progression) : progression
    )
  );

export interface ExerciseLocation {
  progressionId: string;
  order: number;
}

export const createLocation = (
  progressionId: string,
  order: number
): ExerciseLocation => ({
  progressionId,
  order
});

export interface MoveExercise {
  destination: ExerciseLocation;
  source: ExerciseLocation;
}

const getExercisesFromProgression = (
  progression: DraftProgression
): DraftExercise[] => {
  const exercises =
    progression.activities.find(isEtSessionActivity)?.data?.exercises;

  if (exercises === undefined) {
    throw new Error(
      `Cannot find et-session activity in progression ${progression.uuid}`
    );
  }

  return exercises;
};

/**
 * Get a list of exercises associated with a Progression.
 * @throws {Error} when a progression or an et-session activity cannot be found
 */
export const getExercises = (
  { progressions }: DraftActivityPlan,
  progressionId: string
): DraftExercise[] => {
  const progression = progressions.find(
    progression => progression.uuid === progressionId
  );

  if (progression === undefined) {
    throw new Error(`Cannot find progression ${progressionId}`);
  }

  return getExercisesFromProgression(progression);
};

/**
 * Get a specific exercise within a Progression.
 * @throws {Error} when the exercise cannot be found
 */
export const getExercise = (
  activityPlan: DraftActivityPlan,
  { progressionId, order }: ExerciseLocation
): DraftExercise => {
  const exercise = getExercises(activityPlan, progressionId).find(
    exercise => exercise.order === order
  );

  if (exercise === undefined) {
    throw new Error(
      `Cannot find exercise #${order} in progression ${progressionId}`
    );
  }

  return exercise;
};

/**
 * Generically edit exercises within a specific progression. The progression
 * is marked as `dirty` in anticipation of the changes to exercises.
 */
const editExercises = (
  activityPlan: DraftActivityPlan,
  progressionId: string,
  fn: (exercises: DraftExercise[]) => DraftExercise[],
  substitutionFn?: (
    userSubstitutions: UserSubstitutionFragment[]
  ) => UserSubstitutionFragment[]
): DraftActivityPlan =>
  editProgression(activityPlan, progressionId, progression => ({
    ...progression,
    dirty: true,
    activities: progression.activities.map(activity => ({
      ...activity,
      data: {
        __typename: 'EtSessionActivityMetadata',
        exercises: fn(activity?.data?.exercises ?? []).sort(
          (a, b) => a.order - b.order
        ),
        userSubstitutions: substitutionFn
          ? substitutionFn(activity?.data?.userSubstitutions ?? [])
          : activity?.data?.userSubstitutions
      }
    }))
  }));

/**
 * Removes an exercise from a progression.
 */
export const removeExercise = (
  activityPlan: DraftActivityPlan,
  { progressionId, order }: ExerciseLocation
): DraftActivityPlan =>
  editExercises(
    activityPlan,
    progressionId,
    exercises =>
      exercises
        .filter(exercise => exercise.order !== order)
        .map(exercise => ({
          ...exercise,
          order: shiftOrderLeft(order, exercise.order)
        })),
    substitutions =>
      substitutions
        .filter(substitution => substitution.originalExercise.order !== order)
        .map(substitution => ({
          ...substitution,
          originalExercise: {
            ...substitution.originalExercise,
            order: shiftOrderLeft(order, substitution.originalExercise.order)
          }
        }))
  );

const getSubstitutionsFromProgression = (
  progression: DraftProgression
): UserSubstitutionFragment[] => {
  const substitutions =
    progression.activities.find(isEtSessionActivity)?.data?.userSubstitutions;

  if (!substitutions) return [];
  return substitutions;
};

export const getSubstitutions = (
  { progressions }: DraftActivityPlan,
  progressionId: string
): UserSubstitutionFragment[] => {
  const progression = progressions.find(
    progression => progression.uuid === progressionId
  );

  if (progression === undefined) {
    throw new Error(`Cannot find progression ${progressionId}`);
  }

  return getSubstitutionsFromProgression(progression);
};

export const getSubstitution = (
  activityPlan: DraftActivityPlan,
  { progressionId, order }: ExerciseLocation
): UserSubstitutionFragment | undefined => {
  const substitution = getSubstitutions(activityPlan, progressionId).find(
    substitution => substitution.originalExercise.order === order
  );

  return substitution;
};

/**
 * Adds an exercise to a progression.
 */
const addExercise = (
  activityPlan: DraftActivityPlan,
  progressionId: string,
  newExercise: DraftExercise,
  newSubstitution?: UserSubstitutionFragment
): DraftActivityPlan =>
  editExercises(
    activityPlan,
    progressionId,
    exercises => [
      ...exercises.map(exercise => ({
        ...exercise,
        order: shiftOrderRight(newExercise.order, exercise.order)
      })),
      newExercise
    ],
    substitutions => [
      ...substitutions.map(substitution => ({
        ...substitution,
        originalExercise: {
          ...substitution.originalExercise,
          order: shiftOrderRight(
            newExercise.order,
            substitution.originalExercise.order
          )
        }
      })),
      ...(newSubstitution ? [newSubstitution] : [])
    ]
  );

/**
 * Duplicates an exercise within a progression. The duplicated exercise
 * will be injected into the Progression directly after the original
 * exercise.
 */
export const duplicateExercise = (
  activityPlan: DraftActivityPlan,
  location: ExerciseLocation
): DraftActivityPlan => {
  const baseExercise = getExercise(activityPlan, location);
  return addExercise(activityPlan, location.progressionId, {
    ...baseExercise,
    order: baseExercise.order + 1
  });
};

/**
 * Append `Exercise` objects to the end of the Progression
 */
export const addExercises = (
  activityPlan: DraftActivityPlan,
  progressionId: string,
  newExercises: Exercise[]
): DraftActivityPlan => {
  const existingExercises = getExercises(activityPlan, progressionId);

  return (
    newExercises
      // New exercises are appended to the end of the progression
      .map((exercise, index) => ({
        ...exercise,
        order: existingExercises.length + index
      }))
      .reduce(
        (activityPlan, exercise) =>
          addExercise(activityPlan, progressionId, exercise),
        activityPlan
      )
  );
};

/**
 * Moves an exercise from one location to another.
 */
export const moveExercise = (
  activityPlan: DraftActivityPlan,
  { destination, source }: MoveExercise
): DraftActivityPlan => {
  const existingSubstitution = getSubstitution(activityPlan, source);
  let substitutionToMove: UserSubstitutionFragment | undefined;
  if (existingSubstitution) {
    substitutionToMove = {
      ...existingSubstitution,
      originalExercise: {
        ...existingSubstitution.originalExercise,
        order: destination.order
      }
    };
  }
  return addExercise(
    removeExercise(activityPlan, source),
    destination.progressionId,
    {
      ...getExercise(activityPlan, source),
      order: destination.order
    },
    substitutionToMove
  );
};

/**
 * Target a specific exercise for editing.
 */
const editExercise = (
  activityPlan: DraftActivityPlan,
  { progressionId, order }: ExerciseLocation,
  fn: (e: DraftExercise) => DraftExercise
): DraftActivityPlan =>
  editExercises(activityPlan, progressionId, exercises =>
    exercises.map(exercise =>
      exercise.order === order ? fn(exercise) : exercise
    )
  );

/**
 * Edit configurable details of an exercise.
 */
export const editExerciseDetails = (
  activityPlan: DraftActivityPlan,
  location: ExerciseLocation,
  exerciseEdits: EditableExercise
): DraftActivityPlan =>
  editExercise(activityPlan, location, exercise => ({
    ...exercise,
    ...exerciseEdits
  }));

/**
 * DATA CONVERSIONS
 */

type DuplicateProgressionInput = Pick<
  ProgressionDraftCreateInput,
  'etSession' | 'order'
>;
export const progressionToDuplicate = (
  progression: DraftProgression
): DuplicateProgressionInput => ({
  etSession: {
    exercises: getExercisesFromProgression(progression).map(exercise =>
      omit(exercise, [
        'numSteps',
        '__typename',
        'thumbnail',
        'repType',
        'anatomicalName',
        'numHoldMotions'
      ])
    )
  },
  order: progression.order + 1
});

/**
 * Fold progression additions and removals into a draft activity plan.
 */
export const mergeChangesIntoDirtyDraft = (
  activityPlan: DraftActivityPlanFragment,
  draft: DraftActivityPlan
): DraftActivityPlan => ({
  ...draft,
  progressions: [
    ...draft.progressions
      // Remove progressions that were deleted,
      .reduce((draftProgressions: DraftProgression[], draftProgression) => {
        const canonicalProgression = activityPlan.progressions.find(
          progression => progression.uuid === draftProgression.uuid
        );

        return [
          ...draftProgressions,
          ...(canonicalProgression
            ? // Update order for found progressions - removals change ordering
              [{ ...draftProgression, order: canonicalProgression.order }]
            : [])
        ];
      }, []),
    ...activityPlan.progressions
      // Include progressions that were added
      .filter(
        progression =>
          !draft.progressions.find(
            draftProgression => progression.uuid === draftProgression.uuid
          )
      )
      .map(progressionToDraft)
  ]
});

export const countDiffs = (
  plan: ActivityPlan,
  draft: DraftActivityPlan | ActivityPlanFragment
): number => {
  return plan.progressions.reduce((numDiffs, progression) => {
    const activity = progression.activities?.[0];
    const draftExercises = draft.progressions.find(
      p => p.order === progression.order
    )?.activities?.[0]?.data?.exercises;
    if (activity?.data?.exercises.length !== draftExercises?.length) {
      return numDiffs + 1;
    } else {
      const progressionDiffs = (activity?.data?.exercises ?? []).filter(
        exercise => {
          const draftExercise = draftExercises?.find(
            e => e.order === exercise.order
          );

          return draftExercise ? !isEqual(exercise, draftExercise) : true;
        }
      ).length;

      return numDiffs + (progressionDiffs > 0 ? 1 : 0);
    }
  }, 0);
};
