import React, { useCallback, useState } from 'react';
import { ExerciseLocation } from 'utils/draftActivityPlan';
import {
  EditExerciseModal,
  EditExerciseModalProps
} from '../EditExerciseModal';
import { ValidationFn } from '../ExerciseInput';

interface PerisurgicalExerciseValues {
  reps: number;
  holdTime: number;
  switchLimbTime: null | number;
}

export interface PerisurgicalEditExerciseModalProps
  extends Omit<EditExerciseModalProps, 'inputs'> {
  initialValues: null | PerisurgicalExerciseValues;
}

const validations: ValidationFn[] = [
  value => ({ isValid: value > 0, reason: 'must be greater than 0' }),
  value => ({ isValid: value < 100, reason: 'must be less than 100' })
];

const valuesToInputs = ({
  reps,
  holdTime,
  switchLimbTime
}: PerisurgicalExerciseValues): EditExerciseModalProps['inputs'] => ({
  reps: {
    label: 'Reps',
    initialValue: reps,
    validations
  },
  holdTime: {
    label: 'Hold Time',
    unit: 'sec',
    initialValue: holdTime / 1000,
    validations
  },
  // When switchLimbTime is null, we have a one-sided exercise
  ...(switchLimbTime !== null
    ? {
        switchLimbTime: {
          label: 'Switch Limb Time',
          unit: 'sec',
          initialValue: switchLimbTime / 1000,
          validations
        }
      }
    : null)
});

export const PerisurgicalEditExerciseModal = ({
  name,
  open,
  initialValues,
  onSubmit,
  onCancel
}: PerisurgicalEditExerciseModalProps): JSX.Element => (
  <EditExerciseModal
    name={name}
    open={open}
    onCancel={onCancel}
    onSubmit={values => {
      onSubmit({
        ...values,
        ...(values.holdTime ? { holdTime: values.holdTime * 1000 } : {}),
        ...(values.switchLimbTime
          ? { switchLimbTime: values.switchLimbTime * 1000 }
          : {})
      });
    }}
    inputs={initialValues !== null ? valuesToInputs(initialValues) : {}}
  />
);

export interface ExerciseData {
  name: string;
  reps: number;
  holdTime: number;
  switchLimbTime: null | number;
}

interface EditExerciseModalState {
  name: string;
  location: ExerciseLocation;
  initialValues: Omit<ExerciseData, 'name'>;
}

export interface UseEditExerciseModal {
  open: (location: ExerciseLocation, exercise: ExerciseData) => void;
  close: () => void;
  modal: EditExerciseModalState | null;
}

export const usePerisurgicalEditExerciseModal = (): UseEditExerciseModal => {
  const [modal, setModal] = useState<null | EditExerciseModalState>(null);

  return {
    open: useCallback(
      (location: ExerciseLocation, exercise: ExerciseData) =>
        setModal({
          name: exercise.name,
          location,
          initialValues: {
            reps: exercise.reps,
            holdTime: exercise.holdTime,
            switchLimbTime: exercise.switchLimbTime ?? null
          }
        }),
      []
    ),
    close: useCallback(() => setModal(null), []),
    modal
  };
};
