import { Redirect, RouteComponentProps } from '@reach/router';
import { userAuthState } from 'apollo/reactive-variables/userAuthState';
import GoogleOAuth from 'components/GoogleOAuth';
import VerticalLogo from 'components/VerticalLogo';
import React from 'react';
import styled from 'styled-components';

const Layout = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
`;

const Header = styled.header`
  ${({ theme }) => `
    width: ${theme.spacing(90)};
    height: ${theme.spacing(90)};
    display: flex;
    flex-direction: column;
    justify-content: center;
  `}
`;

const Login: React.FC<RouteComponentProps> = () =>
  !userAuthState().accessToken ? (
    <Layout>
      <Header>
        <VerticalLogo />
      </Header>
      <GoogleOAuth />
    </Layout>
  ) : (
    <Redirect to="/" noThrow replace />
  );

export default Login;
