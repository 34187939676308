import { IconButton, Tooltip, TooltipProps } from '@material-ui/core';
import RestoreIcon from '@material-ui/icons/Restore';
import React from 'react';

interface Props {
  disabled?: boolean;
  title: string;
  placement: TooltipProps['placement'];
  handlePublish: ({ resetStartDate }: { resetStartDate: boolean }) => void;
  confirmationMessage?: () => boolean;
}

export const ResetButtonIcon = ({
  disabled,
  title,
  placement,
  handlePublish,
  confirmationMessage = () => true
}: Props): JSX.Element => {
  return (
    <Tooltip title={title} placement={placement}>
      <span>
        <IconButton
          disabled={disabled}
          aria-label={title}
          onClick={async () =>
            confirmationMessage() && handlePublish({ resetStartDate: true })
          }
        >
          <RestoreIcon
            color={disabled ? 'disabled' : 'inherit'}
            htmlColor="rgba(0,0,0,0.7)"
          />
        </IconButton>
      </span>
    </Tooltip>
  );
};
