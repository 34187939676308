import { Button } from '@material-ui/core';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { ActivityPlanToolbar } from 'components/activity-plan/ActivityPlanToolbar';
import { DiscardDraftButton } from 'components/activity-plan/DiscardDraftButton';
import { UserHeader } from 'components/activity-plan/UserHeader';
import { CopyToClipboardButtonIcon } from 'components/CopyToClipboardButtonIcon';
import { DownloadPDFButtonIcon } from 'components/DownloadPDFButtonIcon';
import { ActivityPlanPageLayout } from 'components/layout/ActivityPlanPageLayout';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { UserPDFDocument } from 'components/PDFDocuments/UserPDFDocumentSwitch';
import { AcuteActivityPlan } from 'connected/activity-plan/AcuteActivityPlan';
import { ChronicActivityPlan } from 'connected/activity-plan/ChronicActivityPlan';
import { PerisurgicalActivityPlan } from 'connected/activity-plan/PerisurgicalActivityPlan';
import { useCopyPlanToClipboard } from 'hooks/useCopyPlanToClipboard';
import { useGetUser } from 'hooks/useGetUser';
import {
  ActivityPlanProvider,
  useActivityPlan
} from 'providers/ActivityPlanProvider';
import {
  DraftActivityPlanProvider,
  useDraftActivityPlan,
  useDraftActivityPlanAPI
} from 'providers/DraftActivityPlanProvider';
import React from 'react';
import styled from 'styled-components';
import { findCurrentProgressionNum, isSupportedPlan } from 'utils/activityPlan';
import { extractProgramInfoFromUri } from 'utils/extractProgramInfoFromUri';
import { Programs } from 'utils/programConstants';
import { useSharedFeatureFlag } from '../../hooks/useSharedFeatureFlag';

interface Props extends RouteComponentProps {
  id?: string;
  uuid?: string;
}

const EditButton = styled(Button)`
  height: 36px;
`;

const ActivityPlanView = ({
  program,
  userLevel
}: {
  program: Programs;
  userLevel: number | null | undefined;
}): JSX.Element => {
  switch (program) {
    case Programs.CHRONIC:
      return <ChronicActivityPlan userLevel={userLevel} />;
    case Programs.PERISURGICAL:
      return <PerisurgicalActivityPlan />;
    default:
      return <AcuteActivityPlan />;
  }
};

const UserDetailBody = ({
  userId,
  onEdit
}: {
  userId: string;
  onEdit: () => void;
}): JSX.Element => {
  const { loading, activityPlan } = useActivityPlan();
  const userLevelData = useGetUser(userId);

  const navigate = useNavigate();
  const {
    draft,
    commitInProcess: publishInProcess,
    saveInProcess
  } = useDraftActivityPlan();
  const { discard } = useDraftActivityPlanAPI();
  const { treatment: isTimeBasedUser } = useSharedFeatureFlag(
    'enableTimeBasedExercises',
    userId
  );

  const disableToolbarButtons = !draft || saveInProcess || publishInProcess;

  const { canCopyToClipboard, handleCopyExercisesToClipboard } =
    useCopyPlanToClipboard(activityPlan?.progressions);

  if (loading || !activityPlan) {
    return <LoadingSpinner />;
  }

  const { program, indication } = extractProgramInfoFromUri(activityPlan.uri);
  const { progressions } = activityPlan;

  const userLevel = userLevelData ? userLevelData.userGet.level : null;
  const currentProgressionNum = userLevel
    ? userLevel
    : findCurrentProgressionNum(progressions);

  const handleDiscard = async (): Promise<void> => {
    const didDiscard = await discard({ userId });
    if (didDiscard) {
      navigate(`/users/${userId}`);
      snackbarState({
        type: 'generic',
        severity: 'success',
        message: 'All changes have been discarded!'
      });
    } else {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: 'Unable to discard changes',
        message: 'Try again'
      });
    }
  };

  return isSupportedPlan(program) ? (
    <ActivityPlanPageLayout
      stickyToolbar={
        <ActivityPlanToolbar
          actionButtons={
            <>
              <DiscardDraftButton
                onClick={handleDiscard}
                disabled={disableToolbarButtons}
              />
              <EditButton
                variant="contained"
                color="primary"
                data-testid="editBaseActivityPlanButton"
                onClick={onEdit}
                disabled={
                  !!(
                    activityPlan.draft &&
                    !activityPlan.draft.isOwnedByCurrentAdmin
                  )
                }
              >
                EDIT
              </EditButton>
            </>
          }
        >
          <DownloadPDFButtonIcon
            testId="userActivityPlanViewDownloadIcon"
            title="Download PDF"
            placement="bottom-start"
            document={
              <UserPDFDocument activityPlan={activityPlan} userId={userId} />
            }
            fileName={`${userId}-${program}-${indication}`}
          />
          <CopyToClipboardButtonIcon
            disabled={!canCopyToClipboard}
            onClick={handleCopyExercisesToClipboard}
            testId="userActivityPlanViewCopyIcon"
            title={
              canCopyToClipboard
                ? 'Copy all activity plan exercises'
                : 'Copy all exercises disabled'
            }
            placement="bottom-start"
          />
        </ActivityPlanToolbar>
      }
      header={
        <UserHeader
          viewOnly
          userId={userId}
          isTimeBasedUser={isTimeBasedUser}
          activityPlan={activityPlan}
          currentProgressionNum={currentProgressionNum}
          isBeta={Boolean(activityPlan.isBeta)}
        />
      }
      testId="userActivityPlanViewPage"
    >
      <ActivityPlanView program={program} userLevel={userLevel} />
    </ActivityPlanPageLayout>
  ) : (
    <div>
      The user is assigned {program} program and it is not supported in
      configurator
    </div>
  );
};

export default function UserDetail({
  id: userId = '',
  uuid = '',
  navigate
}: Props): JSX.Element {
  return (
    <ActivityPlanProvider
      uuid={uuid}
      userId={userId}
      onError={err => {
        throw new Error(
          `Unable to view user's (id=${userId}) Activity Plan (${err})`
        );
      }}
    >
      <DraftActivityPlanProvider>
        <UserDetailBody userId={userId} onEdit={() => navigate?.('edit')} />
      </DraftActivityPlanProvider>
    </ActivityPlanProvider>
  );
}
