import { useChangeDetector } from 'hooks/useChangeDetector';
import React, { useEffect, useState } from 'react';
import { RepType } from 'types';
import { ExerciseChip } from './ExerciseChip';
import {
  ExerciseInput,
  ExerciseInputHandler,
  ExerciseInputProps
} from './ExerciseInput';

export interface EditableExerciseChipProps {
  label: string;
  value: number;
  repType?: RepType | null;
  validations: ExerciseInputProps['validations'];
  /**
   * Runs when validations pass as the EditableExerciseChip transitions
   * from an `isEditing` state to `!isEditing`
   */
  onEdit: (value: number) => void;
  /**
   * Runs when validations fails as the EditableExerciseChip attempts
   * to transition from an `isEditing` state to `!isEditing`.
   */
  onInvalid?: (value: number, reasons: string[]) => void;
}

/**
 * A single piece of editable information about an exercise.
 */
export const EditableExerciseChip = ({
  label,
  value,
  onEdit,
  onInvalid,
  validations,
  repType
}: EditableExerciseChipProps): JSX.Element => {
  const [editing, setIsEditing] = useState<boolean>(false);
  const [localValue, setLocalValue] = useState<number>(value);
  const didValueChange = useChangeDetector(value);
  useEffect(() => {
    if (didValueChange(value)) {
      setLocalValue(value);
    }
  }, [didValueChange, value]);

  const onSubmit: ExerciseInputHandler = (value, { isInvalid, reasons }) => {
    if (isInvalid) {
      onInvalid?.(value, reasons);
    } else {
      setIsEditing(false);
      setLocalValue(value);
      onEdit(value);
    }
  };

  return editing ? (
    <ExerciseInput
      name={label}
      unit={label}
      value={localValue}
      validations={validations}
      onFocus={event => {
        event.target.select();
      }}
      onEnter={onSubmit}
      onBlur={onSubmit}
      repType={repType}
    />
  ) : (
    <ExerciseChip
      label={label}
      value={localValue}
      onClick={() => {
        setIsEditing(true);
      }}
      repType={repType}
    />
  );
};
