import { useReactiveVar } from '@apollo/client';
import { Button, Snackbar as Snacks } from '@material-ui/core';
import { Alert, AlertTitle as MuiAlertTitle } from '@material-ui/lab';
import {
  globalHistory,
  HistoryListenerParameter,
  HistoryUnsubscribe
} from '@reach/router';
import { Event, useMixpanel } from 'hooks/useMixpanel';
import { useRefreshContext } from 'providers/RefreshProvider';
import React, { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { snackbarState } from '../apollo/reactive-variables/snackbarState';

const MuiSnackbar = styled(Snacks)`
  top: 104px;
`;

const AlertTitle = styled(MuiAlertTitle)`
  font-size: 16px;
  font-weight: bold;
`;

interface SnackbarBaseProps {
  open: boolean;
  severity?: 'success' | 'error' | 'info' | 'warning';
  title?: string;
  message?: string;
  autoHide: boolean;
  autoHideTimeout?: number;
  onClose: () => void;
  action?: React.ReactNode;
  myKey?: string;
}

const SnackbarBase = ({
  open,
  severity,
  title,
  message,
  action,
  autoHide,
  autoHideTimeout = 4000,
  onClose,
  myKey
}: SnackbarBaseProps): JSX.Element => {
  const { trackEvent } = useMixpanel();
  useEffect(() => {
    if (severity === 'error') {
      const reason = `${title}, ${message}`;
      trackEvent(Event.SNACKBAR_ERROR, {
        reason
      });
    }
  }, [severity, trackEvent, title, message]);

  const handleClose = (
    _event?: React.SyntheticEvent,
    reason?: string
  ): void => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <MuiSnackbar
      key={myKey ?? `${title}-${Math.random()}`}
      open={open}
      autoHideDuration={autoHide ? autoHideTimeout : null}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      onClose={handleClose}
      onExited={onClose}
      data-testid="snackbar"
    >
      <Alert
        elevation={6}
        severity={severity}
        variant="filled"
        onClose={severity === 'error' ? handleClose : undefined}
        data-testid={`${severity}Alert`}
        action={action}
      >
        {title && <AlertTitle>{title}</AlertTitle>}
        {message}
      </Alert>
    </MuiSnackbar>
  );
};

const GenericSnackbar = ({
  onClose,
  ...props
}: SnackbarBaseProps): JSX.Element => {
  const historyUnsubscribe: HistoryUnsubscribe = useMemo(
    () =>
      globalHistory.listen(({ action }: HistoryListenerParameter): void => {
        if (action === 'PUSH') {
          onClose();
        }
      }),
    [onClose]
  );

  useEffect(() => historyUnsubscribe, [historyUnsubscribe]);

  return <SnackbarBase {...props} onClose={onClose} />;
};

const expiringSoonTimer = (
  seconds: number,
  onTick: (seconds: number) => void
): (() => void) => {
  let secondsLeft = seconds;
  const interval = setInterval(() => {
    onTick(--secondsLeft);
    if (secondsLeft === 0) {
      clearInterval(interval);
    }
  }, 1000);

  return () => clearInterval(interval);
};

interface IdleSnackbarProps
  extends Pick<SnackbarBaseProps, 'onClose' | 'open'> {
  secondsLeft: number;
}
const IdleSnackbar = ({
  onClose,
  open,
  secondsLeft
}: IdleSnackbarProps): JSX.Element | null => {
  const [seconds, setSeconds] = useState<number>(secondsLeft);
  const { extendSession } = useRefreshContext();

  const onExtendSession = (): void => {
    extendSession();
    onClose();
  };

  useEffect(() => {
    const clearTimer = expiringSoonTimer(secondsLeft, setSeconds);
    return clearTimer;
  }, [secondsLeft]);

  return seconds === 0 ? null : (
    <SnackbarBase
      myKey="idle-snack"
      open={open}
      autoHide={false}
      onClose={onClose}
      message={`You will lose unsaved changes in ${seconds} seconds`}
      title="Your session is about to expire"
      severity="warning"
      action={
        <Button color="inherit" onClick={onExtendSession}>
          Stay Signed In
        </Button>
      }
    />
  );
};

type ExpiredSnackbarProps = Pick<SnackbarBaseProps, 'onClose' | 'open'>;
const ExpiredSnackbar = ({
  onClose,
  open
}: ExpiredSnackbarProps): JSX.Element => (
  <SnackbarBase
    open={open}
    autoHide={false}
    onClose={onClose}
    message="Please sign back in"
    title="Your session expired"
    severity="error"
  />
);

export default function Snackbar(): JSX.Element {
  const [open, setOpen] = useState(false);
  const snack = useReactiveVar(snackbarState);

  useEffect(() => {
    if (snack) {
      setOpen(true);
    }
  }, [snack]);

  const onClose = (): void => {
    setOpen(false);
  };

  switch (snack?.type) {
    case 'idle':
      return (
        <IdleSnackbar
          onClose={onClose}
          open={open}
          secondsLeft={snack.secondsLeft}
        />
      );
    case 'expired':
      return <ExpiredSnackbar onClose={onClose} open={open} />;
    case 'generic':
      return (
        <GenericSnackbar
          open={open}
          autoHide={snack?.severity !== 'error'}
          autoHideTimeout={4000}
          onClose={onClose}
          severity={snack?.severity}
          title={snack?.title}
          message={snack?.message}
        />
      );
    default:
      return <></>;
  }
}
