import React from 'react';

interface Props {
  textColor?: string;
  width?: number;
}

const Logo = ({ textColor = '#42484c', width = 156 }: Props): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 358.21 90"
    width={width}
    aria-labelledby="hingeHealthLogo"
    role="img"
  >
    <title id="hingeHealthLogo">Hinge Health logo</title>
    <rect
      x="24.56"
      y="24.53"
      width="40.88"
      height="40.94"
      rx="4.66"
      transform="rotate(-45 45 45)"
      fill="#ff6b31"
    />
    <path
      fill="#fff"
      d="M51.61 30.07v11.19H38.44V30.07H31v29.86h7.44V48.24h13.17v11.69H59V30.07h-7.39z"
    />
    <g fill={textColor}>
      <path d="M90 30.32a.87.87 0 01.84-.84H95a.85.85 0 01.84.84V42h14.42V30.32a.85.85 0 01.84-.84h4.07a.87.87 0 01.84.84V59.6a.87.87 0 01-.84.84h-4.07a.85.85 0 01-.84-.84V47.39H95.79V59.6a.85.85 0 01-.84.84h-4.11a.87.87 0 01-.84-.84zM121.31 33a3.52 3.52 0 110 .75 3.14 3.14 0 010-.75zm.8 8a1 1 0 011-1h3.58a1 1 0 011 1v18.47a1 1 0 01-1 1h-3.58a1 1 0 01-1-1zM133.3 41a1 1 0 011-1h1.7a.78.78 0 01.84.62l.75 1.9a8.29 8.29 0 016.59-3c6.51 0 8.19 4.37 8.19 9.33v10.62a1 1 0 01-1 1h-3.58a1 1 0 01-1-1V48.9c0-2.66-1.06-4.29-3.54-4.29a4.5 4.5 0 00-4.42 3.18v11.68c0 .75-.31 1-1.29 1h-3.27a1 1 0 01-1-1zM177.08 60.66c0 6.59-4.6 9.91-10.17 9.91a13.52 13.52 0 01-7.34-2.3 1 1 0 01-.22-1.15l1.33-2.88a.82.82 0 011.14-.29h.05a10.24 10.24 0 004.86 1.55c3.15 0 4.83-1.06 4.83-4.87v-.57a15.21 15.21 0 01-4.91.84c-6.11 0-10.13-4.69-10.13-10.66 0-6.19 4.2-10.7 9.77-10.7a8.81 8.81 0 016.68 3l.53-1.54c.18-.57.49-1 1-1h1.64a1 1 0 011 1zm-5.52-13.22a4.71 4.71 0 00-4.56-2.83c-3.18 0-4.82 2.78-4.82 5.66s1.73 5.62 5.09 5.62a8 8 0 004.25-1.15zM191.81 39.57a9.16 9.16 0 019.38 8.93v.5c0 .17-.05 1-.09 1.33a1 1 0 01-1 .93H187a4.9 4.9 0 005.05 4.74h.07a6.71 6.71 0 004.38-1.51c.49-.39 1-.44 1.33 0l1.72 2.3a.86.86 0 010 1.22.69.69 0 01-.13.11 11.51 11.51 0 01-7.6 2.79c-6.11 0-10.35-4.87-10.35-10.71a10.33 10.33 0 0110-10.63zm3.81 8.09a3.74 3.74 0 00-3.93-3.54 4.06 4.06 0 00-4.34 3.54z" />
    </g>
    <g fill={textColor}>
      <path d="M213.23 30.32a.87.87 0 01.84-.84h4.11a.85.85 0 01.84.84V42h14.42V30.32a.85.85 0 01.84-.84h4.07a.87.87 0 01.84.84V59.6a.87.87 0 01-.84.84h-4.07a.85.85 0 01-.84-.84V47.39H219V59.6a.85.85 0 01-.84.84h-4.11a.87.87 0 01-.84-.84zM254.05 39.57a9.16 9.16 0 019.38 8.93v.5c0 .17-.05 1-.09 1.33a1 1 0 01-1 .93h-13.06a4.9 4.9 0 005.05 4.74 6.71 6.71 0 004.38-1.51c.49-.39 1-.44 1.33 0l1.72 2.3a.86.86 0 010 1.22.69.69 0 01-.13.11 11.51 11.51 0 01-7.6 2.79c-6.11 0-10.35-4.87-10.35-10.71a10.33 10.33 0 0110-10.63zm3.81 8.09a3.74 3.74 0 00-3.93-3.54 4 4 0 00-4.33 3.54zM287 59.47a1 1 0 01-1 1h-2c-.49 0-.8-.4-1-1l-.53-1.59a8.27 8.27 0 01-6.51 3c-5.57 0-9.68-4.87-9.68-10.71s4.11-10.65 9.73-10.65a8.5 8.5 0 016.41 2.69l.71-1.63A.85.85 0 01284 40h2a1 1 0 011 1zm-5.62-12.08a4.63 4.63 0 00-4.38-2.78c-3.31 0-5 2.56-5 5.66s1.73 5.62 4.91 5.62a4.5 4.5 0 004.55-2.84zM292.71 30.45a1 1 0 011-1h3.58a1.06 1.06 0 011 1v29a1 1 0 01-1 1h-3.58a1 1 0 01-1-1zM305.31 45h-2a1 1 0 01-.93-1v-3a.93.93 0 01.84-1h2.09v-5.65a1 1 0 011-1h3.62a1 1 0 01.93 1V40h4.78a1 1 0 011 1v3a1 1 0 01-1 1h-4.78v9.2c0 1.37.66 1.68 1.68 1.68a13.59 13.59 0 003.1-.66.78.78 0 011.07.32.76.76 0 01.08.21l.57 2.7a1 1 0 01-.57 1.19 16.25 16.25 0 01-5.79 1.29c-4.56 0-5.71-3-5.71-6.59zM321.19 30.45a1.06 1.06 0 011-1h3.59a1 1 0 011 1v10.84a8 8 0 015.22-1.72c6.5 0 8.27 4.51 8.27 9.64v10.26a1 1 0 01-1 1h-3.59a1 1 0 01-1-1V49.16c0-2.83-1.28-4.55-3.71-4.55s-3.72 1.5-4.25 3.58v11.28c0 .57-.27 1-1.06 1h-3.5a1 1 0 01-1-1z" />
    </g>
  </svg>
);

export default Logo;
