import { AcuteActivityPlan as AcuteActivityPlanUI } from 'components/activity-plan/acute/AcuteActivityPlan';
import { useActivityPlan } from 'providers/ActivityPlanProvider';
import React from 'react';
import {
  copyAllExercises,
  copyUniqueExercises
} from 'utils/copyExercisesToClipboard';

export const AcuteActivityPlan = (): JSX.Element | null => {
  const { activityPlan } = useActivityPlan();

  return activityPlan ? (
    <AcuteActivityPlanUI
      activityPlan={activityPlan}
      onCopyAllExercises={copyAllExercises}
      onCopyUniqueExercises={copyUniqueExercises}
    />
  ) : null;
};
