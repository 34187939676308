import { safeUseContext } from 'hooks/safeUseContext';
import { useChangeDetector } from 'hooks/useChangeDetector';
import { useMutateBetas } from 'hooks/useMutateBetas';
import { useMutateDraft } from 'hooks/useMutateDraft';
import { useMutateProgression } from 'hooks/useMutateProgression';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Exercise, Progression } from 'types/activityPlan';
import { DraftActivityPlan, EditableExercise } from 'types/draftActivityPlan';
import {
  activityPlanToDraft,
  addExercises,
  cleanProgressions,
  draftToProgressionDraftUpdateInput,
  duplicateExercise,
  editExerciseDetails,
  ExerciseLocation,
  isDraftSaveable,
  mergeChangesIntoDirtyDraft,
  MoveExercise,
  moveExercise,
  MoveProgression,
  moveProgression,
  progressionToDraft,
  progressionToDuplicate,
  removeExercise
} from 'utils/draftActivityPlan';
import {
  commitBatchErrorMessage,
  formattedCommitErrorResponse,
  formattedSaveErrorResponse,
  genericCommitErrorMessage,
  genericSaveErrorMessage,
  handleDraftErrorMessage
} from 'utils/errorFormatter';
import { Programs } from 'utils/programConstants';
import { addLifecycleBreadcrumb, Severity } from 'utils/sentry';
import { useActivityPlan } from './ActivityPlanProvider';

export enum DraftStatuses {
  IDLE = 'idle',
  SAVING = 'saving',
  DISCARDING = 'discarding',
  COMMITTING = 'committing'
}

type IsProcessingBooleans =
  | 'isDiscarding'
  | 'isCommitting'
  | 'isCommittingBetas'
  | 'isDiscardingBetaDrafts';

const booleansToAutosaveStatus = ({
  isDiscarding,
  isCommitting,
  isDiscardingBetaDrafts,
  isCommittingBetas
}: Record<IsProcessingBooleans, boolean>): DraftStatuses => {
  if (isDiscarding || isDiscardingBetaDrafts) {
    return DraftStatuses.DISCARDING;
  } else if (isCommitting || isCommittingBetas) {
    return DraftStatuses.COMMITTING;
  } else {
    return DraftStatuses.IDLE;
  }
};

interface DraftActivityPlanContextType {
  draft?: DraftActivityPlan;
  status: DraftStatuses;
  saveInProcess: boolean;
  commitInProcess: boolean;
  isDirty: boolean;
}

const DraftActivityPlanContext = React.createContext<
  DraftActivityPlanContextType | undefined
>(undefined);

export const useDraftActivityPlan = safeUseContext(
  DraftActivityPlanContext,
  'DraftActivityPlanContext'
);

export interface DraftActivityPlanAPI {
  addProgression: () => void;
  deleteProgression: (progressionUuid: string) => void;
  duplicateProgression: (progression: Progression) => void;
  moveProgression: (move: MoveProgression) => void;
  moveExercise: (move: MoveExercise) => void;
  removeExercise: (location: ExerciseLocation) => void;
  duplicateExercise: (location: ExerciseLocation) => void;
  editExerciseDetails: (
    location: ExerciseLocation,
    edits: EditableExercise
  ) => void;
  addExercises: (progressionId: string, newExercises: Exercise[]) => void;
  commit: (options?: {
    userId?: string;
    resetStartDate?: boolean;
    onNotSaveable: () => void;
  }) => Promise<{
    didCommit: boolean;
    activityPlanUuid?: string;
    errorMessage?: string;
  }>;
  save: () => Promise<{ status: boolean; errorMessage?: string }>;
  discard: (options?: { userId?: string }) => Promise<boolean>;
  batchDeleteBetaDrafts: () => Promise<boolean>;
  batchCommitBetas: () => Promise<{
    didCommit: boolean;
    activityPlanUuid?: string;
    errorMessage?: string;
  }>;
  forceAutosave: () => Promise<void>; // This had to be cleared
}

const DraftActivityPlanAPIContext = React.createContext<
  DraftActivityPlanAPI | undefined
>(undefined);

export const useDraftActivityPlanAPI = safeUseContext(
  DraftActivityPlanAPIContext,
  'DraftActivityPlanAPIContext'
);

export const DraftActivityPlanProvider = ({
  children,
  indication
}: {
  children: React.ReactNode;
  indication?: string;
}): JSX.Element => {
  const { activityPlan, betaConfigs } = useActivityPlan();
  const didActivityPlanChange = useChangeDetector(activityPlan);
  const [draft, setDraft] = useState<DraftActivityPlan>();
  const {
    saveDraft,
    saveInProcess,
    commitDraft,
    commitInProcess,
    deleteDraft,
    deleteInProcess
  } = useMutateDraft();
  const {
    deleteDrafts: deleteBetaDrafts,
    commitDrafts: commitBetaDrafts,
    commitBetasInProcess,
    deleteBetaDraftsInProcess
  } = useMutateBetas(betaConfigs);
  const { addProgression, deleteProgression } = useMutateProgression();

  const isDirty = !!draft?.progressions?.some(progression => progression.dirty);

  useEffect(() => {
    if (didActivityPlanChange(activityPlan) && activityPlan?.draft) {
      if (draft && isDirty) {
        setDraft(mergeChangesIntoDirtyDraft(activityPlan?.draft, draft));
      } else {
        setDraft(activityPlanToDraft(activityPlan?.draft));
      }
    }
  }, [didActivityPlanChange, activityPlan, isDirty, draft]);

  const save = useCallback(async () => {
    if (activityPlan && draft && isDirty) {
      if (!isDraftSaveable(draft))
        return formattedSaveErrorResponse(false, genericSaveErrorMessage);

      addLifecycleBreadcrumb({
        level: Severity.Info,
        message: 'Saving draft',
        data: {
          activityPlanUuid: activityPlan.uuid,
          draftUuid: draft.uuid
        }
      });

      const result = await saveDraft(
        draftToProgressionDraftUpdateInput(activityPlan?.uuid, draft)
      );

      addLifecycleBreadcrumb({
        level: Severity.Info,
        message: 'Saved draft',
        data: {
          activityPlanUuid: activityPlan.uuid,
          draftUuid: draft.uuid,
          ...result
        }
      });

      // useMutation functions may return undefined or result.errors when an error occurs
      if (result && !result.errors) {
        setDraft(cleanProgressions(draft));
        return formattedSaveErrorResponse(true);
      }
      const errors = result && result.errors ? result.errors : null;
      const errorMessage = errors
        ? handleDraftErrorMessage(errors, 'save')
        : genericSaveErrorMessage;

      return formattedSaveErrorResponse(false, errorMessage);
    }
    return formattedSaveErrorResponse(false, genericSaveErrorMessage);
  }, [activityPlan, draft, isDirty, saveDraft]);

  const value = useMemo(
    () => ({
      draft,
      saveInProcess: saveInProcess,
      commitInProcess,
      isDirty,
      status: booleansToAutosaveStatus({
        isCommitting: commitInProcess,
        isDiscarding: deleteInProcess,
        isDiscardingBetaDrafts: deleteBetaDraftsInProcess,
        isCommittingBetas: commitBetasInProcess
      })
    }),
    [
      draft,
      isDirty,
      saveInProcess,
      commitInProcess,
      deleteInProcess,
      deleteBetaDraftsInProcess,
      commitBetasInProcess
    ]
  );

  const commit = useCallback(
    async ({
      userId,
      resetStartDate = false,
      onNotSaveable
    }: {
      userId?: string;
      onNotSaveable?: () => void;
      resetStartDate?: boolean;
    } = {}) => {
      if (activityPlan && draft) {
        // No committing when there are zero exercises in activity plan
        if (!isDraftSaveable(draft)) {
          onNotSaveable?.();
          formattedCommitErrorResponse(false, genericCommitErrorMessage);
        }

        const commitCleanDraft = async (): Promise<{
          didCommit: boolean;
          activityPlanUuid?: string;
          errorMessage?: string;
        }> => {
          addLifecycleBreadcrumb({
            level: Severity.Info,
            message: 'Committing draft',
            data: {
              activityPlanUuid: activityPlan.uuid,
              draftUuid: draft.uuid
            }
          });

          const result = await commitDraft(
            {
              draftUuid: draft.uuid,
              activityPlanUuid: activityPlan.uuid,
              userUuid: activityPlan.userUuid,
              resetStartDate: !!activityPlan.userUuid && resetStartDate // Only user activity plans should have reset feature
            },
            { userId }
          );

          addLifecycleBreadcrumb({
            level: Severity.Info,
            message: 'Committed draft',
            data: {
              activityPlanUuid: activityPlan.uuid,
              draftUuid: draft.uuid,
              ...result
            }
          });

          // useMutation functions may return undefined or result.errors when an error occurs
          if (result && !result.errors) {
            return formattedCommitErrorResponse(
              true,
              undefined,
              result?.data?.activityPlanDraftCommit.activityPlan.uuid
            );
          }
          const errors = result && result.errors ? result.errors : null;
          const errorMessage = errors
            ? handleDraftErrorMessage(errors, 'publish')
            : genericCommitErrorMessage;
          return formattedCommitErrorResponse(false, errorMessage);
        };

        // We are first checking if there is are any changes
        // If yes, we save the draft first
        if (isDirty) {
          await save();
        }
        return commitCleanDraft();
      }
      return formattedCommitErrorResponse(false, genericCommitErrorMessage);
    },
    [activityPlan, commitDraft, draft, isDirty, save]
  );

  const discard = useCallback(
    async ({ userId }: { userId?: string } = {}) => {
      if (activityPlan && draft) {
        addLifecycleBreadcrumb({
          level: Severity.Info,
          message: 'Deleting draft',
          data: {
            activityPlanUuid: activityPlan.uuid,
            draftUuid: draft.uuid
          }
        });

        const result = await deleteDraft(
          {
            draftUuid: draft.uuid,
            activityPlanUuid: activityPlan.uuid,
            userUuid: activityPlan.userUuid
          },
          { userId }
        );

        addLifecycleBreadcrumb({
          level: Severity.Info,
          message: 'Deleted draft',
          data: {
            activityPlanUuid: activityPlan.uuid,
            draftUuid: draft.uuid,
            ...result
          }
        });

        if (result && !result.errors) {
          return true;
        }
      }
      return false;
    },
    [activityPlan, draft, deleteDraft]
  );

  const batchDeleteBetaDrafts = useCallback(async () => {
    if (activityPlan && draft) {
      addLifecycleBreadcrumb({
        level: Severity.Info,
        message: 'Batch deleting beta drafts',
        data: {
          indication
        }
      });

      if (indication) {
        const result = await deleteBetaDrafts({
          program: Programs.CHRONIC,
          indication
        });

        addLifecycleBreadcrumb({
          level: Severity.Info,
          message: 'Deleted beta drafts',
          data: {
            indication
          }
        });

        if (result && !result.errors) {
          return true;
        }
      }
    }
    return false;
  }, [indication, activityPlan, draft, deleteBetaDrafts]);

  const batchCommitBetas = useCallback(
    async (onNotSaveable?: () => void) => {
      if (draft) {
        if (!isDraftSaveable(draft)) {
          onNotSaveable?.();
          return formattedCommitErrorResponse(false, genericCommitErrorMessage);
        }

        if (isDirty) {
          await save();
        }

        addLifecycleBreadcrumb({
          level: Severity.Info,
          message: 'Batch committing beta drafts',
          data: {
            indication
          }
        });

        if (indication) {
          const result = await commitBetaDrafts({
            program: Programs.CHRONIC,
            indication
          });

          addLifecycleBreadcrumb({
            level: Severity.Info,
            message: 'Committed drafts to betas',
            data: {
              indication
            }
          });

          if (result && !result.errors) {
            return formattedCommitErrorResponse(true);
          }
          const errors = result && result.errors ? result.errors : null;
          const action = 'publish';
          const errorMessage = errors
            ? handleDraftErrorMessage(errors, action)
            : genericCommitErrorMessage;
          const batchCommitErrorMessage = commitBatchErrorMessage(
            errorMessage,
            action
          );
          return formattedCommitErrorResponse(false, batchCommitErrorMessage);
        }
      }

      return formattedCommitErrorResponse(false, genericCommitErrorMessage);
    },
    [indication, commitBetaDrafts, draft, isDirty, save]
  );

  const confirmationMessage = (activity: string): boolean => {
    return window.confirm(
      `Any changes will be saved and week will be ${activity}.`
    );
  };

  const api = useMemo(
    () => ({
      commit,
      discard,
      batchDeleteBetaDrafts,
      batchCommitBetas,
      save,
      forceAutosave: async () => {
        if (activityPlan && draft && isDirty) {
          await save();
        }
      },
      addProgression: async () => {
        if (activityPlan && draft && confirmationMessage('added')) {
          if (isDirty) {
            await save();
          }
          addProgression({
            activityPlanUuid: activityPlan.uuid,
            userUuid: activityPlan.userUuid
          });
        }
      },
      deleteProgression: async (progressionUuid: string) => {
        if (activityPlan && draft && confirmationMessage('deleted')) {
          if (isDirty) {
            await save();
          }
          deleteProgression(
            activityPlan.uuid,
            progressionUuid,
            activityPlan.userUuid
          );
        }
      },
      duplicateProgression: async (progression: Progression) => {
        if (activityPlan && draft && confirmationMessage('duplicated')) {
          if (isDirty) {
            await save();
          }
          addProgression({
            activityPlanUuid: activityPlan.uuid,
            userUuid: activityPlan.userUuid,
            ...progressionToDuplicate(progressionToDraft(progression))
          });
        }
      },
      moveProgression: (move: MoveProgression): void => {
        if (draft) {
          setDraft(moveProgression(draft, move));
        }
      },
      moveExercise: (move: MoveExercise): void => {
        if (draft) {
          setDraft(moveExercise(draft, move));
        }
      },
      removeExercise: (location: ExerciseLocation) => {
        if (draft) {
          setDraft(removeExercise(draft, location));
        }
      },
      duplicateExercise: (location: ExerciseLocation) => {
        if (draft) {
          setDraft(duplicateExercise(draft, location));
        }
      },
      editExerciseDetails: (
        location: ExerciseLocation,
        edits: EditableExercise
      ) => {
        if (draft) {
          setDraft(editExerciseDetails(draft, location, edits));
        }
      },
      addExercises: (progressionId: string, newExercises: Exercise[]) => {
        if (draft) {
          setDraft(addExercises(draft, progressionId, newExercises));
        }
      }
    }),
    [
      isDirty,
      draft,
      activityPlan,
      save,
      commit,
      discard,
      addProgression,
      deleteProgression,
      batchCommitBetas,
      batchDeleteBetaDrafts
    ]
  );

  return (
    <DraftActivityPlanContext.Provider value={value}>
      <DraftActivityPlanAPIContext.Provider value={api}>
        {children}
      </DraftActivityPlanAPIContext.Provider>
    </DraftActivityPlanContext.Provider>
  );
};
