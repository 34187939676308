import { navigate, RouteComponentProps } from '@reach/router';
import { ActionButton } from 'components/activity-plan/ActionButton';
import { ChronicHeader } from 'components/activity-plan/chronic/ChronicHeader';
import { CopyToClipboardButtonIcon } from 'components/CopyToClipboardButtonIcon';
import { DownloadPDFButtonIcon } from 'components/DownloadPDFButtonIcon';
import { ActivityPlanPageLayout } from 'components/layout/ActivityPlanPageLayout';
import { ChronicConfigPDFDocument } from 'components/PDFDocuments/ChronicConfigPDFDocument';
import { ChronicStreams } from 'connected/activity-plan/ChronicStreams';
import { useCopyPlanToClipboard } from 'hooks/useCopyPlanToClipboard';
import {
  ActivityPlanProvider,
  useActivityPlan
} from 'providers/ActivityPlanProvider';
import { DraftActivityPlanProvider } from 'providers/DraftActivityPlanProvider';
import React from 'react';
import { Programs } from 'utils/programConstants';
import { ActivityPlanToolbar } from '../../components/activity-plan/ActivityPlanToolbar';

const ChronicConfigsView = ({
  indication
}: {
  indication?: string;
}): JSX.Element => {
  const { activityPlan, betaConfigs, loading } = useActivityPlan();
  const { canCopyToClipboard, handleCopyExercisesToClipboard } =
    useCopyPlanToClipboard(activityPlan?.progressions);
  const betasExist = betaConfigs?.length === 3;

  return (
    <ActivityPlanPageLayout
      stickyToolbar={
        <ActivityPlanToolbar
          actionButtons={
            <ActionButton
              text={betasExist ? 'VIEW TEST' : 'CREATE TEST'}
              onClick={() => navigate?.(`${indication}/test`)}
              disabled={
                loading ||
                !!(
                  activityPlan?.draft &&
                  !activityPlan.draft.isOwnedByCurrentAdmin
                )
              }
              testId="viewBaseChronicBetasButton"
            />
          }
        >
          <DownloadPDFButtonIcon
            title="Download PDF"
            placement="bottom-start"
            disabled={loading}
            document={<ChronicConfigPDFDocument activityPlan={activityPlan} />}
            fileName={activityPlan?.name ?? 'exercise-therapy-template'}
            testId="baseChronicActivityPlanViewDownloadIcon"
          />
          <CopyToClipboardButtonIcon
            disabled={!canCopyToClipboard}
            onClick={handleCopyExercisesToClipboard}
            testId="baseChronicActivityPlanViewCopyIcon"
            title={
              canCopyToClipboard
                ? 'Copy all activity plan exercises'
                : 'Copy all exercises disabled'
            }
            placement="bottom-start"
          />
        </ActivityPlanToolbar>
      }
      header={<ChronicHeader viewOnly name={`Chronic ${indication} Program`} />}
      testId="baseChronicActivityPlansViewPage"
    >
      <ChronicStreams />
    </ActivityPlanPageLayout>
  );
};

interface Props extends RouteComponentProps {
  indication?: string;
}

const ConnectedChronicConfigsView = ({
  indication = ''
}: Props): JSX.Element => {
  return (
    <ActivityPlanProvider
      uuid={''}
      configsInput={{ program: Programs.CHRONIC, indication }}
      onError={err => {
        throw new Error(`Unable to view base Chronic Activity Plan (${err})`);
      }}
    >
      <DraftActivityPlanProvider>
        <ChronicConfigsView indication={indication} />
      </DraftActivityPlanProvider>
    </ActivityPlanProvider>
  );
};

export { ConnectedChronicConfigsView as ChronicConfigsView };
