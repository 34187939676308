import { Checkbox, FormControlLabel } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

export interface Props {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  defaultChecked?: boolean;
}

const CheckboxWrapper = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(1.75)};
  width: 100%;
`;

const ExpandAllCheckbox = ({
  onChange,
  defaultChecked
}: Props): JSX.Element => (
  <CheckboxWrapper>
    <FormControlLabel
      control={
        <Checkbox
          color="primary"
          onChange={onChange}
          checked={defaultChecked}
        />
      }
      label="Expand All"
    />
  </CheckboxWrapper>
);

export default ExpandAllCheckbox;
