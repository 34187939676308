export const removeWhitespace = (str: string): string =>
  str.replace(/\s+/g, '');

export const onEnterPress = (
  event: React.KeyboardEvent<any>,
  callback: () => void
): void => {
  if (event.key === 'Enter') {
    callback();
  }
};

export const truncateString = (
  str: string,
  start: number,
  limit: number
): string => (str.length > limit ? str.slice(start, limit) + '...' : str);
