import { Button as MuiButton } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProgressionTypes } from 'utils/activityPlan';
import { DraggableProgression } from '../DraggableProgression';
import { createExerciseDropStage } from '../ProgressionStage';
import { EditablePerisurgicalWeekHeader } from './EditablePerisurgicalWeekHeader';

const AddExerciseButton = styled(MuiButton)`
  width: 100%;
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export interface EditablePerisurgicalWeekProps {
  id: string;
  index: number;
  children: React.ReactNode;
  week: number;
  current: boolean;
  estimatedTime: number;
  onDelete: () => void;
  onDuplicate: () => void;
  onAddNewExercises: () => void;
  onCopyUniqueExercises: () => void;
  onCopyAllExercises: () => void;
  expanded: boolean;
  canCopyExercises: boolean;
  isDuplicateDisabled: boolean;
}

export const EditablePerisurgicalWeek = ({
  id,
  index,
  week,
  current,
  children,
  estimatedTime,
  expanded,
  canCopyExercises,
  onDelete,
  onDuplicate,
  onAddNewExercises,
  onCopyUniqueExercises,
  onCopyAllExercises,
  isDuplicateDisabled
}: EditablePerisurgicalWeekProps): JSX.Element => {
  const [localExpanded, setExpanded] = useState<boolean>(expanded);

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  return (
    <DraggableProgression
      draggableId={id}
      scrollToId={`${ProgressionTypes.perisurgical}${week}`}
      expanded={localExpanded}
      mainComponent={createExerciseDropStage(id)}
      index={index}
      header={
        <EditablePerisurgicalWeekHeader
          id={id}
          week={week}
          current={current}
          onToggleCollapse={() => setExpanded(!localExpanded)}
          expanded={localExpanded}
          estimatedTime={estimatedTime}
          canCopyExercises={canCopyExercises}
          onDelete={onDelete}
          onDuplicate={onDuplicate}
          onCopyUniqueExercises={onCopyUniqueExercises}
          onCopyAllExercises={onCopyAllExercises}
          isDuplicateDisabled={isDuplicateDisabled}
        />
      }
      footer={
        <AddExerciseButton
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={onAddNewExercises}
          data-testid={`${id}AddExerciseButton`}
        >
          Add Exercise
        </AddExerciseButton>
      }
    >
      {children}
    </DraggableProgression>
  );
};
