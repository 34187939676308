import { useQuery } from '@apollo/client';
import { Box, Divider as MuiDivider, Slide } from '@material-ui/core';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import Search from 'components/Search';
import { Formik } from 'formik';
import { useAddSearchTranslationHook } from 'hooks/useAddSearchTranslationHook';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import {
  useDraftActivityPlan,
  useDraftActivityPlanAPI
} from 'providers/DraftActivityPlanProvider';
import React, { useState } from 'react';
import styled from 'styled-components';
import {
  GetAllExercisesDocument,
  GetAllExercisesQuery,
  GetAllExercisesQueryVariables
} from 'types';
import { TargetProgression } from 'types/targetProgression';
import { mapFormValuesToExerciseObject } from 'utils/exerciseLibrary';
import Form, { InitialValues } from './ExerciseLibraryForm';
import FormHeader from './ExerciseLibraryFormHeader';
import { LoadingSpinner } from './LoadingSpinner';

interface Props {
  targetProgression: TargetProgression | null;
  handleCloseExerciseLibrary: () => void;
}

const initialValues: InitialValues = {
  selectedExercises: []
};

const Library = styled(Box)`
  height: calc(100vh - 64px);
  width: 40%;
  min-width: 350px;
  position: fixed;
  top: 0;
  right: 0;
  ${({ theme }) => `
    margin-top: ${theme.spacing(16)};
    margin-left: ${theme.spacing(8)};
    background-color: ${theme.palette.common.white};
  `}
  z-index: 10;
`;

const Divider = styled(MuiDivider)`
  margin-left: ${({ theme }) => theme.spacing(8)};
`;

const SearchWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing(10)};
  margin-bottom: ${({ theme }) => theme.spacing(8)};
  margin-left: ${({ theme }) => theme.spacing(8)};
`;

export default function ExerciseLibrary({
  targetProgression,
  handleCloseExerciseLibrary
}: Props): JSX.Element | null {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const { saveInProcess, draft } = useDraftActivityPlan();
  const { addExercises } = useDraftActivityPlanAPI();
  const { data, loading, error } = useQuery<
    GetAllExercisesQuery,
    GetAllExercisesQueryVariables
  >(GetAllExercisesDocument, { onError: defaultErrorHandler });
  const [searchedExercises, setSearchedExercises] = useState(
    data?.exercisesGetAll ?? []
  );
  const { exerciseSearchTerm, setExerciseSearchTerm } =
    useAddSearchTranslationHook(data, setSearchedExercises);
  let buttonLabel: string;

  if (error || !data) {
    if (error) {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: 'Unable to load Exercise Library',
        message: `Reload the page to try again. Error: ${error.message}`
      });
    }
    return null;
  }

  if (targetProgression) {
    buttonLabel = targetProgression.buttonLabel;
  }

  // This required because of the update to progression.uuid after first auto save
  // More info -> createDraft and saveDraft mutation
  // Issue occurs when an auto save happens with the add exercise panel open
  // keeping the track of old progressionId which gets updated after first save and
  // hence on second addition progression ID is not found
  const getProgressionIdAfterAutoSave = (
    capturedUUid: string,
    index: number
  ): string => {
    if (!draft) return capturedUUid;
    return draft.progressions[index].uuid;
  };

  const handleSubmit = (values: InitialValues): void => {
    if (!!targetProgression) {
      const updatedUuid = getProgressionIdAfterAutoSave(
        targetProgression.uuid,
        targetProgression.index
      );
      addExercises(
        updatedUuid,
        mapFormValuesToExerciseObject(
          values.selectedExercises,
          data?.exercisesGetAll
        )
      );
    }
    handleCloseExerciseLibrary();
  };

  const handleSearchTermChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setExerciseSearchTerm(e.target.value.toLowerCase());
  };

  return (
    <Slide direction="left" in={Boolean(targetProgression)} timeout={500}>
      <Library
        boxShadow={1}
        component="aside"
        aria-labelledby="exercise-library-title"
        data-testid="exerciseLibraryMenu"
      >
        <FormHeader handleClose={handleCloseExerciseLibrary} />
        <Divider />
        <SearchWrapper>
          <Search
            autoFocus
            searchTerm={exerciseSearchTerm}
            handleChange={handleSearchTermChange}
            name="searchExercises"
            placeholder="Search Exercises"
          />
        </SearchWrapper>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Formik onSubmit={handleSubmit} initialValues={initialValues}>
            {props => (
              <Form
                {...props}
                saveInProcess={saveInProcess}
                buttonLabel={buttonLabel}
                exercises={searchedExercises}
                handleCloseExerciseLibrary={handleCloseExerciseLibrary}
              />
            )}
          </Formik>
        )}
      </Library>
    </Slide>
  );
}
