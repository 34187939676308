import {
  IconButton,
  Menu,
  MenuItem as MenuItemComponent,
  PopoverOrigin
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
  bindMenu,
  bindTrigger,
  PopupState,
  usePopupState
} from 'material-ui-popup-state/hooks';
import React from 'react';
import styled from 'styled-components';

type MenuClickHandler = (
  event: React.MouseEvent<HTMLLIElement>,
  name: string
) => void;

export type MenuItem = {
  text: string;
  disabled?: boolean;
  onClick?: MenuClickHandler;
  [key: string]: any;
};
interface Props {
  ariaLabel: string;
  iconSize?: 'small' | 'default' | 'inherit' | 'large';
  items: MenuItem[];
  popupId?: string;
  testId?: string;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;

  /** Deprecated */
  popupState?: PopupState;
  /** Deprecated */
  handleSelectMenuItem?: (event: React.MouseEvent<HTMLLIElement>) => void;
}

const MoreVertIcon = styled(MoreVert)`
  color: ${({ theme }) => theme.palette.common.black};
`;

const defaultOrigin: PopoverOrigin = {
  vertical: 'top',
  horizontal: 'right'
};

export default function EllipsisMenu({
  ariaLabel,
  iconSize = 'small',
  items,
  popupState,
  popupId,
  testId,
  anchorOrigin = defaultOrigin,
  transformOrigin = defaultOrigin,
  handleSelectMenuItem
}: Props): JSX.Element {
  const menuPopupState = usePopupState({
    variant: 'popover',
    popupId
  });

  const withClosePopup =
    (action: string, onClick?: MenuClickHandler) =>
    (event: React.MouseEvent<HTMLLIElement>): void => {
      onClick?.(event, action);
      menuPopupState.close();
    };

  return (
    <>
      <IconButton
        aria-label={ariaLabel}
        data-testid={testId}
        {...bindTrigger(popupState ?? menuPopupState)}
      >
        <MoreVertIcon fontSize={iconSize} />
      </IconButton>
      <Menu
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        getContentAnchorEl={null}
        {...bindMenu(popupState ?? menuPopupState)}
      >
        {items.map(({ text, disabled = false, onClick }: MenuItem) => (
          <MenuItemComponent
            key={`${popupState?.popupId ?? menuPopupState.popupId}${text}`}
            onClick={handleSelectMenuItem ?? withClosePopup(text, onClick)}
            disabled={disabled}
            data-testid={`${testId}-${text}`}
          >
            {text}
          </MenuItemComponent>
        ))}
      </Menu>
    </>
  );
}
