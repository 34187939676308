import {
  Avatar,
  Button,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem
} from '@material-ui/core';
import { useLogout } from 'hooks/useLogout';
import { Event, useMixpanel } from 'hooks/useMixpanel';
import React, { useState } from 'react';
import { GoogleLogout } from 'react-google-login';
import styled from 'styled-components';
import { LocalStorage, LocalStorageKey } from 'utils/localStorage';

const googleClientID = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID || '';

interface PtInfo {
  imageUrl: string;
  name: string;
}

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 40px;
  margin-top: auto;
  padding: ${({ theme }) => theme.spacing(0, 6, 18)};
`;

const StyledAvatar = styled(Avatar)`
  height: 40px;
  width: 40px;
  margin-right: ${({ theme }) => theme.spacing(2)};
`;

const MenuButton = styled(Button)`
  color: rgba(255, 255, 255, 0.87);
  font-size: 16px;
  text-transform: none;
  width: 100%;
`;

const MenuItem = styled(MuiMenuItem)`
  width: 127px;
  padding-top: 0;
  padding-bottom: 0;
`;

const SignoutButton = styled(Button)`
  text-align: left;
  text-transform: none;
  padding-left: 0;
  &:hover {
    background-color: transparent;
  }
  > span {
    justify-content: flex-start;
  }
`;

const FeedDrewLink = styled.a`
  text-decoration: none;
  color: black;
  line-height: 24px;
  padding: ${({ theme }) => theme.spacing(1.5)} 0;
`;

const AdminMenu = (): JSX.Element => {
  const { trackEvent } = useMixpanel();
  const ptInfo: PtInfo = LocalStorage.get(LocalStorageKey.PTUserInfo);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const formatPtName = (fullName: string): string => {
    if (!fullName) {
      return 'Admin Menu';
    }
    const nameArr = fullName.split(' ');
    const firstName = nameArr[0];
    const lastName = nameArr[nameArr.length - 1];

    return `${firstName} ${lastName.charAt(0)}.`;
  };

  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (): void => setAnchorEl(null);

  const { handleLogout } = useLogout();

  return (
    <MenuWrapper aria-label="secondary navigation">
      <StyledAvatar src={ptInfo?.imageUrl} alt={`${ptInfo?.name} avatar`} />
      <MenuButton onClick={handleMenu} data-testid="menuButton">
        {formatPtName(ptInfo?.name)}
      </MenuButton>
      <MuiMenu
        keepMounted
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testid="secondary-nav-menu"
      >
        <MenuItem onClick={handleClose}>
          <GoogleLogout
            clientId={googleClientID}
            onLogoutSuccess={() => {
              trackEvent(Event.LOG_OUT);
              handleLogout();
            }}
            render={renderProps => (
              <SignoutButton fullWidth onClick={renderProps.onClick}>
                Sign out
              </SignoutButton>
            )}
          />
        </MenuItem>
        <MenuItem onClick={handleClose}>
          <FeedDrewLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.fritolay.com/products/funyuns-onion-flavored-rings"
          >
            Feed Drew
          </FeedDrewLink>
        </MenuItem>
      </MuiMenu>
    </MenuWrapper>
  );
};

export default AdminMenu;
