import {
  Button,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import React from 'react';
import styled from 'styled-components';

export interface TestMenuButtonProps {
  disabled?: boolean;
  onEdit: () => void;
  onPromote: () => void;
  onDelete: () => void;
}

const Menu = styled(MuiMenu)`
  margin-top: ${({ theme }) => theme.spacing(1)};
`;

const MenuItem = styled(MuiMenuItem)`
  font-size: 16px;
  line-height: 24px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`;

export const TestMenuButton = ({
  disabled,
  onEdit,
  onPromote,
  onDelete
}: TestMenuButtonProps): JSX.Element => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event: React.MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        data-testid="testMenuButton"
        color="primary"
        variant="contained"
        disabled={disabled}
        onClick={handleMenu}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Test
      </Button>
      <Menu
        keepMounted
        data-testid="testMenu"
        aria-label="Test Menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        anchorEl={anchorEl}
        open={open}
        getContentAnchorEl={null}
        onClose={handleClose}
      >
        <MenuItem onClick={onEdit}>Edit Test</MenuItem>
        <MenuItem onClick={onPromote}>Promote to Production</MenuItem>
        <MenuItem onClick={onDelete}>Delete Test</MenuItem>
      </Menu>
    </>
  );
};
