import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { EditableChronicActivityPlan as EditableChronicActivityPlanUI } from 'components/activity-plan/chronic/EditableChronicActivityPlan';
import {
  useDraftActivityPlan,
  useDraftActivityPlanAPI
} from 'providers/DraftActivityPlanProvider';
import { useCurrentProgressionNum } from 'providers/hooks/useCurrentProgressionNum';
import React from 'react';
import { TargetProgression } from 'types/targetProgression';
import {
  copyAllExercises,
  copyUniqueExercises
} from 'utils/copyExercisesToClipboard';

const createInvalidHandler =
  (name: string, min: number): (() => void) =>
  () => {
    snackbarState({
      type: 'generic',
      severity: 'error',
      message: `Please enter a number greater than ${min} for ${name}`
    });
  };

const onInvalidHoldExercise = createInvalidHandler('hold time', 1);
const onInvalidPointsPerRepExercise = createInvalidHandler('reps', 0);
const onInvalidRepsExercise = createInvalidHandler('pointsPerRep', 0);

export interface EditableChronicConfigProps {
  onAddExercises: (target: TargetProgression) => void;
  loading: boolean;
  userLevel: number | null | undefined;
}

export const EditableChronicActivityPlan = ({
  onAddExercises,
  loading,
  userLevel
}: EditableChronicConfigProps): JSX.Element | null => {
  const { draft } = useDraftActivityPlan();
  const {
    removeExercise,
    duplicateExercise,
    editExerciseDetails,
    moveExercise
  } = useDraftActivityPlanAPI();
  const currentProgressionNum = useCurrentProgressionNum();

  return (
    <EditableChronicActivityPlanUI
      activityPlan={draft}
      loading={loading}
      currentProgression={userLevel ? userLevel : currentProgressionNum}
      onAddExercises={onAddExercises}
      onCopyAllExercises={copyAllExercises}
      onCopyUniqueExercises={copyUniqueExercises}
      onDeleteExercise={removeExercise}
      onDuplicateExercise={duplicateExercise}
      onEditExercise={editExerciseDetails}
      onMoveExercise={moveExercise}
      onInvalidHoldExercise={onInvalidHoldExercise}
      onInvalidPointsPerRepExercise={onInvalidPointsPerRepExercise}
      onInvalidRepsExercise={onInvalidRepsExercise}
    />
  );
};
