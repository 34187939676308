import { Button as MuiButton } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProgressionTypes } from 'utils/activityPlan';
import { createExerciseDropStage, ProgressionStage } from '../ProgressionStage';
import { EditableChronicLevelHeader } from './EditableChronicLevelHeader';

const AddExerciseButton = styled(MuiButton)`
  margin-top: ${({ theme }) => theme.spacing(3)};
  width: 100%;
`;

export interface EditableChronicLevelProps {
  children: React.ReactNode;
  id: string;
  level: number;
  current?: boolean | null;
  points: number;
  targetPoints: number;
  estimatedTime: number;
  numEtSessions: number;
  wasModifiedByAdmin?: boolean;
  etpeVersion?: string | null;
  canCopyExercises: boolean;
  expanded: boolean;
  onExpandChange: (expanded: boolean) => void;
  onAddExercise: () => void;
  onCopyUniqueExercises: () => void;
  onCopyAllExercises: () => void;
}

export const EditableChronicLevel = ({
  id,
  level,
  current,
  points,
  targetPoints,
  estimatedTime,
  numEtSessions,
  wasModifiedByAdmin,
  etpeVersion,
  canCopyExercises,
  expanded,
  onExpandChange,
  onCopyUniqueExercises,
  onCopyAllExercises,
  onAddExercise,
  children
}: EditableChronicLevelProps): JSX.Element => {
  const [localExpanded, setExpanded] = useState<boolean>(expanded);

  useEffect(() => {
    setExpanded(expanded);
  }, [expanded]);

  return (
    <ProgressionStage
      expanded={localExpanded}
      mainComponent={createExerciseDropStage(id)}
      id={`${ProgressionTypes.chronic}${level}`}
      padding={4.5}
      header={
        <EditableChronicLevelHeader
          id={id}
          level={level}
          current={current}
          points={points}
          targetPoints={targetPoints}
          estimatedTime={estimatedTime}
          wasModifiedByAdmin={wasModifiedByAdmin}
          etpeVersion={etpeVersion}
          numEtSessions={numEtSessions}
          onToggleCollapse={() => {
            onExpandChange(!localExpanded);
            setExpanded(!localExpanded);
          }}
          canCopyExercises={canCopyExercises}
          onCopyUniqueExercises={onCopyUniqueExercises}
          onCopyAllExercises={onCopyAllExercises}
          expanded={localExpanded}
        />
      }
      footer={
        <AddExerciseButton
          variant="outlined"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={onAddExercise}
          data-testid={`${id}AddExerciseButton`}
        >
          Add Exercise
        </AddExerciseButton>
      }
    >
      {children}
    </ProgressionStage>
  );
};
