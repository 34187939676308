import { useMutation } from '@apollo/client';
import { navigate, RouteComponentProps } from '@reach/router';
import {
  GenericSnackbarState,
  snackbarState
} from 'apollo/reactive-variables/snackbarState';
import { Body } from 'components/layout/Body';
import { PageCard } from 'components/PageCard';
import { Formik, FormikHelpers } from 'formik';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import React, { useEffect, useState } from 'react';
import {
  CreateActivityPlanDocument,
  CreateActivityPlanMutation,
  CreateActivityPlanMutationVariables
} from 'types';
import { Programs } from 'utils/programConstants';
import { number, NumberSchema, object, string, StringSchema } from 'yup';
import TemplateForm, { InitialValues } from '../components/TemplateForm';

interface ValidationSchema {
  bodyRegion: StringSchema<string, Record<string, unknown>>;
  condition: StringSchema<string, Record<string, unknown>>;
  frequency: StringSchema<string, Record<string, unknown>>;
  weeks: NumberSchema<number, Record<string, unknown>>;
  templateName: StringSchema<string, Record<string, unknown>>;
}
const validationSchema: ValidationSchema = {
  bodyRegion: string().required(),
  condition: string().required(),
  frequency: string().required(),
  weeks: number()
    .typeError('Weeks must be a number')
    .required()
    .positive()
    .integer(),
  templateName: string().required()
};

const initialValues: InitialValues = {
  bodyRegion: '',
  condition: '',
  frequency: '',
  weeks: '',
  templateName: ''
};

const TemplateNew: React.FC<RouteComponentProps> = ({
  uri
}: RouteComponentProps): JSX.Element => {
  const program = uri?.split('/')[2] as Programs;
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const [has409Error, setHas409Error] = useState<boolean>(false);
  const [createTemplateMutation, { data, error }] =
    useMutation<CreateActivityPlanMutation>(CreateActivityPlanDocument, {
      onError: defaultErrorHandler
    });

  const handleSubmit = async (
    values: InitialValues,
    { setSubmitting }: FormikHelpers<InitialValues>
  ): Promise<void> => {
    setSubmitting(true);
    const variables: CreateActivityPlanMutationVariables = {
      activityPlanCreateInput: {
        program: program,
        bodyRegion: values['bodyRegion'],
        condition: values['condition'],
        frequency: parseInt(values['frequency'].split('')[0]),
        weeks: parseInt(values['weeks']),
        name: values['templateName']
      }
    };

    await createTemplateMutation({ variables });
  };

  useEffect(() => {
    if (error) {
      const is409Error = error.message.includes('409');
      setHas409Error(is409Error);
      const errorState: GenericSnackbarState = is409Error
        ? {
            type: 'generic',
            severity: 'error',
            title: 'Template name already exists',
            message: 'Please use a different name'
          }
        : {
            type: 'generic',
            severity: 'error',
            title: 'Unable to create template',
            message: 'Try again'
          };
      snackbarState(errorState);
      return;
    }

    if (data?.activityPlanCreate?.activityPlan) {
      const { activityPlan } = data.activityPlanCreate;
      const program = activityPlan.uri.split(':')[0];
      navigate(`/programs/${program}/${activityPlan.uuid}/edit`);
      snackbarState({
        type: 'generic',
        severity: 'success',
        message: `Created new template ${activityPlan.name}`
      });
    }
  }, [data, error]);

  return (
    <Body pageTitle={`Create new ${program} template`}>
      <PageCard>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={object().shape(validationSchema)}
        >
          {props => (
            <TemplateForm
              has409Error={has409Error}
              program={program}
              {...props}
            />
          )}
        </Formik>
      </PageCard>
    </Body>
  );
};

export default TemplateNew;
