import * as Sentry from '@sentry/react';
import { userAuthState } from 'apollo/reactive-variables/userAuthState';
import { LocalStorage, LocalStorageKey } from './localStorage';

/**
 * This module contains configurator-flavored sentry utilities.
 */

let logToConsole = LocalStorage.get(LocalStorageKey.DebugMode);

if (logToConsole) {
  console.log('Debug mode is on.');
}

/**
 * Toggle Debug Mode by pressing `ctrl + shift + /`
 * NOTE: Debug Mode is persistent in LocalStorage.
 */
try {
  window.addEventListener('keypress', function (e) {
    if (e.ctrlKey && e.shiftKey && e.key === '/') {
      logToConsole = !logToConsole;
      LocalStorage.set(LocalStorageKey.DebugMode, logToConsole);
      console.log(`Debug mode is ${logToConsole ? 'on' : 'off'}.`);
    }
  });
} catch (err) {
  console.log('Debug mode unavailable', err);
}

/**
 * Logs a breadcrumb to the console.
 */
const logBreadcrumb = (breadcrumb: Sentry.Breadcrumb): void => {
  const time = breadcrumb?.timestamp
    ? new Date(breadcrumb.timestamp).toLocaleTimeString()
    : new Date();
  const level = breadcrumb.level ?? 'info';
  const tag = `${breadcrumb.type}::${breadcrumb.category}`;
  const message = breadcrumb.message ?? '';

  console.groupCollapsed(`${time} [${level}] ${tag} - ${message}`);
  console.table(breadcrumb, ['Value']);
  console.group('Data');
  breadcrumb.data
    ? console.table(breadcrumb.data, ['Value'])
    : console.log('No Data');
  console.groupEnd();
  console.groupEnd();
};

type C10RBreadcrumb = Omit<
  Sentry.Breadcrumb,
  'timestamp' | 'type' | 'category'
>;

enum BreadcrumbType {
  AUTH = 'auth',
  DRAFT = 'draft'
}

// re-export so that folks don't need to import two modules
export const Severity = Sentry.Severity;

/**
 * Create well-typed breadcrumbs. It's a two-level hierarchy: type & category.
 *
 * The types are defined in `BreadcrumbType`.
 * The categories are flexible - just provide an enum or string type
 * (ex. `'refresh' | 'session'`) of the sub-categories as the `Category` type
 * variable.
 */
const addBreadcrumb =
  <Category extends string>(type: BreadcrumbType) =>
  (category: Category) =>
  (crums: C10RBreadcrumb): void => {
    const breadcrumb = {
      type,
      category,
      timestamp: Date.now(),
      ...crums,
      ...(type === BreadcrumbType.AUTH
        ? { data: { adminId: userAuthState().adminId, ...crums.data } }
        : {})
    };

    if (logToConsole) {
      logBreadcrumb(breadcrumb);
    }

    Sentry.addBreadcrumb(breadcrumb);
  };

enum AuthCategory {
  REFRESH = 'refresh',
  SESSION = 'session'
}

const addAuthBreadcrumb = addBreadcrumb<AuthCategory>(BreadcrumbType.AUTH);
export const addRefreshBreadcrumb = addAuthBreadcrumb(AuthCategory.REFRESH);
export const addSessionBreadcrumb = addAuthBreadcrumb(AuthCategory.SESSION);

enum DraftCategory {
  LIFECYCLE = 'lifecycle'
}

const addDraftBreadcrumb = addBreadcrumb<DraftCategory>(BreadcrumbType.DRAFT);
export const addLifecycleBreadcrumb = addDraftBreadcrumb(
  DraftCategory.LIFECYCLE
);

/** sets user scope */
export const setSentryScope = (adminId: number | null): void => {
  Sentry.configureScope((scope: Sentry.Scope) => {
    if (adminId) {
      scope.setUser({ id: `${adminId}` });
    }
  });
};
