import {
  Button,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableRow as MuiTableRow,
  Typography
} from '@material-ui/core';
import { navigate } from '@reach/router';
import Search from 'components/Search';
import { useSorted } from 'hooks/useSorted';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DraftActivityPlanSummaryFragment } from 'types';
import { ActivityPlan } from 'types/activityPlan';
import { extractProgramInfoFromUri } from 'utils/extractProgramInfoFromUri';
import { chronicTableHeadCells, TableCell } from 'utils/libraryTableCells';
import { flattenAndFilterList } from '../utils/libraryFiltering';
import SortTableHead from './SortTableHead';

interface Props {
  showSearch?: boolean;
  activityPlans: ActivityPlan[];
}

interface FlattenedChronicConfig {
  uuid: string;
  name?: string;
  numProgressions: number;
  uri: string;
  indication: string;
  stream: string;
  hasBetas: boolean;
  draft?: DraftActivityPlanSummaryFragment;
}

type OrderByChronic = 'name' | 'numProgressions' | 'indication' | 'stream';

type InterfaceToRecord<I> = {
  [P in keyof I]: I[P];
};

const isOrderByChronic = (property: string): property is OrderByChronic => {
  return (
    property === 'name' ||
    property === 'indication' ||
    property === 'stream' ||
    property === 'numProgressions'
  );
};

const TableWrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(0, 15, 0, 3)};
  `}
`;

const SearchWrapper = styled.div`
  margin: ${({ theme }) => theme.spacing(0, 0, 6)};
`;

const Divider = styled(MuiDivider)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const TableRow = styled(MuiTableRow)`
  height: 72px;
`;

export default function ChronicConfigsLibrary({
  showSearch,
  activityPlans
}: Props): JSX.Element {
  const { order, orderBy, toggle, items, setItems } = useSorted<
    OrderByChronic,
    InterfaceToRecord<FlattenedChronicConfig>
  >({ initialOrderBy: 'name' });
  const [configsSearch, setConfigsSearch] = useState<string>('');

  useEffect(() => {
    const configs = activityPlans.map((plan: ActivityPlan) => {
      const { indication, stream } = extractProgramInfoFromUri(plan.uri);
      return {
        ...plan,
        indication,
        stream
      };
    });

    const flattenedConfigs = flattenAndFilterList(configs, configsSearch);
    setItems(flattenedConfigs);
  }, [activityPlans, configsSearch, setItems]);

  const handleRequestSort = (
    event: React.MouseEvent,
    property: string
  ): void => {
    if (isOrderByChronic(property)) {
      toggle(property);
    }
  };

  const handleConfigsSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setConfigsSearch(event.target.value.toLowerCase());
  };

  return (
    <TableWrapper data-testid="chronicConfigsLibrary">
      {showSearch && (
        <>
          <SearchWrapper>
            <Search
              autoFocus
              searchTerm={configsSearch}
              handleChange={handleConfigsSearchChange}
              name="configsSearch"
              placeholder="Search chronic configs"
            />
          </SearchWrapper>
          <Divider />
        </>
      )}
      <Table aria-label="Config library table">
        <SortTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          tableHeadCells={chronicTableHeadCells}
        />
        <TableBody data-testid="configLibraryBody">
          {items.map(
            ({
              uuid,
              indication,
              hasBetas
            }: FlattenedChronicConfig): JSX.Element => (
              <TableRow key={uuid} data-testid="configLibraryItem">
                <TableCell width={25} data-testid="nameCell">
                  {`Chronic ${indication}`}
                </TableCell>
                <TableCell width={33}>
                  <Typography color="textSecondary">{indication}</Typography>
                </TableCell>
                <TableCell width={14}>40</TableCell>
                <TableCell width={12} paddingleft={0}>
                  <Button
                    color="primary"
                    onClick={() => navigate(`/programs/chronic/${indication}`)}
                    data-testid="previewConfigButton"
                  >
                    VIEW
                  </Button>
                </TableCell>
                <TableCell paddingleft={0}>
                  <Button
                    color="primary"
                    onClick={() =>
                      hasBetas
                        ? navigate(`/programs/chronic/${indication}/test`)
                        : navigate(`/programs/chronic/${indication}/test/edit`)
                    }
                    data-testid="viewBetaConfig"
                  >
                    {hasBetas ? 'VIEW TEST' : 'CREATE TEST'}
                  </Button>
                </TableCell>
              </TableRow>
            )
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  );
}
