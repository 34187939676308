import { Divider, List, SvgIcon } from '@material-ui/core';
import { Link as ReachLink, LinkGetProps } from '@reach/router';
import React from 'react';
import styled from 'styled-components';

export interface NavigationItem {
  label: string;
  route: string;
  testId: string;
}
interface Props {
  title: string;
  icon?: typeof SvgIcon;
  items: NavigationItem[];
}

interface PartiallyActiveLinkProps {
  'aria-current': string;
}

const NavDivider = styled(Divider)`
  background-color: #394a62;
`;

const TitleWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    height: ${theme.spacing(11)};
    line-height: ${theme.spacing(5)};
    margin-left: ${theme.spacing(4)};
  `}
`;

const IconWrapper = styled.div`
  ${({ theme }) => `
    display: flex;
    align-items: center;
    margin-right: ${theme.spacing(2)};
  `}
`;

const GroupHeader = styled.h2`
  font-size: 14px;
  font-weight: normal;
`;

const Link = styled(ReachLink)`
  ${({ theme }) => `
    align-items: center;
    box-sizing: border-box;
    color: rgba(255, 255, 255, 0.87);
    display: flex;
    font-size: 14px;
    height: ${theme.spacing(9)};
    justify-content: flex-start;
    line-height: ${theme.spacing(5)};
    padding-left: ${theme.spacing(13)};
    text-align: left;
    text-decoration: none;
    width: 100%;

    &:hover,
    :focus {
      background-color: #0b0f15;
    }

    &[aria-current='page'] {
      background-color: #000;
      font-weight: bold;
    }
  `}
`;

const MenuGroup = ({ title, icon: Icon, items }: Props): JSX.Element => {
  const ariaLabelledbyId = `${title}-nav-group`;

  const isCurrentlyActive = ({
    isCurrent
  }: LinkGetProps): PartiallyActiveLinkProps => {
    return {
      'aria-current': isCurrent ? 'page' : 'false'
    };
  };

  return (
    <div role="group" aria-labelledby={ariaLabelledbyId}>
      <NavDivider />
      <TitleWrapper id={ariaLabelledbyId}>
        {Icon && (
          <IconWrapper>
            <Icon />
          </IconWrapper>
        )}
        <GroupHeader>{title}</GroupHeader>
      </TitleWrapper>
      <List disablePadding>
        {items.map((item, index) => (
          <Link
            key={`${item.label}-${index}`}
            getProps={isCurrentlyActive}
            to={item.route}
            data-testid={item.testId}
          >
            {item.label}
          </Link>
        ))}
      </List>
    </div>
  );
};

export default MenuGroup;
