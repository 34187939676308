import { Exercise, Progression } from 'types/activityPlan';
import { DraftProgression } from 'types/draftActivityPlan';

export type Progressions = DraftProgression | Progression;

export const findExercises = (progression: Progressions): Exercise[] =>
  progression.activities?.find(activity => activity.kind === 'et-session')?.data
    ?.exercises ?? [];

export const allUniqueExercisesToString = (
  progressions: Progressions[]
): string =>
  progressions
    .map(findExercises)
    .flat()
    .reduce(
      (exercises: string[], exercise) =>
        exercises.includes(exercise.name)
          ? exercises
          : [...exercises, exercise.name],
      []
    )
    .sort()
    .map(exercise => `- ${exercise}`)
    .join('\n');

export const simpleExerciseToString = ({
  name,
  reps,
  holdTime
}: Exercise): string =>
  `- ${name}, reps: ${reps}, ${holdTime ? `hold: ${holdTime / 1000}s` : ''}`;

/**
 * example return val:
 * "- Hamstring stretch, reps: 4, hold: 3s
    pts/rep: 3, switch: 4s"
 */
const detailedExerciseToString = ({
  name,
  reps,
  holdTime,
  pointsPerRep,
  switchLimbTime
}: Exercise): string =>
  `- ${name}, reps: ${reps}, ${holdTime ? `hold: ${holdTime / 1000}s` : ''}
    ${pointsPerRep ? `pts/rep: ${pointsPerRep}` : ''}${
    switchLimbTime ? `, switch: ${switchLimbTime / 1000}s` : ''
  }`;

export const progressionToString = (
  exercises: Exercise[],
  forDiff?: boolean
): string =>
  exercises.reduce(
    (acc, exercise): string =>
      `${acc}${
        forDiff
          ? detailedExerciseToString(exercise)
          : simpleExerciseToString(exercise)
      }\n`,
    ''
  );

export const allExercisesToString = (
  progressions: Progressions[],
  forDiff?: boolean
): string =>
  progressions.reduce(
    (acc: string, progression) =>
      `${acc}Level ${progression.order + 1}:\n${progressionToString(
        findExercises(progression),
        forDiff
      )}\n`,
    ''
  );
