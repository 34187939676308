import { useQuery } from '@apollo/client';
import {
  Button,
  Divider as MuiDivider,
  Link,
  Table,
  TableBody,
  TableRow as MuiTableRow,
  Tooltip,
  Typography
} from '@material-ui/core';
import { navigate } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { NoTemplates } from 'components/NoTemplates';
import Search from 'components/Search';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import { useSorted } from 'hooks/useSorted';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  DraftActivityPlanSummaryFragment,
  GetAllPerisurgicalTemplatesDocument,
  GetAllPerisurgicalTemplatesQuery,
  GetAllPerisurgicalTemplatesQueryVariables,
  Maybe
} from 'types';
import { User } from 'types/user';
import { Programs } from 'utils/programConstants';
import { useMutateTemplates } from '../hooks/useMutateTemplates';
import { flattenAndFilterList } from '../utils/libraryFiltering';
import {
  perisurgicalTableHeadCells,
  TableCell,
  templateTableHeadCells
} from '../utils/libraryTableCells';
import PreviewModal from './PreviewModal';
import SortTableHead from './SortTableHead';

interface Props {
  user?: User;
  showSearch?: boolean;
  showCreateLink?: boolean;
}
interface FlattenedPerisurgicalTemplate {
  uuid: string;
  name?: Maybe<string>;
  numProgressions: number;
  uri: string;
  bodyRegion: string;
  condition: string;
  draft?: Maybe<DraftActivityPlanSummaryFragment>;
}

type OrderByPerisurgical =
  | 'name'
  | 'bodyRegion'
  | 'condition'
  | 'numProgressions';

type InterfaceToRecord<I> = {
  [P in keyof I]: I[P];
};

const isOrderByPerisurgical = (
  property: string
): property is OrderByPerisurgical => {
  return (
    property === 'name' ||
    property === 'bodyRegion' ||
    property === 'condition' ||
    property === 'numProgressions'
  );
};

const TableWrapper = styled.div`
  ${({ theme }) => `
    padding: ${theme.spacing(0, 15, 0, 3)};
  `}
`;

const Aside = styled.div`
  margin: ${({ theme }) => theme.spacing(0, 0, 6)};
  display: flex;
  align-items: center;
`;

const NewTemplateLink = styled(Link)`
  margin-left: auto;
`;

const Divider = styled(MuiDivider)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const TableRow = styled(MuiTableRow)`
  height: 52px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
`;

const defaultPage = 0;
const defaultCount = 250;

export default function PerisurgicalTemplateLibrary({
  user,
  showSearch,
  showCreateLink = false
}: Props): JSX.Element {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const { order, orderBy, toggle, setItems, items } = useSorted<
    OrderByPerisurgical,
    InterfaceToRecord<FlattenedPerisurgicalTemplate>
  >({
    initialOrderBy: 'name'
  });
  const [templatesSearch, setTemplatesSearch] = useState<string>('');
  const [targetPreviewTemplate, setTargetPreviewTemplate] = useState<
    string | null
  >(null);
  const { assignLoading, handleAssignTemplate } = useMutateTemplates(
    user,
    Programs.PERISURGICAL
  );

  const { loading, error, data, client } = useQuery<
    GetAllPerisurgicalTemplatesQuery,
    GetAllPerisurgicalTemplatesQueryVariables
  >(GetAllPerisurgicalTemplatesDocument, {
    onError: defaultErrorHandler,
    variables: {
      activityPlansGetInput: {
        program: Programs.PERISURGICAL,
        page: defaultPage,
        count: defaultCount
      }
    }
  });

  useEffect(() => {
    if (error) {
      snackbarState({
        type: 'generic',
        severity: 'error',
        title: 'Unable to load templates',
        message: `Try again. Error: ${error.message}`
      });
      return;
    }
  }, [client, user, error]);

  useEffect(() => {
    const templates = flattenAndFilterList(
      data?.activityPlansGetAll?.activityPlans,
      templatesSearch
    );
    setItems(templates);
  }, [data, setItems, templatesSearch]);

  const handleShowPreviewModal = (uuid: string): void => {
    setTargetPreviewTemplate(uuid);
  };

  const handleClosePreviewModal = (): void => {
    setTargetPreviewTemplate(null);
  };

  const handleRequestSort = (
    event: React.MouseEvent,
    property: string
  ): void => {
    if (isOrderByPerisurgical(property)) {
      toggle(property);
    }
  };

  const handleTemplatesSearchChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setTemplatesSearch(event.target.value.toLowerCase());
  };

  return (
    <div data-testid="perisurgicalTemplateLibrary">
      <TableWrapper>
        {(showSearch || showCreateLink) && (
          <>
            <Aside>
              {showSearch && (
                <Search
                  autoFocus
                  searchTerm={templatesSearch}
                  handleChange={handleTemplatesSearchChange}
                  name="templatesSearch"
                  placeholder="Search perisurgical templates"
                />
              )}
              {showCreateLink && (
                <NewTemplateLink
                  href="/programs/perisurgical/new"
                  data-testid="newTemplateLink"
                >
                  Create New Template
                </NewTemplateLink>
              )}
            </Aside>
            <Divider />
          </>
        )}
        {items.length > 0 ? (
          <>
            <Table aria-label="Template library table">
              <SortTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                tableHeadCells={
                  user ? templateTableHeadCells : perisurgicalTableHeadCells
                }
              />
              <TableBody data-testid="templateLibraryBody">
                {items.map(
                  ({
                    uuid,
                    name,
                    condition,
                    bodyRegion,
                    numProgressions,
                    draft
                  }: FlattenedPerisurgicalTemplate): JSX.Element => (
                    <TableRow key={uuid} data-testid="templateLibraryItem">
                      <TableCell data-testid="nameCell">{name}</TableCell>
                      <TableCell>
                        <Typography color="textSecondary">
                          {bodyRegion}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography color="textSecondary">
                          {condition}
                        </Typography>
                      </TableCell>
                      <TableCell width={12}>{numProgressions}</TableCell>
                      {!user ? (
                        <TableCell width={12} data-testid="activeDraftCell">
                          {draft ? (
                            <Tooltip
                              title={`Draft created ${draft.createdAt.toLocaleDateString()}`}
                              placement="bottom-start"
                            >
                              <Typography>Yes</Typography>
                            </Tooltip>
                          ) : (
                            <Typography>No</Typography>
                          )}
                        </TableCell>
                      ) : null}
                      <TableCell paddingleft={0}>
                        <ActionsWrapper
                          data-testid={`${name?.replace(/\s+/g, '')}Actions`}
                        >
                          <Button
                            color="primary"
                            onClick={() =>
                              user
                                ? handleShowPreviewModal(uuid)
                                : navigate(`/programs/perisurgical/${uuid}`)
                            }
                            data-testid="previewTemplateButton"
                          >
                            VIEW
                          </Button>
                          {user ? (
                            <Button
                              color="primary"
                              disabled={assignLoading}
                              onClick={() => handleAssignTemplate(uuid)}
                              data-testid="assignTemplateButton"
                            >
                              ASSIGN
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              onClick={() =>
                                navigate(`/programs/perisurgical/${uuid}/edit`)
                              }
                              data-testid="editTemplateButton"
                              disabled={
                                !!(draft && !draft.isOwnedByCurrentAdmin)
                              }
                            >
                              EDIT
                            </Button>
                          )}
                        </ActionsWrapper>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
          </>
        ) : (
          <NoTemplates isLoading={loading} />
        )}
      </TableWrapper>
      <PreviewModal
        targetTemplate={targetPreviewTemplate}
        user={user}
        program={Programs.PERISURGICAL}
        handleClosePreviewModal={handleClosePreviewModal}
      />
    </div>
  );
}
