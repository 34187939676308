import { userAuthState } from 'apollo/reactive-variables/userAuthState';
import mixpanel, { Dict } from 'mixpanel-browser';
import { LocalStorage, LocalStorageKey } from 'utils/localStorage';

const MIXPANEL_PROD = '035ea4cdc05a066e5aa4be4ebceb105d';
const MIXPANEL_STAGING = '19d35a7f7d9d11cef7c953c12dd6f170';
const MIXPANEL_DEV = '7d056bb6ba2e4013dc69a4e6dee6af25';

const nodeEnv = process.env.REACT_APP_ENV;

switch (nodeEnv) {
  case 'production':
    mixpanel.init(MIXPANEL_PROD);
    break;
  case 'staging':
    mixpanel.init(MIXPANEL_STAGING);
    break;
  default:
    // flip debug to true for development logs
    mixpanel.init(MIXPANEL_DEV, { debug: false });
}

export enum Event {
  SIGN_IN = 'Sign in',
  SIGN_IN_FAIL = 'Sign in failed',
  GOOGLE_OAUTH_FAIL = 'Google Oauth failed',
  REFRESH = 'Refresh',
  REFRESH_FAIL = 'Refresh failed',
  REFRESH_SUCCESS = 'Refresh succeeded',
  LOG_OUT = 'Log out',
  SNACKBAR_ERROR = 'Snackbar error viewed',
  GRAPHQL_ERROR = 'GraphQL error',
  ERROR_BOUNDARY = 'Error boundary',
  LOAD_ACTIVITY_PLAN = 'Load activity plan',
  PUBLISH_CLICKED = 'Send activity plan button clicked',
  RESET_START_DATE_ACTIVITY_PLAN_CLICKED = 'Reset start date activity plan button clicked'
}

interface UseMixpanelResponse {
  identifyAdmin: () => void;
  trackEvent: (event: Event | string, properties?: Dict) => void;
}

export const useMixpanel = (): UseMixpanelResponse => {
  /** Set unique key in mixpanel for merging tracked events and set user profile */
  const identifyAdmin = (): void => {
    const adminId = userAuthState().adminId;
    const { name, imageUrl, email } = LocalStorage.get(
      LocalStorageKey.PTUserInfo
    );
    mixpanel.identify(`${adminId}`);
    mixpanel.people.set({
      $name: name,
      $avatar: imageUrl,
      $email: email,
      adminId
    });
  };

  /** Event enum should be used for all event args with the exception of location.pathname */
  const trackEvent = (event: Event | string, properties?: Dict): void => {
    mixpanel.track(event, {
      ...properties
    });
  };

  return {
    identifyAdmin,
    trackEvent
  };
};
