import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import {
  allExercisesToString,
  allUniqueExercisesToString,
  Progressions
} from './stringifyProgressions';

const copyExercises =
  (fn: (p: Progressions[]) => string) =>
  async (
    progressions: Progressions | Progressions[],
    message: string
  ): Promise<void> => {
    await navigator.clipboard.writeText(
      fn(Array.isArray(progressions) ? progressions : [progressions])
    );
    snackbarState({ type: 'generic', severity: 'info', message });
  };

export const copyUniqueExercises = copyExercises(allUniqueExercisesToString);

export const copyAllExercises = copyExercises(allExercisesToString);
