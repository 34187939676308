import { Link } from '@material-ui/core';
import { blue } from '@material-ui/core/colors';
import { Schedule } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

const Span = styled.span`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

const CurrentLink = styled(Link)`
  color: ${blue[900]};
  display: block;
  cursor: pointer;
`;

const scrollToCurrentProgression = (progressionId: string) => (): void => {
  document.getElementById(progressionId)?.scrollIntoView({
    behavior: 'smooth'
  });
};

export interface CurrentProgressionProps {
  current: number;
  targetId: string;
  progressionLabel: string;
}

export const CurrentProgression = ({
  current,
  targetId,
  progressionLabel
}: CurrentProgressionProps): JSX.Element => (
  <Span>
    <Schedule />
    {progressionLabel} {current}
    {current > 0 ? (
      <CurrentLink
        color="primary"
        onClick={scrollToCurrentProgression(targetId)}
        data-testid="currentWeekLink"
      >
        View
      </CurrentLink>
    ) : null}
  </Span>
);
