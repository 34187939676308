import { navigate, RouteComponentProps } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import { ChronicHeader } from 'components/activity-plan/chronic/ChronicHeader';
import { TestMenuButton } from 'components/activity-plan/TestMenuButton';
import { CopyToClipboardButtonIcon } from 'components/CopyToClipboardButtonIcon';
import {
  ABInfoSection,
  ABInfoSectionType
} from 'components/diff/ABInfoSection';
import { DownloadPDFButtonIcon } from 'components/DownloadPDFButtonIcon';
import { ActivityPlanPageLayout } from 'components/layout/ActivityPlanPageLayout';
import { ChronicConfigPDFDocument } from 'components/PDFDocuments/ChronicConfigPDFDocument';
import { ChronicStreams } from 'connected/activity-plan/ChronicStreams';
import { useCopyPlanToClipboard } from 'hooks/useCopyPlanToClipboard';
import {
  ActivityPlanProvider,
  useActivityPlan,
  useActivityPlanApi
} from 'providers/ActivityPlanProvider';
import {
  DraftActivityPlanProvider,
  useDraftActivityPlan
} from 'providers/DraftActivityPlanProvider';
import { useEnsureBetas } from 'providers/hooks/useEnsureBetas';
import React, { useState } from 'react';
import { countDiffs } from 'utils/draftActivityPlan';
import { Programs } from 'utils/programConstants';
import { allExercisesToString } from 'utils/stringifyProgressions';
import { ActivityPlanToolbar } from '../../components/activity-plan/ActivityPlanToolbar';

const ChronicBetasView = ({
  indication
}: {
  indication?: string;
}): JSX.Element => {
  const [canCreateBetas, setCanCreateBetas] = useState<boolean>(true);
  const { activityPlan, diffActivityPlan, loading } = useActivityPlan();
  const { draft } = useDraftActivityPlan();
  const { deleteBetas, promoteBetas } = useActivityPlanApi();
  const { canCopyToClipboard, handleCopyExercisesToClipboard } =
    useCopyPlanToClipboard(activityPlan?.progressions);

  useEnsureBetas(canCreateBetas);

  const handleOnDelete = async (): Promise<void> => {
    if (
      window.confirm(
        'Are you sure you want to delete this test?\nAll changes will be permanently deleted.'
      )
    ) {
      setCanCreateBetas(false);
      if (await deleteBetas()) {
        /**
         * Replacing the history stack here so that the user cannot return
         * to the `/programs/chronic/<indication>/test` page by hitting the
         * broswer's BACK button. This helps avoid any errant betas creation.
         */
        navigate('/programs/chronic/', { replace: true });
        snackbarState({
          type: 'generic',
          severity: 'success',
          message: 'Test has been deleted'
        });
      }
    }
  };

  const handleOnPromote = async (): Promise<void> => {
    if (
      window.confirm(
        'Are you sure you want to promote changes to production?\nThese changes cannot be reversed and will be pushed to production.'
      )
    ) {
      setCanCreateBetas(false);
      if (await promoteBetas()) {
        /**
         * Replacing the history stack here so that the user cannot return
         * to the `/programs/chronic/<indication>/test` page by hitting the
         * broswer's BACK button. This helps avoid any errant betas creation.
         */
        navigate('/programs/chronic/', { replace: true });
        snackbarState({
          type: 'generic',
          severity: 'success',
          message: 'Test has successfully been promoted'
        });
      }
    }
  };

  const betaDraftOrPlan = draft ?? activityPlan;
  const diffViewerChanges = {
    oldValue: diffActivityPlan
      ? allExercisesToString(diffActivityPlan.progressions, true)
      : '',
    newValue: betaDraftOrPlan
      ? allExercisesToString(betaDraftOrPlan.progressions, true)
      : '',
    number:
      diffActivityPlan && betaDraftOrPlan
        ? countDiffs(diffActivityPlan, betaDraftOrPlan)
        : 0
  };

  return (
    <ActivityPlanPageLayout
      stickyToolbar={
        <ActivityPlanToolbar
          actionButtons={
            <TestMenuButton
              disabled={
                loading ||
                !!(
                  activityPlan?.draft &&
                  !activityPlan.draft.isOwnedByCurrentAdmin
                )
              }
              onEdit={() => navigate('test/edit')}
              onDelete={handleOnDelete}
              onPromote={handleOnPromote}
            />
          }
        >
          <DownloadPDFButtonIcon
            title="Download PDF"
            placement="bottom-start"
            disabled={loading}
            document={<ChronicConfigPDFDocument activityPlan={activityPlan} />}
            fileName={activityPlan?.name ?? 'exercise-therapy-template'}
            testId="baseChronicBetasViewDownloadIcon"
          />
          <CopyToClipboardButtonIcon
            disabled={!canCopyToClipboard}
            onClick={handleCopyExercisesToClipboard}
            testId="baseChronicBetasViewCopyIcon"
            title={
              canCopyToClipboard
                ? 'Copy all activity plan exercises'
                : 'Copy all exercises disabled'
            }
            placement="bottom-start"
          />
        </ActivityPlanToolbar>
      }
      sidebar={
        <ABInfoSection
          type={ABInfoSectionType.TEST_CONTROLLED_BY_SPLITIO}
          activityPlanName={activityPlan?.name ?? `Chronic ${indication}`}
          changes={diffViewerChanges}
        />
      }
      header={<ChronicHeader viewOnly name={`Chronic ${indication} Program`} />}
      testId="baseChronicBetasViewPage"
    >
      <ChronicStreams />
    </ActivityPlanPageLayout>
  );
};

interface Props extends RouteComponentProps {
  indication?: string;
}

const ConnectedChronicBetasView = ({ indication = '' }: Props): JSX.Element => {
  return (
    <ActivityPlanProvider
      uuid={''}
      configsInput={{ program: Programs.CHRONIC, indication }}
      betas={true}
      onError={err => {
        throw new Error(`Unable to view Chronic Beta Activity Plan (${err})`);
      }}
    >
      <DraftActivityPlanProvider>
        <ChronicBetasView indication={indication} />
      </DraftActivityPlanProvider>
    </ActivityPlanProvider>
  );
};

export { ConnectedChronicBetasView as ChronicBetasView };
