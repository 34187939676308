import { Typography } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import React from 'react';
import styled from 'styled-components';

const Span = styled(Typography)`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1.5)};
  align-items: center;
  color: ${({ theme }) => theme.palette.grey[500]};
`;

export const ViewOnly = (): JSX.Element => (
  <Span>
    <Visibility /> View only
  </Span>
);
