import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { TFunction } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EtPreview, Motion } from 'types/exerciseRepo';
import { MotionsTableRow } from './MotionsTableRow';

const TableCard = styled(Paper)`
  padding: ${({ theme }) => theme.spacing(2.5, 6, 4, 6)};
  margin-bottom: ${({ theme }) => theme.spacing(12)};
`;

const Heading = styled(Typography)`
  margin-bottom: ${({ theme }) => theme.spacing(6)};
`;

const HeadingCellText = styled(Typography)`
  font-weight: bold;
`;

const headerCells = ['Step', 'Image', 'Alt Text', 'Instructions', 'Verbal Cue'];

export interface MotionsTableProps {
  t: TFunction;
  motions: Motion[];
  etPreview: EtPreview;
}

export const MotionsTable = withTranslation()(
  ({ t, motions, etPreview }: MotionsTableProps): JSX.Element => (
    <TableCard data-testid="motionsTable">
      <Heading variant="h6">Motions for a step</Heading>
      <Table data-testid="MotionsTable">
        <TableHead>
          <TableRow>
            {headerCells.map(text => (
              <TableCell key={text}>
                <HeadingCellText variant="subtitle2">{text}</HeadingCellText>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <MotionsTableRow
            type="Preview"
            text={t(etPreview.message)}
            image={etPreview.image}
            verbalCues={etPreview.verbalCue ? [etPreview.verbalCue] : []}
          />
          {motions.map(({ uuid, type, text, image, verbalCues }: Motion) => (
            <MotionsTableRow
              key={uuid}
              type={type}
              text={t(text)}
              image={image}
              verbalCues={verbalCues}
            />
          ))}
        </TableBody>
      </Table>
    </TableCard>
  )
);
