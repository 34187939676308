import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';

export interface DiscardDraftButtonProps {
  disabled: boolean;
  onClick: () => Promise<void>;
}

const TextButton = styled(Button)`
  color: ${({ theme }) => theme.palette.error.main};
  height: 36px;
`;

export const DiscardDraftButton = ({
  disabled,
  onClick
}: DiscardDraftButtonProps): JSX.Element => (
  <TextButton
    onClick={onClick}
    disabled={disabled}
    data-testid="discardDraftButton"
    variant="text"
  >
    DISCARD DRAFT
  </TextButton>
);
