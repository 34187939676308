import { Typography } from '@material-ui/core';
import { RouteComponentProps } from '@reach/router';
import BummerLlama from 'components/BummerLlama';
import { Body } from 'components/layout/Body';
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  width: 100%;
`;

const TextWrapper = styled.div`
  width: 388px;

  h5:first-child {
    padding-bottom: ${({ theme }) => theme.spacing(8)};
  }
`;

const ImageWrapper = styled.div`
  margin-top: -100px;
  width: calc(100% - 388px);
`;

const NotFound: React.FC<RouteComponentProps> = () => {
  return (
    <Body pageTitle="Oops... Page not found." testId="notFoundPage">
      <Container>
        <TextWrapper>
          <Typography variant="h5">
            But you did find this Llama enjoying some Funyuns so there&#39;s
            that.
          </Typography>
          <Typography variant="h5">
            Slack a Llama with any questions at #llama-pod.
          </Typography>
        </TextWrapper>
        <ImageWrapper>
          <BummerLlama />
        </ImageWrapper>
      </Container>
    </Body>
  );
};

export default NotFound;
