import { Chip, Typography } from '@material-ui/core';
import { amber, blueGrey, common } from '@material-ui/core/colors';
import React from 'react';
import styled from 'styled-components';
import { ActivityPlanStatus } from 'types';

const BlueGreyChip = styled(Chip)`
  background-color: ${blueGrey[600]};
  color: ${common.white};
  width: 85px;
`;

const AmberChip = styled(Chip)`
  background-color: ${amber[600]};
  color: ${common.black};
  width: 85px;
`;

const StatusWrapper = styled.div`
  padding: ${({ theme }) => theme.spacing(2, 0)};
  display: flex;
  flex-direction: column;
`;

export interface ActivityPlanStatusChipProps {
  status: ActivityPlanStatus;
}

export const ActivityPlanStatusChip = ({
  status
}: ActivityPlanStatusChipProps): JSX.Element | null => {
  switch (status) {
    case ActivityPlanStatus.Assigned:
      return (
        <BlueGreyChip label="Assigned" aria-label="Assigned" size="small" />
      );
    case ActivityPlanStatus.Published:
      return (
        <AmberChip label="Published" aria-label="Published" size="small" />
      );
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.error(new Error(`Encountered unsupported status: ${status}`));
      }
      return null;
    }
  }
};

export interface StatusChipandCaptionProps extends ActivityPlanStatusChipProps {
  updatedAt: Date;
}

export const ActivityPlanStatusCaption = ({
  status,
  updatedAt
}: StatusChipandCaptionProps): JSX.Element | null => {
  switch (status) {
    case ActivityPlanStatus.Assigned:
      return (
        <Typography variant="caption">{`Last modified ${updatedAt.toLocaleDateString()}`}</Typography>
      );
    case ActivityPlanStatus.Published:
      return (
        <Typography variant="caption">{`Published ${updatedAt.toLocaleDateString()}`}</Typography>
      );
    default: {
      if (process.env.NODE_ENV === 'development') {
        console.error(new Error(`Encountered unsupported status: ${status}`));
      }
      return null;
    }
  }
};

export const ActivityPlanStatusWithCaption = ({
  updatedAt,
  status
}: StatusChipandCaptionProps): JSX.Element => {
  return (
    <StatusWrapper>
      <ActivityPlanStatusChip status={status} />
      <ActivityPlanStatusCaption status={status} updatedAt={updatedAt} />
    </StatusWrapper>
  );
};
