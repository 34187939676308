import React from 'react';
import {
  ChronicActivityPlan,
  ChronicActivityProps
} from './ChronicActivityPlan';
import {
  ChronicActivityPlanTabs,
  ChronicActivityPlanTabsProps
} from './ChronicActivityPlanTabs';

export interface ChronicStreamsProps
  extends ChronicActivityProps,
    ChronicActivityPlanTabsProps {}

export const ChronicStreams = ({
  onLow,
  onMedium,
  onHigh,
  ...rest
}: ChronicStreamsProps): JSX.Element => {
  return (
    <ChronicActivityPlan
      toolbar={
        <ChronicActivityPlanTabs
          onLow={onLow}
          onMedium={onMedium}
          onHigh={onHigh}
        />
      }
      {...rest}
    />
  );
};
