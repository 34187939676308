import { useQuery } from '@apollo/client';
import { Button, Divider as MuiDivider, Typography } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Redirect, RouteComponentProps } from '@reach/router';
import { snackbarState } from 'apollo/reactive-variables/snackbarState';
import AcuteTemplateLibrary from 'components/AcuteTemplateLibrary';
import { Body } from 'components/layout/Body';
import { Header } from 'components/layout/Header';
import { LoadingSpinner } from 'components/LoadingSpinner';
import { PageCard } from 'components/PageCard';
import PerisurgicalTemplateLibrary from 'components/PerisurgicalTemplateLibrary';
import UserActivityPlansTable from 'components/UserActivityPlansTable';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { GetUserDocument, GetUserQuery, GetUserQueryVariables } from 'types';
import { Programs } from 'utils/programConstants';
import { TimeBasedUserChip } from '../../components/TimeBasedUserChip';
import { useSharedFeatureFlag } from '../../hooks/useSharedFeatureFlag';

// TODO: add other TemplateLibrary components, as needed
type TemplateLibraryType =
  | typeof AcuteTemplateLibrary
  | typeof PerisurgicalTemplateLibrary;

const programTemplateLibrary: { [key: string]: TemplateLibraryType } = {
  acute: AcuteTemplateLibrary,
  perisurgical: PerisurgicalTemplateLibrary
};
// end TODO

interface Props extends RouteComponentProps {
  id?: string;
}

const LibraryHeader = styled.header`
  ${({ theme }) => `
    margin-bottom: ${theme.spacing(5)};
    text-indent: ${theme.spacing(2)};
  `}
`;

const AlertWrapper = styled.div`
  width: 662px;
`;

const Divider = styled(MuiDivider)`
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

export default function UserDetail({
  id: userId = ''
}: Props): JSX.Element | null {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const [showTemplateLibrary, setShowTemplateLibrary] = useState(false);
  const queryOptions = {
    variables: { userId: parseInt(userId) },
    onError: defaultErrorHandler
  };
  const { data, loading, error } = useQuery<
    GetUserQuery,
    GetUserQueryVariables
  >(GetUserDocument, queryOptions);
  const { treatment: isTimeBasedUser } = useSharedFeatureFlag(
    'enableTimeBasedExercises',
    userId
  );

  useEffect(() => {
    if (!error) return;
    snackbarState({
      type: 'generic',
      severity: 'error',
      title: "Unable to load user's data",
      message: 'Try again'
    });
  }, [error]);

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <Redirect to="/users" replace noThrow />;
  }

  if (data) {
    const { id, program, indication, activityPlans } = data.userGet;
    const hasActivityPlans = !!activityPlans?.length;
    const chronicUserWithoutPlans =
      !hasActivityPlans && program === Programs.CHRONIC;
    const ProgramTemplateLibrary = programTemplateLibrary[program];
    const cardStyles = {
      display: `${hasActivityPlans || showTemplateLibrary ? 'flex' : 'none'}`,
      minHeight: 'calc(100vh - 398px)',
      paddingTop: '20px'
    };

    const handleShowTemplateLibrary = (): void => {
      setShowTemplateLibrary(true);
    };

    if (chronicUserWithoutPlans) {
      snackbarState({
        title: "This chronic user doesn't have an activity plan",
        message:
          'You may have used a classic chronic user ID by mistake or this is an AE chronic user that is in a bad state.',
        type: 'generic',
        severity: 'error'
      });
    }

    return (
      <Body
        pageTitle={`User ID: ${id}`}
        header={
          <Header
            title={`User ID: ${id}`}
            subTitle={`${program} ${indication}`}
          >
            {isTimeBasedUser ? <TimeBasedUserChip /> : null}
          </Header>
        }
      >
        {!hasActivityPlans &&
          !showTemplateLibrary &&
          handleShowTemplateLibrary && (
            <AlertWrapper>
              <Alert
                severity="info"
                action={
                  <Button
                    color="inherit"
                    size="small"
                    data-testid="chooseTemplateButton"
                    onClick={handleShowTemplateLibrary}
                    disabled={chronicUserWithoutPlans}
                  >
                    CHOOSE TEMPLATE
                  </Button>
                }
              >
                <AlertTitle>
                  <strong>Activity plan</strong>
                </AlertTitle>
                User has not been assigned an activity plan
              </Alert>
            </AlertWrapper>
          )}
        {!!activityPlans?.length && (
          <PageCard style={cardStyles}>
            <UserActivityPlansTable activityPlans={activityPlans} userId={id} />
          </PageCard>
        )}
        {showTemplateLibrary && !hasActivityPlans && (
          <PageCard style={cardStyles}>
            <LibraryHeader>
              <Typography variant="h5" component="h2">
                Template Library
              </Typography>
            </LibraryHeader>
            <Divider />
            <ProgramTemplateLibrary user={data.userGet} />
          </PageCard>
        )}
      </Body>
    );
  }
  return null;
}
