import { EventEmitter } from 'events';
import { useCallback, useEffect, useRef, useState } from 'react';

const createObserver = (): {
  emitter: EventEmitter;
  observer: IntersectionObserver;
} => {
  const emitter = new EventEmitter().setMaxListeners(41);
  const onEntry = (entries: IntersectionObserverEntry[]): void =>
    entries.forEach(e => emitter.emit('entry', e));

  return {
    emitter,
    observer: new IntersectionObserver(onEntry, {
      root: null,
      threshold: 0
    })
  };
};

let emitter: EventEmitter | null = null;
let observer: IntersectionObserver | null;

/**
 * Note that when using this hook, if the `ref` is passed to an element and
 * needs to be passed further to a component,
 * it will not be available in props.
 * The `ref` will need to be manually forwarded by wrappping the
 * receiving component with a `React.forwardRef` call.
 */
export const useInViewport = (): {
  ref: (node: Element) => void;
  inViewport: boolean;
} => {
  const target = useRef<Element | null>(null);
  const [inViewport, setInViewport] = useState<boolean>(false);

  if (!emitter || !observer) {
    ({ emitter, observer } = createObserver());
  }

  useEffect(() => {
    // If an entry
    const onEntry = (entry: IntersectionObserverEntry): void => {
      if (entry.target === target.current) {
        setInViewport(entry.isIntersecting);
      }
    };

    emitter?.on('entry', onEntry);

    return () => {
      emitter?.off('entry', onEntry);
      if (target.current) {
        observer?.unobserve(target.current);
        target.current = null;
      }
    };
  }, []);

  /**
   * Connect DOM nodes to the IntersectionObserver. Viewport tracking
   * will update as this ref is passed to new components.
   */
  const ref = useCallback((node: Element): void => {
    if (target.current) {
      observer?.unobserve(target.current);
      target.current = null;
    }

    if (node) {
      observer?.observe(node);
      target.current = node;
    }
  }, []);

  return { ref, inViewport };
};
