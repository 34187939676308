import { DraftStatuses } from 'providers/DraftActivityPlanProvider';
import React from 'react';
import { ActivityPlanStatus } from 'types';
import { ProgressionTypes } from 'utils/activityPlan';
import { Header } from '../../layout/Header';
import { TimeBasedUserChip } from '../../TimeBasedUserChip';
import { ActivityPlanStatusChip } from '../ActivityPlanStatus';
import { CurrentProgression } from '../CurrentProgression';
import { DraftStatus } from '../DraftStatus';
import { ViewOnly } from '../ViewOnly';

export interface PerisurgicalHeaderProps {
  name: string;
  viewOnly?: boolean;
  draftStatus?: { status: DraftStatuses; lastUpdatedAt: Date };
}

export const PerisurgicalHeader = ({
  name,
  viewOnly,
  draftStatus
}: PerisurgicalHeaderProps): JSX.Element => (
  <Header title={name}>
    {viewOnly ? <ViewOnly /> : null}
    {draftStatus ? (
      <DraftStatus
        lastUpdatedAt={draftStatus.lastUpdatedAt}
        status={draftStatus.status}
      />
    ) : null}
  </Header>
);

export interface PerisurgicalUserHeaderProps {
  bodyRegion: string;
  viewOnly?: boolean;
  userId: string;
  isTimeBasedUser?: boolean;
  currentWeek: number | null;
  draftStatus?: { status: DraftStatuses; lastUpdatedAt: Date };
  activityPlanStatus: { status: ActivityPlanStatus };
}

export const PerisurgicalUserHeader = ({
  currentWeek,
  userId,
  isTimeBasedUser,
  bodyRegion,
  viewOnly,
  activityPlanStatus,
  draftStatus
}: PerisurgicalUserHeaderProps): JSX.Element => (
  <Header title={`User ID: ${userId}`} subTitle={`Perisurgical ${bodyRegion}`}>
    {isTimeBasedUser ? <TimeBasedUserChip /> : null}
    <ActivityPlanStatusChip status={activityPlanStatus.status} />
    {!currentWeek ? null : (
      <CurrentProgression
        progressionLabel={ProgressionTypes.perisurgical}
        current={currentWeek}
        targetId={`${ProgressionTypes.perisurgical}${currentWeek}`}
      />
    )}
    {viewOnly ? <ViewOnly /> : null}

    {draftStatus ? (
      <DraftStatus
        lastUpdatedAt={draftStatus.lastUpdatedAt}
        status={draftStatus.status}
      />
    ) : null}
  </Header>
);
