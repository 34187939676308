import { globalHistory, RouteComponentProps, Router } from '@reach/router';
import PrivateRoute from 'components/PrivateRoute';
import { useMixpanel } from 'hooks/useMixpanel';
import { AcuteTemplateEdit } from 'pages/acute-templates/AcuteTemplateEdit';
import AcuteTemplatesIndex from 'pages/acute-templates/AcuteTemplatesIndex';
import { AcuteTemplateView } from 'pages/acute-templates/AcuteTemplateView';
import { ChronicBetasEdit } from 'pages/chronic-configs/ChronicBetasEdit';
import { ChronicBetasView } from 'pages/chronic-configs/ChronicBetasView';
import { ChronicConfigsView } from 'pages/chronic-configs/ChronicConfigsView';
import ChronicConfigurationsIndex from 'pages/chronic-configs/ChronicConfigurationsIndex';
import ExerciseIndex from 'pages/exercise-repo/ExerciseIndex';
import { ExerciseView } from 'pages/exercise-repo/ExerciseView';
import Home from 'pages/Home';
import Login from 'pages/Login';
import NotFound from 'pages/NotFound';
import { PerisurgicalTemplateEdit } from 'pages/perisurgical-templates/PerisurgicalTemplateEdit';
import PerisurgicalTemplatesIndex from 'pages/perisurgical-templates/PerisurgicalTemplatesIndex';
import { PerisurgicalTemplateView } from 'pages/perisurgical-templates/PerisurgicalTemplateView';
import ProgramsSwitchboard from 'pages/ProgramsSwitchboard';
import TemplateNew from 'pages/TemplateNew';
import User from 'pages/user/User';
import UserActivityPlanEdit from 'pages/user/UserActivityPlanEdit';
import UserActivityPlanView from 'pages/user/UserActivityPlanView';
import UsersIndex from 'pages/user/UsersIndex';
import React, { useEffect } from 'react';
import { LocalStorageKey } from 'utils/localStorage';

type SwitchProps = { children: React.ReactNode };
const Switch = ({
  children
}: SwitchProps & RouteComponentProps): JSX.Element => <>{children}</>;

export default function App(): JSX.Element {
  const { trackEvent } = useMixpanel();
  globalHistory.listen(({ location }) => trackEvent(location.pathname));

  useEffect(() => {
    window.addEventListener('storage', (event: StorageEvent): void => {
      if (event.key === LocalStorageKey.LogoutEvent) {
        if (window.location.pathname === '/login') {
          return;
        } else {
          window.location.reload();
        }
      }
    });
  }, []);

  return (
    <Router>
      <Login path="/login" />
      <PrivateRoute path="/">
        <NotFound default />
        <Home path="/" />
        <Switch path="programs">
          <ProgramsSwitchboard path="/" />
          <Switch path="acute">
            <AcuteTemplatesIndex path="/" />
            <AcuteTemplateView path=":uuid" />
            <AcuteTemplateEdit path=":uuid/edit" />
            <TemplateNew path="new" />
          </Switch>
          <Switch path="chronic">
            <ChronicConfigurationsIndex path="/" />
            <ChronicConfigsView path=":indication" />
            <ChronicBetasView path=":indication/test" />
            <ChronicBetasEdit path=":indication/test/edit" />
          </Switch>
          <Switch path="perisurgical">
            <PerisurgicalTemplatesIndex path="/" />
            <PerisurgicalTemplateView path=":uuid" />
            <PerisurgicalTemplateEdit path=":uuid/edit" />
            <TemplateNew path="new" />
          </Switch>
        </Switch>
        <Switch path="users">
          <UsersIndex path="/" />
          <User path=":id" />
          <UserActivityPlanView path=":id/activity-plans/:uuid" />
          <UserActivityPlanEdit path=":id/activity-plans/:uuid/edit" />
        </Switch>
        <Switch path="exercises">
          <ExerciseIndex path="/" />
          <ExerciseView path=":uuid" />
        </Switch>
      </PrivateRoute>
    </Router>
  );
}
