import Button from '@material-ui/core/Button';
import React from 'react';
import styled from 'styled-components';

export interface ActionButtonProps {
  text: string;
  disabled?: boolean;
  testId?: string;
  onClick: () => Promise<void> | void;
}

const CustomButton = styled(Button)`
  height: 36px;
`;

export const ActionButton = ({
  text,
  disabled,
  testId = 'actionButton',
  onClick
}: ActionButtonProps): JSX.Element => (
  <CustomButton
    onClick={onClick}
    disabled={disabled}
    color="primary"
    data-testid={testId}
    variant="contained"
  >
    {text}
  </CustomButton>
);
