import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core';
import { FormikHandlers } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { SelectInput } from 'utils/programConstants';

interface Props {
  value: string;
  label: string;
  name: string;
  error: boolean;
  errorMessage: string | undefined;
  disabled?: boolean;
  disabledMessage?: string;
  menuItems: SelectInput['options'];
  handleChange: FormikHandlers['handleChange'];
}

const Control = styled(FormControl)`
  margin-bottom: ${props => props.theme.spacing(6)};
  width: 200px;
`;

const SelectField = ({
  value,
  label,
  name,
  error,
  errorMessage,
  disabled = false,
  menuItems,
  handleChange,
  disabledMessage = 'Disabled'
}: Props): JSX.Element => (
  <Control error={error} disabled={disabled}>
    <InputLabel htmlFor={`${name}`}>{label}</InputLabel>
    <Select
      value={value}
      onChange={handleChange}
      name={name}
      aria-describedby={errorMessage && 'error'}
      data-testid={`${name}-select`}
      disabled={disabled}
      inputProps={{ id: name }}
    >
      {menuItems &&
        menuItems.map((item, index) => (
          <MenuItem key={index} value={item} data-testid={`${item}-option`}>
            {item}
          </MenuItem>
        ))}
    </Select>
    <FormHelperText id="error" data-testid={`${name}-error`}>
      {disabled ? disabledMessage : errorMessage && error ? errorMessage : ' '}
    </FormHelperText>
  </Control>
);

export default SelectField;
