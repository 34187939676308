import { useMutation } from '@apollo/client';
import { navigate } from '@reach/router';
import {
  resetUserAuthState,
  userAuthState
} from 'apollo/reactive-variables/userAuthState';
import { useDefaultErrorHandler } from 'hooks/useDefaultErrorHandler';
import { useCallback } from 'react';
import { LogoutDocument, LogoutMutation } from 'types';
import { LocalStorage, LocalStorageKey } from 'utils/localStorage';

interface UseLogoutResponse {
  handleExpiredSessionLogout: () => void;
  handleLogout: () => void;
}

export const useLogout = (): UseLogoutResponse => {
  const { defaultErrorHandler } = useDefaultErrorHandler();
  const [logout] = useMutation<LogoutMutation>(LogoutDocument, {
    onError: defaultErrorHandler
  });

  const clearUserAuthStateAndRedirect = useCallback(() => {
    const { refreshTimeoutId } = userAuthState();
    if (refreshTimeoutId) {
      window.clearTimeout(refreshTimeoutId);
    }
    LocalStorage.set(LocalStorageKey.LogoutEvent, Date.now());
    resetUserAuthState();
    navigate('/login');
  }, []);

  const handleLogout = useCallback(async (): Promise<void> => {
    await logout();
    clearUserAuthStateAndRedirect();
  }, [logout, clearUserAuthStateAndRedirect]);

  const handleExpiredSessionLogout = useCallback((): void => {
    LocalStorage.set(LocalStorageKey.LastVisitedPage, {
      pathname: window.location.pathname
    });
    clearUserAuthStateAndRedirect();
  }, [clearUserAuthStateAndRedirect]);

  return { handleExpiredSessionLogout, handleLogout };
};
